<template>
	<div class="layout design">
		<div class="top fixed">
			<div class="title">
				<span>עיצוב אפליקציה</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue :tabs="{
			theme: { label: 'ערכת נושא', icon: 'receipt', groups: ['theme'] },
			colors: { label: 'צבעים', icon: 'receipt', groups: ['colors'] },
			header: { label: 'חלק עליון', icon: 'receipt', groups: ['header'] },
			footer: { label: 'חלק תחתון', icon: 'assessment', groups: ['footer'] }
		}">
			<form-group name="theme">
				<form-select name="style" label="ערכת נושא" v-model="theme.style" :options="[{value: 'tomy', label: 'טומי'}]" />
				<form-toggle name="round" label="פינות עגולות" v-model="theme.round" />
			</form-group>

			<form-group name="colors">
				<form-colors name="color" v-for="color in colors"
					:key="color.key" :label="color.key" v-model="color.value"></form-colors>
			</form-group>

			<form-group name="header">
				<form-area>
					<fs v-model="header" :colors="colors"></fs>
				</form-area>
			</form-group>

			<form-group name="footer">
				<form-area>
					<fs v-model="footer" :colors="colors"></fs>
				</form-area>
			</form-group>

		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			theme: {
				style: 'tomy',
				round: true
			},
			colors: [
				{key: 'primary', value: '#000000'},
				{key: 'secondary', value: '#000000'},
				{key: 'light', value: '#000000'},
				{key: 'text', value: '#000000'},
				{key: 'text-light', value: '#000000'}
			],
			header: [],
			footer: [],
		}
	},
	methods: {
		submit(){

		}
	}
}
</script>
