const Model = require('@/models/model');

class Cart extends Model{

	constructor(){
		super();
	}

	all(store, payload){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + JSON.stringify(payload);
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.post(store+'/inventory/carts', payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

}

module.exports = new Cart()
