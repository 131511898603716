<template>
	<div class="front-start">
		<slot name="header"></slot>

		<div class="button closer error" v-if="referenceMode" data-icon="closer" @click="chooseComponentReference(null)">סגור</div>

		<fs-pop ref="component" :components="availableComponents" :globals="globals" />

		<index class="layouts" :data="input" sort-by="id" @sort="setLayoutsSort">
			<div class="fs-layout" slot="index" slot-scope="item"
				:draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">

				<fs-editor :ref="`editor-${item.id}`" @close="activeEditor = false"></fs-editor>

				<div class="toolbar">
					<i class="material-icons sort" :class="{ disabled: activeEditor }"
						@mousedown="item.$sort.activate" @mouseup="item.$sort.end">more_vert</i>
					<i class="material-icons" @click="openEditor(item.id, item, ['layout', 'props'])">settings</i>
					<i class="material-icons left remove" @click="removeLayout(item.$index)" v-if="input.length > 1">delete_outline</i>
					<i class="material-icons left copy" @click="copyLayout(item)">copy_all</i>
					<i class="material-icons left mobile" v-if="item.props.mobile!==false">phone_iphone</i>
					<i class="material-icons left desktop" v-if="item.props.desktop!==false">desktop_mac</i>
					<div class="toolbar_title" v-if="item.props.title">{{ item.props.title }}</div>
				</div>

				<div class="containers" :class="`l-${item.props.layout.toString().split('').join('-')}`">
					<index class="container" v-for="(container, i) in Object.values(item.containers)" :key="i"
						:data="container.components"
						sort-by="id" @sort="setComponentSort(container)">

						<div class="toolbar" slot="header">
							<i class="material-icons" :class="{ disabled: activeEditor }" @click="openEditor(item.id, container, ['props'])">settings</i>

							<i class="material-icons left" :class="{disabled: (i == item.containers.length-1)}"
								@click="moveContainer(item.$index, i, 'left')" v-if="item.containers.length > 1">
									chevron_left
							</i>
							<i class="material-icons left" :class="{disabled: (i==0)}"
								@click="moveContainer(item.$index, i, 'right')" v-if="item.containers.length > 1">
									chevron_right
							</i>
						</div>

						<div class="component" :class="setClass(item.component)" slot="index" slot-scope="item" :data-type="item.component"
							:draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
							<div class="toolbar">
								<i class="material-icons sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end">more_vert</i>
								<i class="material-icons" @click.self="editComponent(item)">settings</i>
								<i class="material-icons left remove" @click.self="removeComponent(container, item.$index)">delete_outline</i>
								<i class="material-icons left mobile" v-if="item.data.settings && item.data.settings.mobile">phone_iphone</i>
								<i class="material-icons left desktop" v-if="item.data.settings && item.data.settings.desktop">desktop_mac</i>
							</div>

							<div class="name" @click.self="chooseComponentReference(item)">
								{{ item.component ? availableComponents[item.component].title : "חדש" }}
							</div>
						</div>

						<div class="add" slot="footer" @click.self="addComponent(container)">הוסף ווידג׳ט</div>
					</index>
				</div>
			</div>
			<div class="add paste" slot="footer" @click="pasteLayout">הדבק אזור</div>
			<div class="add" slot="footer" @click="addLayout">הוסף אזור חדש</div>
		</index>

		<slot name="footer"></slot>
	</div>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	components: {
		'fs-pop': require('./fs-pop').default,
		'fs-editor': require('./fs-editor').default,
		// fs components
		'logo': require('./components/logo').default,
		'search': require('./components/search').default,
		'menu-vue': require('./components/menu-vue').default,
		'closable-line': require('./components/closable-line').default,
		'header-actions': require('./components/header-actions').default,
		'dynamic-boxes': require('./components/dynamic-boxes').default,
		'banner': require('./components/banner').default,
		'faq': require('./components/faq').default,
		'main-title': require('./components/main-title').default,
		'text-component': require('./components/text-component').default,
		'product-data': require('./components/product-data').default,
		'filters': require('./components/filters').default,
		'gallery': require('./components/gallery').default,
		'products': require('./components/products').default,
		'blogs': require('./components/blogs').default,
		'contact': require('./components/contact').default,
		'newsletter': require('./components/newsletter').default,
		'sort': require('./components/sort').default,
		'menu-ref': require('./components/menu-ref').default,
		'filters-ref': require('./components/filters-ref').default,
		'sort-ref': require('./components/sort-ref').default,
		'button-vue': require('./components/button-vue').default,
		'reviews': require('./components/reviews').default,
		'video-vue': require('./components/video-vue').default,
		'instagram': require('./components/instagram').default,
		'reviews-verified': require('./components/reviews-verified').default,
		'bundles': require('./components/bundles').default,
	},
	props: {
		value: { type: Array, required: true },
		components: { type: Array, required: true },
		globals: { type: Array, default: () => [] }
	},
	data(){
		return {
			activeEditor: null,
			referenceMode: false
		}
	},
	mounted(){

		if(this.value.length) return;

		this.addLayout();
	},
	methods:{
		addLayout(){
			this.input.push({
				id: makeHash(10),
				props: {
					layout: 1,
					wrapper: false,
					sticky: false,
					valign: 'middle',
					background: '',
					color: '',
					class: '',
					padding: ['0', '0', '0', '0']
				},
				containers: [
					{
						id: makeHash(10),
						props: {
							sticky: false,
							background: '',
							color: '',
							class: '',
							valign: 'top',
							padding: ['0', '0', '0', '0']
						},
						components: []
					}
				]
			});
		},
		removeLayout(layout){
			VueAlerts.alert({
				title: "שים לב",
				text: "התוכן שימחק לא ניתן יהיה לשיחזור",
				buttons: ["בטל", "מחק"]
			}).then(action => {
				if(action == "מחק") this.input.splice(layout, 1);
			})
		},
		copyLayout(layout){
			navigator.clipboard.writeText(JSON.stringify({$layoutCopy: true, copy: layout})).then(() => {
				VueAlerts.flash({
					icon: 'done',
					text: 'הועתק בהצלחה'
				});
			}).catch(() => {});
		},
		pasteLayout(){
			navigator.clipboard.readText().then(copy => {
				let data = JSON.parse(copy);

				if(! (data.$layoutCopy && data.copy)) return;

				data.copy.id = makeHash(10);
				data.copy.containers = data.copy.containers.map(container => {
					container.id = makeHash(10);
					container.components = container.components.map(component => {
						component.id = makeHash(10);
						return component;
					});

					return container;
				});

				delete data.copy.$index;
				delete data.copy.$sort;

				this.input.push(data.copy);

			}).catch(() => {});
		},
		setLayoutsSort(){
			this.input.sort((a, b) => a.__sort - b.__sort);
		},
		moveContainer(layout, container, direction){
			let l = this.input[layout], c = JSON.stringify(l.containers[container]);

			if(
				(direction == 'right' && container == 0) ||
				(direction == 'left' && container >= l.containers.length-1)
			) return;

			l.containers.splice(container, 1);
			l.containers.splice((container+(direction == 'left' ? 1 : -1)), 0, JSON.parse(c));
		},
		openEditor(id, item, mode){
			this.activeEditor = true;
			this.$refs[`editor-${id}`].init({mode, item});
		},
		addComponent(container){
			let component = {
				id: makeHash(10)
			}

			this.$refs.component.init(component);

			container.components.push(component);
		},
		removeComponent(container, component){
			VueAlerts.alert({
				title: "שים לב",
				text: "התוכן שימחק לא ניתן יהיה לשיחזור",
				buttons: ["בטל", "מחק"]
			}).then(action => {
				if(action == "מחק") container.components.splice(component, 1);
			});
		},
		setComponentSort(container){
			container.components.sort((a, b) => a.__sort - b.__sort);
		},
		editComponent(component){
			this.$refs.component.init(component);
		},
		setClass(component){
			if(! (this.referenceMode && this.$refs.component && this.$refs.component.$refs.component)) return '';

			if(component == this.$refs.component.$refs.component.ref) return 'selectable';

			return '';
		},
		chooseComponentReference(item){
			if(! this.referenceMode) return;

			this.referenceMode(item ? {id: item.id, title: this.availableComponents[item.component].title, component: item.component} : null);
		},
		resetEditors(){
			let self = this;

			Object.keys(this.$refs)
				.forEach(key => {
					if(key.includes('editor-') && self.$refs[key]){
						self.$refs[key].active = false
					}
				});

			this.activeEditor = null;
		}
	},
	computed: {
		availableComponents(){
			return Object.fromEntries(
				Object.entries(this.$options.components).filter(
					component => (this.components.indexOf(component[0]) > -1)
				)
			)
		},
		files(){
			let files = {};

			this.input.forEach(layout =>
			layout.containers.forEach(container =>
			container.components.forEach(component => {
				if(! (
					this.availableComponents[component.component] &&
					this.availableComponents[component.component].methods &&
					this.availableComponents[component.component].methods.bindFiles
				)) return;

				Object.assign(files, this.availableComponents[component.component].methods.bindFiles(component.data));
			})));

			return files;
		},
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
