const Model = require('@/models/model');

class Service extends Model{

	constructor(){
		super();
	}

	index(store, payload){
		if(! store) return reject(new Error("Missing `store`"));

		return this.$api.post(`dashboard/store/${store}/services`, payload)
	}

	update(store, payload) {
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));
			if (!payload) return reject(new Error("Missing `payload`"));

			this.$api.post('dashboard/store/' + store + '/services/update', payload)
				.then(response => resolve(response))
				.catch(error => reject(error))
		});
	}

}

module.exports = new Service()
