const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Stores extends Model{

	constructor(){
		super();
	}

	model() {
		return {
			store: ""
		}
	}

	all(){
		return new Promise((resolve, reject) => {
			this.$api.get('master/stores').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(id){
		return new Promise((resolve, reject) => {
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('master/stores/'+id).then(response => {
				let result = { response };

				let pattern = {
					_id: String,
					active: Boolean,
					dictionary: String,
					store: String,
					prices: {
						monthly: String,
						create: String
					},
					hostingDate: String,
					signingDate: String,
					tokens:[{
						source: String,
						domain: String
					}],
					theme: {
						settings: {
							sitename: String,
							whatsapp: String,
							round: Boolean,
							logo: String,
							favicon: String
						}
					}
				};

				result.data = Cast(response.data, pattern);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	create(payload){
		return new Promise((resolve, reject) => {
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				name: String,
				phone: String,
				store: String,
				storename: String,
				example: String
			});

			this.$api.post('master/stores/create/', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(payload, id){
		return new Promise((resolve, reject) => {
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				storename: String,
				domain: String,
				prices: {
					monthly: Number,
					create: Number
				},
				hostingDate: String,
				signingDate: String
			});

			this.$api.post('master/stores/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Stores()
