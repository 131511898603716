<template>
	<div class="form-field select" :class="{ active }" tabindex="0" @focusout="focusout">
		<input v-if="filter" name="search" type="text" ref="filter" v-model="filterText"/>
		<label @click="toggle">{{ label }}</label>
		<div class="options">
			<div v-for="option in filtered" @click="select(option)" :class="{ active: (input === option.value)}" :key="option.value">
				<i v-if="option.icon && option.icon[0] == '#'" :style="`background: ${option.icon}`"></i>
				<i v-else-if="option.icon && option.icon.includes('http')" :style="`background-image: url('${option.icon}')`"></i>
				<i v-else-if="option.icon" :data-icon="option.icon" class="material-icons"></i>
				<span>{{ option.label }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: String, required: true },
		options: { type: Array, required: true },
		filter: { type: Boolean, default: false},
	},
	data(){
		return {
			active: false,
			filterText: ''
		}
	},
	watch: {
		active(){
			if(this.active && this.$refs.filter) return this.$nextTick(() => this.$refs.filter.focus());

			this.filterText = '';
		}
	},
	methods: {
		toggle(){
			this.active = !this.active;
		},
		select(option){
			this.input = option.value;

			this.active = false;

			this.$emit('change', option.value);
		},
		focusout(){
			this.$nextTick(() => setTimeout(() => (this.active = (document.activeElement == this.$refs.filter) || false), 300));
		}
	},
	computed: {
		filtered(){
			if(! this.filterText) return this.options;

			return this.options.filter(filter =>
				(filter.label.toString().toLowerCase().search(this.filterText.toString().toLowerCase()) >= 0)
			);
		},
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
