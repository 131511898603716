<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'אזור אישי / עגלה / אהבתי', icon: 'receipt', groups: ['general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">

		<form-group style="z-index:1" name="general">
			<index :data="actions" sort-by="type" @sort="sortElement">
				<template slot="index" slot-scope="item" >
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" ></div>
						<div class="info">{{ item.title }}</div>
						<form-toggle name="active" label="" v-model="activeActions[item.type]" @toggle="toggleAction(item.type)" />
					</div>
				</template>
			</index>
		</form-group>

		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "כפתורי מערכת",
	defaults: {
		actions: [],
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	methods: {
		sortElement(sort){
			this.input.actions.sort((a, b) => sort.indexOf(a) - sort.indexOf(b));
		},
		toggleAction(type){
			let i = this.input.actions.indexOf(type);
			if(i > -1) return this.input.actions.splice(i, 1);

			this.input.actions.push(type);

			this.sortElement(this.actions.sort((a, b) => a.__sort - b.__sort).map(c => c.type));
		}
	},
	computed: {
		actions(){
			let actions = [
				{ title: "אזור אישי", type: 'user'},
				{ title: "עגלת קניות", type: 'cart'},
				{ title: "מועדפים", type: 'favorite'},
				{ title: "חיפוש", type: 'search'},
				{ title: "שיתוף", type: 'share'},
			];

			return actions.sort((a,b) => {
				let aPos = this.input.actions.indexOf(a.type),
					bPos = this.input.actions.indexOf(b.type);

				if(aPos < 0) return 1;
				if(bPos < 0) return -1;

				return aPos - bPos;

			})
		},
		activeActions: {
			get(){
				let actives = {};
				this.actions.forEach(action => {
					actives[action.type] = (this.input.actions.indexOf(action.type) > -1)
				})
				return actives;
			},
			set(value){
				return;
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
