const WebSocketManager = {
	pong(){
		this.send(".");
	},
	handler(handler, message){
		if(message.data == ".") return WebSocketManager.pong.bind(this)();

		handler(JSON.parse(message.data));
	},
	install(Vue){
		Vue.prototype.$ws = (channel, handler) => {
			let socket = new WebSocket(`${process.env.MIX_KIPI_WS}${channel}`, [localStorage.token, 'dashboard']);

			socket.onmessage = WebSocketManager.handler.bind(socket, handler);
		}
	}
}

export default WebSocketManager;
