<template>
	<div class="query-filter categories" :class="{ active, loading }">
		<div class="header">
			<span data-icon="category">סנן לפי קטגוריות</span>
			<form-toggle name="" label="" v-model="active" />
		</div>
		<div class="box" v-if="active">
			<form-checkbox name="filter" label="בחר קטגוריות:" v-model="input" :options="categories" />
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			cache: null,
			loading: true,
			categories: [],
		}
	},
	watch: {
		active(){
			this.loadCategories();
		}
	},
	mounted(){
		if(this.active) this.loadCategories();
	},
	methods: {
		loadCategories(){
			this.$models.inventory.category.options(this.$route.params.store)
			.then(res => {
				this.categories = res.data;
				this.loading = false;
			});
		}
	},
	computed: {
		active:{
			get(){
				return Boolean(this.input != undefined);
			},
			set(val){
				if(val){
					return this.input = this.cache || []
				}

				this.cache = this.input;
				this.input = undefined
			}
		},
		input: {
			get() {
				return this.$parent.query.categories;
			},
			set(val) {
				this.$set(this.$parent.query, 'categories', val);
			}
		}
	}
}
</script>
