<template>
	<component :is="wrapper" class="images-preview" v-if="input.length && input[0].source">
		<index :data="input" @sort="sortElement" sort-by="source">
			<template slot="index" slot-scope="item">
				<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
					<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end"></div>
					<div class="image" :style="`background-image: url('${item.source}')`"></div>
					<div class="info">{{ item.title }}</div>
					<div class="actions">
						<div class="button error" data-icon="delete" @click="removePicture(item.$index)"></div>
						<div class="button"
							v-if="form.length"
							:data-icon="currentPictureEdit == item.$index ? 'close' : 'create'"
							@click="editPicture(item.$index)"></div>
					</div>
				</div>
				<form-vue v-if="form && currentPictureEdit == item.$index" style="z-index: 2" :dynamic="form" v-model="input[item.$index]" />
			</template>
		</index>
	</component>
</template>
<script>
export default {
	props: {
		wrapper: {type: String, default: 'div'},
		value: {type: Array, required: true},
		form: {type: Array, default: () => []}
	},
	data(){
		return {
			currentPictureEdit: null
		}
	},
	watch: {
		input:{
			handler(newVal, oldVal){
				if(! newVal.length) return;
				if(newVal.length == oldVal.length && ! newVal[0].name) return;

				this.input = newVal.map(file => {
					if(! (file instanceof File)) return file;

					let fileObj = { file, source: URL.createObjectURL(file) };
					this.form.forEach(form => {
						Object.keys(form).forEach(field => fileObj[field] = form[field].default);
					});
					return fileObj;
				});
			},
			deep: true
		}
	},
	methods: {
		sortElement(sort){
			this.input.sort((a, b) => a.__sort - b.__sort);
		},
		editPicture(index){
			if(this.currentPictureEdit == index) return this.currentPictureEdit = null;

			this.currentPictureEdit = index
		},
		removePicture(index){
			this.$emit('delete', index);

			this.input.splice(index, 1);
		}
	},
	computed: {
		input: {
			get(){
				return this.value;
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
