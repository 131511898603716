<template>
	<div class="layout forms settings">

		<div class="title">הגדרות החנות</div>
		<div class="tabs">
			<div data-icon="account_circle" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטים כללים</div>

			<div data-icon="storefront" :class="{active: state.tab == 'store'}"
				@click="$router.push({query: {tab: 'store'}})">הגדרות חנות</div>

			<div data-icon="receipt" :class="{active: state.tab == 'services'}"
				@click="$router.push({query: {tab: 'services'}})">שירותים</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="submit">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטים כללים</span>
					</div>
					<div class="content">
						<form-input name="name" label="שם" v-model="model.name" />
						<form-input name="address" label="כתובת" v-model="model.address"/>
						<form-input name="phone" label="טלפון" v-model="model.phone"/>
						<form-input name="email" label="אימייל" v-model="model.email"/>
					</div>
				</div>
			</div>
			<div class="container store" key="store" :class="{active: (state.tab == 'store')}" v-if="state.tab == 'store'">
				<div class="data store" animated>
					<div class="options">
						<span data-icon="description">הגדרות חנות</span>
					</div>
					<div class="content">
						<form-toggle label="ללא הזמנות" name="disable_orders" v-model="model.checkout_settings.disable_orders" />

						<form-input name="min_price" label="מינימום מחיר להזמנה" v-model="model.checkout_settings.min_price" />
						<form-input name="max_price" label="מקסימום מחיר להזמנה" v-model="model.checkout_settings.max_price"/>
					</div>
				</div>
			</div>
			<div class="container services" key="services" :class="{active: (state.tab == 'services')}" v-if="state.tab == 'services'">
				<template v-if="Object.keys(services).length">
				<div class="data replaces" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="cached"
								:class="{active: services.replaces.active}" @click.self="services.replaces.active = !services.replaces.active">
								<span class="title">{{services.replaces.name}}</span>
								<div class="description">{{services.replaces.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.replaces.active" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="services.replaces.active">
							<form-numbers label="מקסימום ימים להחלפה" name="replace" v-model="services.replaces.data.replace" v-if="services.replaces.active" />
							<form-numbers label="מקסימום ימים להחזרה" name="collect" v-model="services.replaces.data.collect" v-if="services.replaces.active" />
							<form-numbers label="מקסימום ימים להחזרת מוצר פגום" name="defect" v-model="services.replaces.data.defect" v-if="services.replaces.active" />
						</div>
					</div>
				</div>
				<div class="data tapuz" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="local_shipping"
								:class="{active: services.tapuz.active}" @click.self="services.tapuz.active = !services.tapuz.active">
								<span class="title">{{services.tapuz.name}}</span>
								<div class="description">{{services.tapuz.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.tapuz.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.tapuz.active">
							<form-input label="משתמש תפוז" name="user" v-model="services.tapuz.data.user" />
							<form-input label="שם משתמש" name="username" v-model="services.tapuz.data.username" />
							<form-input type="password" label="סיסמה" name="password" v-model="services.tapuz.data.password" />
						</div>
						<div class="form-group two" v-if="services.tapuz.active">
							<form-input label="שם נמען" name="name" v-model="services.tapuz.data.address.name" />
							<form-input label="עיר מוצא" name="city" v-model="services.tapuz.data.address.city" />
							<form-input label="רחוב מוצא" name="street" v-model="services.tapuz.data.address.street" />
							<form-input label="מס׳ בית מוצא" name="house" v-model="services.tapuz.data.address.house" />
						</div>
					</div>
				</div>
				<div class="data tamnon" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="local_shipping"
								:class="{active: services.tamnon.active}" @click.self="services.tamnon.active = !services.tamnon.active">
								<span class="title">{{services.tamnon.name}}</span>
								<div class="description">{{services.tamnon.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.tamnon.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.tamnon.active">
							<form-input label="משתמש תמנון" name="user" v-model="services.tamnon.data.user" />
							<form-input label="שם משתמש" name="username" v-model="services.tamnon.data.username" />
							<form-input type="password" label="סיסמה" name="password" v-model="services.tamnon.data.password" />
						</div>
						<div class="form-group two" v-if="services.tamnon.active">
							<form-input label="שם נמען" name="name" v-model="services.tamnon.data.address.name" />
							<form-input label="עיר מוצא" name="city" v-model="services.tamnon.data.address.city" />
							<form-input label="רחוב מוצא" name="street" v-model="services.tamnon.data.address.street" />
							<form-input label="מס׳ בית מוצא" name="house" v-model="services.tamnon.data.address.house" />
						</div>
					</div>
				</div>
				<div class="data ydm" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="local_shipping"
								:class="{active: services.ydm.active}" @click.self="services.ydm.active = !services.ydm.active">
								<span class="title">{{services.ydm.name}}</span>
								<div class="description">{{services.ydm.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.ydm.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.ydm.active">
							<form-input label="משתמש" name="user" v-model="services.ydm.data.user" />
							<form-input label="שם נמען" name="url" v-model="services.ydm.data.name" />
							<form-input label="כתובת" name="url" v-model="services.ydm.data.url" />
						</div>
					</div>
				</div>

				<div class="data icredit" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.icredit.active}" @click.self="services.icredit.active = !services.icredit.active">
								<span class="title">{{services.icredit.name}}</span>
								<div class="description">{{services.icredit.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.icredit.active" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="services.icredit.active">
							<form-input label="תוקן" name="token" v-model="services.icredit.data.token" />
						</div>
					</div>
				</div>

				<div class="data easycard" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.easycard.active}" @click.self="services.easycard.active = !services.easycard.active">
								<span class="title">{{services.easycard.name}}</span>
								<div class="description">{{services.easycard.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.easycard.active" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="services.easycard.active">
							<form-input label="תוקן" name="token" v-model="services.easycard.data.token" />
						</div>
					</div>
				</div>

				<div class="data meshulam" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.meshulam.active}" @click.self="services.meshulam.active = !services.meshulam.active">
								<span class="title">{{services.meshulam.name}}</span>
								<div class="description">{{services.meshulam.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.meshulam.active" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="services.meshulam.active">
							<form-input label="משתמש" name="userId" v-model="services.meshulam.data.userId" />
							<form-input label="קוד עמוד" name="pageCode" v-model="services.meshulam.data.pageCode" />
						</div>
					</div>
				</div>

				<div class="data cash" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.cash.active}" @click.self="services.cash.active = !services.cash.active">
								<span class="title">{{services.cash.name}}</span>
								<div class="description">{{services.cash.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.cash.active" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data phone" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.phone.active}" @click.self="services.phone.active = !services.phone.active">
								<span class="title">{{services.phone.name}}</span>
								<div class="description">{{services.phone.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.phone.active" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data credit" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.credit.active}" @click.self="services.credit.active = !services.credit.active">
								<span class="title">{{services.credit.name}}</span>
								<div class="description">{{services.credit.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.credit.active" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data maskyoo" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="notifications"
								:class="{active: services.maskyoo.active}" @click.self="services.maskyoo.active = !services.maskyoo.active">
								<span class="title">{{services.maskyoo.name}}</span>
								<div class="description">{{services.maskyoo.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.maskyoo.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.maskyoo.active">
							<form-input label="שם משתמש" name="username" v-model="services.maskyoo.data.username" />
							<form-input type="password" label="סיסמה" name="password" v-model="services.maskyoo.data.password" />
							<form-input label="שם שליחת sms" name="sender" v-model="services.maskyoo.data.sender" />
						</div>
						<div class="form-group two" v-if="services.maskyoo.active">
							<form-toggle label="סמס דיפולט" name="smsDefault" v-model="services.maskyoo.settings.smsDefault" />
							<form-toggle label="אימייל דיפולט" name="emailDefault" v-model="services.maskyoo.settings.emailDefault" />
							<form-area />
						</div>
					</div>
				</div>

				<div class="data activetrail" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="notifications"
								:class="{active: services.activetrail.active}" @click.self="services.activetrail.active = !services.activetrail.active">
								<span class="title">{{services.activetrail.name}}</span>
								<div class="description">{{services.activetrail.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.activetrail.active" />
								</div>
							</div>
						</div>
						<div class="form-group two" v-if="services.activetrail.active">
							<form-input label="תוקן" name="token" v-model="services.activetrail.data.token" />
							<form-input label="שם שליחת sms" name="sender" v-model="services.activetrail.data.sender" />
						</div>
						<div class="form-group two" v-if="services.activetrail.active">
							<form-toggle label="סמס דיפולט" name="smsDefault" v-model="services.activetrail.settings.smsDefault" />
							<form-toggle label="אימייל דיפולט" name="emailDefault" v-model="services.activetrail.settings.emailDefault" />
							<form-area />
						</div>
					</div>
				</div>

				<div class="data greeninvoice" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.greeninvoice.active}" @click.self="services.greeninvoice.active = !services.greeninvoice.active">
								<span class="title">{{services.greeninvoice.name}}</span>
								<div class="description">{{services.greeninvoice.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.greeninvoice.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.greeninvoice.active">
							<form-input label="מפתח" name="key" v-model="services.greeninvoice.data.key" />
							<form-input label="מפתח סודי" name="secret" v-model="services.greeninvoice.data.secret" />
							<form-input label="פלאגין" name="plugin" v-model="services.greeninvoice.data.plugin" />
						</div>
						<div class="form-group two" v-if="services.greeninvoice.active">
							<form-toggle label="בדיקות" name="smsDefault" v-model="services.greeninvoice.data.test" />
							<form-area />
						</div>
					</div>
				</div>

				<div class="data maxyaad" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="paid"
								:class="{active: services.maxyaad.active}" @click.self="services.maxyaad.active = !services.maxyaad.active">
								<span class="title">{{services.maxyaad.name}}</span>
								<div class="description">{{services.maxyaad.description}}</div>
								<div class="form">
									<form-toggle label="" name="replace_toggle" v-model="services.maxyaad.active" />
								</div>
							</div>
						</div>
						<div class="form-group tree" v-if="services.maxyaad.active">
							<form-input label="מפתח" name="key" v-model="services.maxyaad.data.key" />
							<form-input label="מפתח סודי" name="pass" v-model="services.maxyaad.data.pass" />
							<form-input label="טרמינל" name="terminal" v-model="services.maxyaad.data.terminal" />
						</div>
					</div>
				</div>
				</template>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			model:{
				name: '',
				phone: '',
				address: '',
				email: '',
				checkout_settings:{
					disable_orders: false,
					min_price: '',
					max_price: ''
				}
			},
			state: {
				tab: '',
				paramsActiveIndex: null
			},
			services: {}
		}
	},
	watch: {
		'$route.query.tab': 'setTab'
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([this.getData(), this.getServices()]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details')

		}).catch(error => {
			VueAlerts.flash({
				text: 'אירע שגיאה',
				closeable: false
			});
		});

	},
	methods:{
		setTab(tab){
			if(['details', 'services', 'store'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			return this.$models.dashboard.store.get(this.$route.params.store)
			.then(result => {
				Object.assign(this.model,result.data);

				this.loading = false;
			}).catch(error => {
				this.loading = false;
			});
		},
		getServices(){
			return this.$models.dashboard.service.index(this.$route.params.store)
			.then(result => {
				this.services = result.data;
			})
		},
		submit(){
			this.$root.loading = true;

			this.$models.dashboard.store.update(this.$route.params.store, this.model)
			.then((result) => {
				return this.$models.dashboard.service.update(this.$route.params.store, this.services)
			})
			.then((result) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$root.loading = false;
			})
			.catch((response) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$root.loading = false;
			});
		}
	}
}
</script>
