<template>
	<div class="form">
		<div class="tabs" v-if="tabs">
			<div v-for="tab in Object.keys(tabs)"
				class="tab" :class="{ active: (tab == activeTab) }"
				@click="setTab(tab)">
				<i class="material-icons-round" v-if="tabs[tab].icon">{{ tabs[tab].icon }}</i>
				<span>{{ tabs[tab].label }}</span>
			</div>
		</div>
		<slot></slot>
		<component v-for="(group, i) in dynamic" :key="i" is="form-group" v-if="dynamic" >

			<template v-for="(field, key) in group">
				<template v-if="field.type == 'dynamic'">
					<form-vue v-for="(subModel, subIndex) in input[key]" :key="subIndex"
						:dynamic="field.form" v-model="input[key][subIndex]"  :class="`group-${key}`" :style="`z-index:${(input[key].length - subIndex)}`">
						<form-group>
							<form-area><div class="button error" data-icon="close" @click="removeDynamic(key, subIndex)"></div></form-area>
						</form-group>
					</form-vue>
				</template>

				<div v-else-if="field.type == 'button'" class="button" :data-icon="field.icon || 'add'" @click="call(field.action, field)" style="z-index: 0">{{ field.label }}</div>

				<component v-else :name="key" :key="key" :is="field.type" v-bind="field"
					v-model="input[key[0] != '$' ? key : field.ref]"
				/>
			</template>
		</component>
	</div>
</template>

<script>
export default {
	props: {
		tabs: { type: Object },
		dynamic: { type: Array },
		preventHash: { type: Boolean, default: false },
		value: { type: Object }
	},
	data(){
		return {
			activeTab: null
		}
	},
	watch: {
		tabs: 'filterTabChilds',
		activeTab(){
			if(! this.tabs[this.activeTab]) return;

			this.filterTabChilds();
		},
		'$route.hash'(){
			if(! this.tabs || this.preventHash) return;

			let hash = this.$route.hash ? this.$route.hash.replace('#', '') : null;
			this.activeTab = (this.tabs[hash] ? hash : Object.keys(this.tabs)[0]);
		}
	},
	mounted(){
		if(this.tabs){
			let hash = this.$route.hash ? this.$route.hash.replace('#', '') : null;
			this.activeTab = (this.tabs[hash] ? hash : Object.keys(this.tabs)[0]);
		}
	},
	methods: {
		setTab(tab){
			if(! this.tabs) return;

			if(this.preventHash){
				return this.activeTab = tab;
			}

			this.$router.push({ hash: '#'+tab }).catch(() => {});
		},
		filterTabChilds(){
			this.$children.forEach(child => {
				child[(this.tabs[this.activeTab].groups.indexOf(child.name) > -1 ? 'show' : 'hide')]();
			});
		},
		addDynamic(button){
			if(button.limit && (this.input[button.ref].length >= button.limit)) return;

			this.input[button.ref].push(JSON.parse(JSON.stringify(button.default)));
		},
		removeDynamic(key, index){
			this.input[key].splice(index, 1);
		},
		call(action, field){
			this[action](field)
		}
	},
	computed: {
		input: {
			get(){
				return this.value;
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
