<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'קוביות דינאמיות', icon: 'receipt', groups: ['general'] },
		'visual': { label: 'תצוגה', icon: 'design_services', groups: ['layout'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['colors', 'settings', 'height', 'spacing'] }
		}" class="dynamic">

		<form-group style="z-index:1" name="general">
			<index :data="input.boxes" sort-by="title" @sort="sortElement">
				<template slot="index" slot-scope="item">
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end"></div>
						<div class="info">{{ input.boxes[item.$index].title }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableBox != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" v-if="input.boxes.length > 1" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group :style="`z-index:${item.$index}`" :key="item.$index" class="item" v-if="editableBox == item.$index">
						<form-area style="vertical-align:top;z-index:2">
							<form-group style="z-index:5">
								<form-input name="title" label="כותרת" v-model="input.boxes[item.$index].title" />
								<form-input name="author" label="כותב החוות דעת" v-model="input.boxes[item.$index].author" />
								<form-datepicker name="date" label="תאריך" v-model="input.boxes[item.$index].date"
									:disabled-dates="{  }"
								/>
							</form-group>
							<form-group style="z-index:1;overflow: hidden;">
				 				<form-editor name="text" label="טקסט" v-model="input.boxes[item.$index].content" />
							</form-group>
							<form-group style="z-index:3">
								<form-toggle name="link" label="הוסף קישור" v-model="links[item.$index]" @toggle="toggleLink(item.$index)" />
				 				<link-vue v-model="input.boxes[item.$index].link" v-if="links[item.$index]" wrapper="form-group" />
							</form-group>
						</form-area>
						<form-area style="vertical-align:top;z-index:1">
							<form-group style="z-index:6">
								<form-file name="image" label="בחר תמונה" v-model="images[item.$index]" @change="setImage(item.$index)" />
							</form-group>
							<form-group style="z-index:5">
								<images-preview v-model="images[item.$index]" @delete="removeImage(item.$index)" :form="[{
									title: { type: 'form-input', label: 'כותרת', default: '' }
								}]" />
							</form-group>
						</form-area>
					</form-group>
				</template>
			</index>
			<div class="buttons">
				<div class="button add" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
			</div>
		</form-group>

		<form-group style="z-index:1" name="layout">
			<form-area style="z-index:4;vertical-align: top;" name="visual_settings">
				<form-group>
					<form-toggle name="visual_gallery" v-model="toggleVisualGallery" label="הצג כגלריה" />
				</form-group>
				<form-group style="z-index:3" name="gallery" v-if="input.visual.gallery">
					<form-toggle name="arrows" label="חצים" v-model="input.visual.gallery.arrows"  />
					<form-toggle name="dots" label="נקודות" v-model="input.visual.gallery.dots"  />
					<form-select name="interval" label="זמן החלפת תמונות" v-model="input.visual.gallery.interval" :options="[
							{ value: '0', label: 'ללא' },
							{ value: '1', label: 'מהיר' },
							{ value: '3', label: 'בינוני' },
							{ value: '6', label: 'איטי' },
						]"/>
				</form-group>
			</form-area>
			<form-area class="layout-select">
				<div class="layout-card" v-for="l in layouts" @click="chooseLayout(l.name)"
					:class="{active: layout == l.name}" style="width:240px;height:140px;">
					<i v-for="i in l.layout" :style="i"></i>
					<div class="label">{{ l.label }}</div>
				</div>
			</form-area>
		</form-group>
		<form-group name="settings">
			<form-area style="z-index: 1;vertical-align: top;">
				<form-group style="z-index:4">
					<form-group style="z-index:4">
						<form-toggle name="background" label="צבע פונט" v-model="fontColor"/>
						<form-colors v-if="fontColor"  name="color" label="צבע פונט" v-model="input.settings.color" />
					</form-group>
					<form-group style="z-index:3">
						<form-toggle name="background" label="צבע רקע" v-model="background"/>
					</form-group>
					<form-group v-if="background" style="z-index:2">
						<form-colors name="color" label="צבע רקע" v-model="input.settings.background.colors[0].color"></form-colors>
						<form-select name="opacity" label="שקיפות" v-model="input.settings.background.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
						<template v-if="input.settings.background.type != 'full'">
							<form-colors name="color" label="צבע רקע" v-model="input.settings.background.colors[1].color"></form-colors>

							<form-select  name="opacity" label="שקיפות" v-model="input.settings.background.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
						</template>
					</form-group>
					<form-group v-if="background" style="z-index:1">
						<form-area class="layout-select">
							<div class="layout-card"
							@click="toggleBackgroundType('full')"
							:class="{active: input.settings.background.type == 'full'}"
							style="width:140px;height:100px;">
								<i :style="`background-color:${input.settings.background.colors[0].color};opacity:${input.settings.background.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
							</div>
							<div class="layout-card"
							@click="toggleBackgroundType('top')"
							:class="{active: input.settings.background.type == 'top'}"
							style="width:140px;height:100px;">
								<i :style="`background:${linearGradient('to top', input.settings.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
							</div>
							<div class="layout-card"
							@click="toggleBackgroundType('rtl')"
							:class="{active: input.settings.background.type == 'rtl'}"
							style="width:140px;height:100px;">
								<i :style="`background:${linearGradient('to right', input.settings.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
							</div>
						</form-area>
					</form-group>
				</form-group>
			</form-area>
			<form-area style="z-index: 2;vertical-align: top;">
				<form-group style="z-index:3" >
					<form-toggle name="height-toggle" label="גובה דיפולט" v-model="height" />
					<form-numbers name="height" label="גובה" v-if="! height" v-model="input.settings.height" clear point />
				</form-group>
				<form-group style="z-index:2">
					<form-toggle name="spacing-toggle" label="ריווח דיפולט" v-model="spacing" />
					<form-numbers name="spacing" label="ריווח" v-if="! spacing" v-model="input.settings.spacing" clear point />
				</form-group>
				<form-group style="z-index:1">
					<form-select name="rowCount" v-model="input.settings.rowCount" label="כמות מקסימלית לתצוגה בשורה"
						:options="[{label: '1 בשורה', value: '1'}, {label: '2 בשורה', value: '2'},{label: '3 בשורה', value: '3'}, {label: '4 בשורה', value: '4'}]"
					/>
					<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
					<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
					<form-area></form-area>
				</form-group>
			</form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { makeHash, hexToRgb } from '@/bootstrap/helpers';

export default {
	title: "חוות דעת",
	defaults: {
		boxes: [{
			title: '',
			author: '',
			content: '',
			date: new Date()
		}],
		visual: {
			layout: 'default',
			title: true
		},
		settings: {
			rowCount: '2',
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return {
			editableBox: null
		}
	},
	mounted(){
		if(this.input.boxes.length == 1 && ! this.input.boxes[0].image) this.editableBox = 0;

		this.input.boxes.forEach((item, index) => {
			this.$set(this.input.boxes[index], 'date', (item.date ? new Date(item.date) : new Date()));
		});
	},
	methods: {
		addDynamic(){
			this.input.boxes.push({title: '', content: '', author: '', date: new Date()});

			this.editableBox = (this.input.boxes.length - 1 );
		},
		removeDynamic(index){
			if(this.editableBox == index) this.editableBox = null;

			this.input.boxes.splice(index, 1);
		},
		setImage(key){
			this.$set(this.input.boxes[key], 'image', {
				file: this.images[key][0],
				source: URL.createObjectURL(this.images[key][0]),
				title: ''
			});
		},
		removeImage(key){
			this.$delete(this.input.boxes[key], 'image');
		},
		bindFiles(data){
			let files = {};

			data.boxes.forEach(box => {
				if(! (box.image && box.image.file)) return;

				let key = makeHash();

				files[key] = box.image.file;

				box.image._file = key;
			});

			return files;
		},
		sortElement(sort){
			this.input.boxes.sort((a, b) => a.__sort - b.__sort);
		},
		toggleBox(key){
			if(key == this.editableBox) return this.editableBox = null;

			this.editableBox = key;
		},
		toggleLink(key){
			if(! this.links[key]) return this.$delete(this.input.boxes[key], 'link');

			this.$set(this.input.boxes[key], 'link', {type: 'home'});
		},
		toggleBackgroundType(type){
			let colors = this.input.settings.background.colors;

			this.$set(this.input.settings.background, 'type', type);

			if(type == 'full') return this.$set(this.input.settings.background, 'colors', [colors[0]]);

			if(colors.length == 1) colors.push({color: '#000000', opacity: '100'});

			this.$set(this.input.settings.background, 'colors', colors);
		},
		linearGradient(type, options){
			let color_a = hexToRgb(options[0].color),
				color_b = hexToRgb(options[1] ? options[1].color : '#ffffff');

			return `linear-gradient(${type}, rgba(${color_a.r}, ${color_a.g}, ${color_a.b}, ${options[0].opacity/100}),  rgba(${color_b.r}, ${color_b.g}, ${color_b.b}, ${options[1] ? options[1].opacity/100 : '0'}))`
		},
		chooseLayout(name){
			switch(name){
				case "team":
					this.$set(this.input, 'visual', {layout: name, title: true, image: true, text: true});
					break;
				default:
					this.$set(this.input, 'visual', {layout: name, title: true});
					break;
			}
		},
		buildDate(date){
			return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
		}
	},
	computed: {
		images(){
			return this.input.boxes.map(item => item.image ? [item.image] : []);
		},
		links(){
			return this.input.boxes.map(item => item.link != undefined);
		},
		content(){
			return this.input.boxes.map(item => item.content != undefined);
		},
		background: {
			get(){
				return (this.input.settings && this.input.settings.background != undefined);
			},
			set(value){
				if(value) return this.$set(this.input.settings, 'background', {type: 'full', colors: [{color: '#eeeeee', opacity: '100'}]});

				this.$delete(this.input.settings, 'background');
			}
		},
		fontColor: {
			get(){
				return (this.input.settings && this.input.settings.color != undefined);
			},
			set(value){
				if(value) return this.$set(this.input.settings, 'color', '#000000');

				this.$delete(this.input.settings, 'color');
			}
		},
		toggleVisualGallery: {
			get(){
				return (this.input.visual && this.input.visual.gallery != undefined);
			},
			set(value){
				if(!value) return this.$delete(this.input.visual, 'gallery');

				this.$set(this.input.visual, 'gallery', {arrows: true, dots: true, interval: '3'});
			}
		},
		height: {
			get(){
				return this.input.settings.height == undefined;
			},
			set(value){
				if(! value) return this.$set(this.input.settings, 'height', '');

				this.$delete(this.input.settings, 'height');
			}
		},
		spacing: {
			get(){
				return this.input.settings.spacing == undefined;
			},
			set(value){
				if(! value) return this.$set(this.input.settings, 'spacing', '');

				this.$delete(this.input.settings, 'spacing');
			}
		},
		layout:{
			get(){
				return this.input.visual.layout;
			},
			set(value){
				let layout = { layout: value}

				this.$set(this.input, 'visual', layout);
			}
		},
		layouts(){
			return [
				{
					name: 'default',
					label: 'דיפולט',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '140px', width: '230px',
							top: '0', left: '0', right: '0', bottom: '0', margin: 'auto',
							zIndex: 1, opacity: (this.input.visual.image ? 0.2 : 0)
						},
						{
							height: '6px', width: '50px',
							top: '10px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '140px',
							top: '30px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '140px',
							top: '40px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '140px',
							top: '50px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '140px',
							top: '60px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '140px',
							top: '70px', left: 'auto', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '4px', width: '50px',
							top: '100px', left: '10px', right: 'auto',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},

					]
				}
			]
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
