<template>
	<div class="layout forms category" >

		<div class="back" @click="$router.push({ name: 'inventory', params:{store: $route.params.store}})"></div>
		<div class="title">{{$route.params.category ? 'עריכת' : 'הוספת'}} קטגוריה</div>
		<div class="tabs">
			<div data-icon="receipt" :class="{active: state.tab == 'general'}"
				@click="$router.push({query: {tab: 'general'}})">פרטים כללים</div>
			<div data-icon="collections" :class="{active: state.tab == 'media'}"
				@click="$router.push({query: {tab: 'media'}})">מדיה</div>
			<div data-icon="phonelink" :class="{active: state.tab == 'seo'}"
				@click="$router.push({query: {tab: 'seo'}})">נראות</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container general" key="general" :class="{active: (state.tab == 'general')}" v-if="state.tab == 'general'">

				<div class="data details" animated>
					<div class="options">
						<span data-icon="receipt">פרטי קטגוריה</span>
					</div>
					<div class="content">
						<form-input name="name" label="כותרת" v-model="model.name" />
						<form-select name="parent" :filter="true" label="קטגוריית אב" v-model="model.parent" v-if="categories.length" :options="parents"/>
					</div>
				</div>

				<div class="data description" animated>
					<div class="options">
						<span data-icon="format_align_right">תיאור</span>
					</div>
					<div class="content">
						<form-editor name="description" label="תיאור מלא" v-model="model.description" />
					</div>
				</div>

			</div>

			<div class="container media" key="media" :class="{active: (state.tab == 'media')}" v-if="state.tab == 'media'">
				<div class="data media" animated>
					<index class="data uploader" :data="images" sort-by="source" @sort="setImagesSort">
						<template slot="header">
							<div animated>
								<form-file name="images" v-model="images" accept="image/*" title="הוספת תמונות" description="אפשר לגרור תמונות לכאן" multiple />
							</div>
						</template>

						<div slot="index" slot-scope="image" :key="image.$index" :style="`z-index: ${images.length - image.$index}`" animated>
							<div class="image-card" :draggable="image.$sort.active" @dragover="image.$sort.move" @dragend="image.$sort.end">
								<div class="image" :style="`background-image: url('${image.source}')`" @mousedown="image.$sort.activate" @mouseup="image.$sort.end"></div>
								<form-input name="title" label="" v-model="image.title" placeholder="כותרת"/>
								<div class="delete" @click="model.images.splice(image.$index, 1)">הסר תמונה</div>
							</div>
						</div>
					</index>
				</div>
			</div>

			<div class="container seo" key="seo" :class="{active: (state.tab == 'seo')}" v-if="state.tab == 'seo'">
				<div class="data seo" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="web"
								:class="{active: model.page.active}" @click.self="model.page.active = !model.page.active">
								<span class="title">עמוד קטגוריה פעיל</span>
								<div class="description">דף קטגוריה יהיה פעיל לתצוגה</div>
								<div class="form">
									<form-toggle label="" name="page_toggle" v-model="model.page.active" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="model.page.active">
							<form-input name="slug" label="כתובת URL" v-model="model.page.slug" />
							<form-input name="seoTitle" label="כותרת" v-model="model.page.seo.title" />
							<form-input name="description" label="תיאור" v-model="model.page.seo.description" />
							<form-input name="keywords" label="מילות מפתח" v-model="model.page.seo.keywords" />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			state: {
				tab: ''
			},
			currentPictureEdit: null,
			categories: [],
			model: this.$models.inventory.category.model()
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		Promise.all([
			this.getCategories(),
			this.getData()
		]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'general')
		});
	},
	methods:{
		setTab(tab){
			if(['general', 'media', 'seo'].includes(tab)) this.state.tab = tab;
		},
		setImagesSort(sort){
			this.model.images.sort((a, b) => a.__sort - b.__sort);
		},
		getCategories(){
			return this.$models.inventory.category.all(this.$route.params.store)
			.then((res) => {
				this.categories = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getData(){
			if(! this.$route.params.category) return Promise.resolve();

			return this.$models.inventory.category.get(this.$route.params.store, this.$route.params.category)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		previewImages(){
			this.model.images.forEach(image => {
				if(image.file){
					image.source = URL.createObjectURL(image.file);
				}
			})
		},
		editPicture(index){
			if(this.currentPictureEdit == index) return this.currentPictureEdit = null;

			this.currentPictureEdit = index
		},
		removePicture(image){
			delete this.model.images[image.$index];
			this.model.images = this.model.images.filter(e => e != null);
		},
		setImagesSort(sort){
			this.model.images.sort((a, b) => a.__sort - b.__sort);
		},
		save(){
			this.loading = true;

			let request = null;

			if(this.$route.params.category){
				request = this.$models.inventory.category.update(this.$route.params.store, this.$route.params.category, this.model)
			} else{
				request = this.$models.inventory.category.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.category){
					this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

				this.previewImages();

			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.previewImages();

				this.loading = false;
			});
		}
	},
	computed:{
		slug:{
			get(){
				return this.model.page.slug;
			},
			set(value){
				this.model.page.slug = value.split(' ').join('_');
			}
		},
		images: {
			get(){
				return this.model.images;
			},
			set(images){
				images.splice(0, this.model.images.length);

				images.forEach(file => {
					this.model.images.push({
						file,
						source: URL.createObjectURL(file),
						title: '',
						attributes: [{
							key: 'צבע', value: ''
						}]
					})
				})
			}
		},
		parents(){
			if(! this.categories) return [];

			let items = [ { value: '', label: 'ראשי' } ],
				f = (item) => {
					if(this.model && (item._id == this.model._id)) return;

					items.push({value: item._id, label: item.name});

					if(item.sub) item.sub.forEach(sub => f(sub));
				};

			this.categories.forEach(item => f(item));

			return items;
		}
	}
}
</script>
