<template>
	<div class="layout dashboard master">

		<div class="header">
			<div class="title">
				<span>ניהול מאסטר</span>
				<span v-for="(note, i) in notes" :key="i" :class="note.type">{{ note.text }}</span>
			</div>

			<div class="actions">
				<div class="buttons">
					<div class="action" data-icon="add" :class="{active: addActive}" @click="addActive = !addActive">הוספה</div>
				</div>
				<div class="container menu" :class="{active: addActive}">
					<div class="option" data-icon="style" @click="goTo('master/stores/create')">אתר חדש</div>
					<div class="option" data-icon="style" @click="goTo('master/users/create')">משתמש חדש</div>
				</div>
			</div>
		</div>

		<div class="grid">

			<div class="data summary">
				<div class="options">
					<div class="group">
						<span data-icon="shopping_cart"
							:class="{active: summary.state == 'monthly'}" @click="setSummaryState('monthly')">הכנסה חודשית</span>
						<span data-icon="notification_important"
							:class="{active: summary.state == 'creates'}" @click="setSummaryState('creates')">הקמת אתרים</span>
					</div>
				</div>

				<div class="content rows">

					<div class="total">
						<div class="right">
							<span>{{ formatNumber(
								Object.keys(summaryData[summary.state]).length
							) }}</span>
							<span>סה״כ אתרים</span>
						</div>

						<div class="left">
							<span data-sign="₪">{{ formatNumber(
								summaryData[summary.state].reduce((total, r) => total + (r.value || 0), 0)
							, 2) }}</span>
							<span>סה״כ שווי עסקאות</span>
						</div>
					</div>

					<index :data="summary.expend ? summaryData[summary.state] : [].concat(summaryData[summary.state]).splice(0, 3)">

						<div class="row" slot="index" slot-scope="row" :data-count="row.count">
							<span class="sub" v-if="row.sub">{{ row.sub }}</span>
							<span class="title">{{ row.title }}</span>
							<span class="value">{{ `${formatNumber(row.value, 2)} ₪` }}</span>
						</div>

						<div class="expend" slot="footer" @click="summary.expend = !summary.expend"
							v-if="summaryData[summary.state].length > 3">{{ summary.expend ? 'הסתר' : 'הצג הכל' }}</div>
						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>

			<div class="data main">
				<div class="options">
					<div class="group">
						<span data-icon="style" :data-count="stores.length"
							:class="{active: main.state == 'stores'}" @click="setMainState('stores')">אתרים</span>
						<span data-icon="style" :data-count="users.length"
							:class="{active: main.state == 'users'}" @click="setMainState('users')">משתמשים</span>
					</div>
					<div class="group">
						<span data-icon="search" data-label="חיפוש" :class="{active: main.mobile.filters}" @click="main.mobile.filters = !main.mobile.filters; main.mobile.sort = false"></span>
						<span class="mobile" data-icon="sort" data-label="מיון" :class="{active: main.mobile.sort}" @click="main.mobile.sort = !main.mobile.sort; main.mobile.filters = false"></span>
					</div>
				</div>

				<div class="content">
					<index ref="main" :data="mainData" :class="main.state">
						<template slot="header">
							<div class="index-top">
								<div class="filters" :class="{ active: main.mobile.filters }">
									<div class="close" @click="main.mobile.filters = false"></div>
									<component v-for="filter in mainFilters"
										:key="`${main.state}_${filter.id}`" :is="filter.component" :action="(value) => setMainFilter(filter, value)"
										:label="filter.label" :options="filter.options" :placeholder="`חיפוש ב${filter.placeholder.join(', ')}`" />
								</div>
							</div>
							<div class="header" :class="{active: main.mobile.sort}">
								<div class="close" @click="main.mobile.sort = false"></div>
								<div class="item" v-for="header in main.headers" :key="header.key"
									:class="[(main.sort && header.key == main.sort.key ? {
										active: true,
										up: main.sort.dir > 0,
										down: main.sort.dir < 0
									} : ''), {sort: header.sort}]"
									@click="setMainSort(header)">
									{{ header.label }}
								</div>
							</div>
						</template>


						<template slot="index" slot-scope="item">
							<index-row :class="[main.state, {hide: item.$filtered}]"
								:headers="main.headers" :data="item" @click="goToItem(item)" />
						</template>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>

			<div class="data messages">
				<div class="options">
					<div class="group">
						<span class="active" data-icon="forum" :data-count="newMessages">כל ההודעות</span>
					</div>
				</div>

				<index class="content" :data="messages">
					<div class="message" slot="index" slot-scope="message" :class="[message.status, {system: message.system}]" :key="message.$index"
						@click="$router.push({ name: 'master/stores/edit', params: {id: message.store}, query: {tab: 'messages', type: message.type} })" >
						<div class="name">{{ message.storename }}</div>
						<div class="phone">{{ message.name }}</div>
						<div class="date">{{ message.time }}</div>
						<div class="text">{{ message.message }}</div>
					</div>

					<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו הודעות</div>
				</index>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	data(){
		return {
			addActive: false,
			summary: {
				state: 'monthly',
				expend: false
			},
			main: {
				state: 'stores',
				headers: [],
				mobile: {
					filters: false,
					sort: false
				}
			},
			stores: [],
			users: [],
			supports: [],
		}
	},
	mounted(){
		this.$root.loading = true;

		this.$ws(`master/support`, this.appendMessages.bind(this));

		Promise.all([
			this.getStores(),this.getUsers(),this.getSupports()
		]).then(() => this.setMainState('stores'));
	},
	methods: {
		getStores(){
			this.$models.master.stores.all(this.$route.params.store).then((res) => {
				this.stores = res.data;
			})
		},
		getUsers(){
			this.$models.master.users.all(this.$route.params.store).then((res) => {
				this.users = res.data;
			})
		},
		getSupports(){
			this.$models.master.support.all().then((res) => {
				this.supports = res.data;
			})
		},
		appendMessages(text){
			this.getSupports();
		},
		setSummaryState(state){
			this.summary.expend = false;
			this.summary.state = state;
		},
		setMainState(state){
			let headers = {
				stores: [
					{key: 'image', label: '', type: 'image'},
					{key: 'store', label: 'מפתח', sort: true, sortLocale: true, search: true},
					{key: 'sitename', label: 'שם האתר', sort: true, search: true},
					{key: 'domain', label: 'דומיין', sort: true, search: true},
					{key: 'active', label: 'פעיל', sort: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				users: [
					{key: 'name', label: 'שם מלא', sort: true, sortLocale: true, search: true},
					{key: 'phone', label: 'טלפון', sort: true, search: true},
					{key: 'storesList', label: 'רשימת אתרים', sort: true},
					{key: 'master', label: 'מאסטר', sort: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'מאסטר'},
						{value: false, label: 'משתמשים רגילים'},
					]}}
				]
			};

			this.$root.loading = true;

			this.$refs.main.clearFilters();

			this.main = { state, headers: headers[state],
				mobile: {
					filters: false,
					sort: false
				}
			};

			this.$root.loading = false;
		},
		setMainSort(header){
			if(! header.sort) return;

			let sort = {key: header.key, dir: -1, sortLocale: header.sortLocale};

			if(this.main.sort && this.main.sort.key == header.key) sort.dir = (this.main.sort.dir*-1);

			this.$set(this.main, 'sort', sort);
		},
		setMainFilter(filter, value){
			this.$refs.main.filter(Object.assign(filter, { value }))
		},
		formatNumber(n, f = 0){
			return (n || 0).toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		goTo(name, params = {}){
			this.$router.push({ name, params }).catch(() => {})
		},
		goToItem(item){
			let params = {};

			if(this.main.state == 'stores') params.id = item._id;
			if(this.main.state == 'users') params.id = item._id;

			this.$router.push({ name: `master/${this.main.state}/edit`, params }).catch(() => {})
		}
	},
	computed: {
		storesList(){
			if(! this.stores.length) return {};

			let list = {};

			this.stores.forEach(store => {
				list[store._id] = (store.theme && store.theme.settings && store.theme.settings.sitename ? store.theme.settings.sitename : store.store);
			})

			return list;
		},
		newMessages(){
			return 0;
		},
		messages(){
			if(! this.supports.length) return [];

			let messages = [];

				this.supports.forEach(support => {
					if(! (support.messages && support.messages.length)) return;

					let item = JSON.parse(JSON.stringify(support.messages[support.messages.length -1]));

					messages.push({
						name: item.name,
						message: item.message,
						time: moment(item.time).fromNow(),
						status: item.status,
						system: item.system ? true : false,
						storename: this.storesList[support.store],
						store: support.store,
						type: support.type,
						originalTime: new Date(item.time)
					});
				});

			return messages.sort((a,b) => {
				return b.originalTime - a.originalTime;
			});
		},
		notes(){
			let notes = [];

			if(! notes.length){
				return [{
					type: 'success',
					text: 'הכל כאן מתקתק נהדר! כל הכבוד על ניהול החנות'
				}];
			}

			return notes;
		},
		summaryData(){
			let monthly = {}, creates = {};

			this.stores.forEach(store => {
				if(store.prices){
					monthly[store._id] = {
						value: store.prices.monthly,
						title: (store.theme && store.theme.settings && store.theme.settings.sitename ? store.theme.settings.sitename : null)
					}
					creates[store._id] = {
						value: store.prices.create,
						title: (store.theme && store.theme.settings && store.theme.settings.sitename ? store.theme.settings.sitename : null)
					}
				}
			})

			return {monthly: Object.values(monthly), creates: Object.values(creates)};
		},
		statisticsCount(){
			let counts = { active: 0, stocks: 0, last: 0, out: 0};

			this.stores.forEach(store => {
				if(! store.active) return;

				counts.active++;
			})

			return counts;
		},
		mainData(){
			let data = this[this.main.state].map(item => {
				if(this.main.state == 'stores'){
					item.image = (item.theme && item.theme.settings && item.theme.settings.logo ? item.theme.settings.logo : '');
					item.sitename = (item.theme && item.theme.settings && item.theme.settings.sitename ? item.theme.settings.sitename : '');
					item.domain = item.tokens[0].domain;
				}

				if(this.main.state == 'users'){
					item.master = Boolean(item.level == 'master');
					item.storesList = item.stores.map(store => this.storesList[store.store]).join(', ');
				}

				return item;
			});

			if(this.main.sort){
				data.sort((a, b) => (this.main.sort.sortLocale ?
					(b[this.main.sort.key].toString().localeCompare(a[this.main.sort.key].toString(), 'he')) :
					(a[this.main.sort.key] - b[this.main.sort.key])
				) * this.main.sort.dir);
			}

			return data;
		},
		mainFilters(){
			let filters = {};

			this.main.headers.forEach(header => {
				if(header.search){
					if(! filters.search) filters.search = {
						id: 'search',
						component: 'index-search',
						method: 'like',
						in: [],
						placeholder: [],
						options: []
					};

					filters.search.in.push(header.key);
					filters.search.placeholder.push(header.label);
				}

				if(header.filter){
					filters[header.key] = Object.assign(header.filter, {
						id: header.key,
						in: [header.key],
						label: header.label,
						component: 'index-filter',
						placeholder: []
					})
				}
			});

			return Object.values(filters);
		}
	}
}
</script>
