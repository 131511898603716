<template>
	<div class="layout stores"></div>
</template>

<script>
export default {
	watch: {
		'$root.$refs.menuApp.active'(state){
			this.$root.$refs.menuApp.active = state || 'stores-drawer';
		},
		'$index.master'(){
			if(this.$index.master) this.$router.push({ name: 'master'});
		}
	},
	mounted(){
		this.$root.$refs.menuApp.active = 'stores-drawer';
	}
}
</script>
