<template>
	<div class="query-filter properties" :class="{ active }">
		<div class="header">
			<span data-icon="style">סנן לפי מאפיינים</span>
			<form-toggle name="" label="" v-model="active" />
		</div>
		<div class="box" v-if="active && ! loading">
			<div class="group" v-for="(attribute, i) in input" :style="{ zIndex: (99-i)}">
				<form-area v-if="i!=0">
					<div class="remove material-icons-round" data-icon="remove" @click="remove(i)">close</div>
				</form-area>
				<form-select name="" label="בחר מאפיין" v-model="attribute.key"
					@change="(key) => attribute.value = attributes[key][0].value"
					:options="Object.keys(attributes).map(key => ({ value: key, label: key }))" />
				<form-select name="" label="בחר ערך" v-model="attribute.value" :options="attributes[attribute.key]" />
			</div>
			<div class="button light" data-icon="add" @click="add">הוסף מאפיין</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			cache: null,
			loading: true,
			attributes: {}
		}
	},
	watch: {
		active(){
			if(! this.active) return;

			 this.load().then(() => {
				let key = Object.keys(this.attributes)[0];
				this.input = this.cache || [{ key , value: this.attributes[key][0].value }]
				this.loading = false;
			});
		}
	},
	mounted(){
		if(this.active) this.load()
	},
	methods: {
		load(){
			this.$models.inventory.stock.getStockColors(this.$route.params.store).then(result => {
				if(! result.data[0]) return;

				this.attributes['צבע'] = result.data[0].colors.map(color => {
					color.icon = color.image || color.color;
					return color;
				}) || [];
			})


			return this.$models.inventory.stock.getTemplatesAttributes(this.$route.params.store).then(({ data }) => {
				if(data){
					data.forEach((attributes, key) => {
						if(attributes._id == 'צבע') return this.attributes['צבע'] = this.attributes['צבע'] || [];

						this.attributes[attributes._id] = attributes.values.map(attr => ({ value: attr, label: attr }));
					});
				}
				this.loading = false;
			})
		},
		add(){
			let key = Object.keys(this.attributes)[0];
			this.input = this.input.concat([{ key, value: this.attributes[key][0].value }]);
		},
		remove(index){
			this.input.splice(index, 1);
		}
	},
	computed: {
		active:{
			get(){
				return Boolean(this.input != undefined);
			},
			set(val){
				if(val){
					return this.input = this.cache || []
				}

				this.cache = this.input;
				this.input = undefined
			}
		},
		input: {
			get() {
				return this.$parent.query.properties;
			},
			set(val) {
				this.$set(this.$parent.query, 'properties', val);
			}
		}
	}
}
</script>
