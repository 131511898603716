<template>
	<div class="extend-stock">

		<slot name="templates"></slot>
		<colors-vue ref="colors" @save="selectNewColor" @close="selectNewColor"></colors-vue>

		<form-vue class="headers">
			<form-group name="header">
				<template v-for="(header, h) in headers">
					<form-select :key="`header_select${h}`" label="בחר מאפיין" :class="{disabled: !availableHeaders.length}" :name="header.value" :options="[{value: header.value, label: header.value}].concat(availableHeaders)" v-model="header.value" />
					<div :key="`div${h}`" class="remover" v-if="currentHeaders.length > 1" @click="removeHeader(h)"></div>
				</template>
				<form-area class="add" v-if="availableHeaders.length"><span @click="addHeader"></span></form-area>
				<form-area class="header"><span>מק״ט</span></form-area>
				<form-area class="header"><span>מחיר</span></form-area>
				<form-area class="header"><span>כמות</span></form-area>
			</form-group>
		</form-vue>

		<form-vue class="stock">
			<form-group v-for="(stock, i) in input" :key="i" :style="`z-index: ${input.length - i}`">
				<form-area class="remove"><i class="material-icons-round" @click="deleteStock(i)">close</i></form-area>
				<form-area class="duplicate"><i class="material-icons-round" @click="duplicateStock(stock, i)">add</i></form-area>
				<form-area class="moveUp"><i class="material-icons-round" @click="move(i, (i-1))" v-if="i != 0">keyboard_arrow_up</i></form-area>
				<form-area class="moveDown"><i class="material-icons-round" @click="move(i, (i+1))" v-if="(i+1) != input.length">keyboard_arrow_down</i></form-area>
				<template v-for="(header, h) in headers">
					<form-numbers :key="`number${h}`"
						v-if="headersConfig[header.value].type == 'numbers'" :point="headersConfig[header.value].point" :clear="headersConfig[header.value].clear"
						class="attribute" label="" :name="header.value" v-model="stock.attributes[h].value" />
					<form-select :key="`select${h}`" v-else-if="headersConfig[header.value].type == 'select'"
						class="attribute" label="" :filter="true" :name="header.value" v-model="stock.attributes[h].value"
						@change="changeColor(i, h, stock.attributes[h].value)"
						:options="colors"/>
					<form-input :key="`input${h}`" v-else
						class="attribute" label="" :name="header.value" v-model="stock.attributes[h].value" :trim="true" />
				</template>
				<form-input class="values" name="sku" label="" v-model="stock.sku" />
				<form-numbers class="values" name="price" label="" v-model="stock.price" :placeholder="price" clear point />
				<form-numbers class="values" name="quantity" label="" v-model="stock.quantity" clear />
			</form-group>
		</form-vue>
	</div>
</template>

<script>
export default {
	components: {
		'colors-vue': require('./colors').default,
	},
	props: {
		sku: { type: String, required: true },
		price: { type: String, required: true },
		value: { type: Array, required: true }
	},
	data(){
		return {
			currentHeaders: null,
			headersConfig: this.$models.inventory.stock.attributes(this.$route.params.store)
		}
	},
	mounted(){
		this.getColors();
	},
	methods: {
		getColors(){
			this.$models.inventory.stock.getStockColors(this.$route.params.store)
			.then(result => {
				if(! result.data[0]) return;

				this.headersConfig['צבע'].options = result.data[0].colors || [];
			})
		},
		addHeader(){
			if(! this.availableHeaders.length) return;

			this.currentHeaders = this.currentHeaders.concat(this.availableHeaders[0]);
		},
		removeHeader(index){
			VueAlerts.alert({
				icon: "delete_forever",
				title: "האם להסיר מאפיין זה?",
				text: "הסרה של מאפיין זה תמחק את המאפיין וכל הערכים שהוזנו עבורו",
				buttons: ["ביטול", "מחק"]
			}).then(action => {
				if(action == "ביטול") return;

				this.currentHeaders.splice(index, 1);
				this.value.map(stock => stock.attributes.splice(index, 1));
			})
		},
		move(from, to){
			let i = this.input.splice(from, 1)[0];
			this.input.splice(to, 0, i);

			this.input = this.input;
		},
		deleteStock(index){
			if(this.input.length < 2) return;

			this.input.splice(index, 1);
			this.input = this.input;
		},
		duplicateStock(stock, i){
			let copy = JSON.parse(JSON.stringify(stock));

			copy.price = copy.price ? copy.price : this.price;

			this.input.splice((i+1), 0, Object.assign(copy, {
				_id: null,
				sku: this.sku
			}, true));

			this.input = this.input;
		},
		setTemplate(attributes){
			let headers = {};

			this.input = attributes.map((res, i) => {
				let stock = {_id: null, sku: `${this.sku}-${i + 1}`, price: this.price, quantity: '10', attributes: []};
					stock.attributes = res;
					stock.attributes.forEach(attribute => headers[attribute.key] = { value: attribute.key });
				return stock;
			});

			this.currentHeaders = Object.values(headers);
		},
		changeColor(key, AttrKey, value){
			if(value != 'add') return false;

			this.$refs.colors.activate({key, AttrKey});
		},
		selectNewColor(value){
			if(value.colors) this.headersConfig['צבע'].options = value.colors;

			this.input[value.key].attributes[value.AttrKey].value = value.value;

			this.$emit('update-colors');
		}
	},
	computed: {
		headers(){
			if(this.currentHeaders) return this.currentHeaders;

			let headers = {};

			this.value.forEach(stock => {
				stock.attributes.forEach(attribute => headers[attribute.key] = { value: attribute.key });
			});

			return this.currentHeaders = Object.values(headers);
		},
		availableHeaders(){
			return Object.keys(this.headersConfig).map(header => {
				if(this.headers.map(active => active.value).indexOf(header) > -1) return null;

				return { value: header, label: header };
			}).filter(header => header);
		},
		colors(){
			let options = [{value: 'add', label: 'הוסף צבע'}];

			return options.concat(this.headersConfig['צבע'].options.map(color => {
				color.icon = color.image || color.color;
				return color;
			}));
		},
		input: {
			get() {
				return this.value.map(stock => {
					this.headers.forEach((header, i) => {
						if(stock.attributes[i]){
							stock.attributes[i].key = header.value,
							stock.attributes[i].value = (stock.attributes[i] ? stock.attributes[i].value : "")
						}else{
							stock.attributes.push({
								key: header.value,
								value: (stock.attributes[i] ? stock.attributes[i].value : "")
							});
						}
					});

					return stock;
				});
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
