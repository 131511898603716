const Model = require('@/models/model');

class Checkout extends Model{

	constructor(){
		super();
	}

	all(store, payload, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/checkouts?limit=${limit}&skip=${skip}`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

}

module.exports = new Checkout()
