<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'כללי', icon: 'receipt', groups: ['general', 'global'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'text_settings'] }
		}">
		<form-group style="z-index:2" name="global">
			<form-toggle name="principal" v-model="input.principal" label="משוך ראשיות"></form-toggle>
			<form-toggle name="showAverage" v-model="input.showAverage" label="הצג ממוצע"></form-toggle>
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "חוות דעת מאומתות",
	defaults: {
		principal: false,
		showAverage: true,
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	computed: {
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
