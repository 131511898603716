const Model = require('@/models/model');

class Review extends Model{

	constructor(){
		super();
	}

	all(store, payload){
		if(! store) return reject(new Error("Missing `store`"));

		return this.$api.post(`dashboard/store/${store}/reviews/index`, payload)
	}

}

module.exports = new Review()
