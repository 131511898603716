<template>
	<div class="layout forms dictionary" :class="{ loading }">

		<div class="title">{{$route.params.id ? 'עריכת' : 'הוספת'}} מילון</div>
		<div class="tabs">
			<div data-icon="account_circle" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטים כללים</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="submit">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטים כללים</span>
					</div>
					<div class="content">
						<form-input name="name" label="כותרת" v-model="model.name" />
						<form-select name="section" label="שפה" v-model="model.code"
							:options="lang_codes"/>
						<form-select name="section" label="ברירת מחדל" v-model="model.defaultVariation"
							:options="variations"/>
						<form-select name="section" label="כיוון" v-model="model.direction"
							:options="[{label: 'מימין לשמאל', value: 'rtl'}, {label: 'משמאל לימין', value: 'ltr'}]"/>
						<div class="button" data-icon="done" :class="{dark: model._id != defaultDictionary, success: model._id == defaultDictionary}" @click="setDefault(model._id)">{{ `${model._id != defaultDictionary ? 'הפוך ל' : ''}שפה ראשית` }}</div>
					</div>
				</div>
				<div class="data words" animated >
					<div class="options">
						<span data-icon="description">תרגומים</span>
					</div>
					<div class="content" v-if="words && model._id">
						<div class="index-filters" v-if="words">
							<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['key', 'first'], value })"/>
							<index-filter
								label="אזורים"
								:options="sectionsFilter" :style="`z-index: ${words.length + 1}`"
								:action="(value) => $refs.index.filter({ id: 'section', method: 'eq', in: ['section'], value })"
							/>
						</div>

						<index ref="index" :data="words" v-if="words" sort-by="_id" @sort="(sort) => {}">

							<dictionary-row slot="index" slot-scope="item" :class="{ hide: item.$filtered }"
								:key="item.key"
								:dictionary="$route.params.id"
								:id="item._id"
								:code="model.code"
								:word="item"
								:variations="variations"
								v-model="model.dictionary"
								:style="`z-index: ${words.length - item.$index}`"
							/>

						</index>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	components: {
		'dictionary-row': require('@/pages/dictionary/dictionary-row').default,
	},
	data(){
		return {
			loading: true,
			dictionary: null,
			defaultDictionary: null,
			words: null,
			model: this.$models.dashboard.dictionary.model(),
			variations: this.$models.master.dictionary.variations(),
			lang_codes: this.$models.master.dictionary.lang_codes(),
			sections: this.$models.master.dictionary.sections(),
			state: {
				tab: '',
				paramsActiveIndex: null
			}
		}
	},
	watch: {
		'$route.query.tab': 'setTab'
	},
	mounted(){
		if(this.$route.params.id){
			this.getData();
		}else{
			this.loading = false;
		}

		this.$index.stores.then(result => {
			let store = result.data.filter(data => data.store == this.$route.params.store)[0];

			this.defaultDictionary = store.dictionary || null;
		})

		this.setTab(this.$route.query.tab || 'details');
	},
	methods:{
		setTab(tab){
			if(['details'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			this.$models.dashboard.dictionary.get(this.$route.params.store, this.$route.params.id)
			.then(res => {
				if(res.data.data.dictionary) this.dictionary = JSON.parse(JSON.stringify(res.data.data.dictionary));
				this.model = res.data.data;
				this.words = res.data.default.map(word => {

					word.first = word.dictionary[this.model.code].default;

					return word;
				});
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;
			let require;

			if(this.$route.params.id){
				require = this.$models.dashboard.dictionary.update(this.$route.params.store, this.$route.params.id, this.model)
			} else {
				require = this.$models.dashboard.dictionary.create(this.$route.params.store, this.model)
			}

			require.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.id){
					return this.$router.push({ name: 'store/dictionary', params:{store: this.$route.params.store}});
				}
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		setDefault(id){
			this.$models.dashboard.store.setDictionary(this.$route.params.store, id)
			.then(result => {
				this.defaultDictionary = id;
			})
			.catch(error => {
				console.log(error);
			});
		}
	},
	computed: {
		defaultWords(){
			if(! (this.words && this.model.code)) return;

			let words = {};

			this.words.forEach(word => {
				words[word.key] = word.dictionary[this.model.code];
			})

			return words;
		},
		sectionsFilter(){
			if(! this.sections) return [];
			let items = this.sections;

			items.unshift({label: 'הכל', value: null});

			return items;
		}
	}
}
</script>
