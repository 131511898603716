<template>
	<div class="layout products-create">

		<div class="top fixed">
			<div class="title">הגדרות משתמש</div>

			<div class="actions">
				<div class="button" data-icon="save" @click="submit()">שמור</div>
			</div>
		</div>

		<form-vue>
			<form-group name="section1" title="פרטי המשתמש" style="z-index: 2">
				<form-input name="phone" label="מספר טלפון" v-model="phone" disabled/>
				<form-input name="name" label="שם מלא" v-model="name" />
				<form-input name="email" label="אימייל" v-model="email" />
			</form-group>
		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			name: '',
			email: '',
			phone: ''
		}
	},
	mounted(){


		this.setData();
	},
	methods:{
		submit(){
			this.$root.loading = true;

			let model = {
				name: this.name,
				phone: this.phone,
				email: this.email
			};


			this.$models.dashboard.user.update(model)
			.then(result => {
	        	VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});
				this.$root.loading = false;
			});
		},
		setData(){

			this.$models.dashboard.user.get()
			.then(res => {
				this.name = res.data.name || '';
				this.email = res.data.email || '';
				this.phone = res.data.phone;
			});
		}
	}
}
</script>
