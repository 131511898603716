<template>
	<ul class="menu-drawer">
		<li :class="{ active: (store && active == 'orders') }" @click="goTo('orders')">
			<span data-icon="shopping_cart">הזמנות</span>
		</li>
		<li :class="{ active: (store && active == 'inventory') }" @click="goTo('inventory')" v-if="isOwner">
			<span data-icon="storefront">חנות</span>
		</li>
		<li :class="{ active: (store && active == 'clients') }" @click="goTo('clients')">
			<span data-icon="people">לקוחות</span>
		</li>
		<li :class="{ active: (store && active == 'marketing') }" @click="goTo('marketing')" v-if="false && isOwner">
			<span data-icon="trending_up">שיווק</span>
		</li>
		<li :class="{ active: (store && active == 'web') }" @click="goTo('web')" v-if="isOwner">
			<span data-icon="desktop_mac">אתר</span>
		</li>
		<li :class="{ active: (store && active == 'settings') }" @click="goTo('settings')" v-if="isOwner">
			<span data-icon="tune">הגדרות</span>
		</li>
	</ul>
</template>

<script>
export default {
	methods:{
		goTo(name){
			this.$router.push({ name, params: { store: this.store } }).catch(() => {});
		}
	},
	computed: {
		store(){
			return this.$route.params.store;
		},
		isOwner(){
			if(! this.$index.permission) return false;

			return Boolean(this.$index.permission == 'owner');
		},
		active(){
			return this.$route.name.split('/')[0];
		}
	}
}
</script>
