<template>
	<div class="search" :class="{focus: isFocus, active: text.length}">
		<div class="search-box">
			<div class="clear" @click="text = ''"></div>
			<input
				v-model="text"
				type="text" :placeholder="placeholder"
				@focusin="isFocus = true"
				@focusout="isFocus = false"
			>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		placeholder: { type: String, default: "חיפוש" },
		action: { type: Function, required: true }
	},
	watch:{
		text(){
			this.action(this.text);
		}
	},
	data(){
		return {
			text: '',
			isFocus: false
		}
	}
}
</script>
