<template>
	<div class="layout dashboard inventory">

		<div class="header">
			<div class="title">
				<span>ניהול חנות</span>
				<span v-for="(note, i) in notes" :key="i" :class="note.type">{{ note.text }}</span>
			</div>

			<div class="actions">
				<div class="buttons">
					<div class="action" data-icon="add" :class="{active: activeMenu == 'add'}"
						@click="activeMenu = (activeMenu == 'add' || 'add')">הוספה</div>
					<div class="action" data-icon="add" :class="{active: activeMenu == 'actions'}"
						@click="activeMenu = (activeMenu == 'actions' || 'actions')">פעולות</div>
				</div>
				<div class="container menu" :class="{active: activeMenu == 'add'}">
					<div class="option" data-icon="style" @click="goTo('inventory/products/create')">מוצר חדש</div>
					<div class="option" data-icon="category" @click="goTo('inventory/categories/create')">קטגוריה חדשה</div>
					<div class="option" data-icon="dashboard_customize" @click="goTo('inventory/bundles/create')">חבילה חדשה</div>
					<div class="option" data-icon="verified" @click="goTo('inventory/discounts/create')">מבצע חדש</div>
					<div class="option" data-icon="local_activity" @click="goTo('inventory/coupons/create')">קופון חדש</div>
					<div class="option" data-icon="domain" @click="goTo('inventory/branches/create')">סניף חדש</div>
					<div class="option" data-icon="local_shipping" @click="goTo('inventory/deliveries/create', {type: 'ship'})">שיטת משלוח חדשה</div>
					<div class="option" data-icon="add_location_alt" @click="goTo('inventory/deliveries/create', {type: 'pickup'})">נקודת איסוף חדשה</div>
					<div class="option" data-icon="compare_arrows" @click="goTo('inventory/deliveries/create', {type: 'collect'})">שיטת החזרה חדשה</div>
				</div>
				<div class="container menu" :class="{active: activeMenu == 'actions'}">
					<div class="option" data-icon="style" @click="notifyClients">עדכן על חזרה מהמלאי</div>
				</div>
			</div>
		</div>

		<div class="grid">

			<div class="data summary">
				<div class="options">
					<div class="group">
						<span data-icon="shopping_cart" :data-count="summaryData.cart.reduce((total, r) => total + r.count, 0)"
							:class="{active: summary.state == 'cart'}" @click="setSummaryState('cart')">נמצאים בעגלה</span>
						<span data-icon="notification_important" :data-count="summaryData.notify.reduce((total, r) => total + r.count, 0)"
							:class="{active: summary.state == 'notify'}" @click="setSummaryState('notify')">בקשות חזרה למלאי</span>
					</div>
				</div>

				<div class="content rows">

					<div class="total">
						<div class="right">
							<span>{{ formatNumber(
								summaryData[summary.state].reduce((total, r) => total + r.count, 0)
							) }}</span>
							<span>סה״כ פריטים</span>
						</div>

						<div class="left">
							<span data-sign="₪">{{ formatNumber(
								summaryData[summary.state].reduce((total, r) => total + r.value, 0)
							, 2) }}</span>
							<span>סה״כ שווי פריטים</span>
						</div>
					</div>

					<index :data="summary.expend ? summaryData[summary.state] : [].concat(summaryData[summary.state]).splice(0, 3)">

						<div class="row" slot="index" slot-scope="row" :data-count="row.count" :key="row.$index">
							<span class="sub" v-if="row.sub">{{ row.sub }}</span>
							<span class="title">{{ row.title }}</span>
							<span class="value">{{ `${formatNumber(row.value, 2)} ₪` }}</span>
						</div>

						<div class="expend" slot="footer" @click="summary.expend = !summary.expend"
							v-if="summaryData[summary.state].length > 3">{{ summary.expend ? 'הסתר' : 'הצג הכל' }}</div>
						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>

			<div class="data stats">
				<div class="options">
					<div class="group">
						<span data-icon="bar_chart" class="active">פירוט מוצרים</span>
					</div>
				</div>

				<div class="content">
					<div class="stat primary" data-icon="local_offer">
						<span>{{ formatNumber(statisticsCount.active) }}</span>
						<span>סה״כ מוצרים פעילים</span>
					</div>
					<div class="stat success" data-icon="style">
						<span>{{ formatNumber(statisticsCount.stocks) }}</span>
						<span>סה״כ פריטים</span>
					</div>
					<div class="stat warning" data-icon="warning">
						<span>{{ formatNumber(statisticsCount.last) }}</span>
						<span>אחרונים במלאי</span>
					</div>
					<div class="stat error" data-icon="error">
						<span>{{ formatNumber(statisticsCount.out) }}</span>
						<span>מלאי אזל</span>
					</div>
				</div>
			</div>

			<div class="data main" :class="main.state">
				<div class="options">
					<div class="group">
						<span data-icon="style" :data-count="main.counts.products"
							:class="{active: main.state == 'products'}" @click="setMainState('products')">מוצרים</span>
						<span data-icon="category" :data-count="categories.length"
							:class="{active: main.state == 'categories'}" @click="setMainState('categories')">קטגוריות</span>
						<span data-icon="dashboard_customize" :data-count="bundles.length"
							:class="{active: main.state == 'bundles'}" @click="setMainState('bundles')">חבילות</span>
						<span data-icon="verified" :data-count="discounts.length"
							:class="{active: main.state == 'discounts'}" @click="setMainState('discounts')">מבצעים</span>
						<span data-icon="local_activity" :data-count="coupons.length"
							:class="{active: main.state == 'coupons'}" @click="setMainState('coupons')">קופונים</span>
						<span data-icon="domain" :data-count="branches.length"
							:class="{active: main.state == 'branches'}" @click="setMainState('branches')">סניפים</span>
						<span data-icon="local_shipping" :data-count="deliveries.length"
							:class="{active: main.state == 'deliveries'}" @click="setMainState('deliveries')">אספקה</span>
					</div>
					<div class="group">
						<span data-icon="beenhere" @click="goTo('inventory/stocks')" v-if="main.state == 'products'">עדכון מלאי</span>
						<span data-icon="beenhere" @click="goTo('inventory/deliveries/settings')" v-if="main.state == 'deliveries'">הגדרות הפצה</span>
						<span data-icon="search" data-label="חיפוש" :class="{active: main.mobile.filters}" @click="main.mobile.filters = !main.mobile.filters; main.mobile.sort = false"></span>
						<span class="mobile" data-icon="sort" data-label="מיון" :class="{active: main.mobile.sort}" @click="main.mobile.sort = !main.mobile.sort; main.mobile.filters = false"></span>
					</div>
				</div>

				<div class="content">
					<index ref="main" :data="mainData" @lazyload="lazyload">
						<template slot="header">
							<div class="index-top">
								<div class="filters" :class="{ active: main.mobile.filters }">
									<div class="close" @click="main.mobile.filters = false"></div>
									<component v-for="filter in mainFilters"
										:key="`${main.state}_${filter.id}`" :is="filter.component" :action="(value) => setMainFilter(filter, value)"
										:label="filter.label" :options="filter.options" :placeholder="`חיפוש ב${filter.placeholder.join(', ')}`" />
								</div>
							</div>
							<div class="header" :class="{active: main.mobile.sort}" :key="main.state">
								<div class="close" @click="main.mobile.sort = false"></div>
								<div class="item" v-for="header in main.headers" :key="header.key"
									:class="[(main.sort && header.key == main.sort.key ? {
										active: true,
										up: main.sort.dir > 0,
										down: main.sort.dir < 0
									} : ''), {sort: header.sort}]"
									@click="setMainSort(header)">
									{{ header.label }}
								</div>
							</div>
						</template>


						<template slot="index" slot-scope="item">
							<product-row :key="item.$index" :product="item" @toggle="toggle(item)" v-if="main.state == 'products'" />
							<template v-else>
								<index-row :key="item.$index" :class="[main.state, {hide: item.$filtered}]"
									:headers="main.headers" :data="item" @click="goToItem(item)" @toggle="toggle(item)" />

								<index :data="item.sub" v-if="item.sub">
									<template slot="index" slot-scope="item">
										<category-row :key="item.$index" :headers="main.headers" :data="item" :go="goToItem" :toggle="toggle"/>
									</template>
								</index>
							</template>
						</template>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		'product-row': require('@/components/product-row').default,
		'category-row': {
			name: 'category-row',
			props: ['headers', 'data', 'go', 'toggle'],
			template: `
				<div>
					<index-row :headers="headers" :data="data" @click="go(data)" @toggle="toggle(data)" />

					<index :data="data.sub" v-if="data.sub">
						<template slot="index" slot-scope="item">
							<category-row :headers="headers" :data="item" :go="go" :toggle="toggle"/>
						</template>
					</index>
				</div>
			`
		}
	},
	data(){
		return {
			activeMenu: false,
			summary: {
				state: 'cart',
				expend: false
			},
			main: {
				state: 'products',
				loading: false,
				counts: {},
				headers: [],
				mobile: {
					filters: false,
					sort: false
				}
			},
			products: [],
			categories: [],
			bundles: [],
			discounts: [],
			coupons: [],
			branches: [],
			deliveries: [],
			graph: {
				cart: {},
				notify: {}
			}
		}
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([
			this.getProducts(),
			this.getCategories(),
			this.getBundles(),
			this.getDiscounts(),
			this.getCoupons(),
			this.getBranches(),
			this.getDeliveries(),
			this.getProductsGraph(),
		]).then(() => this.setMainState('products'));
	},
	methods: {
		lazyload(){
			if(Object.keys(this.$refs.main.filters).length) return;

			if(this.main.state == 'products') return this.getProducts();
		},
		getProducts(all = false){
			if(this.main.loading) return;

			if((this.products.length >= (this.main.counts.products !== undefined ? this.main.counts.products : 15))) return;

			let payload = {
				query:{active: false},
				sort: {priority: {period: 'w'}},
				offset: (all ? 0 : this.products.length)
			}

			if(!all) payload.limit = 15;
			else this.loading = this.$root.loading = true;

			this.main.loading = true;

			return this.$models.inventory.product.query(this.$route.params.store, payload).then((res) => {
				this.products = (all ? [] : this.products).concat(res.data);

				if(all) this.$root.loading = false;
				this.main.loading = false;

				if(res.response.headers["kipi-pagination-total"])
					this.main.counts.products = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.main.counts.products = this.products.length
			})
		},
		getCategories(){
			return this.$models.inventory.category.all(this.$route.params.store).then((res) => {
				this.categories = res.data;
			})
		},
		getBundles(){
			return this.$models.inventory.bundle.all(this.$route.params.store).then((res) => {
				this.bundles = res.data;
			})
		},
		getDiscounts(){
			return this.$models.inventory.discount.all(this.$route.params.store).then((res) => {
				this.discounts = res.data;
			})
		},
		getCoupons(){
			return this.$models.inventory.coupon.all(this.$route.params.store).then((res) => {
				this.coupons = res.data;
			})
		},
		getBranches(){
			return this.$models.inventory.branch.all(this.$route.params.store).then((res) => {
				this.branches = res.data;
			})
		},
		getDeliveries(){
			return this.$models.inventory.delivery.all(this.$route.params.store).then((res) => {
				this.deliveries = res.data;
			})
		},
		getProductsGraph(){
			return this.$models.inventory.product.graph(this.$route.params.store).then((res) => {
				this.graph.cart = res.data.cart;
				this.graph.notify = res.data.notify;
			})
		},
		setSummaryState(state){
			this.summary.expend = false;
			this.summary.state = state;
		},
		setMainState(state){
			let headers = {
				products: [
					{key: 'title', label: 'שם המוצר', sort: true, sortLocale: true, search: true},
					{key: 'quantity', label: 'מלאי', sort: true},
					{key: 'price', label: 'מחיר', sort: true},
					{key: 'orders', label: 'כמות הזמנות', sort: true},
					{key: 'cart', label: 'כמות בעגלה', sort: true},
					{key: 'notify', label: 'בקשות חזרה למלאי', sort: true},
					{key: 'status', label: 'פעיל', toggle: true, sort: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: 'active', label: 'פעילים'},
						{value: 'pause', label: 'מושהה'},
						{value: 'archive', label: 'ארכיון'},
					]}}
				],
				categories: [
					{key: 'name', label: 'שם', sort: true, sortLocale: true, search: true},
					{key: 'productsCount', label: 'כמות מוצרים', sort: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				bundles: [
					{key: 'title', label: 'שם', sort: true, sortLocale: true, search: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				discounts: [
					{key: 'title', label: 'כותרת', sort: true, sortLocale: true, search: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				coupons: [
					{key: 'title', label: 'כותרת', sort: true, sortLocale: true, search: true},
					{key: 'code', label: 'קוד קופון', search: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				branches: [
					{key: 'title', label: 'שם', sort: true, sortLocale: true, search: true},
					{key: 'phone', label: 'מספר טלפון', search: true},
					{key: 'city', label: 'עיר', sort: true, sortLocale: true, search: true},
					{key: 'address', label: 'כתובת', sort: true, sortLocale: true, search: true},
					{key: 'allow_pickup', label: 'אפשרות לאיסוף', sort: true, sortLocale: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'סניפים עם איסוף'},
						{value: false, label: 'סניפים ללא איסוף'},
					]}},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				deliveries: [
					{key: 'title', label: 'שם', sort: true, sortLocale: true, search: true},
					{key: 'type-text', label: 'סוג', sort: true, sortLocale: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				]
			};

			this.$root.loading = true;

			this.$refs.main.clearFilters();

			this.main = Object.assign(this.main,
				{ state, headers: headers[state],
				mobile: {
					filters: false,
					sort: false
				}
			});

			this.$root.loading = false;
		},
		setMainSort(header){
			if(! header.sort) return;

			if(this.main.state == 'products') this.getProducts(true);

			let sort = {key: header.key, dir: -1, sortLocale: header.sortLocale};

			if(this.main.sort && this.main.sort.key == header.key) sort.dir = (this.main.sort.dir*-1);

			this.$set(this.main, 'sort', sort);
		},
		setMainFilter(filter, value){
			if(this.main.state == 'products') this.getProducts(true);

			this.$refs.main.filter(Object.assign(filter, { value }))
		},
		formatNumber(n, f = 0){
			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		goTo(name, params = {}){
			Object.assign(params, {store: this.$route.params.store});

			this.$router.push({ name, params }).catch(() => {})
		},
		goToItem(item){
			let params = {
				store: this.$route.params.store
			};

			if(this.main.state == 'products') params.product = item._id;
			if(this.main.state == 'categories') params.category = item._id;
			if(this.main.state == 'bundles') params.bundle = item._id;
			if(this.main.state == 'discounts') params.discount = item._id;
			if(this.main.state == 'coupons') params.coupon = item._id;
			if(this.main.state == 'branches') params.branch = item._id;
			if(this.main.state == 'deliveries') params.delivery = item._id;

			this.$router.push({ name: `inventory/${this.main.state}/edit`, params }).catch(() => {})
		},
		toggle(item){
			let type = null, index = item.$index, parentId = item.parent;

			if(this.main.state == 'products') type = 'product';
			if(this.main.state == 'categories') type = 'category';
			if(this.main.state == 'discounts') type = 'discount';
			if(this.main.state == 'coupons') type = 'coupon';
			if(this.main.state == 'branches') type = 'branch';
			if(this.main.state == 'deliveries') type = 'delivery';

			if(! type) return;

			this.$models.inventory[type][item.active ? 'remove' : 'restore'](this.$route.params.store, item._id)
			.then(() => {

				let nestedUpdate = (nested) => {
					if(nested._id == item._id) return nested.active = ! nested.active;

					if(nested.sub) nested.sub.forEach(nestedUpdate);
				};

				this[this.main.state].forEach(nestedUpdate);

				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		notifyClients(){
			this.$models.inventory.product.notify(this.$route.params.store)
			.then((res) => {
				if(res.data){
					VueAlerts.flash({
						text: `${! res.data.sended ? 'לא נשלחו עדכונים' : 'העדכונים נשלחו בהצלחה'}`,
						closeable: false
					});
				}

				this.actionsActive = false;
			})
			.catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			})
		}
	},
	computed: {
		notes(){
			let notes = [];

			if(this.statisticsCount.last){
				notes.push({
					type: 'warning',
					text: (this.statisticsCount.last == 1 ? 'פריט אחד עתיד להגמר מהמלאי' : `${this.statisticsCount.last} פריטים שעתידים להגמר מהמלאי`)
				});
			}

			if(this.statisticsCount.out){
				notes.push({
					type: 'error',
					text: (this.statisticsCount.out == 1 ? 'פריט אחד אזל מהמלאי' : `${this.statisticsCount.out} פריטים שאזלו מהמלאי`)
				});
			}

			if(! notes.length){
				return [{
					type: 'success',
					text: 'הכל כאן מתקתק נהדר! כל הכבוד על ניהול החנות'
				}];
			}

			return notes;
		},
		summaryData(){
			return {cart: Object.values(this.graph.cart), notify: Object.values(this.graph.notify)};
		},
		statisticsCount(){
			let counts = { active: 0, stocks: 0, last: 0, out: 0};

			this.products.forEach(product => {
				if(! product.active) return;

				counts.active++;

				product.stocks.forEach(stock => {
					counts.stocks++;

					if(stock.quantity === true) return;

					if(stock.quantity < 1) return counts.out++;

					if(stock.quantity < 4) return counts.last++;
				})
			})

			return counts;
		},
		mainData(){
			let categoriesMap = (cat) => {
				cat.productsCount = this.products.reduce((sum, product) =>
					sum + (product.categories && product.categories.includes(cat._id) ? 1 : 0)
				, 0);

				if(cat.sub) cat.sub = cat.sub.map(sub => categoriesMap(sub));

				return cat;
			},
			data = this[this.main.state].map(item => {
				if(this.main.state == 'products'){
					item.image = (item.images && item.images.length ? item.images[0].source : null);

					item.cart = this.summaryData.cart.reduce((sum, cart) =>
						(cart.product == item._id ? sum + cart.count : sum)
					, 0);

					item.notify = this.summaryData.notify.reduce((sum, notify) =>
						(notify.product == item._id ? sum + notify.count : sum)
					, 0);


					if(item.active && item.link.active) item.status = 'active';
					if(item.active && ! item.link.active) item.status = 'pause';
					if(! item.active && !item.link.active) item.status = 'archive';
				}

				if(this.main.state == 'categories') item = categoriesMap(item);

				if(this.main.state == 'deliveries') item['type-text'] = (item.type == 'ship' ? 'משלוח' : (
					item.type == 'pickup' ? 'נקודת איסוף' : (
					item.type == 'collect' ? 'שיטת החזרה' : ''
				)));

				return item;
			});

			if(this.main.sort){
				data.sort((a, b) => (this.main.sort.sortLocale ?
					(b[this.main.sort.key].toString().localeCompare(a[this.main.sort.key].toString(), 'he')) :
					(a[this.main.sort.key] - b[this.main.sort.key])
				) * this.main.sort.dir);
			}

			return data;
		},
		mainFilters(){
			let filters = {};

			this.main.headers.forEach(header => {
				if(header.search){
					if(! filters.search) filters.search = {
						id: 'search',
						component: 'index-search',
						method: 'like',
						in: [],
						placeholder: [],
						options: []
					};

					filters.search.in.push(header.key);
					filters.search.placeholder.push(header.label);
				}

				if(header.filter){
					filters[header.key] = Object.assign(header.filter, {
						id: header.key,
						in: [header.key],
						label: header.label,
						component: 'index-filter',
						placeholder: []
					})
				}
			});

			return Object.values(filters);
		}
	}
}
</script>
