<template>
	<div class="layout pages" :class="{loading}">

		<div class="top">
			<div class="title">ניהול דפים</div>

			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('web/pages/create', {type: 'search'})">הוספת דף חיפוש</div>
				<div class="button" data-icon="add" @click="goTo('web/pages/create', {type: 'blog'})">הוספת דף בלוג</div>
				<div class="button" data-icon="add" @click="goTo('web/pages/create', {type: 'page'})">הוספת דף סטטי</div>
			</div>

		</div>

		<div class="index-filters" v-if="items.length">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['title'], value })" />
			<index-filter label="פעיל"
				:options="[
					{value: null, label: 'הכל'},
					{value: 1, label: 'פעיל בלבד'},
					{value: 0, label: 'לא פעיל'}
				]"
				:action="(value) => $refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value })"
			/>

			<index-filter label="סוג"
				:options="[
					{value: null, label: 'הכל'},
					{value: 'blog', label: 'בלוג'},
					{value: 'search', label: 'דפי חיפוש'},
					{value: 'page', label: 'דפי סטטי'}
				]"
				:action="(value) => $refs.index.filter({ id: 'type', method: 'eq', in: ['type'], value })"
			/>
		</div>

		<index ref="index" :data="list" v-if="items.length">
			<div slot="header" slot-scope="items" class="table header">
				<div>כותרת</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item" :class="{ hide: item.$filtered }">
				<div class="title">
					<span>{{ item.data && item.data.title ? item.data.title : item.slug }}</span>
					<span>{{ item.type }}</span>
				</div>

				<div class="actions" >
					<template v-if="item.type != 'home'">
						<form-toggle name="active" label="" v-model="list[item.$index].active" @toggle="toggle(item)"/>
						<div class="button" data-icon="create" @click="goTo('web/pages/edit', {id: item._id})">ערוך</div>
					</template>
					<template v-else>
						<div class="button" data-icon="create" @click="goTo('web/pages/home', {id: item._id})">ערוך</div>
					</template>
				</div>
			</div>
		</index>


	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: []
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.pages.page.index(this.$route.params.store)
			.then((res) => {

				if(res.data) this.items = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		goTo(name, page = null){
			if(page){
				let params = {store: this.$route.params.store}
				Object.assign(params, page);
				return this.$router.push({ name, params }).catch(() => {});
			}

			this.$router.push({ name }).catch(() => {});
		},
		toggle(page){
			this.$models.pages.page[page.active ? 'remove' : 'restore'](this.$route.params.store, page._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	},
	computed: {
		list(){
			let items = [{_id: 'home', slug: '/', data: { title: 'דף הבית'}, active: true, type: 'home'}];

			this.items.forEach(page => {
				if( page._id != 'home' && (page.ref || (page.slug == '/'))) return;

				page.title = page.data.title;

				items.push(page);
			});

			return items;
		}
	}
}
</script>
