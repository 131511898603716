let $Cast = {
	Object(data, pattern){
		if(pattern.constructor != Object) return console.warn("Pattern needs to be an Object");

		let clone = {};

		Object.keys(data).forEach(key => {
			if(data[key] === null || data[key] === undefined) return clone[key] = data[key];

			if(! pattern[key])
				return clone[key] = data[key];

			if(pattern[key].constructor == Object && data[key].constructor == Object){
				return clone[key] = $Cast.Object(data[key], pattern[key]);
			}

			if(pattern[key].constructor == Array && data[key].constructor == Array){
				return clone[key] = $Cast.Array(data[key], pattern[key]);
			}

			if(data[key].constructor == pattern[key]) return clone[key] = data[key];

			if(pattern[key].$isCustomCast)
				return clone[key] = $Cast.convert(data[key], pattern[key].fn(typeof data[key]))

			clone[key] = $Cast.convert(data[key], pattern[key])
		});

		return clone;
	},
	Array(data, pattern){
		if(pattern.constructor != Array) return console.warn("Pattern needs to be an Array");

		return data.map(item => {
			if(item === null || item === undefined) return item;

			if(pattern.length > 1) return $Cast.convert(item[pattern[1]], pattern[0]);

			if(pattern[0].constructor == Object && item.constructor == Object){
				return $Cast.Object(item, pattern[0]);
			}

			if(pattern[0].constructor == Array && item.constructor == Array){
				return $Cast.Array(item, pattern);
			}

			if(item.constructor == pattern[0]) return item;

			return $Cast.convert(item, pattern[0]);

		});
	},

	convert(value, to){
		if(typeof to != 'function') return value;

		let converter = $Cast[`${typeof value}_to_${to.name.toLowerCase()}`]

		return converter ? converter(value) : value;
	},


	string_to_number(value){
		let num = parseInt(value);

		return isNaN(num) ? value : num;
	},
	string_to_boolean(value){
		let val = value.toLowerCase().trim();

		if(val == '1' || val == 'true' || val == 'yes' || val == 'on') return true;

		if(val == '0' || val == 'false' || val == 'no' || val == 'off') return false;

		return value;
	},
	string_to_date(value){
		let date = new Date(value);

		if(date != "Invalid Date") return date;

		return value;
	},


	number_to_string(value){
		return value.toString();
	},
	number_to_boolean(value){
		return Boolean(value > 0);
	},
	number_to_date(value){
		let date = new Date(value);

		if(date != "Invalid Date") return date;

		return value;
	},

	boolean_to_string(value){
		return value ? 'true' : 'false';
	},
	boolean_to_number(value){
		return value ? 1 : 0;
	}

}

let Cast = (data, pattern) => {
	if(data.constructor == Object) return $Cast.Object(data, pattern);

	if(data.constructor == Array) return $Cast.Array(data, pattern);

	return data;
}

Cast.$custom = (fn) => ({fn, $isCustomCast: true});

module.exports = Cast;
