<template>
	<form-vue class="dynamic" preventHash :tabs="{
		'general': { label: 'פילטרים', icon: 'receipt', groups: ['general', 'list'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">

		<form-group style="z-index:2" name="general">
			<form-area>
				<div class="button" data-icon="pan_tool" @click="startRefernceMode()">בחר קומפוננטה לפילטור</div>
				<div class="refcomponent" v-if="input.reference">{{ input.reference.title }} {{ input.reference.id }}</div>
			</form-area>
		</form-group>

		<form-group style="z-index:1" name="list">
			<index :data="list" sort-by="title" @sort="sortElement">
				<template slot="index" slot-scope="item" >
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" ></div>
						<div class="info">{{ item.title }}</div>
						<div class="actions">
							<form-toggle name="active" label="" v-model="list[item.$index].active" />

							<div class="button" data-icon="edit" @click="toggleBox(item.$index)" v-if="editable(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else-if="editableBox == item.$index" @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" v-if="list[item.$index].type == 'properties'" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>

					<form-group :style="`z-index:${item.$index}`" :key="item.$index" class="item" v-if="editableBox == item.$index">

						<form-group style="z-index:8" v-if="item.type == 'categories'">
							<form-toggle name="allCats" label="אוטומטי" v-model="allCategories" />
						</form-group>

						<form-group style="z-index:7" v-if="item.type == 'categories'">
							<form-checkbox v-if="categories.length && ! allCategories" name="cats" label="בחר קטגוריות" v-model="list[item.$index].data" :options="categories"/>
						</form-group>

						<form-group style="z-index:6" v-else-if="item.type == 'properties'">
							<form-select name="properties_type" label="בחר סוג מאפיין" v-model="list[item.$index].title"
								:options="[{value: list[item.$index].title, label: list[item.$index].title}].concat(propertiesList)"
								@change="list[item.$index].data = attributesList[list[item.$index].title].map(i => i.value)"
							/>
						</form-group>

						<form-group style="z-index:5" v-if="item.type == 'properties' && list[item.$index].title">
							<form-checkbox v-if="attributes.length" name="properties" label="בחר מאפיינים" v-model="list[item.$index].data" :options="attributesList[list[item.$index].title]"/>
						</form-group>

					</form-group>
				</template>
			</index>
			<div class="buttons">
				<div class="button add" data-icon="add" @click="addDynamic()" style="z-index: 0" v-if="propertiesList.length">הוסף חדש</div>
			</div>
		</form-group>

		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "פילטרים",
	defaults: {
		reference: null,
		colorsList: [],
		filters: [],
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	data(){
		return {
			ref: 'products',
			editableBox: null,
			attributes: [],
			categories: [],
			filters: [
				{ title: "מחיר", active: true, type: 'price' },
				{ title: "קטגוריה", active: true, type: 'categories', data: [] }
			]
		}
	},
	mounted(){
		this.getCategories();
		this.getAttributes();
		this.getColors();
	},
	methods: {
		sortElement(sort){
			this.input.filters.sort((a, b) => a.__sort - b.__sort);
		},
		setReference(ref){
			if(ref){
				if(this.$parent.component.id == ref.id || ref.component != 'products')
					return VueAlerts.flash({
						text: 'לא ניתן לבחור את הקומפוננטה שבחרת',
						closeable: false
					});;

				this.$set(this.input, 'reference', ref);
			}
			this.$parent.$parent.referenceMode = null;
			document.body.classList.remove('refMode')
		},
		startRefernceMode(){
			this.$parent.$parent.referenceMode = this.setReference.bind(this);
			document.body.classList.add('refMode')
		},
		editable(key){
			if(this.list[key].type == 'price') return false;
			if(this.list[key].type == 'categories' && this.global) return false;
			if(this.editableBox == key) return false;

			return true;
		},
		toggleBox(key){
			if(key == this.editableBox) return this.editableBox = null;

			this.editableBox = key;
		},
		addDynamic(){
			this.input.filters.push({
				title: this.propertiesList[0].value,
				active: true,
				type: 'properties',
				data: this.attributesList[this.propertiesList[0].value].map(i => i.value)
			});

			this.editableBox = (this.input.filters.length - 1 );
		},
		removeDynamic(index){
			if(this.editableBox == index) this.editableBox = null;

			this.input.filters.splice(index, 1);
		},
		getCategories(){
			this.$models.inventory.category.options(this.$route.params.store)
			.then((res) => {
				if(res.data) this.categories = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getAttributes(){
			this.$models.inventory.stock.getTemplatesAttributes(this.$route.params.store)
			.then((res) => {
				if(res.data) this.attributes = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getColors(){
			this.$models.inventory.stock.getStockColors(this.$route.params.store)
			.then(result => {
				if(! result.data[0]) return;

				this.colorsList = result.data[0].colors || [];
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	},
	computed: {
		list(){
			if(! this.input.filters.length) this.input.filters = this.filters;

			return this.input.filters;
		},
		propertiesList(){
			return this.attributes
					.filter(attr => this.input.filters.map(f => f.title).indexOf(attr._id) < 0 )
					.map(item => ({value: item._id, label: item._id}));
		},
		attributesList(){
			let attributes = {};
			this.attributes.forEach(attr => {
				if(attr._id == 'צבע') return;
				attributes[attr._id] = attr.values.map(item => ({value: item, label: item}))
			})

			attributes['צבע'] = this.colorsList.map(color => ({value: color.value, label: color.label, icon: color.image || color.color}));
			return attributes;
		},
		allCategories: {
			get(){
				return Boolean(this.input.filters.filter(e => e.title == 'קטגוריה')[0].data.length < 1)
			},
			set(value){
				if(value){
					return this.input.filters.filter(e => e.title == 'קטגוריה')[0].data = [];
				}

				let cats = []
				this.categories.forEach(cat => {
					cats.push(cat.value);
					if(cat.sub){
						cat.sub.forEach(sub => cats.push(sub.value));
					}
				});

				return this.input.filters.filter(e => e.title == 'קטגוריה')[0].data = cats;
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
