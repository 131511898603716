<template>
	<div class="query-filter manual-products unblockable" :class="{ active: input }">
		<div class="header">
			<span data-icon="touch_app">בחירה מוצרים ידנית</span>
			<form-toggle name="" label="" v-model="input" />
		</div>
		<div class="box" v-if="input">
			<div class="label">יש לבחור דפים ידנית</div>
			<div class="count">{{(! this.$parent.query.products.length ? 'לא נבחרו מוצרים' : (this.$parent.query.products.length == 1 ? 'נבחר מוצר אחד' : 'נבחרו ' + this.$parent.query.products.length + ' מוצרים')) }} </div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			cache: null
		}
	},
	methods:{
		activate(){
			this.cache = JSON.parse(JSON.stringify(this.$parent.query));

			Object.keys(this.cache).forEach(key => {
				this.$delete(this.$parent.query, key);
			});

			this.$set(this.$parent.query, 'products', []);

			this.$parent.blockFilters();
		},
		disactivate(){
			this.$delete(this.$parent.query, 'products');

			if(this.cache){
				Object.keys(this.cache).forEach(key => {
					this.$set(this.$parent.query, key, this.cache[key]);
				});
			}

			this.$parent.unBlockFilters();
		}
	},
	computed: {
		input: {
			get() {
				return Boolean(this.$parent.query.products != undefined);
			},
			set(val) {
				this[val ? 'activate' : 'disactivate']();
			}
		}
	}
}
</script>
