<template>
	<div class="form-field textarea">
		<label>{{ label }}</label>
		<textarea ref="input" :name="name" :disabled="disabled" :placeholder="placeholder" v-model="input" @keydown="activateAutocomplete">{{ input }}</textarea>

		<template v-if="autocomplete && autocomplete.length">
			<div class="autocomplete" :class="{active: autocompleteActive}" >
				<div class="items" v-for="item in autocomplete" @click="setAutocomplete(item)">
					{{ item.label }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		placeholder: { type: String, default: "" },
		value: { type: String, required: true },
		disabled: { type: Boolean, default: false },
		autocomplete: {type: Array}
	},
	data(){
		return {
			autocompleteActive: false
		}
	},
	methods: {
		focus(){
			this.$refs.input.focus();
		},
		activateAutocomplete(e){
			if(this.autocomplete) this.autocompleteActive = Boolean(e.key == "@");
		},
		setAutocomplete(item){
			if(! this.autocomplete && this.autocomplete.length) return;

			this.input = this.input.replace('@', `{${item.key}}`);

			this.autocompleteActive = false;
		}
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
