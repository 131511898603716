<template>
	<div class="nested-tables" :class="{ hide: item.$filtered }">
		<div class="level" v-if="level"></div>
		<div class="table">
			<div class="title">
				<div class="image" :style="`background-image: url('${item.images[0].source}')`" v-if="item.images && item.images[0]"></div>
				<span>{{ item.name }}</span>
			</div>
			<div class="actions">
				<form-toggle name="active" label="" v-model="state" @toggle="toggle(item)"/>
				<div class="button" data-icon="create" @click="goTo('inventory/categories/edit', item._id)">ערוך</div>
			</div>
		</div>

		<index ref="index" v-if="item.sub" :data="item.sub">
			<category-index-row slot="index" slot-scope="item" :ref="`index[${item.$index}]`" :level="level+1" :item="item" :goTo="goTo" :toggle="toggle" />
		</index>
	</div>
</template>

<script>
export default {
	name: 'categoryIndexRow',
	props: {
		level: { type: Number, default: 0 },
		item: { type: Object, required: true },
		goTo: { type: Function, required: true },
		toggle: { type: Function, required: true },
	},
	methods: {
		filter(run){
			if(! this.item.sub) return;

			Object.values(this.$refs).forEach(index => index.filter(run));
		}
	},
	computed: {
		state: {
			get(){
				return this.item.active;
			},
			set(value){
				this.$parent.data[this.item.$index].active = value;
			}
		}
	}
}
</script>
