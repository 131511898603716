<template>
	<component :is="wrapper" class="images-library" :class="{visible}">
		<div class="close" @click="close"></div>

		<div class="back" v-if="library && library.cacheFolder !== null" @click="backFolder">חזור</div>
		<div class="uploader" :class="{hide: library !== null}">
			<form-vue>
				<form-group name="image" title="">
					<form-file name="images" label="" v-model="input.upload" @change="uploadFiles"/>
					<div class="open_library" @click="openLibrary" ></div>
				</form-group>
			</form-vue>
		</div>
		<div class="library" :class="{active: library !== null, loader: loading}">
			<div class="title">ספריית תמונות</div>
			<div class="folders" v-if="folders.length">
				<div v-for="folder in folders" class="folder" @click="getFolderFiles(folder)">{{ folder }}</div>
			</div>
			<div class="images" v-if="library && library.images.length">
				<div v-for="image in library.images" class="image" :class="{active: choosed.includes(image)}" :style="`background-image:url('${image}')`" @click="choosePicture(image)"></div>
			</div>
		</div>
		<div v-if="library !== null" class="button save" data-icon="add_photo_alternate" @click="$parent.setPictures">המשך</div>
	</component>
</template>

<script>
export default {
	props: {
		wrapper: { type: String, default: 'div' },
		multiple: { type: Boolean, default: false },
		value: { type: Object }
	},
	data(){
		return {
			path: 'https://cdn.kipi.app/',
			loading: true,
			visible: false,
			library: null,
			data: null,
			choosed: []
		}
	},
	methods: {
		openLibrary(){
			this.$set(this, 'library', {
				cacheFolder: null,
				images: [],
				model: {
					startAfter: null,
					folder: null
				}
			});
			this.getFiles();
		},
		close(){
			this.$set(this, 'library', null);

			return this.$parent.toggleUploaderBox();
		},
		getFiles(){
			this.loading = true;

			this.$models.dashboard.store.files(this.$route.params.store, this.model)
			.then(result => {
				this.data = result.data;

				this.data.content.forEach(image => {
					this.library.images.push(this.path + '' + image.Key);
				});

				if(this.data.next){
					this.$set(this.library.model, 'startAfter', this.data.next);
				}

				this.loading = false;
			}).catch(error => {
				this.loading = false;
			});
		},
		getFolderFiles(folder){
			this.library.images = [];
			this.library.cacheFolder = this.library.model.folder || '';
			this.library.model.folder = folder;
			this.getFiles();
		},
		loadMore(){
			this.getFiles();
		},
		backFolder(){
			this.library.model.folder = this.library.cacheFolder || null;
			this.library.images = [];
			this.library.cacheFolder = null;
			this.getFiles();
		},
		choosePicture(image){
			if(this.choosed.includes(image)) return this.choosed.splice(this.choosed.indexOf(image), 1);

			if(! this.choosed.includes(image)){
				if(! this.multiple) this.choosed = [];

				return this.choosed.push(image);
			}
		},
		uploadFiles(){
			this.$models.dashboard.store.uploadFiles(this.$route.params.store, this.input.upload)
			.then(result => {
				if(! Object.keys(result.data).length) return;

				Object.keys(result.data).forEach(index => {
					this.choosed.push(result.data[index]);
				});

				this.$parent.setPictures();
			}).catch(error => {
				console.log(error);
			});
		}
	},
	computed: {
		folders(){
			if(! (this.library && this.library.data && this.library.data.folders)) return [];

			return this.library.data.folders.map(prefix => {
				let split = prefix.Prefix.split('/');
				return split[split.length - 2];
			})
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
