<template>
	<form-vue preventHash :tabs="{
		'query': { label: 'קוביות בלוג', icon: 'receipt', groups: ['query'] },
		'visual': { label: 'תצוגה', icon: 'design_services', groups: ['round', 'gallery', 'gallery_options', 'layout-select'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'height', 'spacing'] }
	}">
		<form-group style="z-index: 1" name="query">
			<form-area>
				<query ref="query" :query="input.query" :sort="input.sort" :limit="input.limit"
					:search="['_id', 'title']" :uncache="['pages']" showSort
					:load="this.loadQuery.bind(this)"
				>
					<query-pages slot="filters" />
					<query-categories slot="filters" />

					<template slot="index" slot-scope="item">
						<div class="checkbox" v-if="input.query.pages">
							<input type="checkbox" :value="item._id" v-model="input.query.pages">
							<label></label>
						</div>
						<div><span>{{ item.title }}</span></div>
					</template>
				</query>
			</form-area>
		</form-group>


		<form-group name="layout-select" class="layout-select" style="z-index: 6">
			<div class="layout-card" v-for="l in layouts" @click="chooseLayout(l.name)"
				:class="{active: layout == l.name}" style="width:180px;height:180px;">
				<i v-for="i in l.layout" :style="i"></i>
				<div class="label">{{ l.label }}</div>
			</div>
		</form-group>

		<form-group style="z-index: 6" name="round" >
			<form-toggle name="visual_gallery" v-if="input.visual.layout != 'default' && input.visual.layout != 'hover'" v-model="toggleVisualRound" label="פינות עגולות" />
		</form-group>
		<form-group style="z-index: 5" name="gallery" >
			<form-toggle name="visual_gallery" v-model="toggleVisualGallery" label="הצג כגלריה" />
		</form-group>
		<form-group style="z-index:4" name="gallery_options">
			<template  v-if="input.visual.gallery">
				<form-toggle name="arrows" label="חצים" v-model="input.visual.gallery.arrows"  />
				<form-toggle name="dots" label="נקודות" v-model="input.visual.gallery.dots"  />
				<form-select name="interval" label="זמן החלפת תמונות" v-model="input.visual.gallery.interval" :options="[
						{ value: '0', label: 'ללא' },
						{ value: '1', label: 'מהיר' },
						{ value: '3', label: 'בינוני' },
						{ value: '6', label: 'איטי' },
					]"/>
			</template>
		</form-group>

		<form-group style="z-index:2" name="spacing">
			<form-toggle name="spacing-toggle" label="ריווח דיפולט" v-model="spacing" />
			<form-numbers name="spacing" label="ריווח" v-if="! spacing" v-model="input.settings.spacing" clear point />
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-select name="rowCount" v-model="input.settings.rowCount" label="כמות מקסימלית לתצוגה בשורה"
				:options="[{label: '1 בשורה', value: '1'}, {label: '2 בשורה', value: '2'},{label: '3 בשורה', value: '3'}, {label: '4 בשורה', value: '4'},{label: '5 בשורה', value: '5'},{label: '6 בשורה', value: '6'}]"
			/>
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>

	</form-vue>
</template>

<script>
export default {
	title: "קוביות בלוג",
	defaults: {
		type: 'blog',
		query: {
			type: 'blog'
		},
		sort: {},
		limit: { },
		visual: {
			layout: 'default'
		},
		settings: {
			rowCount: '2',
			spacing: '0',
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	methods: {
		loadQuery(query, sort, limit){
			return this.$models.pages.page.query(this.$route.params.store, { query, sort, limit })
		},
		chooseLayout(name){
			this.$set(this.input.visual, 'layout', name);

			if(name == 'default' || name == 'hover'){
				this.$delete(this.input.visual, 'round');
			}
		}
	},
	computed: {
		toggleVisualGallery: {
			get(){
				return (this.input.visual && this.input.visual.gallery != undefined);
			},
			set(value){
				if(!value) return this.$delete(this.input.visual, 'gallery');

				this.$set(this.input.visual, 'gallery', {arrows: true, dots: true, interval: '3'});
			}
		},
		toggleVisualRound: {
			get(){
				return (this.input.visual && this.input.visual.round != undefined);
			},
			set(value){
				if(!value) return this.$delete(this.input.visual, 'round');

				this.$set(this.input.visual, 'round', true);
			}
		},
		spacing: {
			get(){
				return this.input.settings.spacing == undefined;
			},
			set(value){
				if(! value) return this.$set(this.input.settings, 'spacing', '');

				this.$delete(this.input.settings, 'spacing');
			}
		},
		layout:{
			get(){
				return this.input.visual.layout;
			},
			set(value){
				let layout = { layout: value}

				this.$set(this.input, 'visual', layout);
			}
		},
		layouts(){
			return [
				{
					name: 'default',
					label: 'ברירת מחדל',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '70px',
							top: '0', left: '0', right: '0',
							margin: 'auto',
							zIndex: 1, opacity: 0.2
						},
						{
							height: '30px', width: '30px',
							top: '20px', left: '0', right: '0',
							margin: 'auto',
							borderRadius: '100px',
							backgroundColor: '#000',
							zIndex: 2, opacity: 1
						},
						{
							height: '10px', width: '90px',
							top: '80px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '100px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '114px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '128px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '20px', width: '50px',
							bottom: '10px', left: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						}
					]
				},
				{
					name: 'hover',
					label: 'מעבר עכבר',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							top: '0', left: '0', right: '0', bottom: '0',
							margin: 'auto',
							zIndex: 1, opacity: 0.2
						},
						{
							height: '30px', width: '30px',
							top: '40px', left: '0', right: '0',
							margin: 'auto',
							borderRadius: '100px',
							backgroundColor: '#000',
							zIndex: 2, opacity: 1
						},
						{
							height: '10px', width: '90px',
							top: '100px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						}
					]
				},
				{
					name: 'center',
					label: 'מרכז',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							top: '0', left: '0', right: '0', bottom: '0',
							margin: 'auto',
							zIndex: 1, opacity: 0.2
						},
						{
							height: '30px', width: '30px',
							top: '20px', left: '0', right: '0',
							margin: 'auto',
							borderRadius: '100px',
							backgroundColor: '#000',
							zIndex: 2, opacity: 1
						},
						{
							height: '10px', width: '90px',
							top: '80px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '100px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '114px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '140px',
							top: '128px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '20px', width: '50px',
							bottom: '10px', right: '0', left: '0',
							margin: 'auto',
							zIndex: 2, opacity: 1
						}
					]
				},
				{
					name: 'right',
					label: 'ימין',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '170px', width: '60px',
							top: '0', bottom: '0', right: '10px',
							margin: 'auto',
							zIndex: 1, opacity: 0.2
						},
						{
							height: '30px', width: '30px',
							top: '0', bottom: '0', right: '25px',
							margin: 'auto',
							borderRadius: '100px',
							backgroundColor: '#000',
							zIndex: 2, opacity: 1
						},
						{
							height: '10px', width: '60px',
							top: '20px', right: '80px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '60px', right: '80px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '74px', right: '80px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '88px', right: '80px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '20px', width: '50px',
							bottom: '10px', right: '80px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						}
					]
				},
				{
					name: 'left',
					label: 'שמאל',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '170px', width: '60px',
							top: '0', bottom: '0', left: '10px',
							margin: 'auto',
							zIndex: 1, opacity: 0.2
						},
						{
							height: '30px', width: '30px',
							top: '0', bottom: '0', left: '25px',
							margin: 'auto',
							borderRadius: '100px',
							backgroundColor: '#000',
							zIndex: 2, opacity: 1
						},
						{
							height: '10px', width: '60px',
							top: '20px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '60px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '74px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '6px', width: '90px',
							top: '88px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						},
						{
							height: '20px', width: '50px',
							bottom: '10px', right: '10px',
							margin: 'auto',
							zIndex: 2, opacity: 1
						}
					]
				}
			]
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
