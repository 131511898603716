const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Stock extends Model{

	constructor(){
		super();
	}

	attributes(store){
		return {
			"מידה": { type: "input" },
			"צבע": { type: "select", options: []},
			"משקל": { type: "numbers", point: true, clear: true },
			"תאריך": { type: "input" },
			"מוצא": { type: "input" },
			"יעד": { type: "input" }
		}
	}

	getTemplates(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/stock_templates').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	getTemplatesAttributes(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_templates_attributes';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/stock_templates/attributes').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	updateTemplate(store, name, attributes){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! name) return reject(new Error("Missing `name`"));
			if(! attributes) return reject(new Error("Missing `attributes`"));

			let data = {
				name: name,
				attributes: attributes.map(item => item.attributes)
			};

			this.$api.post(store+'/inventory/stock_templates', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	getStockColors(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_stock_colors';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/stock_colors').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	prePost(payload){
		let files = {};

		if(typeof payload.image != 'string'){
			files['image'] = payload.image;
		}

		let data = Cast(payload, {
			name: String,
			value: String,
			image: [{
				source: String
			}]
		});

		return {data, files};
	}

	updateStockColors(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/stock_colors', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	updateStock(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				price: Number,
				quantity: Cast.$custom((type) => {
					if(type == 'boolean') return Boolean;

					return Number
				})
			});

			this.$api.post(store+'/inventory/stock/'+id, data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Stock();
