<template>
	<div class="form-group" :class="{ hidden }">
		<div class="title" v-if="title">{{ title }}</div>
		<div class="group">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String },
		title: { type: String }
	},
	data(){
		return {
			hidden: false
		}
	},
	methods: {
		hide(){
			this.hidden = true;
		},
		show(){
			this.hidden = false;
		}
	}
}
</script>
