<template>
	<div class="layout forms client">

		<div class="back" @click="$router.push({ name: 'clients', params:{store: $route.params.store}})"></div>
		<div class="title">מועדון לקוחות</div>
		<div class="tabs">
			<div data-icon="account_circle" :class="{active: state.tab == 'client'}"
				@click="$router.push({query: {tab: 'client'}})">פרטים כללים</div>

			<div data-icon="shopping_cart" :class="{active: state.tab == 'products'}"
				@click="$router.push({query: {tab: 'products'}})">מוצרים</div>

			<div data-icon="receipt" :class="{active: state.tab == 'orders'}"
				@click="$router.push({query: {tab: 'orders'}})">הזמנות</div>
		</div>
		<div class="actions"></div>


		<transition name="fade" mode="out-in" v-if="client">
			<div class="container client" key="client" :class="{active: (state.tab == 'client')}" v-if="state.tab == 'client'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="person">פרטים אישיים</span>
					</div>
					<div class="content">
						<div class="rows">
							<div class="row"><span class="title">מס׳ טלפון</span><span class="value">{{ client.phone }}</span></div>
							<template v-if="client.customer">
								<div class="row"><span class="title">שם פרטי</span><span class="value">{{ client.customer.first_name ? client.customer.first_name : '' }}</span></div>
								<div class="row"><span class="title">שם משפחה</span><span class="value">{{ client.customer.last_name ? client.customer.last_name : '' }}</span></div>
								<div class="row"><span class="title">אימייל</span><span class="value">{{ client.customer.email }}</span></div>
								<div class="row" v-if="client.customer.birthday"><span class="title">תאריך יום הולדת</span><span class="value">{{ buildDate(client.customer.birthday, false) }}</span></div>
							</template>
						</div>
					</div>
				</div>
				<div class="data address" animated>
					<div class="options">
						<span data-icon="my_location">כתובת למשלוח/חשבונית</span>
					</div>
					<div class="content">
						<div class="rows" v-if="client.address">
							<div class="row" v-if="client.address.city"><span class="title">עיר</span><span class="value">{{ client.address.city }}</span></div>
							<div class="row" v-if="client.address.street"><span class="title">רחוב</span><span class="value">{{ client.address.street }}</span></div>
							<div class="row" v-if="client.address.house"><span class="title">דירה</span><span class="value">{{ client.address.house }}</span></div>
							<div class="row" v-if="client.address.floor"><span class="title">קומה</span><span class="value">{{ client.address.floor }}</span></div>
							<div class="row" v-if="client.address.entrance"><span class="title">כניסה</span><span class="value">{{ client.address.entrance }}</span></div>
							<div class="row" v-if="client.address.zip"><span class="title">מיקוד</span><span class="value">{{ client.address.zip }}</span></div>
						</div>
					</div>
				</div>

				<div class="data sms" animated>
					<div class="options">
						<span data-icon="sms">שלח sms</span>
					</div>
					<div class="content">
						<form-textarea name="credit" label="טקסט" v-model="sms.message" />
						<div class="buttons">
							<div class="historyButton" @click="showSmsHistory = !showSmsHistory">צפייה בהיסטוריה</div>
							<div class="button" data-icon="send" @click="sendSms">שלח</div>
						</div>

						<div class="index history" v-if="showSmsHistory && client.sms_log">
							<div class="rows">
								<div class="row" v-for="sms in client.sms_log" data-icon="sms" :key="sms.time">
									<span class="title">{{ sms.message }}</span>
									<span class="sub date">{{ buildDate(sms.time, true) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data credit" animated>
					<div class="options">
						<span data-icon="credit_card">קרדיט</span>
					</div>
					<div class="content">
						<div class="total" v-if="clientCredit">
							<div class="left">
								<span data-sign="₪">{{ clientCredit }}</span>
								<span>בחשבון</span>
							</div>
						</div>
						<form-numbers name="credit" label="קרדיט" v-model="model.credit" clear point style="z-index:2"/>
						<div class="buttons">
							<div class="historyButton" @click="showCreditHistory = !showCreditHistory">צפייה בהיסטוריה</div>
							<div class="button" data-icon="remove" @click="setCredit('remove')">הורד</div>
							<div class="button" data-icon="add" @click="setCredit('add')">הוסף</div>
						</div>
						<div class="index history" v-if="showCreditHistory && client.credit">
							<div class="rows">
								<div class="row"
									v-for="(credit,i) in credits"
									:key="i"
									:class="{red: (credit.credit < 0), green: (credit.credit > 0)}"
									:data-icon="(credit.credit < 0 ? 'south' : 'north')"
								>
									<span class="sub">{{ buildDate(credit.time, true) }}</span>
									<span class="title">{{ creditType(credit) }}</span>
									<span class="value">{{ Math.abs(credit.credit) }} ₪</span>
								</div>
							</div>
						</div>
					</div>

				</div>

				<div class="data chat" animated>
					<div class="options">
						<span data-icon="chat">שירות לקוחות</span>
					</div>
					<div class="content">
						<support class="data" v-if="client" :client="client._id" />
					</div>
				</div>
			</div>

			<div class="container products" key="products" :class="{active: (state.tab == 'products')}" v-if="state.tab == 'products'">
				<div class="data cart" animated>
					<div class="options">
						<span data-icon="shopping_cart">כרגע בסל קניות</span>
					</div>
					<div class="content">
						<div class="content" v-if="client && client.cart && Object.keys(client.cart).length && client.cartProducts">
							<div class="products" >
								<div class="product" v-for="(product,i) in cartProducts" :key="i">
									<div class="image" :style="`background-image: url('${product.images[0].source}')`" v-if="product.images"></div>
									<div class="title">{{ product.title }}</div>
									<div class="stocks">
										<div class="attributes" v-for="(stock, s) in product.stocks" :key="s">
											<div class="row" >
												<span class="title">
													<span v-for="(attribute, a) in stock.attributes" :key="a">{{`${attribute.key}: ${attribute.value}`}}</span>
												</span>
												<span class="value">{{ stock.time }}</span>
											</div>
											<div class="quantity">כמות: {{ stock.inCart }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data favorites" animated>
					<div class="options">
						<span data-icon="favorite_border">פריטים שאהבתי</span>
					</div>
					<div class="content">
						<div class="content" v-if="client && client.favorites && client.favorites.length">
							<div class="products favorites" >
								<div class="product" v-for="(product, i) in client.favorites" :key="i">
									<div class="image" :style="`background-image: url('${product.images[0].source}')`" v-if="product.images"></div>
									<div class="title">{{ product.title }}</div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<div class="data notify" animated>
					<div class="options">
						<span data-icon="notifications_none">עדכנו אותי כשחוזר למלאי</span>
					</div>
					<div class="content">
						<div class="content" v-if="client && client.notify && client.notify.length && client.notifyProducts">
							<div class="products">
								<div class="product" v-for="(product, i) in notifyProducts" :key="i">
									<div class="image" :style="`background-image: url('${product.images[0].source}')`" v-if="product.images"></div>
									<div class="title">{{ product.title }}</div>
									<div class="stocks">
										<div class="attributes" v-for="(stock, s) in product.stocks" :key="s">
											<div class="row" >
												<span class="title">
													<span v-for="(attribute, a) in stock.attributes" :key="a">{{`${attribute.key}: ${attribute.value}`}}</span>
												</span>
												<span class="value">{{ stock.time }}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container orders" key="orders" :class="{active: (state.tab == 'orders')}" v-if="state.tab == 'orders'">
				<div class="data orders" animated>
					<div class="options">
						<span data-icon="receipt">הזמנות</span>
					</div>
					<div class="content">
						<index ref="orders" class="orders" :data="orders">
							<order-row slot="index" slot-scope="order" :order="order"></order-row>
						</index>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import moment from 'moment';
moment.locale('he');

export default {
	components:{
		'order-row': require('@/components/order-row').default
	},
	data(){
		return {
			state: {
				tab: ''
			},
			client: null,
			showCreditHistory: false,
			showSmsHistory: false,
			profile: {
				state: 'person'
			},
			products: {
				state: 'cart'
			},
			sms: {
				message: ''
			},
			model: {
				credit: ''
			}
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		this.getData().then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'client')
		});
	},
	methods: {
		setTab(tab){
			if(['client', 'products', 'orders'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			return this.$models.dashboard.client.get(this.$route.params.store, this.$route.params.client)
			.then((res) => {
				this.client = res.data;
				this.$root.loading = false;
			}).catch(({response}) => {
				this.errors = response.data.errors;
			});
		},
		setCredit(type){
			if(! this.model.credit) return;

			if(type == 'remove' && this.model.credit > this.clientCredit){
				this.model.credit = '';

				return VueAlerts.alert({
					title: 'אירע שגיאה!',
					text: 'לא ניתן להוריד יותר קרדיט ממה שקיים ללקוח'
				});
			}

			this.$models.dashboard.client.updateCredit(this.$route.params.store, this.$route.params.client, {
				credit: this.model.credit, type
			})
			.then(response => {
				this.model.credit = '';
				this.getData();
			})
			.catch(error => {
				if(error.response.data.code == 400){
					return VueAlerts.alert({
						title: 'אירע שגיאה!',
						text: 'לא ניתן להוריד יותר קרדיט ממה שקיים ללקוח'
					});
				}
			})
		},
		creditType(credit){
			if(credit.cancelation) return 'ביטול הזמנה';
			if(credit.order) return 'הזמנה';
			if(credit.modification) return 'החזרות והחלפות';

			return 'מערכת'
		},
		buildDate(obj, full){
			let date = new Date(obj);
			if(! full) return `${date.getDate()}/${(date.getMonth() + 1)}/${date.getFullYear()}`;

			return date.getDate() + '/' + (date.getMonth() + 1)  + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + ((date.getMinutes()<10?'0':'') + date.getMinutes());
		},
		sendSms(){
			if(! this.sms.message) return;

			this.$models.dashboard.client.sendSms(this.$route.params.store, this.$route.params.client, {message: this.sms.message})
			.then(response => {
				return VueAlerts.flash({
					text: 'ההודעה נשלחה ללקוח'
				});
			})
			.catch(error => {
				if(error.response.data.code == 400){
					return VueAlerts.alert({
						title: 'אירע שגיאה!',
						text: 'אנה נסה מאוחר יותר'
					});
				}
			})
		}
	},
	computed: {
		orders(){
			if(! this.client.orders) return null;

			return this.client.orders.reverse();
		},
		clientCredit(){
			if(! this.client.credit) return null;

			return this.client.credit.reduce((total, credit) => {
		        return total + credit.credit;
		    }, 0);
		},
		credits(){
			if(! this.client.credit) return null;

			return this.client.credit.reverse();
		},
		notifyProducts(){
			if(! (this.client.notify && this.client.notify.length && this.client.notifyProducts && this.client.notifyProducts.length)) return [];

			let products = [];

			this.client.notifyProducts.forEach(product => {
				let stocks = [];

				product.stocks.forEach(stock => {
					this.client.notify.forEach(st => {
						if(st.stockId == stock._id && ! st.sended_time){
							stock.time = moment(st.lastUpdate).format("DD/MM/YYYY");
							stocks.push(stock);
						}
					});

				});
				if(stocks.length){
					product.stocks = stocks;

					products.push(product);
				}
			})

			return products;
		},
		cartProducts(){
			if(! (this.client.cart && Object.keys(this.client.cart).length && this.client.cartProducts && this.client.cartProducts.length)) return [];

			let products = [];

			this.client.cartProducts.forEach(product => {
				let stocks = [];

				product.stocks.forEach(stock => {
					Object.keys(this.client.cart).forEach(st => {
						if(st == stock._id){
							stock.inCart = this.client.cart[st].reduce((sum, c) => sum + c.quantity, 0);
							stocks.push(stock);
						}
					});
				});

				product.stocks = stocks;

				products.push(product);
			})

			return products;
		}
	}
}
</script>
