const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Tags extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			tags: []
		}
	}

	get(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = [store].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/layout/tags')
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					tags: [{
						title: String,
						position: String,
						code: String
					}]
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	update(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				tags: [{
					title: String,
					position: String,
					code: String
				}]
			});

			this.$api.post('dashboard/store/'+store+'/layout/tags', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Tags()
