<template>
	<div class="layout newsletter" :class="{loading}">

		<div class="top">
			<div class="title">ניוזלטר</div>
		</div>

		<div class="index-filters" v-if="items.length">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['email', 'date'], value })" />
		</div>

		<index ref="index" :data="items" v-if="items.length">
			<div slot="header" slot-scope="items" class="table header">
				<div>אימייל</div>
				<div>תאריך הרשמה</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item" :class="{ hide: item.$filtered }">
				<div class="title">
					<span>{{ item.email }}</span>
				</div>
				<div class="message">
					<span class="date">{{ item.date }}</span>
				</div>
				<div class="actions">
					<form-toggle name="active" label="" v-model="items[item.$index].active" @toggle="toggle(item)"/>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: []
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.support.newsletter.index(this.$route.params.store)
			.then((res) => {
				if(res.data) this.items = res.data.map(item => {
					item.date = this.buildDate(item.time);
					return item;
				}).reverse();

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		toggle(item){
			this.$models.support.newsletter[item.active ? 'remove' : 'restore'](this.$route.params.store, item._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		addZero(number){
			if(number < 10){
				number = "0" + number;
			}

			return number;
		},
		buildDate(item){
			let date = new Date(item);

			let resolve = [this.addZero(date.getDate()), this.addZero(date.getMonth() + 1), date.getFullYear()];
			let times = [this.addZero(date.getHours()), this.addZero(date.getMinutes())];

			return resolve.join('-') + ' ' + times.join(':');
		}
	}
}
</script>
