<template>
	<div class="form-field numbers">
		<label>{{ label }}</label>
		<input ref="input" type="tel" :name="name" :disabled="disabled" :placeholder="placeholder"
			v-model="input" @keydown="keyHanlder" autocomplete="off" />
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: String, required: true },
		point: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		placeholder: { type: String, default: '' }
	},
	methods: {
		keyHanlder(e){
			if(! e.key) return e.preventDefault();

			let key = e.key.toLowerCase(),
				keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "backspace"];

			if(key == '.' && this.point && this.input.length != 0 && !this.input.includes('.')) return;

			if(! keys.includes(key)) return e.preventDefault();
		}
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
