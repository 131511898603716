<template>
	<div class="layout forms products">
		<div class="back" @click="state == 'media' ? state = 'details' : $router.push({name: 'inventory'})"></div>
		<div class="title fixed">יצירת מוצר חדש</div>
		<div class="actions">
			<div data-icon="keyboard_arrow_left" @click="state = 'media'" v-if="state == 'details'">המשך</div>
			<div data-icon="save" @click="submit" v-else>שמירה</div>
		</div>

		<transition name="fade" mode="out-in">

			<div class="container details" :class="{active: state == 'details'}" key="details" v-if="state =='details'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטי המוצר</span>
					</div>
					<div class="content">
						<form-input name="title" label="כותרת" v-model="model.title" />
						<form-input name="slogen" label="תיאור קצר" v-model="model.slogen" />
						<!-- <form-input name="sku" label="מק״ט" v-model="model.sku" /> -->
					</div>
				</div>

				<div class="data pricing" animated>
					<div class="options">
						<span data-icon="donut_small">תמחור</span>
					</div>
					<div class="content">
						<form-numbers name="price" label="מחיר בסיס" v-model="model.price" clear point />
						<!-- <form-numbers name="price_before_discount" label="מחיר לפני הנחה" v-model="model.price_before_discount" clear point /> -->
						<!-- <form-numbers name="price_cost" label="מחיר עלות" v-model="model.price_cost" clear point /> -->
					</div>
				</div>
			</div>

			<div class="container media" key="media" :class="{active: (state == 'media')}" v-if="state == 'media'">
				<div class="data">
					<div class="options">
						<span data-icon="play_circle_outline">וידאו</span>
					</div>
					<div class="content" animated>
						<form-input name="video" label="סרטון Vimeo" v-model="model.video" />
					</div>
				</div>
				<index class="data uploader" :data="images" sort-by="source" @sort="setImagesSort">
					<template slot="header">
						<div animated>
							<form-file name="images" v-model="images" accept="image/*" title="הוספת תמונות" description="אפשר לגרור תמונות לכאן" multiple />
							<!-- <form-file name="video" v-model="video" accept="video/*" library
								icon="play_circle_outline" title="הוספת וידאו" description="אפשר לגרור וידאו לכאן">

								<div class="actions" slot="actions">
									<div class="action">הוספה דרך YouTube</div>
									<div class="action">הוספה דרך Vimeo</div>
								</div>
							</form-file> -->
						</div>
					</template>

					<div slot="index" slot-scope="image" :key="image.$index" :style="`z-index: ${images.length - image.$index}`" animated>
						<div class="image-card" :draggable="image.$sort.active" @dragover="image.$sort.move" @dragend="image.$sort.end">
							<div class="image" :style="`background-image: url('${image.source}')`" @mousedown="image.$sort.activate" @mouseup="image.$sort.end"></div>
							<form-input name="title" label="" v-model="image.title" placeholder="כותרת"/>
							<form-select name="color" label="צבע" :filter="true" v-model="image.attributes[0].value" :options="colors" placeholder="שיוך לצבע"/>
							<div class="delete" @click="model.images.splice(image.$index, 1)">הסר תמונה</div>
						</div>
					</div>
				</index>
			</div>

		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			state: 'details',
			model: this.$models.inventory.product.model(),
			colorsList: [],
			video: []
		}
	},
	mounted(){
		this.getColors()
	},
	methods: {
		getColors(){
			return this.$models.inventory.stock.getStockColors(this.$route.params.store)
			.then(result => {
				if(! result.data[0]) return;

				this.colorsList = result.data[0].colors || [];
			});
		},
		setImagesSort(){
			this.model.images.sort((a, b) => a.__sort - b.__sort);
		},
		submit(){
			this.$root.loading = true;

			this.$set(this.model.page, 'active', true);
			this.$set(this.model.page, 'slug', this.model.title.split(' ').join('_'));
			this.$set(this.model.page.seo, 'title', this.model.title);

			this.$models.inventory.product.create(this.$route.params.store, this.model)
			.then((res) => {
				VueAlerts.alert({
					icon: "done",
					title: "המוצר נוצר בהצלחה",
					text: `המוצר ${this.model.title} נוצר בהצלחה.`,
					buttons: ["סיימתי", "הוסף מוצר נוסף", "המשך לעריכה"]
				}).then(action => {
					if(action == "סיימתי") return this.$router.push({name: 'inventory'});
					if(action == "המשך לעריכה") return this.$router.push({name: 'inventory/products/edit', params: {product: res.data.id}});

					this.model = this.$models.inventory.product.model();
					this.state = "details";
					this.$root.loading = false;
				})
			}).catch(error => {
				this.$root.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	},
	computed: {
		images: {
			get(){
				return this.model.images.map(file => {
					file.attributes = [file.attributes[0] || {key: 'צבע', value: ''}];

					return file;
				});
			},
			set(images){
				images.splice(0, this.model.images.length);

				images.forEach(file => {
					this.model.images.push({
						file,
						source: URL.createObjectURL(file),
						title: '',
						attributes: [{key: 'צבע', value: ''}]
					})
				})
			}
		},
		colors(){
			let options = [{value: '', label: 'ללא צבע'}];

			return options.concat(this.colorsList.map(color => {
				color.icon = color.image || color.color;
				return color;
			}));
		}
	}
}
</script>
