<template>
	<div class="layout" :class="{loading}">

		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>{{ this.$route.params.id ? 'עריכת' : 'הוספת' }} {{ pageType }}</span>
			</div>

			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>

		</div>

		<form-vue ref="form" :tabs="{
			'general': { label: 'פרטי הדף', icon: 'receipt', groups: ['general', 'categories', 'gallery', 'description', 'content', 'query_buttons', 'query'] },
			'seo': { label: 'SEO', icon: 'receipt', groups: ['seo'] },
			'layout': { label: 'נראות', icon: 'phonelink', groups: ['layout'] }
		}" >

			<form-group name="general" title="פרטי הדף" style="z-index: 8">
				<form-input name="title" label="כותרת" v-model="model.data.title" />
				<form-toggle name="active" label="מוצג" v-model="model.active" />
			</form-group>

			<form-group name="categories" title="קטגוריות" style="z-index: 7" v-if="model.type == 'blog'">
				<form-checkbox v-if="categories.length" name="cats" label="בחר קטגוריות"  v-model="model.data.categories"
				:options="categories"/>
			</form-group>

			<form-group name="gallery" style="z-index: 6">
				<form-file name="images" label="תמונות" v-model="images" multiple />
			</form-group>

			<form-group name="gallery" style="z-index: 5">
				<form-area class="images-preview" v-if="model.data.images.length">
					<index :data="model.data.images" sort-by="source" @sort="setImagesSort">
						<template slot="index" slot-scope="item" >
							<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
								<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" v-if="currentPictureEdit == null"></div>
								<div class="image" :style="`background-image: url('${item.source}')`"></div>

								<div class="info">{{ item.title }}</div>

								<div class="actions">
									<div class="button error" data-icon="delete" @click="removePicture(item)"></div>
									<div class="button"
										:data-icon="currentPictureEdit == item.$index ? 'close' : 'create'"
										@click="editPicture(item.$index)"></div>
								</div>
							</div>

							<form-group v-if="currentPictureEdit == item.$index" style="z-index: 2">
								<form-input name="title" label="כותרת" v-model="model.data.images[item.$index].title" />
							</form-group>
						</template>
					</index>
				</form-area>
			</form-group>

			<form-group name="description" title="" style="z-index: 4" v-if="model.type == 'blog'">
				<form-textarea name="description" label="תיאור קצר" v-model="model.data.description" />
			</form-group>

			<form-group name="content" title="" style="z-index: 3">
				<form-editor name="content" label="תוכן העמוד" v-model="model.data.content" />
			</form-group>

			<form-group name="query_buttons" title="" v-if="model.type == 'search'">
				<form-area>
					<div class="autogrid">
						<div class="button large" data-icon="check_box" @click="setQueryTemplate('all')">כל המוצרים</div>
						<div class="button large" data-icon="shopping_bag" @click="setQueryTemplate('best')">10 המוצרים הכי נמכרים</div>
						<div class="button large" data-icon="favorite" @click="setQueryTemplate('favorites')">10 המוצרים הכי אהובים</div>
						<div class="button large" data-icon="fiber_new" @click="setQueryTemplate('recents')">10 המוצרים הכי חדשים</div>
					</div>
				</form-area>
			</form-group>

			<form-group name="query" title="" v-if="model.type == 'search'">
				<form-area>
					<query ref="query" :query="model.query" :sort="model.sort" :limit="model.limit" priority showSort
						:search="['_id', 'title']" :uncache="['products']"
						:load="this.loadQuery.bind(this)"
					>
						<query-products slot="filters" />
						<query-price slot="filters" />
						<query-categories slot="filters" />
						<query-quantity slot="filters" />
						<query-properties slot="filters" />

						<template slot="index" slot-scope="item">
							<div class="checkbox" v-if="model.query.products">
								<input type="checkbox" :value="item._id" v-model="model.query.products">
								<label></label>
							</div>

							<div><span>{{ item.title }}</span></div>
							<div><span>₪{{ item.price }}</span></div>
							<div><span>{{ (! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי')) }}</span></div>

						</template>
					</query>
				</form-area>
			</form-group>

			<form-group name="seo" title="ניהול SEO" style="z-index: 2">
				<form-input name="slug" label="כתובת URL" v-model="slug" />
				<form-input name="seoTitle" label="כותרת" v-model="model.seo.title" />
			</form-group>
			<form-group name="seo"  style="z-index: 1">
				<form-input name="description" label="תיאור" v-model="model.seo.description" />
				<form-input name="keywords" label="מילות מפתח" v-model="model.seo.keywords" />
			</form-group>

			<form-group name="layout" >
				<form-toggle name="default" label="ברירת מחדל" v-model="customize" />
			</form-group>
			<form-group name="layout">
				<form-area v-if="! customize">
					<fs v-model="model.layout" ref="layout"
					:globals="pages[model.type].globals || []"
					:components="pages[model.type].components" v-if="pages && model.type" />
				</form-area>
			</form-group>
		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			categories: [],
			model: this.$models.pages.page.model(),
			pages: this.$models.layout.web.pages(),
			cacheLayout: null,
			currentPictureEdit: null
		}
	},
	mounted(){
		if(this.$route.params.id){
			this.getData();
		} else {
			this.model.type = this.$route.params.type ||  this.$route.params.id;
			this.model.layout = this.model.type;
			this.loading = false;
		}

		this.getCategories();
	},
	methods:{
		loadQuery(query, sort, limit){
			let q = Object.assign(JSON.parse(JSON.stringify(query)), {products: undefined});
			return this.$models.inventory.product.query(this.$route.params.store, { query: q, sort, limit })
		},
		setQueryTemplate(template){
			let templates = {
				'all': {
					query: {},
					sort: {},
					limit: {}
				},
				'best': {
					query: {},
					sort: {
						priority: { type: 'orders', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				},
				'favorites': {
					query: {},
					sort: {
						priority: { type: 'favorites', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				},
				'recents': {
					query: {},
					sort: {
						priority: { type: 'recents', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				},

			}

			this.model.query = templates[template].query;
			this.model.sort = templates[template].sort;
			this.model.limit = templates[template].limit;

			this.$refs.query.unBlockFilters();
		},
		getData(){
			 this.$models.pages.page.get(this.$route.params.store, this.$route.params.id)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		getCategories(){
			this.$models.inventory.category.options(this.$route.params.store)
			.then((res) => {
				if(res.data) this.categories = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;

			let request = null;
			let create = false;

			if(this.$route.params.id){
				request = this.$models.pages.page.update(this.$route.params.store, this.$route.params.id, this.model, (this.$refs.layout ? this.$refs.layout.files : {}) )
			}
			else{
				create = true;
				request = this.$models.pages.page.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if(this.model.type){
					this.model.layout = res.data.layout || [];

					if(this.$refs.layout) this.$refs.layout.resetEditors();
				}

				if((! this.$route.params.id) || create){
					return this.$router.push({ name: 'web', params:{store: this.$route.params.store}});
				}

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});

		},
		editPicture(index){
			if(this.currentPictureEdit == index) return this.currentPictureEdit = null;

			this.currentPictureEdit = index
		},
		removePicture(image){
			delete this.model.data.images[image.$index];
			this.model.data.images = this.model.data.images.filter(e => e != null);
		},
		setImagesSort(sort){
			this.model.data.images.sort((a, b) => a.__sort - b.__sort);
		},
	},
	computed: {
		pageType(){
			if(! this.model) return;
			if(this.model.type == 'search') return 'דף חיפוש';
			if(this.model.type == 'blog') return 'דף בלוג';
			return 'דף סטטי';
		},
		customize:{
			get(){
				return Boolean(typeof this.model.layout == 'string')
			},
			set(value){

				if(value){
					this.cacheLayout = this.model.layout;

					return this.$set(this.model, 'layout', this.model.type);
				}

				this.$set(this.model, 'layout', this.cacheLayout || this.model.original_layout || []);
			}
		},
		slug:{
			get(){
				return this.model.slug;
			},
			set(value){
				this.model.slug = value.split(' ').join('_');
			}
		},
		images: {
			get(){
				return this.model.data.images.map(image => image.source);
			},
			set(images){
				images.splice(0, this.model.data.images.length);

				images.forEach(file => {
					this.model.data.images.push({
						file,
						source: URL.createObjectURL(file),
						title: '',
						attributes: []
					})
				})
			}
		}
	}
}
</script>
