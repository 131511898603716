<template>
	<div class="row-cart">
		<div class="title">הזמנה מקורית</div>
		<div class="item" v-for="(item, i) in items" :key="i">
			<div class="stockBox">
				<div class="image" :style="`background-image: url('${item.product.images[0].source}')`" v-if="item.product.images"></div>
				<div class="title">{{ item.product.title }}</div>

				<div class="stock" v-if="item.stock.title">{{ item.stock.title }}</div>
				<div class="stock" v-else>
					<div v-for="(attr) in item.stock.attributes" :key="attr.key"><span>{{ attr.key }}: {{ attr.value }}</span></div>
				</div>

				<div class="parameters" v-if="item.cart.parameters && item.parameters" >
					<template v-for="(parameters, group) in item.cart.parameters">
						<span v-if="item.parameters[group]" :key="group">{{ item.parameters[group] }}: {{ Object.fromEntries(item.product.parameters.map(g => [g.id, g]))[group].type == 'input' ? item.cart.parameters[group][0] : parameters.map(param => item.parameters[`${group}:${param}`]).join(', ') }}</span>
					</template>
				</div>

				<div class="discount" v-if="item.stock.discount">
					{{ discount(item.stock) }}
				</div>

				<div class="price" :data-old-price="(item.stock.discount ? item.stock.price : (item.stock.unitPrice > item.stock.price ? item.stock.unitPrice : ''))">₪ {{ item.stock.discount ? item.stock.discount.price : item.stock.price }}</div>

				<div class="amount">{{ item.stock.inCart }}</div>
				<div class="totalPrice">₪ {{ (item.stock.discount ? item.stock.discount.price : item.stock.price) * item.stock.inCart }}</div>
			</div>
		</div>
		<div class="summary" v-if="order.summary">
			<div class="discount"><span>מחיר</span><span>{{ order.summary.price }} ₪</span></div>
			<div class="shipping" v-if="order.delivery"><span>{{order.delivery.type == 'pickup' ? 'איסוף עצמי: ' : ''}}{{ order.delivery.title }}</span><span>{{ order.summary.delivery }} ₪</span></div>

			<div class="discount" v-if="order.coupon"><span>קופון: {{ order.coupon.title }}</span><span></span></div>
			<div class="discount" v-if="order.summary.credit"><span>זיכוי</span><span>{{ order.summary.credit }} ₪ -</span></div>
			<div class="discount" v-if="order.summary.discount"><span>חסכון בקנייה זו</span><span>{{ order.summary.discount }} ₪ -</span></div>

			<div class="total"><span>סה״כ מחיר</span><span>{{ order.summary.total }} ₪</span></div>
		</div>


		<div class="modifications" v-if="order.modification">
			<div class="title">החלפות והחזרות</div>
			<div class="item" v-for="(request,i) in modifications" :key="i">
				<div class="stockBox original">
					<div class="image" :style="`background-image: url('${request.order.stock.product.images[0].source}')`" v-if="request.order.stock.product.images"></div>
					<div class="title">{{ request.order.stock.product.title }}</div>

					<div class="stock" v-if="request.order.stock.title">{{ request.order.stock.title }}</div>
					<div class="stock" v-else>
						<div v-for="(attr) in request.order.stock.attributes" :key="attr.key"><span>{{ attr.key }}: {{ attr.value }}</span></div>
					</div>

					<div class="parameters" v-if="request.order.parameters && params" >
						<template v-for="(parameters, group) in request.order.parameters">
							<span v-if="params[group]" :key="group">{{ params[group] }}: {{ parameters.map(param => params[`${group}:${param}`]).join(', ') }}</span>
						</template>
					</div>

					<div class="status" :class="request.type">{{ request.type == 'replace' ? 'החלפה' : (request.type == 'collect' ? 'החזרה' : 'מוצר פגום')}}</div>

					<div class="totalPrice">- ₪ {{ (request.order.stock.discount ? request.order.stock.discount.price : request.order.stock.price) }}</div>
				</div>
				<div class="stockBox" v-if="request.type == 'replace'">
					<div class="image" :style="`background-image: url('${request.modification.stock.product.images[0].source}')`" v-if="request.modification.stock.product.images"></div>
					<div class="title">{{ request.modification.stock.product.title }}</div>

					<div class="stock" v-if="request.modification.stock.title">{{ request.modification.stock.title }}</div>
					<div class="stock" v-else>
						<div v-for="(attr) in request.modification.stock.attributes" :key="attr.key"><span>{{ attr.key }}: {{ attr.value }}</span></div>
					</div>

					<div class="parameters" v-if="request.modification.parameters && params" >
						<template v-for="(parameters, group) in request.modification.parameters">
							<span v-if="params[group]" :key="group">{{ params[group] }}: {{ parameters.map(param => params[`${group}:${param}`]).join(', ') }}</span>
						</template>
					</div>

					<div class="totalPrice">₪ {{ (request.modification.stock.discount ? request.modification.stock.discount.price : request.modification.stock.price) }}</div>
				</div>
			</div>

			<div class="summary">
				<div class="collect" v-if="order.modification.collect">
					<span>איסוף: {{ `${order.modification.collect.title} ${order.modification.collect.includeShipping ? ' - כולל משלוח חזרה' : ''}` }}</span>
					<span>{{ selections.includes('defect') ? order.modification.collect.prices.defect : order.modification.collect.prices.collect }} ₪</span>
				</div>
				<div class="delivery" v-if="order.modification.delivery">
					<span>משלוח: {{ `${order.modification.delivery.title}` }}</span>
					<span>{{ order.modification.delivery.price }} ₪</span>
				</div>
				<div class="status" v-if="order.modification.stage.type != 'resolved'"><span>{{ getStage(order.modification.stage) }}</span><span></span></div>
				<div class="total"><span>סה״כ מחיר</span><span>{{ order.modification.result }} ₪</span></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		order: { type: Object, required: true}
	},
	data(){
		return {

		}
	},
	methods: {
		discount(stock){
			return (
				stock.discount.title ? stock.discount.title :
				( stock.discount.percentage ? stock.discount.percentage+'% הנחה' :
					(stock.discount.price ? 'מחיר מבצע '+stock.discount.price+'₪' : '')
				)
			);
		},
		getStage(stage){
			let text = "";

			switch(stage.type){
				case "credit_refound": text = `זוכה בחשבון ${stage.request ? 'וקיימת בקשה לזיכוי לכרטיס האשראי' : ''}`; break;
				case "awaiting_payment": text = "הלקוח צריך לשלם תוספת לשינוי"; break;
				case "payment_refounded": text = `זוכה בכרטיס האשראי - ${stage.amount}₪`; break;
				case "conflict": text = `הזמנה חריגה - נא לטפל בהזמנה בצורה ידנית`; break;
			}

			return text;
		}
	},
	computed: {
		selections(){
			let selections = [];

			Object.values(this.order.modification.request).forEach(item => selections.includes(item.type) || selections.push(item.type));

			return selections;
		},
		stocks(){
			let stocks = {};

			this.order.products.forEach(product => product.stocks.forEach(stock => {
				stocks[stock._id] = stock;
				stocks[stock._id].product = product
			}))

			if(this.order.modification && this.order.modification.products){
				this.order.modification.products.forEach(product => product.stocks.forEach(stock => {
					stocks[stock._id] = stock;
					stocks[stock._id].product = product
				}))
			}

			return stocks;
		},
		params(){
			let parameters = {};

			this.order.products.forEach(product => {
				if(product.parameters){
					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' (₪'+ param.price +')' : ''}`);
					})
				}
			});

			return parameters;
		},
		cart(){
			let fullCart = this.order.cart;

			let keys = Object.keys(fullCart);

			if(keys.length && fullCart[keys[0]].constructor === Number){
				fullCart = Object.fromEntries(keys.map(stock => [stock, [{quantity: fullCart[stock]}]]));
			}

			return fullCart;
		},
		items(){
			if(! (this.order && this.order.products.length)) return [];

			let items = [], parameters;

			this.order.products.forEach(product => {

				if(product.parameters){
					parameters = {};

					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' (₪'+ param.price +')' : ''}`);
					})
				}

				product.stocks.forEach(stock => {
					if(! this.cart[stock._id]) return;

					this.cart[stock._id].forEach(cart => {
						items.push({
							product,
							stock,
							parameters,
							cart
						})
					})
				})
			})

			return items;
		},
		modifications(){
			if(! (this.order && this.order.modification)) return [];

			let fullModificationRequest = this.order.modification.request;

			if(fullModificationRequest.constructor == Array){
				let modification = {};

				fullModificationRequest.forEach((request,key) => {
					if(! modification[request.stock]) {
						modification[request.stock] = [];

						let payload = {quantity: 1};

						if(request.type == 'replace'){
							payload.replace = {[key]: {
								stock: request.replacement
							}};
						}

						if(request.type == 'collect' || request.type == 'defect'){
							payload[request.type] = {[key]: {
								stock: request.stock,
								note: request.note || ''
							}}
						}

						return modification[request.stock].push(payload);
					}

					modification[request.stock].quantity = modification[request.stock].quantity+1;

					if(request.type == 'replace'){
						if(! modification[request.stock].replace) modification[request.stock].replace = {};

						modification[request.stock].replace[key] = {
							stock: request.replacement
						}
					}

					if(request.type == 'collect' || request.type == 'defect'){
						if(! modification[request.stock][request.type]) modification[request.stock][request.type] = {};

						modification[request.stock][request.type][key] = {
							stock: request.stock,
							note: request.note || ''
						}
					}


				});

				fullModificationRequest = modification;
			}

			return Object.keys(fullModificationRequest).reduce((result, stockId) => {
				fullModificationRequest[stockId].forEach(request => {

					if(request.collect) {
						Object.values(request.collect).forEach(collect => result.push({
							type: 'collect',
							order: {
								stock: this.stocks[stockId],
								parameters: request.parameters
							},
							modification: {
								note: collect.note
							}
						}))
					}

					if(request.replace){
						Object.values(request.replace).forEach(replace => result.push({
							type: 'replace',
							order: {
								stock: this.stocks[stockId],
								parameters: request.parameters
							},
							modification: {
								stock: this.stocks[replace.stock],
								parameters: replace.parameters
							}
						}))
					}

					if(request.defect) {
						Object.values(request.defect).forEach(defect => result.push({
							type: 'defect',
							order: {
								stock: this.stocks[stockId]
							},
							modification: {
								note: defect.note
							}
						}))
					}
				})

				return result;
			}, []);
		}
	}
}
</script>
