<template>
	<div class="layout" :class="{loading}">

		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: $route.params.store }}"></router-link>
				<span>{{ pages[$route.params.name].title }}</span>
			</div>

			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form" >
			<form-group name="layout">
				<form-area  v-if="model.layout">
					<fs ref="layout" v-model="model.layout"
						:components="this.pages[this.$route.params.name].components"
						:globals="this.pages[this.$route.params.name].globals || []"
					/>
				</form-area>
			</form-group>
		</form-vue>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			pages: this.$models.layout.web.pages(),
			model: {
				name: this.$route.params.name,
				source: 'web',
				layout: []
			}
		}
	},
	mounted(){
		this.getData();
	},
	methods:{
		getData(){
			this.$models.pages.defaults.get(this.$route.params.store, this.$route.params.name)
			.then(res => {
				this.model.layout = res.data.layout || [];

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;

			this.$models.pages.defaults.update(this.$route.params.store, this.model, this.$refs.layout.files)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.model.layout = res.data.layout || [];

				this.$refs.layout.resetEditors();

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});

		}
	},
	computed: {
		globals(){
			if(this.$route.params.name == 'header' || this.$route.params.name == 'footer') return [];

			if(this.$route.params.name == 'product') return this.pages[this.comp]
		}
	}
}
</script>
