<template>
	<div class="layout coupons" :class="{ loading }">

		<div class="top">
			<div class="title">ניהול קופונים</div>

			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('inventory/coupons/create')">הוספה</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['title'], value })" />
		</div>

		<index ref="index" :data="items" v-if="items">
			<div slot="header" slot-scope="items" class="table header">
				<div>פרטי הקופון</div>
				<div></div>
			</div>

			<div slot="index" slot-scope="item" class="table" :class="{ hide: item.$filtered }" >
				<div class="title"><span>{{ item.title }}</span><span>קוד קופון: {{ item.code }}</span></div>

				<div class="actions">
					<form-toggle name="active" label="" v-model="items[item.$index].active" @toggle="toggle(item)"/>
					<div class="button" data-icon="create" @click="goTo('inventory/coupons/edit', item._id)">ערוך</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.inventory.coupon.all(this.$route.params.store)
			.then((res) => {
				this.items = res.data;
				this.loading = false;
			}).catch(({response}) => {
				this.errors = response.data.errors;
			});
		},
		goTo(name, coupon = null){
			if(coupon)
				return this.$router.push({ name, params: {store: this.$route.params.store, coupon} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		toggle(coupon){
			this.$models.inventory.coupon[coupon.active ? 'remove' : 'restore'](this.$route.params.store, coupon._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	}
}
</script>
