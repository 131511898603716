<template>
	<div class="form-field editor">
		<label>{{ label }}</label>
		<div class="toolbar" :class="{disabled: link}">
			<i class="material-icons-round" @mousedown.prevent="exec('bold')">format_bold</i>
			<i class="material-icons-round" @mousedown.prevent="exec('italic')">format_italic</i>
			<i class="material-icons-round" @mousedown.prevent="exec('underline')">format_underlined</i>
			<i class="material-icons-round" @mousedown.prevent="exec('strikeThrough')">strikethrough_s</i>
			<span></span>
			<div :class="{ active: box == 'forecolor' }">
				<i class="material-icons-round" @mousedown.prevent="toggle('forecolor')">brush</i>
				<div>
					<span v-for="color in colors" class="color"
						:style="`background: ${color}`" @mousedown.prevent="exec('forecolor', color)"></span>
				</div>
			</div>
			<div :class="{ active: box == 'backcolor' }">
				<i class="material-icons-round" @mousedown.prevent="toggle('backcolor')">format_paint</i>
				<div>
					<span v-for="color in colors" class="color"
						:style="`background: ${color}`" @mousedown.prevent="exec('backcolor', color)"></span>
				</div>
			</div>
			<span></span>
			<div :class="{ active: box == 'fontsize' }">
				<i class="text" @mousedown.prevent="toggle('fontsize')">Aa</i>
				<div>
					<span class="size" v-for="i in 7" :key="i" @mousedown.prevent="exec('fontsize', i)">{{i}}</span>
				</div>
			</div>
			<span></span>
			<i class="text" @mousedown.prevent="exec('formatBlock', 'h1')">H1</i>
			<i class="text" @mousedown.prevent="exec('formatBlock', 'h2')">H2</i>
			<i class="text" @mousedown.prevent="exec('formatBlock', 'h3')">H3</i>
			<span></span>
			<i class="material-icons-round" @mousedown.prevent="exec('justifyRight')">format_align_right</i>
			<i class="material-icons-round" @mousedown.prevent="exec('justifyCenter')">format_align_center</i>
			<i class="material-icons-round" @mousedown.prevent="exec('justifyLeft')">format_align_left</i>
			<span></span>
			<i class="material-icons-round" @mousedown.prevent="exec('insertUnorderedList')">format_list_bulleted</i>
			<i class="material-icons-round" @mousedown.prevent="exec('insertOrderedList')">format_list_numbered</i>
			<span></span>
			<i class="material-icons-round" @mousedown.prevent="toggleUploaderBox">image_search</i>
			<i class="material-icons-round" @mousedown.prevent="toggleLinkBox">link</i>
		</div>

		<div v-if="link" class="link" >
			<div class="close" data-icon="close" @click="toggleLinkBox"></div>
			<form-group>
				<form-input name="title" label="טקסט על קישור" v-model="link.title" />
				<link-vue v-model="link.link" wrapper="form-group" />
			</form-group>
			<div class="add button" data-icon="add" @click="addLink">הוסף</div>
		</div>

		<images-library ref="library" wrapper="div" v-model="uploader"></images-library>

		<div class="content" :class="{disabled: link}" contenteditable="true"
			ref="content" v-once  v-html="value"
			@keydown="toggle(null)" @mousedown="toggle(null)" @paste.prevent="onPaste"
			@input="$emit('input', $event.target.innerHTML)"></div>

	</div>
</template>

<script>
export default {
	props: {
		label: { type: String, required: true },
		value: { type: String, required: true },
		type: { type: String, default: "text" }
	},
	data(){
		return {
			box: null,
			link: null,
			uploader: {
				images: [],
				selection: null,
				upload: null
			},
			colors: [
			'#FFFFFF', '#EEF2F6', '#E3E8EF', '#CDD5DF', '#A3ADBD', '#758093', '#4C5565', '#2F3746', '#1B202B', 'black',
			'#FDF5F5', '#F7D8D9', '#F1B5B4', '#EA8886', '#E26F6C', '#D24D4A', '#B43F3B', '#8D3533', '#6A302E', 'red',
			'#FEFAF1', '#FAEBCE', '#F4D299', '#EAAE6A', '#DF8D4F', '#CE703D', '#B15B36', '#8F472F', '#713827', 'orange',
			'#FFFFF2', '#FEFAC8', '#F8EE9B', '#F2DF7A', '#E6C869', '#CD9E4E', '#AD7A3B', '#8E5C2C', '#6C4320', 'yellow',
			'#F3FFF5', '#D0F3D8', '#ACE2B9', '#86CF98', '#6BB780', '#599D6F', '#4A815E', '#3A654C', '#315340', 'green',
			'#EBFEFA', '#C2F3EA', '#9BE3D9', '#77CDC4', '#60B0AA', '#529593', '#447879', '#385D60', '#2F4D51', 'teal',
			'#EDF8FE', '#C6E2F5', '#9ECDEE', '#78B3E6', '#5B9AD8', '#4A84C5', '#3E6EA9', '#36537C', '#2F4461', 'blue',
			'#EDF4FE', '#C7DBFA', '#A8C0F3', '#859FEC', '#6A83E0', '#5C6DCE', '#4E58B6', '#424589', '#3B3867', 'indigo',
			'#F9F5FE', '#E6DBF9', '#D2BFF4', '#B19AEB', '#9981E0', '#7A62CB', '#6550B7', '#514393', '#413874', 'purple',
			'#FDF5F7', '#F7D8E2', '#F0BACD', '#E68FB2', '#DB70A4', '#C3508A', '#A8427D', '#89346A', '#662C56', 'pink',
			]
		}
	},
	watch: {
		value(val){
			if(document.activeElement == this.$refs.content) return;

			this.$refs.content.innerHTML = val;
		}
	},
	mounted(){
		document.execCommand("defaultParagraphSeparator", false, "div");
	},
	methods: {
		exec(cmd, value = null){
			if(cmd == 'formatBlock' &&
				window.getSelection().getRangeAt(0).startContainer.parentNode.tagName.toLocaleLowerCase() == value
			) value = 'div';

			document.execCommand(cmd, false, value);
		},
		toggle(box){
			this.box = (this.box == box ? null : box);
		},
		onPaste(e){
			this.exec('inserttext', e.clipboardData.getData('text'));
		},
		toggleLinkBox(){
			if(this.link){
				return this.$set(this, 'link', null);
			}

			let selection = (window.getSelection ? window.getSelection() : null), title = '';

			if(! selection) return;

			if(! selection.isCollapsed){
				title = selection.toString();
			}

			this.$set(this, 'link', {selection: {
				baseNode: selection.baseNode,
				baseOffset: selection.baseOffset,
				extentNode: selection.extentNode,
				extentOffset: selection.extentOffset
			}, title, link: {type: 'home'}})
		},
		addLink(){
			if(! (this.link && this.link.selection)) return this.link = null;
			if(! this.link.title) return VueAlerts.flash({text: 'חובה להוסיף כותרת', icon: 'error'})

			this.$refs.content.focus();

			this.$nextTick(() => {
				let range = new Range(),
					startRangeNode = new Range(),
					selection = window.getSelection(),
					link = document.createElement('editor-link'),
					parentElem = null;

				range.setStart(this.link.selection.baseNode, this.link.selection.baseOffset);
				range.setEnd(this.link.selection.extentNode, this.link.selection.extentOffset);
				parentElem = range.commonAncestorContainer;

				parentElem.remove();
				range.startContainer.remove();

				link.title = this.link.title;
				link.dataset['ref'] = this.link.link.ref || '';
				link.dataset['type'] = this.link.link.type || '';

				link.appendChild(document.createTextNode(this.link.title));

				range.insertNode(link);
		        range.setStartAfter(link);
		        range.collapse(true);

		        selection.removeAllRanges();
		        selection.addRange(range);

		        this.$emit('input', this.$refs.content.innerHTML);

				this.toggleLinkBox();
			})
		},
		toggleUploaderBox(){
			if(this.$refs.library.visible) return this.$refs.library.visible = false;

			let selection = (window.getSelection ? window.getSelection() : null);

			this.$set(this.uploader, 'selection', {
				baseNode: selection.baseNode,
				baseOffset: selection.baseOffset,
				extentNode: selection.extentNode,
				extentOffset: selection.extentOffset,
				parentContent:
				(selection.baseNode.parentElement && selection.baseNode.parentElement.className == 'content') ||
				(selection.baseNode.offsetParent && selection.baseNode.offsetParent.className == 'content') ? true : false
			});

			return this.$refs.library.visible = true;
		},
		setPictures(){
			if(! this.$refs.library.choosed) return this.toggleUploaderBox();

			this.$refs.library.choosed.forEach(image => this.uploader.images.push({src: image}));

			this.$refs.content.focus();

			this.$nextTick(() => {
				this.uploader.images.forEach(image => {

					let range = new Range(),
					startRangeNode = new Range(),
					selection = window.getSelection(),
					picture = document.createElement('img'),
					baseNode = (this.uploader.selection.parentContent ? this.uploader.selection.baseNode : selection.baseNode),
					baseOffset = (this.uploader.selection.parentContent ? this.uploader.selection.baseOffset : selection.baseOffset),
					extentNode = (this.uploader.selection.parentContent ? this.uploader.selection.extentNode : selection.extentNode),
					extentOffset = (this.uploader.selection.parentContent ? this.uploader.selection.extentOffset : selection.extentOffset);

					range.setStart(baseNode, baseOffset);
					range.setEnd(extentNode, extentOffset);

					picture.src = image.src;

					range.insertNode(picture);
	        		range.setStartAfter(picture);
	        		range.collapse(true);

	        		selection.removeAllRanges();
	        		selection.addRange(range);
				});

				this.$refs.library.choosed = [];
				this.$set(this.uploader, 'images', []);
		    	this.$emit('input', this.$refs.content.innerHTML);
		    	this.toggleUploaderBox();
			});
		}
	}
}
</script>
