<template>
	<div class="flash" :class="{active}">
		<i class="material-icons-round close" @click="active = false" v-if="closeable">close</i>
		<i class="material-icons-round icon">{{ icon }}</i>
		<div class="text">{{ text }}</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			active: false,
			text: '',
			icon: 'notification_important',
			closeable: true,
			timeout: null
		}
	},

	methods: {
		init(params){
			this.text = params.text;
			this.icon = params.icon;

			if(this.timeout){
				clearTimeout(this.timeout);
			}

			if(! params.time){
				this.timeout = null;
				this.closeable = true;
				return this.active = true;
			}

			this.closeable = params.closeable;
			this.active = true;
			this.timeout = setTimeout(() => this.active = false, params.time);
		}
	}
}
</script>
