<template>
	<div class="popover colors" :class="{loading, active}">
		<div class="cancel" data-icon="close" @click="close" style="z-index: 4"></div>
		<div class="list">
			<div class="item header">הוספת צבע</div>
		</div>
		<form-group name="color" style="z-index: 3">
			<form-input name="title" label="כותרת" v-model="model.label" />
		</form-group>
		<form-group name="color" style="z-index: 2">
			<form-colors name="color" label="צבע רקע" v-model="model.color"></form-colors>
		</form-group>
		<form-group name="image" style="z-index: 1">
			<form-file name="image" label="תמונה" v-model="image" />
			<form-area v-if="preview">
				<div class="card">
					<div class="image" :style="`background-image: url('${preview}')`"></div>
				</div>
			</form-area>
		</form-group>
		<form-group name="button" style="z-index: 1">
			<div data-icon="save" class="button dark" @click="save">שמור צבע</div>
		</form-group>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: false,
			active: false,
			cb: {},
			model: {
				label: '',
				color: '#ffffff'
			}
		}
	},
	methods: {
		activate(cb = {}){
			this.cb = cb;

			this.active = true;
		},
		save(){

			this.loading = true;
			this.$models.inventory.stock.updateStockColors(this.$route.params.store, this.model)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});
				this.loading = false;
				this.active = false;

				this.$emit('save', Object.assign(this.cb, {
					value: res.data.colors[res.data.colors.length - 1].value,
					label: res.data.colors[res.data.colors.length - 1].label,
					colors: res.data.colors
				}));

				this.model = {
					label: "",
					color: "#ffffff"
				};
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
				this.active = false;
			});
		},
		close(){
			this.active = false;
			this.$emit('close', Object.assign(this.cb, {value: ''}));
			this.cb = {};
		}
	},
	computed: {
		image:{
			get(){
				return [this.model.image || ''];
			},
			set(value){
				this.$set(this.model, 'image', value[0])
			}
		},
		preview(){
			if(! this.model.image)
				return false;

			if(typeof this.model.image == 'string')
				return this.model.image;

			return URL.createObjectURL(this.model.image);
		}
	}
}
</script>
