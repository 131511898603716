<template>
	<div class="notifications"></div>
</template>

<script>
export default {
	data(){
		return {
			lastView: null,
			audio: null,
			data: []
		}
	},
	mounted(){
		if(this.$route.params.store){
			this.getNotifications();

			this.$index.getUser().then(data => {
				this.lastView = new Date(data.data.lastNotificationView) || null;
			});

			this.$ws(`${this.$route.params.store}/notifications`, this.appendNotification.bind(this));

			let sound = 'https://cdn.kipi.app/assets/notification.mp3';
			
			if(this.$root.$route.params.store === 'capri')  sound = 'https://cdn.kipi.app/assets/ringtone.mp3';

			this.audio = new Audio(sound);
		}
	},
	methods: {
		getNotifications(){
			this.$models.dashboard.store.notifications(this.$route.params.store)
			.then((res) => {
				this.data = res.data;
			})
		},
		appendNotification(notification){
			this.data.push(notification);

			this.audio.play();

			this.$root.$emit('notification', notification);

			VueAlerts.flash({
				text: notification.message,
				closeable: false,
				icon: 'notifications'
			});

			if(notification.type == 'new_order'){
				this.$root.$refs.menuApp.active = 'notifications-drawer';
			}
		}
	},
	computed: {
		new(){
			if(! this.data.length) return [];

			if(! this.lastView) return this.data;

			return this.data.filter(notification => new Date(notification.time) > this.lastView);
		}
	}
}
</script>
