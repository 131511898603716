<template>
	<div class="fs-pop" v-if="component">
		<div class="button saver" data-icon="save" @click="done" v-if="component.component">המשך</div>
		<form-group :class="{ hidden: component.component != null }">
			<form-area>
				<div class="card" v-for="(details, id) in components" @click="setComponent(id)">{{ details.title }}</div>
			</form-area>
		</form-group>
		<components ref="component" :is="components[component.component]" v-model="component.data" v-if="component.component != null" :global="isGlobalComponent" />
	</div>
</template>

<script>
import { deepClone } from '@/bootstrap/helpers';

export default {
	props: {
		components: { type: Object, required: true },
		globals: { type: Array, default: () => [] }
	},
	data(){
		return {
			active: false,
			component: null
		}
	},
	methods: {
		init(component){
			this.component = component;

			if(component.component){
				this.setComponent(component.component)
			}
		},
		setComponent(id){
			this.$set(this.component, 'data', this.component.data || deepClone(this.components[id].defaults));

			this.$set(this.component, 'component', id);
		},
		done(){
			this.component = null;
		}
	},
	computed: {
		isGlobalComponent(){
			return Boolean(this.globals.indexOf(this.component.component) > -1);
		}
	}
}
</script>
