import { render, staticRenderFns } from "./categoryIndexRow.vue?vue&type=template&id=bfa2f044&"
import script from "./categoryIndexRow.vue?vue&type=script&lang=js&"
export * from "./categoryIndexRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports