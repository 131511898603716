<template>
	<div class="menu-main" :class="{active, open}">
		<div class="opener" @click="open = !open"></div>

		<div class="store" :style="`background-image: url('${store.theme.settings.favicon}')`" :key="store.store" v-if="store">{{ store.store }}</div>
		<div class="store" :style="`background-image: url('/assets/kipi-icon.png')`" key="kipi" v-else>KIPI</div>

		<component :is="active" v-if="active" />
	</div>
</template>

<script>
export default {
	components:{
		'menu-drawer': require('@/components/menu-drawers/menu').default,
		'master-drawer': require('@/components/menu-drawers/master').default,
	},
	data(){
		return {
			store: null,
			open: true
		}
	},
	watch: {
		open(){
			window.localStorage.setItem('menu-open', this.open);
		},
		'$route'(){
			this.loadStore();
		}
	},
	mounted(){
		this.loadStore();

		this.open = (window.localStorage.getItem('menu-open') !== 'false');
	},
	methods: {
		loadStore(auto = false){
			return this.$index.getStores().then(res => {
				let stores = Object.fromEntries(res.data.map(store => [store.store, store]));

				this.store = stores[this.$route.params.store] || (auto ? res.data[0] : null);
			});
		}
	},
	computed: {
		active(){
			if(this.$route.params.store) return 'menu-drawer';
			if(this.$route.name.split('/')[0] == 'master') return 'master-drawer';

			return false;
		}
	}
}
</script>
