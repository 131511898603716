<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'שאלות ותשובות', icon: 'receipt', groups: ['general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}" class="dynamic">

		<form-group style="z-index:1" name="general">
			<index :data="input.faq" sort-by="question" @sort="sortElement">
				<template slot="index" slot-scope="item">
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end"></div>
						<div class="info">{{ input.faq[item.$index].question }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableBox != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" v-if="input.faq.length > 1" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group :style="`z-index:${item.$index}`" :key="item.$index" class="item" v-if="editableBox == item.$index">
						<form-group style="z-index:2">
							<form-input name="question" label="שאלה" v-model="input.faq[item.$index].question" />
						</form-group>
						<form-group style="z-index: 1">
							<form-editor name="answer" label="תשובה" v-model="input.faq[item.$index].answer"/>
						</form-group>
					</form-group>
				</template>
			</index>
			<div class="buttons">
				<div class="button add" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
			</div>
		</form-group>


		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "שאלות ותשובות",
	defaults: {
		faq: [{
			question: '',
			answer: ''
		}],
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return {
			editableBox: null
		}
	},
	mounted(){
		if(this.input.faq.length == 1 && ! this.input.faq[0].image) this.editableBox = 0;
	},
	methods: {
		addDynamic(){
			this.input.faq.push({question: '', answer: ''});

			this.editableBox = (this.input.faq.length - 1 );
		},
		removeDynamic(index){
			if(this.editableBox == index) this.editableBox = null;

			this.input.faq.splice(index, 1);
		},
		sortElement(sort){
			this.input.faq.sort((a, b) => a.__sort - b.__sort);
		},
		toggleBox(key){
			if(key == this.editableBox) return this.editableBox = null;

			this.editableBox = key;
		}
	},
	computed: {
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
