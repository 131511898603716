<template>
	<div class="layout categories" :class="{ loading }">

		<div class="top">
			<div class="title">ניהול קטגוריות</div>
			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('inventory/categories/create')">הוספת קטגוריה</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="filter" />
		</div>

		<index ref="index" :data="items" v-if="items" sort-by="_id" @sort="(sort) => {}">
			<category-index-row slot="index" slot-scope="item" :ref="`index[${item.$index}]`" :item="item" :goTo="goTo" :toggle="toggle" />
		</index>

	</div>
</template>

<script>
export default {
	components: {
		'category-index-row': require('@/pages/inventory/categories/categoryIndexRow').default
	},
	data(){
		return {
			loading: true,
			items: null
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.inventory.category.all(this.$route.params.store)
			.then((res) => {
				this.items = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		filter(value){
			Object.values(this.$refs).forEach(
				index => index.filter({ id: 'search', method: 'like', in: ['name'], value })
			);
		},
		goTo(name, category = null){
			if(category)
				return this.$router.push({ name, params: {store: this.$route.params.store, category} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		toggle(category){
			this.$models.inventory.category[category.active ? 'remove' : 'restore'](this.$route.params.store, category._id)
				.then((res) => {
					VueAlerts.flash({
						icon: 'done',
						text: 'עודכן בהצלחה'
					});
				}).catch(error => {
					VueAlerts.flash({
						text: 'אירע שגיאה',
						closeable: false
					});
				});
		}
	}
}
</script>
