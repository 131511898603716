const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Dictionary extends Model{

	constructor(){
		super();
	}

	model() {
		return {
			key: "",
			params: [],
			variations: ["default"],
			dictionary: {},
			section: 'stores.web.general'
		}
	}

	variations(){
		return  [
			{value: "default", label: "ברירת מחדל", disabled: true},
			{value: "verb", label: "רבים"},
			{value: "male", label: "זכר"},
			{value: "female", label: "נקבה"}
		]
	}

	lang_codes(){
		return [
			{value: 'he_IL', label: 'עברית'},
			{value: 'en_US', label: 'אנגלית'}
		]
	}

	sections(){
		return [
			{value: 'stores.web.general', label: 'כללי'},
			{value: 'stores.web.personal', label: 'אזור אישי'},
			{value: 'stores.web.orders', label: 'הזמנות'},
			{value: 'stores.web.products', label: 'מוצרים'},
			{value: 'stores.web.pages', label: 'דפים'},
			{value: 'stores.web.errors', label: 'הודעות שגיאה'},
			{value: 'stores.web.orders.sms', label: 'הודעות sms'},
			{value: 'stores.web.orders.status', label: 'סטטוסים'},
			{value: 'stores.web.components', label: 'קומפוננטות'}
		]
	}

	all(){
		return new Promise((resolve, reject) => {
			this.$api.get('master/dictionary').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}


	get(id){
		return new Promise((resolve, reject) => {
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('master/dictionary/'+id).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	update(payload){
		return new Promise((resolve, reject) => {
			if(! payload) return reject(new Error("Missing `payload`"));

			let data, lang_codes = ['he_IL', 'en_US'],
				patternizer = {
					key: String,
					section: String,
					params: [{
						key: String,
						label: String
					}],
					variations: [String]
				};

			patternizer.dictionary = Object.fromEntries(lang_codes.map(code => [code, {
					default: String,
					verb: String,
					male: String,
					female: String
			}]))

			data = Cast(payload, patternizer);

			this.$api.post('master/dictionary/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Dictionary()
