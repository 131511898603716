<template>
	<div class="report-drawer" :class="{loading}" :data-loading="loading">
		<div class="report">
			<div class="screenshot" :style="`
				background-image: url('${preview}');
				background-position: top -${report.data.scrollY / 4}px center`" v-if="preview && !loading"></div>
			<div ref="input" class="text" contenteditable></div>
			<div class="submit" @click="sendReport">שלח</div>
		</div>
	</div>
</template>

<script>
import html2canvas from 'html2canvas';

export default {
	data(){
		return {
			loading: false,
			report: null,
		}
	},
	mounted(){
		this.buildReport()
	},
	methods: {
		buildReport(){
			this.loading = 'אוסף נתונים';

			let elm = this.$root.$refs.route.$el, data = {
				store: (this.$route.params.store || null),
				navigator: navigator ? navigator.userAgent : 'unknown',
				location: window.location.href,
				width: window.innerWidth,
				height: window.innerHeight,
				scrollX: window.pageXOffset,
				scrollY: window.pageYOffset
			};

			setTimeout(() => html2canvas(elm, {
				width: elm.offsetWidth,
				height: elm.offsetHeight,
				scrollX: window.pageXOffset,
				scrollY: window.pageYOffset,
				x: window.pageXOffset,
				y: window.pageYOffset,
				backgroundColor: '#F2F5F8',
				foreignObjectRendering: true,
				useCORS: true,
				logging: false
			}).then(canvas => {
				canvas.toBlob(blob => {
					this.report = {
						file: new File([blob], 'screenshot'),
						data
					};
					setTimeout(() => this.loading = false, 1000);
				})
			}), 300)
		},
		sendReport(){
			this.loading = 'שולח נתונים';
			this.$models.dashboard.user.report(
				Object.assign(this.report.data,{ message: this.$refs.input.innerText }),
				this.report.file
			).then(() => {
				this.report = null;
				this.$refs.input.innerHTML = "";
				this.$parent.active = null;
				this.loading = false;


				VueAlerts.flash({
					icon: 'done',
					text: 'תודה, הדיווח נרשם ויטופל בהקדם.',
					closeable: false
				})
			})
		}
	},
	computed:{
		preview(){
			if(! (this.report && this.report.file)) return null;

			return URL.createObjectURL(this.report.file);
		}
	}
}
</script>
