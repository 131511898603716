<template>
	<div class="layout order create">

	</div>
</template>

<script>
export default {
	data(){
		return {

		}
	},
	mounted(){


	},
	methods:{

	}
}
</script>
