<template>
	<div class="layout" :class="{loading}">
		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>דף הבית</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form" :tabs="{
			seo: { label: 'seo', icon: 'phonelink', groups: ['seo'] },
			layout: { label: 'עיצוב', icon: 'receipt', groups: ['layout'] }
		}" >
			<form-group name="seo">
				<form-input name="title" label="כותרת" v-model="model.seo.title" />
				<form-input name="description" label="תיאור" v-model="model.seo.description" />
				<form-input name="keywords" label="מילות מפתח" v-model="model.seo.keywords" />
			</form-group>

			<form-group name="layout">
				<form-area>
					<fs v-model="model.layout" ref="layout" :components="components" />
				</form-area>
			</form-group>
		</form-vue>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			model: this.$models.pages.page.model(),
			components: this.$models.layout.web.homeComponents(),
		}
	},
	mounted(){
		this.getData();
	},
	methods:{
		getData(){
			this.$models.pages.home.get(this.$route.params.store)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			this.$models.pages.home.update(this.$route.params.store, this.model, this.$refs.layout.files)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.model.layout = res.data.layout || [];

				this.$refs.layout.resetEditors();

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		}
	}
}
</script>
