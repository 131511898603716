const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Coupon extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			title: '',
			code: '',
			active: true,
			automatic: false,
			cartVisible: false,
			reward: {
				type: 'discount',
				discount: ''
			},
			limitation: {
				forgetedCart: false
			}
		};
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/coupons').then(response => {
				let result = { response };

				result.data = Cast(response.data, [{
					_id: String,
					title: String,
					code: String,
					reward: {
						type: String,
						discount: Number,
						precentage: Number,
						coupon: Number
					},
					limitation:{
						fromDate: Date,
						toDate: Date,
						fromPrice: Number,
						toPrice: Number,
						fromQuantity: Number,
						toQuantity: Number,
						forgetedCart: Boolean
					},
					active: Boolean,
					automatic: Boolean,
					cartVisible: Boolean
				}]);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/coupons/'+id).then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					title: String,
					code: String,
					reward: {
						type: String,
						discount: String,
						precentage: String,
						coupon: String,
					},
					limitation:{
						fromDate: Date,
						toDate: Date,
						fromPrice: String,
						toPrice: String,
						fromQuantity: String,
						toQuantity: String,
						forgetedCart: Boolean
					},
					active: Boolean,
					automatic: Boolean,
					cartVisible: Boolean
				});

				if(result.data.limitation && (result.data.limitation.fromPrice || result.data.limitation.toPrice)){
					result.data.limitation.fromPrice = result.data.limitation.fromPrice || ""
					result.data.limitation.toPrice = result.data.limitation.toPrice || ""
				}

				if(result.data.limitation && (result.data.limitation.fromQuantity || result.data.limitation.toQuantity)){
					result.data.limitation.fromQuantity = result.data.limitation.fromQuantity || ""
					result.data.limitation.toQuantity = result.data.limitation.toQuantity || ""
				}

				resolve(result);

				this.$cache.set(key, result);
			}).catch(error => reject(error));
		});
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				title: String,
				code: String,
				reward: {
					type: String,
					discount: Number,
					precentage: Number,
					coupon: Number
				},
				limitation:{
					fromDate: Date,
					toDate: Date,
					fromPrice: Number,
					toPrice: Number,
					fromQuantity: Number,
					toQuantity: Number,
					forgetedCart: Boolean
				},
				active: Boolean,
				automatic: Boolean,
				cartVisible: Boolean
			});

			this.$api.post(store+'/inventory/coupons/create', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				_id: String,
				title: String,
				code: String,
				reward: {
					type: String,
					discount: Number,
					precentage: Number,
					coupon: Number,
				},
				limitation:{
					fromDate: Date,
					toDate: Date,
					fromPrice: Number,
					toPrice: Number,
					fromQuantity: Number,
					toQuantity: Number,
					forgetedCart: Boolean
				},
				active: Boolean,
				automatic: Boolean,
				cartVisible: Boolean
			});

			this.$api.post(store+'/inventory/coupons/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/coupons/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/coupons/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Coupon()
