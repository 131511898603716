const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Popup extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			title: "",
			content: "",
			image: '',
			width: "600",
			height: "500",
			button: {
				title: "",
				link: {
					ref: "",
					type: "",
					blank: ""
				}
			},
			auto: false,
			active: true
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/popups').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/popups/'+id)
			.then(response => {
				let result = { response };

				result.data =  Cast(response.data, {
					title: String,
					content: String,
					image: String,
					button: {
						title: String,
						link: {
							ref: String,
							type: String,
							blank: Boolean
						}
					},
					width: String,
					height: String,
					auto: Boolean,
					active: Boolean
				})

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}


	prePost(payload){
		let files = {};

		if(typeof payload.image != 'string'){
			files['image'] = payload.image;
		}

		let data = Cast(payload, {
			title: String,
			content: String,
			image: String,
			button: {
				title: String,
				link: {
					ref: String,
					type: String,
					blank: Boolean
				}
			},
			width: Number,
			height: Number,
			auto: Boolean,
			active: Boolean
		})

		return { data, files }
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/popups/create', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/popups/'+id+'/update', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/popups/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/popups/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Popup()
