const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Campaign extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			title: "",
			budget: "",
			media: "",
			type: "",
			unique: "",
			utm: ""
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.get('dashboard/store/'+store+'/campaigns').then(response => {
				let result = { response };

				result.data = Cast(response.data, [{
					_id: String,
					title: String,
					budget: String,
					media: String,
					type: String,
					unique: String,
					utm: String
				}]);

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('dashboard/store/'+store+'/campaigns/'+id)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					title: String,
					budget: String,
					media: String,
					type: String,
					unique: String,
					utm: String
				});

				resolve(result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let data = Cast(payload, {
			title: String,
			budget: Number,
			media: String,
			type: String,
			unique: String,
			utm: String
		})


		return { data }
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data} = this.prePost(payload);

			this.$api.post('dashboard/store/'+store+'/campaigns/create', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data} = this.prePost(payload);

			this.$api.post('dashboard/store/'+store+'/campaigns/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Campaign()
