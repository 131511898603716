module.exports = (Vue) => {
	let $index = Vue.observable({
		stores: null,
		user: null,
		permission: false,
		master: false,
		_data: [],
		data: [],
		getUser () {
			if($index.user) return $index.user;

			return $index.user = Api.get('dashboard/user');
		},
		getStores () {
			if($index.stores) return $index.stores;

			return $index.stores = Api.get('dashboard/user/stores');
		},
		setPermission(store){
			return this.getUser().then(res => {
				this.master = Boolean(res.data.level && res.data.level == "master");

				let stores = Object.fromEntries(res.data.stores_list.map(s => [s.store, s._id]));

				if(! stores[store]) return false;

				let labels = Object.fromEntries(res.data.stores.map(s => [s.store, s.label]));

				this.permission = labels[stores[store]];

			})
		}
	});


	Vue.prototype.$index = $index;
}
