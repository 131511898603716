var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout",class:{loading: _vm.loading}},[_c('div',{staticClass:"top fixed"},[_c('div',{staticClass:"title"},[_c('router-link',{staticClass:"back",attrs:{"to":{ name: 'web', params: { store: this.$route.params.store }}}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(this.$route.params.id ? 'עריכת' : 'הוספת')+" הפניה")])],1),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"button",attrs:{"data-icon":"save"},on:{"click":_vm.submit}},[_vm._v("שמור")])])]),_vm._v(" "),_c('form-vue',{ref:"form"},[_c('form-group',{staticStyle:{"z-index":"8"},attrs:{"name":"general","title":"פרטי ההפניה"}},[_c('form-input',{attrs:{"name":"title","label":"מעמוד"},model:{value:(_vm.model.fromUrl),callback:function ($$v) {_vm.$set(_vm.model, "fromUrl", $$v)},expression:"model.fromUrl"}}),_vm._v(" "),_c('form-area',[_c('link-vue',{staticClass:"form-link",attrs:{"wrapper":"form-group"},model:{value:(_vm.model.link),callback:function ($$v) {_vm.$set(_vm.model, "link", $$v)},expression:"model.link"}})],1),_vm._v(" "),_c('form-select',{attrs:{"name":"code","label":"קוד סטטוס","options":[
				{ value: '300', label: '300'},
				{ value: '301', label: '301'},
				{ value: '302', label: '302'},
				{ value: '303', label: '303'},
				{ value: '304', label: '304'},
				{ value: '305', label: '305'},
				{ value: '306', label: '306'},
				{ value: '307', label: '307'},
				{ value: '308', label: '308'} ]},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"active","label":"פעיל"},model:{value:(_vm.model.active),callback:function ($$v) {_vm.$set(_vm.model, "active", $$v)},expression:"model.active"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }