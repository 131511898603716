var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout deliveries",class:{ loading: _vm.loading }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("ניהול אספקה")]),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goToCreate('inventory/deliveries/create', 'collect')}}},[_vm._v("הוספת שיטת החזרה")]),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goToCreate('inventory/deliveries/create', 'pickup')}}},[_vm._v("הוספת נקודת איסוף")]),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goToCreate('inventory/deliveries/create', 'ship')}}},[_vm._v("הוספת שיטת משלוח")])])]),_vm._v(" "),(_vm.items)?_c('div',{staticClass:"index-filters"},[_c('index-search',{attrs:{"action":function (value) { return _vm.$refs.index.filter({ id: 'search', method: 'like', in: ['title'], value: value }); }}}),_vm._v(" "),_c('index-filter',{attrs:{"label":"פעיל","options":[
				{value: null, label: 'הכל'},
				{value: 1, label: 'פעיל בלבד'},
				{value: 0, label: 'לא פעיל'}
			],"action":function (value) { return _vm.$refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value: value }); }}}),_vm._v(" "),_c('index-filter',{attrs:{"label":"סוגי משלוח","options":[
				{value: null, label: 'הכל'},
				{value: 'ship', label: 'משלוח'},
				{value: 'pickup', label: 'נקודת איסוף'},
				{value: 'return', label: 'שיטת החזרה'}
			],"action":function (value) { return _vm.$refs.index.filter({ id: 'type', method: 'eq', in: ['type'], value: value }); }}})],1):_vm._e(),_vm._v(" "),(_vm.items)?_c('index',{ref:"index",attrs:{"data":_vm.items},scopedSlots:_vm._u([{key:"header",fn:function(items){return _c('div',{staticClass:"table header"},[_c('div',[_vm._v("שם המשלוח")]),_vm._v(" "),_c('div')])}},{key:"index",fn:function(item){return _c('div',{staticClass:"table",class:{ hide: item.$filtered }},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.title))]),_vm._v(" "),(item.type == 'ship')?_c('span',[_vm._v("משלוח")]):(item.type == 'pickup')?_c('span',[_vm._v("נקודת איסוף")]):(item.type == 'collect')?_c('span',[_vm._v("שיטת החזרה")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('form-toggle',{attrs:{"name":"active","label":""},on:{"toggle":function($event){return _vm.toggle(item)}},model:{value:(_vm.items[item.$index].active),callback:function ($$v) {_vm.$set(_vm.items[item.$index], "active", $$v)},expression:"items[item.$index].active"}}),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"create"},on:{"click":function($event){return _vm.goTo('inventory/deliveries/edit', item._id)}}},[_vm._v("ערוך")])],1)])}}],null,false,1827301632)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }