const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Order extends Model{

	constructor(){
		super();
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('dashboard/store/'+store+'/support/order/'+id)
			.then(response => {
				let result = { response };

				resolve(result);
			})
			.catch(error => reject(error))
		});
	}

	update(store, id, message){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! message) return reject(new Error("Missing `message`"));

			this.$api.post('dashboard/store/'+store+'/support/order/'+id, {message})
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}
}

module.exports = new Order()
