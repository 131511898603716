<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'לוגו', icon: 'receipt', groups: ['global', 'general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">
		<form-group style="z-index:2" name="global">
			<form-toggle name="global" v-model="toggleGlobal" label="משוך לוגו מהגדרות האתר"></form-toggle>
		</form-group>
		<form-group style="z-index:1" name="general" v-if="! toggleGlobal">
			<form-group>
				<form-file name="image" label="בחר תמונה" v-model="image" />
			</form-group>
			<form-group>
				<images-preview v-model="image" @delete="image = null" :form="[{
					title: { type: 'form-input', label: 'כותרת', default: '' }
				}]" />
			</form-group>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	title: "לוגו",
	defaults: {
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
	},
	methods: {
		bindFiles(data){
			if(! (data.image && data.image.file)) return {};

			let key = makeHash();

			let files = {[key]: data.image.file};

			data.image._file = key;

			return files;
		}
	},
	computed: {
		image:{
			get(){
				return this.input.image ? [this.input.image] : [];
			},
			set(value){
				if(! value) return this.$delete(this.input, 'image');

				this.$set(this.input, 'image', value[0]);
			}
		},
		toggleGlobal: {
			get(){
				return (this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
