<template>
	<div class="layout dictionary" :class="{ loading }">

		<div class="top">
			<div class="title">מילון</div>
			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('master/dictionary/create')">הוסף חדש</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['key', 'first'], value })"/>
			<index-filter
				label="אזורים"
				:options="sectionsFilter"
				:action="(value) => $refs.index.filter({ id: 'section', method: 'eq', in: ['section'], value })"
			/>
		</div>

		<index ref="index" :data="items" v-if="items" sort-by="_id" @sort="(sort) => {}">
			<div class="table header" slot="header">
				<div class="title">
					<span>מפתח</span>
				</div>
				<div class="title" v-for="lang in languages">
					<span>{{ lang }}</span>
				</div>
				<div class="section">
					אזור
				</div>
			</div>
			<div class="table" slot="index" slot-scope="item"
				:class="{ hide: item.$filtered }"
				@click="goTo('master/dictionary/edit', item._id)"
				>
				<div class="title">
					<span>{{ item.key }}</span>
					<span></span>
				</div>
				<div class="title" v-for="lang in languages">
					<span>{{ item.dictionary[lang].default }}</span>
					<span></span>
				</div>
				<div class="section">
					{{ sectionName(item.section) }}
				</div>
			</div>
		</index>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null,
			languages: [],
			sections: this.$models.master.dictionary.sections()
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.master.dictionary.all()
			.then((res) => {
				this.items = res.data.map(item => {
					Object.keys(item.dictionary).forEach(lang => {
						if(! this.languages.includes(lang)) this.languages.push(lang);
					});

					item.first = Object.values(item.dictionary).filter(e => e.default!=null)[0].default;

					return item;
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		sectionName(section){
			let sectionFiltered = this.sections.filter(e => e.value == section);

			return (sectionFiltered && sectionFiltered[0] ? sectionFiltered[0].label : section);
		},
		goTo(name, id = null){
			if(id)
				return this.$router.push({ name, params: {id} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		}
	},
	computed: {
		sectionsFilter(){
			if(! this.sections) return [];
			let items = this.sections;

			items.unshift({label: 'הכל', value: null});

			return items;
		}
	}
}
</script>
