const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Client extends Model{

	constructor(){
		super();
	}

	all(store, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.get(`dashboard/store/${store}/clients?limit=${limit}&skip=${skip}`).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/clients/'+id)
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	updateCredit(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				credit: Number
			});

			if(payload.type == 'remove') data.credit = (data.credit * -1);

			this.$api.post('dashboard/store/'+store+'/clients/'+id+'/updateCredit', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	sendSms(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				message: String
			});

			this.$api.post('dashboard/store/'+store+'/clients/'+id+'/send_sms', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Client()
