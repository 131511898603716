<template>
	<div class="layout dashboard orders">

		<div class="header">
			<div class="title">
				<span>היי {{ user ? user.name : '' }}</span>
				<span v-for="(note, i) in notes" :key="i" :class="note.type">{{ note.text }}</span>
			</div>

			<div class="actions">
				<div class="buttons">
					<div class="action" data-icon="book_online" :class="{active: filters.active}" @click="filters.active = !filters.active">{{ filterDateTitle }}</div>
				</div>
				<div class="container"  :class="{active: filters.active}">
					<div class="option" :class="{ active: filters.state == 'today' }" @click="setFilterState('today')" data-icon="filter_1">היום</div>
					<div class="option" :class="{ active: filters.state == 'yesterday' }" @click="setFilterState('yesterday')" data-icon="update">אתמול</div>
					<div class="option" :class="{ active: filters.state == 'week' }" @click="setFilterState('week')" data-icon="date_range">השבוע</div>
					<div class="option" :class="{ active: filters.state == 'month' }" @click="setFilterState('month')" data-icon="today">החודש</div>
					<div class="dates">
						<form-datepicker name="from-date" label="החל מתאריך" v-model="fromDate" :disabled-dates="{ from: toDate }" />
						<form-datepicker name="from-date" label="עד לתאריך" v-model="toDate" :disabled-dates="{ to: fromDate }" />
					</div>
				</div>
			</div>
		</div>

		<div class="grid">

			<div class="data summary">
				<div class="options">
					<div class="group">
						<span data-icon="bar_chart"
							:class="{active: summary.state == 'finance'}" @click="summary.state = 'finance'">כלכלי</span>
						<span data-icon="style"
							:class="{active: summary.state == 'products'}" @click="summary.state = 'products'">פריטים</span>
					</div>
				</div>

				<div class="content rows">
					<div class="total">
						<div class="right">
							<span>{{ formatNumber(stocksStatistics.reduce((count, s) => count + s.count, 0)) }}</span>
							<span>סה״כ פריטים שנמכרו</span>
						</div>
						<div class="left">
							<span data-sign="₪">{{ formatNumber(orders.graph.total || 0, 2) }}</span>
							<span>סה״כ הכנסות</span>
						</div>
					</div>

					<index :data="(summary.state == 'finance' ? [
						{ icon: 'north', title: 'רווחים', color: 'success', value: ((orders.graph.total || 0) - (orders.graph.deliveries || 0))},
						{ icon: 'local_shipping', title: 'עלות משלוחים', color: 'primary', value: (orders.graph.deliveries || 0)},
						{ icon: 'vertical_align_center', title: 'ממוצע להזמנה', color: '', value: ((orders.graph.total || 0) / (orders.graph.count || 1))}
					] : (summary.expend ? stocksStatistics : [].concat(stocksStatistics).splice(0, 3)))">

						<div class="row" slot="index" slot-scope="row" :key="row.$index"
							:class="row.color" :data-icon="row.icon" :data-count="row.count">
							<span class="sub" v-if="row.sub">{{ row.sub }}</span>
							<span class="title">{{ row.title }}</span>
							<span class="value">{{ `${formatNumber(row.value, 2)} ₪` }}</span>
						</div>

						<div class="expend" slot="footer" @click="summary.expend = !summary.expend" v-if="summary.state != 'finance' && stocksStatistics.length > 3">{{ summary.expend ? 'הסתר' : 'הצג הכל' }}</div>
						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>

			<div class="data stats">
				<div class="options">
					<div class="group">
						<span data-icon="timeline" class="active">סטטיסטיקות</span>
					</div>
				</div>

				<div class="content">
					<div class="stat primary" data-icon="shopping_cart">
						<span>{{ formatNumber(orders.counts.orders || 0) }}</span>
						<span>הזמנות מאושרות</span>
					</div>
					<div class="stat warning" data-icon="compare_arrows">
						<span>{{ formatNumber(orders.counts.modifications || 0) }}</span>
						<span>החלפות והחזרות</span>
					</div>
					<div class="stat error" data-icon="close">
						<span>{{ formatNumber(orders.counts.cancelations || 0) }}</span>
						<span>הזמנות מבוטלות</span>
					</div>
					<div class="stat success" data-icon="wifi_tethering">
						<span>{{ formatNumber(o.realtime.length) }}</span>
						<span>עגלות פעילות</span>
					</div>
					<div class="stat" data-icon="production_quantity_limits">
						<span>{{ formatNumber(orders.counts.checkouts || 0) }}</span>
						<span>עגלות נטושות</span>
					</div>
				</div>
			</div>

			<div class="data graph primary" v-if="false">
				<div class="options">
					<div class="group">
						<span data-icon="date_range"
							:class="{active: graph.state == 'days'}" @click="graph.state = 'days'">יום בשבוע</span>
						<span data-icon="alarm"
							:class="{active: graph.state == 'hours'}" @click="graph.state = 'hours'">לפי שעה</span>
					</div>
				</div>

				<div class="content">
					<div class="bar" v-for="i in (graph.state == 'days' ? 7 : 24)" :key="i"
						:data-label="graph.state == 'days' ? graph.days[i] : (i-1)"
						:data-value="formatNumber(graphData[graph.state][i] || 0)">
						<span :style="`height: ${
							(((graphData[graph.state][i] || 0)/graphData[graph.state].$max)*100)
						}%`"></span>
					</div>
				</div>
			</div>

			<div class="data orders">
				<div class="options">
					<div class="group">
						<template v-if="!exportOrders">
							<span data-icon="warning_amber" :data-count="orders.counts.importants"
								:class="{active: orders.state == 'important'}" @click="q({tab: 'important'})">דרוש טיפול</span>
							<span data-icon="wifi_tethering" :data-count="o.realtime.length"
								:class="{active: orders.state == 'realtime'}" @click="q({tab: 'realtime'})">עגלות פעילות</span>
							<span data-icon="shopping_cart" :data-count="orders.counts.orders"
								:class="{active: orders.state == 'orders'}" @click="q({tab: 'orders'})">הזמנות</span>
							<span data-icon="compare_arrows" :data-count="orders.counts.modifications"
								:class="{active: orders.state == 'modifications'}" @click="q({tab: 'modifications'})">החלפות והחזרות</span>
							<span data-icon="close" :data-count="orders.counts.cancelations"
								:class="{active: orders.state == 'cancelations'}" @click="q({tab: 'cancelations'})">הזמנות מבוטלות</span>
							<span data-icon="production_quantity_limits" :data-count="orders.counts.checkouts"
								:class="{active: orders.state == 'checkouts'}" @click="q({tab: 'checkouts'})">עגלות נטושות</span>
						</template>
						<template v-else>
							<span class="active" data-icon="task_alt" :data-count="Object.keys(exports).length">הזמנות שנבחרו</span>
						</template>
					</div>
					<div class="group">
						<template v-if="exportOrders">
							<span data-icon="print" @click="print_list">קובץ להדפסה</span>
							<span data-icon="print" @click="print">קובץ דואר רשום</span>
							<span data-icon="local_shipping" @click="$refs.excelLink.click()">קובץ חברת משלוחים</span>
							<span data-icon="cancel" @click="exportOrders = false">בטל</span>
						</template>
						<template v-else>
							<span data-icon="search" data-label="חיפוש" :class="{active: orders.filters}"  @click="orders.filters = !orders.filters"></span>
							<span data-icon="file_download" data-label="ייצוא" @click="exportOrders = true"></span>
						</template>
					</div>
				</div>

				<div class="content">
					<index ref="orders" class="orders" :class="{exportOrders}" :data="o[orders.state].map(order => {
						order.$search = Object.values(order.customer || {}).concat(Object.values(order.address || {})).join(' ');

						return order;
					})" @lazyload="lazyload">

						<div class="index-top" slot="header">
							<template v-if="!exportOrders">
								<div class="filters" :class="{ active: orders.filters }">
									<div class="close" @click="orders.filters = false"></div>

									<index-search :action="(value) => {
										filtersPrefetch();

										$refs.orders.filter({ id: 'search', method: 'like', in: ['number', '$search'], value })
									}" placeholder="חיפוש לפי מס׳ הזמנה או פרטי לקוח"/>

									<index-filter
										label="סטטוס"
										:options="[
											{ value: null, label: 'הכל' },
											{ value: 'processing', label: 'בטיפול' },
											{ value: 'approved', label: 'מאושרת' },
											{ value: 'ready', label: 'מוכנה' },
											{ value: 'shipped', label: 'נשלחה' },
											{ value: 'delivered', label: 'סופקה' },
										]"
										:action="(value) => {
											filtersPrefetch();

											$refs.orders.filter({
												id: (orders.state == 'modifications' ? 'modificationStatus' : 'status'),
												method: 'eq',
												in: [(orders.state == 'modifications' ? 'modificationStatus' : 'status')],
												value
											})
										}"
										v-if="orders.state == 'orders' || orders.state == 'modifications'"
									/>

									<index-filter
										label="איסוף"
										:options="collectsFilters"
										:action="(value) => {
											filtersPrefetch();

											$refs.orders.filter({ id: 'collect', method: 'eq', in: ['collectId'], value })
										}"
										v-if="collectsFilters.length > 1 && orders.state == 'modifications'"
									/>

									<index-filter
										label="אספקה"
										:options="deliveriesFilters"
										:action="(value) => {
											filtersPrefetch();

											$refs.orders.filter({ id: 'delivery', method: 'eq', in: ['deliveryId'], value })
										}"
										v-if="deliveriesFilters.length > 1 && (orders.state != 'cancelations')"
									/>

								</div>
							</template>
						</div>

						<component :is="(orders.state == 'checkouts' || orders.state == 'realtime' ? 'checkout-row' : 'order-row')"
							slot="index" slot-scope="order" :key="order.$index" :order="order" :payment="(orders.state == 'realtime' || orders.state == 'checkouts' ? null : (Object.keys(services).length && order.payment && order.payment.service && services[order.payment.service] ? services[order.payment.service] : {}))"
							:class="{active: exportOrders && exports[order._id]}"
							@click="$router.push({ name: 'orders/view', params: { order: order._id } })">
							<div class="tags" slot="tags" v-if="exportOrders">
								<div class="action" data-icon="add" @click="$set(exports, order._id, order)" v-if="!exports[order._id]">הוסף לרשימת ייצוא</div>
								<div class="action error" data-icon="remove" @click="$delete(exports, order._id, order)" v-else>הסר מרשימת ייצוא</div>
							</div>
						</component>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו הזמנות</div>
					</index>
				</div>
			</div>

			<div class="data messages">
				<div class="options">
					<div class="group">
						<span data-icon="mark_chat_unread" :data-count="messages.unread.length" :class="{active: messages.state == 'unread'}" @click="messages.state = 'unread'">הודעות חדשות</span>
						<span data-icon="forum" :data-count="messages.supports.length" :class="{active: messages.state == 'supports'}" @click="messages.state = 'supports'">כל ההודעות</span>
					</div>
				</div>

				<index class="content" :data="messages[messages.state]">
					<div class="message" slot="index" slot-scope="message" :key="message.$index"
						@click="$router.push(message.order ?
							{ name: 'orders/view', params: { order: message.order._id } } :
							{ name: 'clients/view', params: { client: message.client._id }}
						)">
						<div class="name">{{ message.name }}</div>
						<div class="phone">{{ message.phone }}</div>
						<div class="date">{{ message.time }}</div>
						<div class="order" v-if="message.order">{{ message.order.number }}</div>
						<div class="text">{{ message.message }}</div>
					</div>

					<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו הודעות</div>
				</index>
			</div>

			<div class="data reviews">
				<div class="options">
					<div class="group">
						<span data-icon="mark_chat_unread" :data-count="reviews.length" class="active">חוות דעת</span>
					</div>
				</div>

				<index class="content" :data="reviews">
					<div class="message" slot="index" slot-scope="review" :key="review.$index"
						@click="$router.push({ name: 'orders/view', params: { order: review.order._id }})">
						<div class="name">{{ review.name }}</div>
						<div class="phone">{{ review.client.phone }}</div>
						<div class="date">{{ review.time }}</div>
						<div class="text">
							<div class="section" v-for="product in review.products" :key="product._id">
								<div class="title">{{ product.title }}</div>
								<div class="box" v-for="stock in product.stocks" :key="stock._id">
									<div class="title">
										<span>{{stock.title}}</span>
										<div class="stars">
											<div class="star" :class="{active: (stock.rate == n)}" v-for="n in 5" :key="n"></div>
										</div>
									</div>
									<div class="description">{{stock.description}}</div>
								</div>
							</div>
							<div class="section">
								<div class="title">אתר</div>
								<div class="box">
									<div class="title">
										<span>שירות:</span>
										<div class="stars">
											<div class="star" :class="{active: (review.support == n)}" v-for="n in 5" :key="n"></div>
										</div>
									</div>
									<div class="title">
										<span>הספקה:</span>
										<div class="stars">
											<div class="star" :class="{active: (review.delivery == n)}" v-for="n in 5" :key="n"></div>
										</div>
									</div>
									<div class="description">{{review.description}}</div>
								</div>
							</div>
							<div class="status" :class="{active: review.active}">{{ review.active ? 'מוצג באתר' : 'לא מוצג'}}</div>
							<div class="response" v-if="review.response">נכתב תגובה ללקוח</div>
						</div>
					</div>

					<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו חוות דעת</div>
				</index>
			</div>

			<div class="data clients">
				<div class="options">
					<div class="group">
						<span class="active" data-icon="groups" :data-count="topClients.length">רשימת רוכשים</span>
					</div>
				</div>

				<index class="content rows" :data="topClients">
					<div class="row click" slot="index" slot-scope="client" :key="client.$index" :data-count="client.orders">
						<span class="sub">{{ client.phone }}</span>
						<span class="title">{{ client.name }}</span>
						<span class="value">{{ `${formatNumber(client.spent, 2)} ₪` }}</span>
					</div>

					<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו רוכשים</div>
				</index>
			</div>
		</div>

		<a ref="excelLink" :href="excelBlob" download="orders.csv" target="_blank" style="display: none"></a>
	</div>
</template>

<script>
import moment from 'moment';
moment.locale('he');

export default {
	components:{
		'order-row': require('@/components/order-row').default,
		'checkout-row': require('@/components/checkout-row').default
	},
	data(){
		return {
			blockLoading: false,
			user: null,
			filters: {
				active: false,
				state: 'today',
				dates: {
					from: moment(),
					to: moment()
				}
			},
			summary: {
				state: 'finance',
				expend: false
			},
			graph: {
				state: 'days',
				days: {
					1: 'א',
					2: 'ב',
					3: 'ג',
					4: 'ד',
					5: 'ה',
					6: 'ו',
					7: 'ש'
				}
			},
			orders: {
				state: 'important',
				filters: false,
				counts: {},
				graph: {},
				orders: [],
				checkouts: [],
				importants: [],
				modifications: [],
				cancelations: [],
			},
			messages: {
				state: 'unread',
				unread: [],
				supports: []
			},
			reviews: [],
			store: {},
			services: {},
			exports: null
		}
	},
	watch: {
		'orders.state'(){
			this.$refs.orders.clearFilters();
		},
		'filters.state'(state){
			if(state == 'custom') return;

			this.q({dates: this.filters.state});
		},
		'filters.dates': {
			handler(state){
				if(! state || this.filters.state != 'custom') return;

				this.q({dates: [state.from.format('YYYY-MM-DD'), state.to.format('YYYY-MM-DD')]});
			},
			deep: true
		},
		'$route.query.refresh'(refresh){
			if(refresh) {
				if(this.$route.query.dates){
					if(Array.isArray(this.$route.query.dates) && this.$route.query.dates.length > 1){
						this.fromDate = new Date(this.$route.query.dates[0]);
						this.toDate = new Date(this.$route.query.dates[1]);
					} else {
						this.setFilterState(this.$route.query.dates);
					}
				}

				this.refresh();
			}
		},
		'$route.query.dates'(newVal, oldVal){
			if(oldVal && newVal && newVal[0] == oldVal[0] && newVal[1] == oldVal[1]) return;

			this.refresh();
		},
		'$route.query.tab': 'moveTab'
	},
	mounted(){
		this.moveTab();

		if(this.$route.query.dates){
			if(Array.isArray(this.$route.query.dates) && this.$route.query.dates.length > 1){
				this.fromDate = new Date(this.$route.query.dates[0]);
				this.toDate = new Date(this.$route.query.dates[1]);
			} else {
				this.setFilterState(this.$route.query.dates);
			}
		}

		this.$ws(`${this.$route.params.store}/checkouts`, this.checkoutSocket.bind(this));

		this.$root.$on('notification', (notification) => {
			if(this.filters.state == 'today'){
				if(notification.type == 'client_support' || notification.type == 'order_support'){
					this.getSupports();
					this.getUnreadSupports();
				}
				if(notification.type == 'new_order'){
					this.getOrders();
				}
				if(notification.type == 'cancel_order'){
					this.getCancelations();
				}
				if(notification.type == 'modification_order'){
					this.getModifications();
				}
				if(notification.type == 'review_order'){
					this.getReviews();
				}

				this.getImportants();
			}
		})

		this.refresh()

		this.getUnreadSupports();

		this.getServices();

		this.$index.getUser().then(user => {
			this.user = user.data;
		});
	},
	methods: {
		q(obj, remove = []){
			let query = Object.assign(JSON.parse(JSON.stringify(this.$route.query || {})), obj);

			remove.forEach(key => delete query[key]);

			this.$router.push({query}).catch(() => {});
		},
		moveTab(){
			let tab = this.$route.query.tab || 'important',
				tabs = ['realtime', 'checkouts', 'important', 'orders', 'modifications', 'cancelations'];

				if(! tabs.includes(tab)) return this.orders.state = 'important';

			this.orders.state = tab;
		},
		refresh(){
			this.$root.loading = true;

			this.$refs.orders.clearFilters();

			this.orders.counts = {};

			Promise.all([this.getStore(), this.getImportants(), this.getOrders(), this.getOrdersGraph(), this.getModifications(), this.getCancelations(), this.getCheckouts(), this.getReviews(), this.getSupports()])
				.then(() => {
					this.$root.loading = false;

					this.q({}, ['refresh']);
				});
		},
		lazyload(){
			if(Object.keys(this.$refs.orders.filters).length) return;

			if(this.blockLoading) return;

			this.blockLoading = true;

			if(this.orders.state == 'important') return this.getImportants(true);
			if(this.orders.state == 'orders') return this.getOrders(true);
			if(this.orders.state == 'modifications') return this.getModifications(true);
			if(this.orders.state == 'cancelations') return this.getCancelations(true);
			if(this.orders.state == 'realtime' || this.orders.state == 'checkouts') return this.getCheckouts(true);
		},
		filtersPrefetch(){
			if(this.blockLoading) return;

			this.blockLoading = true;

			if(this.orders.state == 'important') return this.getImportants(false, true);
			if(this.orders.state == 'orders') return this.getOrders(false, true);
			if(this.orders.state == 'modifications') return this.getModifications(false, true);
			if(this.orders.state == 'cancelations') return this.getCancelations(false, true);
			if(this.orders.state == 'realtime' || this.orders.state == 'checkouts') return this.getCheckouts(false, true);
		},
		getStore(){
			this.$models.dashboard.store.get(this.$route.params.store)
			.then(result => {
				Object.assign(this.store,result.data);
			}).catch(() => { });
		},
		getOrders(append = false, all = false){
			if(append && (this.orders.orders.length >= (this.orders.counts.orders !== undefined ? this.orders.counts.orders : 15))){
				this.blockLoading = false;
				this.orders.counts.orders = this.orders.orders.length;

				return;
			}

			let data = {
				status: '',
				delivery: '',
			};

			data.from = this.filters.dates.from.format('YYYY-MM-DD');
			data.to = this.filters.dates.to.format('YYYY-MM-DD');

			return this.$models.orders.order.all(this.$route.params.store, data, (all ? '' : 15), (append ? this.orders.orders.length : 0))
			.then((res) => {
				this.orders.orders = (append ? this.orders.orders : []).concat(res.data);

				if(res.response.headers["kipi-pagination-total"] !== undefined)
					this.orders.counts.orders = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.orders.counts.orders = this.orders.orders.length

				this.blockLoading = false;
			}).catch(error => {
				console.log(error);
			})
		},
		getOrdersGraph(){
			return this.$models.orders.order.graph(this.$route.params.store, {
				from: this.filters.dates.from.format('YYYY-MM-DD'),
				to: this.filters.dates.to.format('YYYY-MM-DD')
			})
			.then((res) => {
				this.orders.graph = res.data;
			})
		},
		getModifications(append = false, all = false){
			if(append && (this.orders.modifications.length >= (this.orders.counts.modifications !== undefined ? this.orders.counts.modifications : 15))){
				this.blockLoading = false;
				this.orders.counts.modifications = this.orders.modifications.length;

				return;
			}

			let data = {
				from: this.filters.dates.from.format('YYYY-MM-DD'),
				to: this.filters.dates.to.format('YYYY-MM-DD')
			};

			return this.$models.orders.order.modifications(this.$route.params.store, data, (all ? '' : 15), (append ? this.orders.modifications.length : 0))
			.then((res) => {
				this.orders.modifications = (append ? this.orders.modifications : []).concat(res.data);

				if(res.response.headers["kipi-pagination-total"] !== undefined)
					this.orders.counts.modifications = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.orders.counts.modifications = this.orders.modifications.length

				this.blockLoading = false;
			})
		},
		getCancelations(append = false, all = false){
			if(append && (this.orders.cancelations.length >= (this.orders.counts.cancelations !== undefined ? this.orders.counts.cancelations : 15))){
				this.blockLoading = false;
				this.orders.counts.cancelations = this.orders.cancelations.length;
				return;
			}

			let data = {
				from: this.filters.dates.from.format('YYYY-MM-DD'),
				to: this.filters.dates.to.format('YYYY-MM-DD')
			};

			return this.$models.orders.order.cancelations(this.$route.params.store, data, (all ? '' : 15), (append ? this.orders.cancelations.length : 0))
			.then((res) => {
				this.orders.cancelations = (append ? this.orders.cancelations : []).concat(res.data);

				if(res.response.headers["kipi-pagination-total"] !== undefined)
					this.orders.counts.cancelations = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.orders.counts.cancelations = this.orders.cancelations.length

				this.blockLoading = false;
			})
		},
		getImportants(append = false, all = false){
			let data = { delivery: ''};

			if(append && (this.orders.importants.length >= (this.orders.counts.importants !== undefined ? this.orders.counts.importants : 15))){
				this.blockLoading = false;
				this.orders.counts.importants = this.orders.importants.length;
				return;
			}

			return this.$models.orders.order.importants(this.$route.params.store, data, (all ? '' : 15), (append ? this.orders.importants.length : 0))
			.then((res) => {
				this.orders.importants = (append ? this.orders.importants : []).concat(res.data);

				if(res.response.headers["kipi-pagination-total"] !== undefined)
					this.orders.counts.importants = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.orders.counts.importants = this.orders.importants.length

				this.blockLoading = false;
			})
		},
		getCheckouts(append = false, all = false){
			let length = Object.keys(this.orders.checkouts).length;

			if(append && (length >= (this.orders.counts.checkouts !== undefined ? this.orders.counts.checkouts : 15))) {
				this.blockLoading = false;
				this.orders.counts.checkouts = length;
				return;
			}

			return this.$models.orders.checkout.all(this.$route.params.store, {
				from: this.filters.dates.from.format('YYYY-MM-DD'),
				to: this.filters.dates.to.format('YYYY-MM-DD')
			}, (all ? '' : 15), (append ? length : 0))
			.then((res) => {
				this.orders.checkouts = Object.fromEntries(
					(append ? Object.values(this.orders.checkouts) : []).concat(res.data).map(checkout => {
					if(! checkout.updates) return null;

					return [checkout.uuid, checkout];
				}).filter(c => c))

				if(res.response.headers["kipi-pagination-total"] !== undefined)
					this.orders.counts.checkouts = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.orders.counts.checkouts = Object.keys(this.orders.checkouts).length

				this.blockLoading = false;
			})
		},
		getReviews(){
			return this.$models.dashboard.review.all(this.$route.params.store, {
				from: this.filters.dates.from.format('YYYY-MM-DD'),
				to: this.filters.dates.to.format('YYYY-MM-DD')
			})
			.then((res) => {
				this.reviews = res.data.map(review => {
					review.time = moment(review.updatedAt).format('LLLL');
					review.name = review.client && review.client.customer && review.client.customer.first_name ? review.client.customer.first_name : review.order.customer.first_name;

					return review;
				});
			})
		},
		getSupports(){
			return this.$models.dashboard.support.all(
				this.$route.params.store,
				this.filters.dates.from.format('YYYY-MM-DD'),
				this.filters.dates.to.format('YYYY-MM-DD')
			).then(res => {
				this.messages.supports = res.data.map(support => {
					return {
						_id: support._id,
						client: (support.client || null),
						order: (support.order || null),
						name: (support.contact ? `${support.contact.first_name || ''} ${support.contact.last_name || ''}` : ''),
						phone: (support.contact && support.contact.phone ? support.contact.phone : ''),
						time: moment(support.messages[(support.messages.length - 1)].time).format("HH:mm DD/MM/YYYY"),
						message: support.messages[(support.messages.length - 1)].message
					}
				});
			})
		},
		getUnreadSupports(){
			return this.$models.dashboard.support.all(
				this.$route.params.store,
				this.filters.dates.from.format('YYYY-MM-DD'),
				this.filters.dates.to.format('YYYY-MM-DD'),
				true
			).then(res => {
				this.messages.unread = res.data.map(support => {
					return {
						_id: support._id,
						client: (support.client || null),
						order: (support.order || null),
						name: (support.contact ? `${support.contact.first_name || ''} ${support.contact.last_name || ''}` : ''),
						phone: (support.contact && support.contact.phone ? support.contact.phone : ''),
						time: moment(support.messages[(support.messages.length - 1)].time).format("HH:mm DD/MM/YYYY"),
						message: support.messages[(support.messages.length - 1)].message
					}
				});
			})
		},
		checkoutSocket(checkout){
			this.$set(this.orders.checkouts, checkout.uuid, checkout);
		},
		setFilterState(state){
			this.filters.dates.to = moment();

			if(state == 'today') {
				this.filters.dates.from = moment();
			} else if(state == 'yesterday'){
				this.filters.dates.from = moment().subtract(1, 'days');
				this.filters.dates.to = moment().subtract(1, 'days');
			} else if(state == 'week'){
				this.filters.dates.from = moment().startOf('week');
			} else if(state == 'month'){
				this.filters.dates.from = moment().startOf('month');
			}

			this.filters.state = state;
			this.filters.active = false;
		},
		formatNumber(n, f = 0){
			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		print_list(){
			this.$root.loading = true;

			let win = window.open('');
			
			let products = {};

			Object.values(this.exports).forEach(order => {
				products[order.number] = this.items(order);
			});

			win.document.write(`<!DOCTYPE html><html><head><style>
				html, body{direction: rtl;font-family: arial}
				@page { size: auto; margin: 0mm 0mm 0mm 0mm; }
				#print { display: flex; flex-wrap: wrap;}
				#print .order { flex: 0 0 calc(50% - 10mm);  margin: 3mm; background: white; border: 1px solid #ccc; box-sizing: border-box; grid-template: "info" "products";}
				#print .order > .info { padding: 10px; line-height: 20px; font-size: 18px;display: grid;grid-template-areas: "client number"  "phone phone" "address address" "notes notes"}
				#print .order > .info div { padding: 4px; line-height: 20px; font-size: 16px;}
				#print .order > .info div.number { grid-area: number;text-align: left; }
				#print .order > .info div.owner { grid-area: owner; }
				#print .order > .info div.owner-address { grid-area: owner-address; }
				#print .order > .info div.client { grid-area: client; }
				#print .order > .info div.address { grid-area: address; }
				#print .order > .info div.phone { grid-area: phone; }
				#print .order > .info div.notes { grid-area: notes;}
				
				#print .order > .products { border-top: 1px solid #ccc; padding: 10px; line-height: 20px; font-size: 16px;}
				#print .order > .products .title{font-weight: bold;}
				#print .order > .products .product{margin-bottom: 10px;padding:6px;display:grid;grid-template-areas: "quantity title" "quantity attributes";grid-template-columns: 40px 1fr;}
				#print .order > .products .product:nth-child(even){background: #eee;}
				#print .order > .products .product .quantity{grid-area: quantity;font-size: 22px;font-weight: bold;text-align: center;justify-self: center;align-self: center;}
				#print .order > .products .product .title{grid-area: title}
				#print .order > .products .product .attributes{grid-area: attributes}

		  	</style></head><body id="print">${Object.values(this.exports).map(order => `
				<div class="order">
					<div class="info">
						<div class="number">מס הזמנה: ${order.number}</div>
						<div class="client">${order.customer.first_name} ${ order.customer.last_name}</div>
						<div class="phone">${ order.customer.phone}</div>
						${order.address ? `<div class="address">כתובת: ${order.address.street} ${order.address.house} ${order.address.apartment ? `דירה ${order.address.apartment}` : ''} ${order.address.city}, ${order.address.zip}</div>` : ''}
						<div class="notes">${order.notes ? `הערות הלקוח: ${order.notes}` : ''}</div>
					</div>
					<div class="products">${
						products[order.number].map(item => `
						<div class="product">
						<div class="quantity">${item.stock.inCart}</div>
						<div class="title">${item.product.title}</div>
						${
							(item.stock.attributes && item.stock.attributes.length) || (item.cart.parameters && Object.keys(item.cart.parameters).length && item.parameters) ? 
							`<div class="attributes">${
								item.stock.attributes && item.stock.attributes.length ? 
								item.stock.attributes.map(attr => `<span>${attr.key}: ${attr.value}</span>`).join(', ') : ''} 
								${item.cart.parameters && Object.keys(item.cart.parameters).length > 0 && Object.keys(item.parameters).length ?
									Object.keys(item.cart.parameters).map(group => `${
										item.parameters[group] && item.cart.parameters[group].length ? 
										`<span>${item.parameters[group]}: ${Object.fromEntries(item.product.parameters.map(g => [g.id, g]))[group].type == 'input' ? item.cart.parameters[group][0] : item.cart.parameters[group].map(param => item.parameters[`${group}:${param}`]).join(', ')}</span>` 
										: ''
									}`).join(', ') 
										: ''
									}
							</div>` 
							: ''
						}
						</div>`).join('')
					}
					</div>
				</div>`).join('')}</body></html>`);

			win.focus();
			win.print();

			this.exportOrders = false;
			this.$root.loading = false;
		},
		print(){
			this.$root.loading = true;

			let win = window.open('');

			win.document.write(`<!DOCTYPE html><html><head><style>
				html, body{direction: rtl;}
				@page { size: auto; margin: 0mm 0mm 0mm 0mm; }
				#print { display: flex; flex-wrap: wrap; margin: 7.5mm 0 0 0; }
				#print .order { display: grid; flex: 0 0 calc(50% - 10mm); height: calc(25% - 10mm); margin: 5mm; background: white; border: 1px solid black; box-sizing: border-box; grid-template: "owner owner" "owner-address owner-address" "client client" "address address" "zip zip" "phone phone";}
				#print .order > div { padding: 4px; line-height: 20px; font-size: 18px; border: 1px solid black; }
				#print .order > div.owner { grid-area: owner; }
				#print .order > div.owner-address { grid-area: owner-address; }
				#print .order > div.client { grid-area: client; }
				#print .order > div.address { grid-area: address; }
				#print .order > div.zip { grid-area: zip; }
				#print .order > div.phone { grid-area: phone; }
		  	</style></head><body id="print">${Object.values(this.exports).map(order => `
				<div class="order" :key="order._id">
					<div class="owner">מאת: ${this.store.name}</div>
					<div class="owner-address">${this.store.address}</div>
					<div class="client">עבור: ${order.customer.first_name} ${ order.customer.last_name}</div>
					${order.address ? `<div class="address">כתובת: ${order.address.street} ${order.address.house} ${order.address.apartment ? `דירה ${order.address.apartment}` : ''} ${order.address.city}</div>
					<div class="zip">מיקוד: ${ order.address.zip }</div>` : ''}
					<div class="phone">${ order.customer.phone}</div>
				</div>
			`).join('')}
		  	</body></html>`);

			win.focus();
			win.print();

			this.exportOrders = false;
			this.$root.loading = false;
		},
		items(order){
			if(! (order && order.products.length)) return [];

			let items = [], parameters;

			let fullCart = order.cart;

			let keys = Object.keys(fullCart);

			if(keys.length && fullCart[keys[0]].constructor === Number){
				fullCart = Object.fromEntries(keys.map(stock => [stock, [{quantity: fullCart[stock]}]]));
			}

			order.products.forEach(product => {

				parameters = {};

				if(product.parameters){
					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' ( ₪ '+ param.price +' )' : ''}`);
					})
				}

				product.stocks.forEach(stock => {
					if(! fullCart[stock._id]) return;

					fullCart[stock._id].forEach(cart => {
						items.push({
							product,
							stock,
							parameters,
							cart
						})
					})
				})
			})

			return items;
		},
		getServices(){
			return this.$models.dashboard.service.index(this.$route.params.store, {type: 'payments'})
			.then(result => {
				let services = {};

				Object.values(result.data).forEach(service => {
					if(! services[service.service]) services[service.service] = {};
					
					services[service.service][service.data && service.data.title ? service.data.title: service.service] =  service.name;
				})

				this.services = services;
			})
		},
	},
	computed: {
		notes(){
			let notes = [];

			if(this.o.important.length){
				notes.push({
					type: 'error',
					text: (this.o.important.length == 1 ? 'הזמנה הדרושה טיפול' : `${this.o.important.length} הזמנות הדורשות טיפול`)
				});
			}

			if(this.messages.unread.length){
				notes.push({
					type: 'warning',
					text: 'יש ' + (this.messages.unread.length == 1 ? 'הודעה חדשה אחת' : `${this.messages.unread.length} הודעות חדשות`)
				});
			}

			if(! notes.length){
				return [{
					type: 'success',
					text: 'הכל כאן מתקתק נהדר! כל הכבוד על ניהול העסק'
				}];
			}

			return notes;
		},
		filterDateTitle(){
			if(this.filters.state == 'today') return "מציג תוצאות מהיום";
			if(this.filters.state == 'yesterday') return "מציג תוצאות מאתמול";
			if(this.filters.state == 'week') return "מציג תוצאות מהשבוע";
			if(this.filters.state == 'month') return "מציג תוצאות מהחודש";

			return `מציג תוצאות מ ${this.filters.dates.from.format("DD/MM/YYYY")} ל ${this.filters.dates.to.format("DD/MM/YYYY")}`
		},
		fromDate: {
			get(){
				return this.filters.dates.from.toDate()
			},
			set(value){
				this.$set(this.filters.dates, 'from', moment(value));

				this.filters.state = "custom";
			}
		},
		toDate: {
			get(){
				return this.filters.dates.to.toDate()
			},
			set(value){
				this.$set(this.filters.dates, 'to', moment(value));

				this.filters.state = "custom";
			}
		},
		stocksStatistics(){
			let stocks = {};

			(this.orders.graph.products || []).forEach(products => products.forEach(product => product.stocks.forEach(stock => {
				if(! stocks[stock._id]) stocks[stock._id] = {
					count: 0,
					title: product.title,
					sub: (stock.attributes ? stock.attributes.map(attr => `${attr.key}: ${attr.value}`).join(', ') : null),
					value: 0
				}

				stocks[stock._id].count = stocks[stock._id].count + stock.inCart;
				stocks[stock._id].value = stocks[stock._id].value + (stock.inCart * stock.paidPrice);
			})))

			return Object.values(stocks).sort((a, b) => b.count - a.count);
		},
		o(){
			let checkouts = [], realtime = [];

			Object.values(this.orders.checkouts).sort((a, b) =>
				moment(b.updates[0].updatedAt)-moment(a.updates[0].updatedAt)
			).forEach(checkout => {
				let last = moment(checkout.updates[0].updatedAt).diff(moment.now(), 'minutes');

				if(last < -4) return checkouts.push(checkout);

				realtime.push(checkout);
			});

			return {
				realtime, checkouts,
				important: this.orders.importants,
				orders: this.orders.orders,
				modifications: this.orders.modifications,
				cancelations: this.orders.cancelations
			}
		},
		deliveriesFilters(){
			let options = {};

			this.o[this.orders.state].forEach(order => {
				let delivery = (order.modification ? order.modification.delivery : order.delivery);

				if(! delivery) return order.deliveryId = '';

				options[delivery._id] = {
					value: delivery._id,
					label: delivery.title
				}

				order.deliveryId = delivery._id;

			});

			return [{ value: null, label: 'הכל' }].concat(Object.values(options));
		},
		collectsFilters(){
			let options = {};

			this.o.modifications.forEach(order => {
				options[order.modification.collect._id] = {
					value: order.modification.collect._id,
					label: order.modification.collect.title
				}

				order.collectId = order.modification.collect._id;
			})

			return [{ value: null, label: 'הכל' }].concat(Object.values(options));
		},
		graphData(){
			let days = {}, hours = {};

			this.o[this.orders.state].forEach(order => {
				let price = (order.summary.total + order.summary.credit - order.summary.delivery),
					time = moment(order.createdAt),
					day = time.format('E'),
					hour = time.format('k');

				if(! days[day]) days[day] = 0;
				if(! hours[hour]) hours[hour] = 0;

				days[day] = days[day] + price;
				hours[hour] = hours[hour] + price;
			});

			days.$max = Math.max(0, ...Object.values(days));
			hours.$max = Math.max(0, ...Object.values(hours));

			return { hours, days };
		},
		topClients(){
			let clients = {};

			this.orders.orders.forEach(order => clients[order.client] = {
				name: `${order.customer.first_name} ${order.customer.last_name}`,
				phone: order.customer.phone,
				spent: (clients[order.client] ? clients[order.client].spent : 0)+(order.summary.total + order.summary.credit - order.summary.delivery),
				orders: (clients[order.client] ? clients[order.client].orders : 0)+1
			})

			return Object.values(clients).sort((a, b) => b.orders - a.orders);
		},
		exportOrders: {
			get(){
				return Boolean(this.exports);
			},
			set(value){
				this.$set(this, 'exports', value ? {} : null);
			}
		},
		excelBlob(){
			if(! (this.exports && Object.keys(this.exports).length)) return;

			let data = [['שם', 'שם מזמין', 'קוד ישוב', 'שם ישוב', 'קוד רחוב', 'שם רחוב', 'בית', 'כניסה', 'קומה', 'דירה', 'טלפון', 'טלפון נוסף', 'תעודה', 'ברקוד', 'קרטונים', 'גוביינא', 'הערות', 'כיוון', 'סוג כפולה', 'סוג רכב', 'סוג דחיפות', 'תאריך משלוח', 'שעת התחלה', 'שעת סיום']];

			Object.values(this.exports).forEach(order => {
				let item = [
					(order.customer.first_name + ' ' + order.customer.last_name),
					'',
					'',
					(order.address ? order.address.city : ''),
					'',
					(order.address ? order.address.street : ''),
					(order.address ? " "+order.address.house : ''),
					'',
					(order.address ? order.address.floor : ''),
					(order.address ? order.address.apartment : ''),
					order.customer.phone,
					'','','','','','','',
					(order.modification ? '2' : ''),
					'','','','',''
				];

				data.push(item);
			});

			let blob = new Blob(["\ufeff" + data.map(item => item.join(',')).join('\n')]);

			return (window.URL || window.webkitURL).createObjectURL(blob);
		}
	}
}
</script>
