<template>
	<div class="layout contact" :class="{loading}">

		<div class="top">
			<div class="title">פניות</div>
		</div>

		<div class="card" v-if="item">
			<b>פרטים הלקוח</b>
			<div class="client">
				<div class="data" v-if="item.contact.first_name"><span>שם פרטי</span>{{ item.contact.first_name }}</div>
				<div class="data" v-if="item.contact.last_name"><span>שם משפחה</span>{{ item.contact.last_name }}</div>
				<div class="data" v-if="item.contact.phone"><span>מס׳ טלפון</span>{{ item.contact.phone }}</div>
				<div class="data" v-if="item.contact.email"><span>אימייל</span>{{ item.contact.email }}</div>
			</div>
		</div>
		<div class="support" v-if="item">
			<div class="messages">
				<div class="message" :class="{owner: chat.owner}" v-for="chat in list">
					<span class="text">{{ chat.message }}</span>
					<span class="time">{{ buildDate(chat.time) }}</span>
				</div>
			</div>
			<div class="bottom">
				<input type="text" name="support" v-model="answer">
				<div class="button" data-icon="send" @click="submit">שלח ללקוח</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			item: null,
			answer: ''
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.support.contact.get(this.$route.params.store, this.$route.params.id)
			.then((res) => {
				if(res.data) this.item = res.data;

				this.item.messages = this.item.messages.reverse();

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		addZero(number){
			if(number < 10){
				number = "0" + number;
			}

			return number;
		},
		buildDate(item){
			let date = new Date(item);

			let resolve = [this.addZero(date.getDate()), this.addZero(date.getMonth() + 1), date.getFullYear()];
			let times = [this.addZero(date.getHours()), this.addZero(date.getMinutes())];

			return resolve.join('-') + ' ' + times.join(':');
		},
		submit(){
			if(! this.answer) return;

			this.$models.support.contact.update(this.$route.params.store, this.$route.params.id, this.answer)
			.then((res) => {

				if(res.data) this.item.messages.unshift(res.data);

				this.answer = '';

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		}
	},
	computed: {
		list(){
			if(! this.item.messages) return [];
			let data = this.item.messages;

			return data
		}
	}
}
</script>
