const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Contact extends Model{

	constructor(){
		super();
	}

	index(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = [store].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/support/contact')
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('dashboard/store/'+store+'/support/contact/'+id)
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);
			})
			.catch(error => reject(error))
		});
	}

	update(store, id, message){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! message) return reject(new Error("Missing `message`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/support/contact/'+id, {message})
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}

}

module.exports = new Contact()
