<template>
	<div class="form-field colors">
		<label>{{ label }}</label>
		<input type="color" :name="name" v-model="input" />
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: String, required: true },
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
