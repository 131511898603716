<template>
	<div class="support">
		<div class="messages">
			<template v-if="messages.length">
			<div class="message" v-for="(item, i) in messages" :key="i" :class="{system: item.user}"
			:data-initials="item.initials" :data-date="item.time ">
				<span class="name">{{ item.name }}</span>
				<span class="text">{{ item.message }}</span>
			</div>
			</template>
		</div>
		<div class="form">
			<input ref="input" class="text" contenteditable  v-model="message" />
			<div class="submit" @click="update">שלח</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		store: {type: String, required: true},
		storeId: {type: String, required: true},
		type: {type: String, required: true}
	},
	data(){
		return {
			loading: true,
			support: [],
			message: ''
		}
	},
	watch:{
		'type': 'get'
	},
	mounted(){
		moment.locale('he');

		this.$ws(`master/support`, this.flash.bind(this));

		this.$ws(`${this.store}/support/master`, this.appendMessage.bind(this));

		this.get();
	},
	methods: {
		get(){
			this.$root.loading = true;
			this.$models.master.support.get(this.storeId, this.type)
			.then((res) => {
				if(res.response.data) this.support = res.response.data;

				this.$root.loading = false;
			}).catch(({response}) => {
				console.log(response);
				this.$root.loading = false;
			});
		},
		update(){
			if(! this.message) return;
			this.$root.loading = true;

			this.$models.master.support.update(this.storeId, this.type, this.message, this.store)
			.then((results) => {

				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});

				this.message = '';
				this.$root.loading = false;

			}).catch(({response}) => {
				VueAlerts.flash({
					text: 'אירע שגיאה!'
				});
				this.$root.loading = false;
			});
		},
		appendMessage(message){
			this.$root.loading = true;
			if(! this.support.messages) this.$set(this.support, 'messages', []);
			this.support.messages.push(message);
			this.$nextTick(() => this.$root.loading = false);
		},
		flash(message){
			VueAlerts.flash({
				text: 'התקבלה הודעה חדשה',
				closeable: false,
				icon: 'support_agent'
			});
		}
	},
	computed: {
		messages(){
			if(! (this.support && this.support.messages && this.support.messages.length)) return [];

			let messages = JSON.parse(JSON.stringify(this.support.messages));


			return messages.map(message => {

				let userName = message.name.split(' ');
				let userInitials = `${userName && userName.length > 1 ? userName.shift().charAt(0) : ''}${userName && userName.length == 1 ? userName.pop().charAt(0) : ''}`;

				message.time = moment(message.time).fromNow();
				message.initials = userInitials.toUpperCase();

				return message;
			}).reverse();
		}
	}
}
</script>
