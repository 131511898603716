<template>
	<div class="layout store" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<router-link class="back" :to="{ name: 'master'}" />
				<span>הוספת אתר</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">הוסף</div>
			</div>
		</div>

		<form-vue ref="form">
			<form-group name="general" title="פרטי האתר" style="z-index: 2">
				<form-input name="store" label="שם האתר(אנגלית בלבד)" v-model="model.store" />
				<form-input name="storename" label="כותרת" v-model="model.storename" />

				<form-select name="section" label="שכפול אתר" v-model="model.example"
					:options="storesList"/>
				<form-area />
			</form-group>

			<form-group name="user" title="פרטי המשתמש" style="z-index: 1">
				<form-input name="name" label="שם משתמש" v-model="model.name" />
				<form-input name="name" label="טלפון" v-model="model.phone" />

				<form-area />
			</form-group>
		</form-vue>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			stores: [],
			model: {
				name: '',
				phone: '',
				store: '',
				storename: '',
				example: ''
			}
		}
	},

	mounted(){


		this.getStores();

		this.loading = false;
	},
	methods:{
		getStores(){
			this.$models.master.stores.all().then((res) => {
				this.stores = res.data;
			});
		},
		submit(){
			this.loading = true;

			this.$models.master.stores.create(this.model).then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;


				this.$router.push({ name: 'master'});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
	},
	computed: {
		storesList(){
			if(! this.stores.length) return [];

			let items = [];

			this.stores.forEach(store => {
				items.push({label: store.store, value: store.store});
			});

			return items;
		}
	}
}
</script>
