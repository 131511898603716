<template>
	<div class="index-row">
		<div class="item" v-for="header in headers"
			:key="header.key" :class="header.key" :data-label="header.label"
			@click="header.toggle && ! data['disableToggle'] ? $emit('toggle') : $emit('click')"
			:style="(header.type == 'image' ? `background-image: url('${data[header.key]}')` : '')">
			<span v-if="data[header.key] === undefined || data[header.key] === null"> - </span>
			<span v-else-if="data[header.key].constructor == Boolean" :data-bool="`${data[header.key]}`"></span>
			<span v-else-if="header.numberFormat">{{ numberFormat(data[header.key], header.numberFormat) }}</span>
			<span v-else-if="header.priceFormat">{{ priceFormat(data[header.key], header.priceFormat) }}</span>
			<span v-else-if="header.dateFormat">{{ formatDate(data[header.key], header.dateFormat) }}</span>
			<span v-else>{{ data[header.key] }}</span>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		headers: { type: Array, required: true },
		data: { type: Object, required: true }
	},
	methods: {
		formatDate(item, format){
			return moment(item).format(format);
		},
		numberFormat(number, format = 0){
			if(format === true) format = 0;

			return number.toFixed(format).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		priceFormat(price, format = 0){
			return `${this.numberFormat(price, format)} ₪`;
		}
	}
}
</script>
