<template>
	<div class="layout dictionary" :class="{ loading }">

		<div class="top">
			<div class="title">שפות</div>
			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('store/dictionary/create')">הוסף חדש</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['name', 'code'], value })"/>
		</div>

		<index ref="index" :data="items" v-if="items" sort-by="_id" @sort="(sort) => {}">
			<div class="table" slot="index" slot-scope="item"
				:class="{ hide: item.$filtered }"
				>
				<div class="title">
					<span>{{ item.name }}</span>
					<span>{{ item.code }}</span>
				</div>
				<div class="actions">
					<div class="button" data-icon="create" @click="goTo('store/dictionary/edit', item._id)">ערוך</div>
					<div class="button" data-icon="done" :class="{dark: item._id != defaultDictionary, success: item._id == defaultDictionary}" @click="setDefault(item._id)">{{ `${item._id != defaultDictionary ? 'הפוך ל' : ''}שפה ראשית` }}</div>
				</div>
			</div>
		</index>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null,
			defaultDictionary: null
		}
	},
	mounted(){
		this.getData();

		this.$index.stores.then(result => {
			let store = result.data.filter(data => data.store == this.$route.params.store)[0];

			this.defaultDictionary = store.dictionary || null;
		})
	},
	methods: {
		getData(){
			this.$models.dashboard.dictionary.all(this.$route.params.store)
			.then((res) => {
				this.items = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		filter(value){
			Object.values(this.$refs).forEach(
				index => index.filter({ id: 'search', method: 'like', in: ['name'], value })
			);
		},
		goTo(name, id = null){
			if(id)
				return this.$router.push({ name, params: {id} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		setDefault(id){
			this.$models.dashboard.store.setDictionary(this.$route.params.store, id)
			.then(result => {
				this.defaultDictionary = id;
			})
			.catch(error => {
				console.log(error);
			});
		}
	}
}
</script>
