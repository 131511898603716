<template>
	<div class="layout forms bundles" :class="{ loading }">

		<div class="back" @click="$router.push({ name: 'inventory', params:{store: $route.params.store}})"></div>
		<div class="title">עריכות חבילות</div>
		<div class="tabs">
			<div data-icon="description" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטי החבילה?</div>
			<div data-icon="dashboard_customize" :class="{active: state.tab == 'groups'}"
				@click="$router.push({query: {tab: 'groups'}})">פריטים בחבילה</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטי חבילה</span>
					</div>
					<div class="content">
						<form-input name="title" label="שם החבילה" v-model="model.title" />
					</div>
				</div>
			</div>

			<div class="container groups" key="groups" :class="{
				active: (state.tab == 'groups'),
				explain: ! (model.groups && model.groups.length)
			}" v-if="state.tab == 'groups'">
				<div class="data explain" v-if="! (model.groups && model.groups.length)" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="account_tree" @click="createGroup">
								<span class="title">יצירת קבוצה חדשה</span>
								<div class="description">כדי ליצור חבילה דינאמית ולקשר מוצרים בצורה חכמה, יש לחלק את המוצרים בחבילה לקבוצות עפ״י מאפיינים דומים.</div>
							</div>
						</div>
					</div>
				</div>

				<index class="dynamic-groups" :data="model.groups" sort-by="id" @sort="setGroupSort">
					<div slot="index" slot-scope="group" class="data dynamic-group" :draggable="group.$sort.active" @dragover="group.$sort.move" @dragend="group.$sort.end" animated>
						<div class="options">
							<div class="group">
								<span class="sort" data-icon="more_vert" :data-count="group.query.products.length"
									@mousedown="group.$sort.activate" @mouseup="group.$sort.end" @touchstart="group.$sort.activate" @touchend="group.$sort.end">{{ group.name != "" ? group.name : "קבוצה חדשה" }}</span>
							</div>
							<div class="group">
                                <span class="error" data-icon="delete_sweep" @click="deleteGroup(group.$index)" v-if="state.activeGroup == group.$index">מחיקת קבוצה</span>
                                <span :class="{primary: state.activeGroup == group.$index}" data-icon="settings" @click="state.activeGroup = group.$index">ניהול קבוצה</span>
							</div>
						</div>
						<div class="content" v-if="state.activeGroup == group.$index" animated>
							<div class="dynamic-group-column">
								<form-input :name="`params${group.$index}title`" label="שם הקבוצה" placeholder="קבוצה חדשה" v-model="model.groups[group.$index].name" />
                            </div>

						</div>
					</div>

					<div slot="footer" class="add-dynamic-group" animated @click="createGroup" v-if="model.groups.length">הוסף קבוצה חדשה</div>
				</index>

				<query ref="query" :query="active.query" :sort="{}" :search="['_id', 'title']" :uncache="['products']" :load="loadQuery.bind(this)" v-if="active">
					<template slot="index" slot-scope="item">
						<div class="checkbox" v-if="active.query.products">
							<input type="checkbox" :value="item._id" v-model="active.query.products">
							<label></label>
						</div>

						<div><span>{{ item.title }}</span></div>
						<div><span>₪{{ item.price }}</span></div>
						<div><span>{{ (! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי')) }}</span></div>
					</template>
				</query>

			</div>
		</transition>
	</div>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	data(){
		return {
			state: {
				tab: '',
				activeGroup: 0
			},
			loading: true,
			model: this.$models.inventory.bundle.model()
		}
	},
	watch:{
		'$route.query.tab': 'setTab',
		'state.activeGroup'(){
			this.$refs.query.refresh();
		}
	},
	mounted(){
		this.getData().then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details')
		});
	},
	methods: {
		setTab(tab){
			if(['details', 'groups'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			if(! this.$route.params.bundle) return Promise.resolve();

			return this.$models.inventory.bundle.get(this.$route.params.store, this.$route.params.bundle)
			.then(res => {
				this.model = res.data;

				this.$root.loading = false;
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		loadQuery(){
			return this.$models.inventory.product.query(this.$route.params.store, {})
		},
        createGroup(){
            this.state.activeGroup = -1 + this.model.groups.push({
				id: makeHash(10),
                name: '',
                query: {
					products: []
				}
            })
        },
		deleteGroup(index){
			this.state.activeGroup = 0;

			this.model.groups.splice(index, 1);
		},
        setGroupSort(sort){
            this.model.groups.sort((a, b) => sort.indexOf(a.id) - sort.indexOf(b.id));
        },
		save(){
			this.$root.loading = true;

			let request = null;

			if(this.$route.params.bundle){
			 	request = this.$models.inventory.bundle.update(this.$route.params.store, this.$route.params.bundle, this.model)
			}
			else{
				request = this.$models.inventory.bundle.create(this.$route.params.store, this.model)
			}

			request.then(() => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if(! this.$route.params.bundle){
					return this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

				this.$root.loading = false;
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.$root.loading = false;
			});
		}
	},
	computed: {
		active(){
			return this.model.groups[this.state.activeGroup];
		}
	}
}
</script>
