<template>
	<div class="layout dashboard web">

		<div class="header">
			<div class="title">
				<span>ניהול אתר</span>
			</div>

			<div class="actions">
				<div class="buttons">
					<div class="action" data-icon="add" :class="{active: addActive}" @click="addActive = !addActive">הוספה</div>
					<div class="action single" data-icon="settings" @click="goTo('web/design')">הגדרות עיצוב</div>
				</div>
				<div class="container menu" :class="{active: addActive}">
					<div class="option" data-icon="add" @click="goTo('web/pages/create', {type: 'search'})">הוספת דף חיפוש</div>
					<div class="option" data-icon="add" @click="goTo('web/pages/create', {type: 'blog'})">הוספת דף בלוג</div>
					<div class="option" data-icon="add" @click="goTo('web/pages/create', {type: 'page'})">הוספת דף סטטי</div>
					<div class="option" data-icon="add" @click="goTo('web/popups/create')">הוספת פופאפ</div>
					<div class="option" data-icon="add" @click="goTo('web/redirects/create')">הוספת הפניה</div>
					<div class="option" data-icon="add" @click="goTo('web/dictionary/create')">הוסף מילון</div>
				</div>
			</div>
		</div>

		<div class="grid">

			<div class="data design">
				<div class="options">
					<div class="group">
						<span data-icon="desktop_mac" class="active">עיצוב האתר</span>
					</div>
				</div>
				<div class="content rows">
					<index :data="layoutsList">
						<div class="row" slot="index" slot-scope="row" data-icon="desktop_mac" @click="goTo('web/layouts/default', {name: row.name})">
							<span class="title">{{ row.title }}</span>
						</div>
					</index>
				</div>
			</div>

			<div class="data main">
				<div class="options">
					<div class="group">
						<span data-icon="web" :data-count="pages.length"
							:class="{active: main.state == 'pages'}" @click="setMainState('pages')">דפים</span>
						<span data-icon="compare_arrows" :data-count="redirects.length"
							:class="{active: main.state == 'redirects'}" @click="setMainState('redirects')">הפניות</span>
						<span data-icon="aspect_ratio" :data-count="popups.length"
							:class="{active: main.state == 'popups'}" @click="setMainState('popups')">פופאפים</span>
						<span data-icon="translate" :data-count="dictionary.length"
							:class="{active: main.state == 'dictionary'}" @click="setMainState('dictionary')">מילון</span>
						<span data-icon="design_services" @click="goTo('web/tags')">תגיות</span>
					</div>
					<div class="group">
						<span data-icon="search" data-label="חיפוש" :class="{active: main.mobile.filters}" @click="main.mobile.filters = !main.mobile.filters; main.mobile.sort = false"></span>
						<span class="mobile" data-icon="sort" data-label="מיון" :class="{active: main.mobile.sort}" @click="main.mobile.sort = !main.mobile.sort; main.mobile.filters = false"></span>
					</div>
				</div>

				<div class="content">
					<index ref="main" :data="mainData" :class="main.state">
						<template slot="header">
							<div class="index-top">
								<div class="filters" :class="{ active: main.mobile.filters }">
									<div class="close" @click="main.mobile.filters = false"></div>
									<component v-for="filter in mainFilters"
										:key="`${main.state}_${filter.id}`" :is="filter.component" :action="(value) => setMainFilter(filter, value)"
										:label="filter.label" :options="filter.options" :placeholder="`חיפוש ב${filter.placeholder.join(', ')}`" />
								</div>
							</div>
							<div class="header" :class="{active: main.mobile.sort}">
								<div class="close" @click="main.mobile.sort = false"></div>
								<div class="item" v-for="header in main.headers" :key="header.key"
									:class="[(main.sort && header.key == main.sort.key ? {
										active: true,
										up: main.sort.dir > 0,
										down: main.sort.dir < 0
									} : ''), {sort: header.sort}]"
									@click="setMainSort(header)">
									{{ header.label }}
								</div>
							</div>
						</template>


						<template slot="index" slot-scope="item">
							<index-row :class="[main.state, {hide: item.$filtered}]"
								:headers="main.headers" :data="item" @click="goToItem(item)" @toggle="toggle(item)" />
						</template>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			addActive: false,
			summary: {
				state: 'monthly',
				expend: false
			},
			main: {
				state: 'pages',
				headers: [],
				mobile: {
					filters: false,
					sort: false
				}
			},
			pages: [],
			popups: [],
			redirects: [],
			dictionary: [],
			defaultDictionary: null,
			layouts: this.$models.layout.web.pages()
		}
	},
	mounted(){
		this.$root.loading = true;

		this.$index.stores.then(result => {
			let store = result.data.filter(data => data.store == this.$route.params.store)[0];

			this.defaultDictionary = store.dictionary || null;
		})

		Promise.all([
			this.getPages(), this.getPopups(), this.getRedirects(), this.getDictionaries()
		]).then(() => this.setMainState('pages'));
	},
	methods: {
		getPages(){
			this.$models.pages.page.index(this.$route.params.store)
			.then((res) => {
				this.pages = res.data.map(page => {
					if( page._id != 'home' && (page.ref || (page.slug == '/'))) return;

					return page;
				}).filter(e => e!=null);

				this.pages.unshift({_id: 'home', slug: '/', data: { title: 'דף הבית'}, active: true, type: 'home'});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getPopups(){
			this.$models.dashboard.popup.all(this.$route.params.store)
			.then((res) => {
				this.popups = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getRedirects(){
			this.$models.dashboard.redirect.all(this.$route.params.store)
			.then((res) => {
				this.redirects = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getDictionaries(){
			this.$models.dashboard.dictionary.all(this.$route.params.store)
			.then((res) => {
				this.dictionary = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		setSummaryState(state){
			this.summary.expend = false;
			this.summary.state = state;
		},
		setMainState(state){
			let headers = {
				pages: [
					{key: 'title', label: 'כותרת', sort: true, sortLocale: true, search: true},
					{key: 'type', label: 'סוג אתר', sort: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: 'page', label: 'דפים סטטים'},
						{value: 'blog', label: 'בלוג'},
						{value: 'search', label: 'חיפוש'}
					]}},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				dictionary: [
					{key: 'name', label: 'כותרת', sort: true, sortLocale: true, search: true},
					{key: 'code', label: 'קוד שפה', sort: true, search: true},
					{key: 'default', label: 'שפה ראשית'}
				],
				popups: [
					{key: 'title', label: 'כותרת', sort: true, sortLocale: true, search: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				],
				redirects: [
					{key: 'fromUrl', label: 'מעמוד', sort: true, sortLocale: true, search: true},
					{key: 'code', label: 'קוד סטטוס', sort: true, search: true},
					{key: 'active', label: 'פעיל', sort: true, toggle: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				]
			};

			this.$root.loading = true;

			this.$refs.main.clearFilters();

			this.main = { state, headers: headers[state],
				mobile: {
					filters: false,
					sort: false
				}
			};

			this.$root.loading = false;
		},
		setMainSort(header){
			if(! header.sort) return;

			let sort = {key: header.key, dir: -1, sortLocale: header.sortLocale};

			if(this.main.sort && this.main.sort.key == header.key) sort.dir = (this.main.sort.dir*-1);

			this.$set(this.main, 'sort', sort);
		},
		setMainFilter(filter, value){
			this.$refs.main.filter(Object.assign(filter, { value }))
		},
		formatNumber(n, f = 0){
			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		goTo(name, params = {}){
			this.$router.push({ name, params }).catch(() => {})
		},
		goToItem(item){
			let params = {};

			if(this.main.state == 'pages') params.id = item._id;
			if(this.main.state == 'redirects') params.id = item._id;
			if(this.main.state == 'popups') params.id = item._id;
			if(this.main.state == 'dictionary') params.id = item._id;

			if(this.main.state == 'pages' && params.id == 'home') {
				return this.$router.push({ name: `web/${this.main.state}/home`, params }).catch(() => {})
			}

			this.$router.push({ name: `web/${this.main.state}/edit`, params }).catch(() => {})
		},
		toggle(item){
			let model = null, index = item.$index, parentId = item.parent;

			if(this.main.state == 'pages') model = this.$models.pages.page;
			if(this.main.state == 'redirects') model = this.$models.dashboard.redirect;
			if(this.main.state == 'popups') model = this.$models.dashboard.popup;

			if(! model) return;

			model[item.active ? 'remove' : 'restore'](this.$route.params.store, item._id)
			.then((res) => {
				this[this.main.state][index].active = ! this[this.main.state][index].active;

				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
	},
	computed: {
		mainData(){
			let data = this[this.main.state].map(item => {

				if(this.main.state == 'pages'){
					item.disableToggle = item.slug == '/' ? true : false;
					item.title = item.data && item.data.title ? item.data.title : item.slug;
				}

				if(this.main.state == 'dictionary'){
					item.default = this.defaultDictionary == item._id ? true : false;
				}

				return item;
			});

			if(this.main.sort){
				data.sort((a, b) => (this.main.sort.sortLocale ?
					(b[this.main.sort.key].toString().localeCompare(a[this.main.sort.key].toString(), 'he')) :
					(a[this.main.sort.key] - b[this.main.sort.key])
				) * this.main.sort.dir);
			}

			return data;
		},
		mainFilters(){
			let filters = {};

			this.main.headers.forEach(header => {
				if(header.search){
					if(! filters.search) filters.search = {
						id: 'search',
						component: 'index-search',
						method: 'like',
						in: [],
						placeholder: [],
						options: []
					};

					filters.search.in.push(header.key);
					filters.search.placeholder.push(header.label);
				}

				if(header.filter){
					filters[header.key] = Object.assign(header.filter, {
						id: header.key,
						in: [header.key],
						label: header.label,
						component: 'index-filter',
						placeholder: []
					})
				}
			});

			return Object.values(filters);
		},
		layoutsList(){
			return Object.keys(this.layouts).map(key =>  key != 'blog-main' ? ({ name: key, title: this.layouts[key].title }) : null).filter(function (el) {
			  return el != null;
			});
		},
		storeList(){
			return [
				{title: 'שם', value: this.store.name || '', icon: 'account_circle'},
				{title: 'כתובת', value: this.store.address || '', icon: 'local_shipping'},
				{title: 'טלפון', value: this.store.phone || '', icon: 'alternate_email'},
				{title: 'אימייל', value: this.store.email || '', icon: 'phone_iphone'}
			]
		}
	}
}
</script>
