const Model = require('@/models/model');

class Support extends Model {

    constructor() {
        super();
    }

    all() {
        return new Promise((resolve, reject) => {
            this.$api.get(`master/support`).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);

            }).catch(error => reject(error));
        });
    }

    get(store, type) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!type) return reject(new Error("Missing `type`"));

            this.$api.post(`master/support/${store}`, { type }).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);
            }).catch(error => reject(error));
        });
    }

    update(store, type, message, storeName) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!type) return reject(new Error("Missing `type`"));
            if (!message) return reject(new Error("Missing `message`"));
            if (!storeName) return reject(new Error("Missing `storeName`"));

            this.$api.post(`master/support/${store}/update`, { type, message, storeName }).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);
            }).catch(error => reject(error));
        });
    }

}

module.exports = new Support()
