const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Rate extends Model {

    constructor() {
        super();
    }

    model(){
        return {
            title: '',
            active: true,
            price: {
                type: '',
                value: ''
            },
            limitations: {

            }
        }
    }

    all(store) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));

            this.$api.get(store + '/calendar/rates').then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);

            }).catch(error => reject(error));
        });
    }

    get(store, id) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));


            let key = [store, id].join('_');
            let cache = this.$cache.get(key);
            if (cache) return resolve(cache);

            this.$api.get(store + '/calendar/rate/' + id)
            .then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);

                this.$cache.set(key, result);
            })
            .catch(error => reject(error))
        });
    }


    prePost(payload) {

        let data = Cast(payload, {
            title: String,
            price: {
                type: String,
                value: Number
            },
            limitations: {
                clients: [String],
                booking: {
                    min: Number,
                    max: Number,
                    start: String,
                    end: String
                },
                session: {
                    days: [Number],
                    start: String,
                    end: String,
                    offset: Number,
                    holidays: Boolean,
                }
            },
            active: Boolean
        })

        return { data }
    }

    create(store, payload) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!payload) return reject(new Error("Missing `payload`"));

            let { data } = this.prePost(payload);

            this.$api.post(store + '/calendar/rates/create', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

    update(store, id, payload) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));
            if (!payload) return reject(new Error("Missing `payload`"));

            let { data } = this.prePost(payload);

            this.$api.post(store + '/calendar/rate/' + id + '/update', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

}

module.exports = new Rate()
