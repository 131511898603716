<template>
	<div class="layout dashboard marketing">

		<div class="header">
			<div class="title">
				<span>ניהול שיווק</span>
			</div>

			<div class="actions">
				<div class="buttons">
					<div class="action" data-icon="add" :class="{active: activeMenu == 'add'}"
						@click="activeMenu = (activeMenu == 'add' || 'add')">הוספה</div>
				</div>
				<div class="container menu" :class="{active: activeMenu == 'add'}">
					<div class="option" data-icon="style" @click="$router.push({name: 'marketing/campaigns/create'})">קמפיין חדש</div>
				</div>
			</div>
		</div>

		<div class="grid">

			<div class="data stats">
				<div class="content">

				</div>
			</div>

			<div class="data campaigns">
				<div class="content">
					<index ref="main" :data="Object.values(campaigns)">
						<div class="index-card campaign" slot="index" slot-scope="campaign" :key="campaign.$index">
							<div class="details">
								<div class="title">{{ campaign.title }}</div>
								<div class="type">{{ campaign.type }}</div>
							</div>
							<div class="id">{{ campaign.unique }}</div>
							<div class="pie">
								<charts-pie :r="100" :data="campaign.data[campaign.state]">
									<div class="inner" slot="inner" slot-scope="data">
										<span v-if="campaign.data[campaign.state][(data.active === null ? 2 : data.active)].value">{{ formatNumber(campaign.data[campaign.state][(data.active === null ? 2 : data.active)].value) }}</span>
										<span v-else>{{ formatNumber(data.precentage, 1) }}%</span>
										<span>{{ campaign.data[campaign.state][(data.active === null ? 2 : data.active)].pieLabel }}</span>
									</div>
								</charts-pie>
							</div>
							<div class="bars">
								<div class="bar" v-for="state in Object.keys(campaign.data)" :key="state"
									:class="{light: state != campaign.state}" @click="setCampaignState(campaign.unique, state)">
									<span :data-label="campaign.data[state][0].barLabel" :style="{
										width: `${(campaign.data[state][0].value/(campaign.data[state][0].value + campaign.data[state][1].value))*100}%`
									}">{{ campaign.data[state][0].value }}</span>
									<span :data-label="campaign.data[state][1].barLabel">{{ campaign.data[state][1].value }}</span>
								</div>
							</div>
							<div class="more">נתונים נוספים</div>
							<div class="prices">
								<span class="success">{{ formatNumber(180*3000) }}</span>
								<span class="error">{{ formatNumber(10500) }}</span>
							</div>
						</div>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו פריטים</div>
					</index>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			activeMenu: false,
			campaigns: {}
		}
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([ this.getCampaigns() ]).then(() => this.$root.loading = false);
	},
	methods: {
		getCampaigns(){
			this.$models.marketing.campaign.all(this.$route.params.store).then((res) => {
				this.campaigns = Object.fromEntries(res.data.map(campaign => {
					this.$set(campaign, 'state', 'conversions');

					campaign.data = {
						conversions: [
							{value: 4717, pieLabel: `₪${this.formatNumber(10500/4717, 2)} להזמנה`, barLabel: 'הזמנות'},
							{value: 34961, pieLabel: `₪${this.formatNumber(10500/34961, 2)} עלות לכניסה`, barLabel: 'כניסות'},
							{pieLabel: 'המרה'}
						],
						clients: [
							{value: 4717, pieLabel: 'לקוחות חוזרים', barLabel: 'לקוחות חוזרים'},
							{value: 30224, pieLabel: 'לקוחות חדשים', barLabel: 'לקוחות חדשים'},
							{value: 34961, pieLabel: 'סה״כ לקוחות'}
						]
					}

					return [campaign.unique, campaign];
				}));
			})
		},
		setCampaignState(unique, state){
			this.campaigns[unique].state = state;
		},
		formatNumber(n, f = 0){
			let o = n;

			if(n.constructor === String) n = parseInt(n);

			if(n.constructor !== Number) return o;

			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	}
}
</script>
