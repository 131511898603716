<template>
	<div class="layout distributions" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<div class="back" @click="$router.back()"></div>
				<span>אזורי הפצה</span>
			</div>
			<div class="actions">
				<div class="button add dark" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-group style="z-index:1" name="lines" v-if="model.distributions">
			<index :data="model.distributions">
				<template slot="index" slot-scope="item">
					<div class="table">
						<div class="info">{{ model.distributions[item.$index].city.name }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableLine != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group :style="`z-index:${item.$index}`" :key="item.$index" class="item" v-if="editableLine == item.$index">
						<form-group style="z-index:3">
							<form-input name="city" label="יישוב"
								:autocomplete="cities"
								:activateAutocomplete="onCitySearch"
								:setAutocomplete="setAutocomplete.bind(item.$index)"
								v-model="model.distributions[item.$index].city.input" />
							<form-toggle name="restriction" label="החרגת רחובות" v-model="restrictions[item.$index]" @toggle="toggleRestrictions(item.$index)" />
							<form-area />
						</form-group>
						<form-group style="z-index:2" v-if="restrictions[item.$index]">
							<form-checkbox name="streets" label="בחר רחובות להחרגה" v-model="model.distributions[item.$index].restrictions"
								:options="streets"/>
						</form-group>
					</form-group>
				</template>
			</index>
			<div class="buttons">

			</div>
		</form-group>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			editableLine: null,
			locations: [],
			deliveriesData: [],
			model: {
				distributions: []
			}
		}
	},
	mounted(){
		this.getData();
		this.getDeliveries();
	},
	methods: {
		addDynamic(){
			this.model.distributions.push({city: {input: ''}, restrictions: null, deliveries: [], branches: []});

			this.editableLine = this.model.distributions.length - 1;
		},
		removeDynamic(index){
			if(this.editableLine == index) this.editableLine = null;

			this.model.distributions.splice(index, 1);
		},
		toggleBox(key){
			if(key == this.editableLine) return this.editableLine = null;

			this.editableLine = key;

			if(this.model.distributions[this.editableLine].city && this.model.distributions[this.editableLine].city.input) this.query(this.model.distributions[this.editableLine].city.input);

		},
		toggleRestrictions(key){
			if(! this.restrictions[key]) return this.$delete(this.model.distributions[key], 'restrictions');

			this.$set(this.model.distributions[key], 'restrictions', []);
		},
		getData(){
			this.loading = true;

			this.$models.inventory.delivery.getSettings(this.$route.params.store)
			.then((res) => {
				if(Object.keys(res.data).length) this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		getDeliveries(){
			this.$models.inventory.delivery.all(this.$route.params.store)
			.then((res) => {
				this.deliveriesData = res.data;
			});
		},
		onCitySearch(e, value){
			if(value.length < 2) return false;

			this.query(value);

			return true;
		},
		query(search){
			if(this.loading) return;

			this.loading = true;
			this.$models.master.location.query({search})
			.then(response => {
				this.locations = response.data;
				this.loading = false;
			})
			.catch(error => {
				console.log(error);
				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			this.$models.inventory.delivery.updateSettings(this.$route.params.store, this.model)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		setAutocomplete(item){
			this.$set(this.model.distributions[this.editableLine], 'city', {id: item.id, name: item.label});

			return item.label;
		}
	},
	computed: {
		cities(){
			return this.locations.map(city => ({id: city._id, label: city.city}));
		},
		streets(){
			if(! this.locations.length || this.locations.length > 1) return [];

			return this.locations[0].streets.map(street => ({value: street._id, label: street.name}))
			.sort((a,b) => {
				if(a.label < b.label) return -1;
				if(a.label > a.label) return 1;
				return 0;
			})
		},
		restrictions() {
			return this.model.distributions.map(item => item.restrictions != undefined);
		},
		deliveries(){
			return this.deliveriesData.map(delivery => {
				if(delivery.type == 'ship' && delivery.active) return {value: delivery._id, label: delivery.title};
			}).filter(e => e!=null);
		}
	}
}
</script>
