const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Page extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			type: '',
			slug: '',
			active: true,
			data: {
				title: '',
				content: '',
				description: '',
				images: [],
				categories: []
			},
			seo: {
				title: '',
				description: '',
				keywords: ''
			},
			query: {},
			sort: {},
			limit: {},
			layout: []
		}
	}

	index(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = [store].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/pages')
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	query(store,  query){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! query) return reject(new Error("Missing `query`"));

			let model = Cast(query, {
				query: {
					pages: [String],
					categories: [String],
					active: Boolean
				},
				sort: {
					priority: {
						type: String,
						period: String,
						limit: Number
					},
					static:[String]
				},
				limit: Number,
				offset: Number
			});

			this.$api.post('dashboard/store/'+store+'/pages/query', model)
			.then(response => {
				let result = { response };

				result.data = response.data.map(page => {
					page.title = page.data.title
					return page;
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	get(store,  id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/pages/' + id)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					type: String,
					slug: String,
					data: {
						title: String,
						content: String,
						description: String,
						images: [{
							source: String,
							title: String,
						}],
						categories: [String]
					},
					seo: {
						title: String,
						description: String,
						keywords: String
					},
					query: {
						price: [String],
						products: [String],
						quantity: [String],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					},
					sort: {
						priority: {
							type: String,
							period: String,
							limit: String
						},
						static:[String]
					},
					limit: String,
					active: Boolean,
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				result.data.limit = result.data.limit ? { limit: result.data.limit } : {};
				if(result.data.query){
					if(result.data.query.price){
						result.data.query.price[0] = result.data.query.price[0] || ""
						result.data.query.price[1] = result.data.query.price[1] || ""
					}

					if(result.data.query.quantity){
						result.data.query.quantity[0] = result.data.query.quantity[0] || ""
						result.data.query.quantity[1] = result.data.query.quantity[1] || ""
					}
				}

				if(result.data.sort && result.data.sort.priority){
					result.data.sort.priority.limit = result.data.sort.priority.limit || "10"
				}

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload, uploads = {}){
		if(payload.data.images.length){
			payload.data.images.forEach((image, i) => {
				if(! image.file) return;

				uploads["images@@"+i] = image.file;
				image.source = "file@@images@@"+i;
			});
		}

		let data = Cast(payload, {
			_id: String,
			type: String,
			slug: String,
			data: {
				title: String,
				content: String,
				description: String,
				images: [{
					source: String,
					title: String,
				}],
				categories: [String]
			},
			seo: {
				title: String,
				description: String,
				keywords: String
			},
			query: {
				price: [Number],
				products: [String],
				quantity: [Number],
				categories: [String],
				properties: [{
					key: String, value: String
				}]
			},
			sort: {
				priority: {
					type: String,
					period: String,
					limit: Number
				},
				static:[String]
			},
			limit: Number,
			active: Boolean,
			layout: [{
				props: {
					padding: [Number]
				},
				containers: [{
					props: {
						padding: [Number]
					}
				}]
			}]
		});

		data.id = data._id;

		data.limit = data.limit ? { limit: data.limit } : {};

		return {data, uploads};
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, uploads} = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/pages/create', data, uploads)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					type: String,
					slug: String,
					data: {
						title: String,
						content: String,
						description: String,
						images: [{
							source: String,
							title: String,
						}],
						categories: [String]
					},
					seo: {
						title: String,
						description: String,
						keywords: String
					},
					query: {
						price: [String],
						products: [String],
						quantity: [String],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					},
					sort: {
						priority: {
							type: String,
							period: String,
							limit: String
						},
						static:[String]
					},
					limit: String,
					active: Boolean,
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}

	update(store, id, payload, files){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));
			if(! id) return reject(new Error("Missing `id`"));

			let {data, uploads} = this.prePost(payload, files);

			this.$api.postWithFiles('dashboard/store/'+store+'/pages/'+id+'/update', data, uploads)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					type: String,
					slug: String,
					data: {
						title: String,
						content: String,
						description: String,
						images: [{
							source: String,
							title: String,
						}],
						categories: [String]
					},
					seo: {
						title: String,
						description: String,
						keywords: String
					},
					query: {
						price: [String],
						products: [String],
						quantity: [String],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					},
					sort: {
						priority: {
							type: String,
							period: String,
							limit: String
						},
						static:[String]
					},
					limit: String,
					active: Boolean,
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/pages/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/pages/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Page()
