const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Bundle extends Model {

    constructor() {
        super();
    }

    model(){
        return {
            title: '',
            description: '',
            groups: [],
            active: true
        }
    }

    all(store) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));

            let key = store + '_all';
            let cache = this.$cache.get(key);
            if (cache) return resolve(cache);

            this.$api.get(store + '/inventory/bundles').then(response => {
                let result = { response };

                result.data = Cast(response.data, [{
                    _id: String,
                    title: String,
                    active: Boolean
                }]);

                resolve(result);

                this.$cache.set(key, result);

            }).catch(error => reject(error));
        });
    }

    get(store, id) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));

            let key = [store, id].join('_');
            let cache = this.$cache.get(key);
            if (cache) return resolve(cache);

            this.$api.get(store + '/inventory/bundles/' + id).then(response => {
                let result = { response };

                result.data = Cast(response.data, {
                    _id: String,
                    title: String,
                    description: String,
                    groups: [{
                        id: String,
                        name: String,
                        query: {
                            products: [String]
                        }
                    }],
                    active: Boolean
                });

                resolve(result);

                this.$cache.set(key, result);
            }).catch(error => reject(error));
        });
    }

    create(store, payload) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!payload) return reject(new Error("Missing `payload`"));

            let data = Cast(payload, {
                title: String,
                description: String,
                groups: [{
                    id: String,
                    name: String,
                    query: {
                        products: [String]
                    }
                }],
                active: Boolean
            });

            this.$api.post(store + '/inventory/bundles/create', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

    update(store, id, payload) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));
            if (!payload) return reject(new Error("Missing `payload`"));

            let data = Cast(payload, {
                _id: String,
                title: String,
                description: String,
                groups: [{
                    id: String,
                    name: String,
                    query: {
                        products: [String]
                    }
                }],
                active: Boolean
            });

            this.$api.post(store + '/inventory/bundles/' + id + '/update', data)
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

    remove(store, id) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));

            this.$api.post(store + '/inventory/bundles/' + id + '/remove', { _id: id })
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }

    restore(store, id) {
        this.$cache.clear();

        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));
            if (!id) return reject(new Error("Missing `id`"));

            this.$api.post(store + '/inventory/bundles/' + id + '/restore', { _id: id })
                .then(response => resolve(response))
                .catch(error => reject(error))
        });
    }
}

module.exports = new Bundle()
