<template>
	<div class="form-field input">
		<label>{{ label }}</label>
		<input ref="input" :type="type" :name="name" v-model="input" :disabled="disabled" :placeholder="placeholder" @blur="$emit('blur')" @keydown="keydown" autocomplete="false"/>
		<template v-if="autocomplete && autocomplete.length">
			<div class="autocomplete" :class="{active: autocompleteActive}" >
				<div class="items" v-for="(item,key) in autocomplete" :key="key"
				@click="select(item)">
					{{ item.label }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: String, required: true },
		type: { type: String, default: "text" },
		placeholder: { type: String, default: "" },
		disabled: { type: Boolean, default: false },
		trim: { type: Boolean, default: false },
		autocomplete: {type: Array},
		activateAutocomplete: {type: Function},
		setAutocomplete: {type: Function}
	},
	data(){
		return {
			autocompleteActive: false
		}
	},
	methods: {
		focus(){
			this.$refs.input.focus();
		},
		keydown(e){
			this.autocompleteActive = (this.activateAutocomplete || this.activateAutocompleteDefault)(e, this.input);
		},
		select(item){
			this.input = (this.setAutocomplete || this.setAutocompleteDefault)(item);

			this.autocompleteActive = false;
		},
		activateAutocompleteDefault(e){
			return this.autocomplete && Boolean(e.key == "@");
		},
		setAutocompleteDefault(item){
			if(! this.autocomplete && this.autocomplete.length) return this.input;

			return this.input.replace('@', `{${item.key}}`);
		}
	},
	computed: {
		input: {
			get() {
				if(this.trim) return this.value.trim();

				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
