<template>
	<ul class="master-drawer">
		<li :class="{active: $route.name == 'master'}" @click="goTo('master')">
			<span data-icon="admin_panel_settings">מאסטר</span>
		</li>
		<li :class="{active: $route.name == 'master/dictionary'}" @click="goTo('master/dictionary')">
			<span data-icon="translate">מילון</span>
		</li>
	</ul>
</template>

<script>
export default {
	methods:{
		goTo(name){
			this.$router.push({ name }).catch(() => {});
		}
	}
}
</script>
