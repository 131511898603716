const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Product extends Model{

	constructor(){
		super();
	}

	model() {
		return {
			sku: "",
			title: "",
			slogen: "",
			description: "",
			price: "",
			price_before_discount: "",
			price_cost: "",
			video: "",
			categories: [],
			images: [],
			stocks: [{
				quantity: true
			}],
			deliveries: {
				type: "default"
			},
			parameters: [],
			page: {
				slug: "",
				seo: {
					title: "",
					description: "",
					keywords: ""
				},
				active: true
			},
			active: false
		}
	}

	query(store, query){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! query) return reject(new Error("Missing `query`"));

			let model = Cast(query, {
				query: {
					price: [Number],
					products: [String],
					quantity: [Number],
					categories: [String],
					properties: [{
						key: String, value: String
					}],
					active: Boolean
				},
				sort: {
					priority: {
						type: String,
						period: String,
						limit: Number
					},
					static:[String]
				},
				limit: Number,
				offset: Number
			});

			let key = store + JSON.stringify(model);
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.post(store+'/inventory/products', model)
			.then(response => {
				let result = { response };

				//result.data = response.data;

				result.data = Cast(response.data, [{
					_id: String,
					sku: String,
					title: String,
					price: String,
					price_before_discount: String,
					price_cost: String,
					video: String,
					images:[{
						source: String,
						title: String,
						attributes: [{key: String, value: String}],
					}],
					stocks: [{
						_id: String,
						price: String,
						attributes: [{key: String, value: String}],
						quantity: Cast.$custom((type) => {
							if(type == 'boolean') return Boolean;

							return String;
						})
					}],
					active: Boolean
				}]);

				resolve(result);

				this.$cache.set(key, result);
			})
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/product/'+id).then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					sku: String,
					title: String,
					slogen: String,
					description: String,
					price: String,
					price_before_discount: String,
					price_cost: String,
					video: String,
					categories:[String, '_id'],
					images:[{
						source: String,
						title: String,
						attributes: [{key: String, value: String}],
					}],
					stocks: [{
						sku: String,
						price: String,
						quantity: Cast.$custom((type) => {
							if(type == 'boolean') return Boolean;

							return String;
						})
					}],
					page: {
						slug: String,
						seo: {
							title: String,
							description: String,
							keywords: String
						},
						active: Boolean,
						layout: Cast.$custom((type) => {
							if (type == 'Array') return Array;

							return String;
						})
					},
					parameters: [{
						id: Number,
						__sort: Number,
						title: String,
						parameters: [{
							id: Number,
							__sort: Number,
							label: String,
							price: String
						}],
						multiselect: Boolean,
						closable: Boolean,
						required: Boolean
					}],
					deliveries:{
						type: String,
						list: [String]
					},
					replace: String,
					collect: String,
					defect: String,
					active: Boolean,
					createTime: Date
				});

				if(! result.data.page){
					result.data.page = {
						slug: result.data.title || '',
						active: true,
						seo: {
							title: '',
							description: '',
							keywords: ''
						}
					}
				}

				resolve(result);

				this.$cache.set(key, result);
			}).catch(error => reject(error));
		});
	}

	prePost(payload){
		let files = {};

		if(payload.images.length){
			payload.images.forEach((image, i) => {
				if(! image.file) return;

				files["images@@"+i] = image.file;
				image.source = "file@@images@@"+i;
			});
		}

		let data = Cast(payload, {
			_id: String,
			sku: String,
			title: String,
			slogen: String,
			description: String,
			price: Number,
			price_before_discount: Number,
			price_cost: Number,
			video: String,
			categories:[String],
			images:[{
				source: String,
				title: String,
				attributes: [{key: String, value: String}]
			}],
			stocks: [{
				sku: String,
				price: Number,
				quantity: Cast.$custom((type) => {
					if(type == 'boolean') return Boolean;

					return Number
				})
			}],
			page: {
				slug: String,
				seo: {
					title: String,
					description: String,
					keywords: String
				},
				active: Boolean,
				layout: Cast.$custom((type) => {
					if (type == 'Array') return Array;

					return String;
				})
			},
			parameters: [{
				id: String,
				__sort: Number,
				title: String,
				parameters: [{
					id: String,
					__sort: Number,
					label: String,
					price: Number
				}],
				multiselect: Boolean,
				closable: Boolean,
				required: Boolean
			}],
			deliveries: {
				type: String,
				list: [String]
			},
			replace: Number,
			collect: Number,
			defect: Number,
			active: Boolean
		});

		data.id = data._id;

		return {data, files};
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/product/create', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/product/'+id+'/update', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/product/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/product/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	file(store, file){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! file) return reject(new Error("Missing `file`"));

			this.$api.postWithFiles(store+'/inventory/product/upload', file, file)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	notify(store){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.get(store+'/inventory/products/notify')
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	graph(store){
		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/products/graph`).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

}

module.exports = new Product()
