<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'מיון', icon: 'receipt', groups: ['general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">
		<form-group style="z-index:1" name="general">
			<form-area>
				<div class="button" data-icon="pan_tool" @click="startRefernceMode()">בחר קומפוננטה למיון</div>
				<div class="refcomponent" v-if="input.reference">{{ input.reference.title }} {{ input.reference.id }}</div>
			</form-area>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "מיון",
	defaults: {
		reference: null,
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return{
			ref: 'products'
		}
	},
	methods: {
		setReference(ref){
			if(ref){
				if(this.$parent.component.id == ref.id || ref.component != 'products')
					return VueAlerts.flash({
						text: 'לא ניתן לבחור את הקומפוננטה שבחרת',
						closeable: false
					});;

				this.$set(this.input, 'reference', ref);
			}
			this.$parent.$parent.referenceMode = null;
			document.body.classList.remove('refMode')
		},
		startRefernceMode(){
			this.$parent.$parent.referenceMode = this.setReference.bind(this);
			document.body.classList.add('refMode')
		}
	},
	computed: {
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
