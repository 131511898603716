<template>
	<div class="index-card order" :class="{ loading, preview, hide: order.$filtered,
		error: order.status == 'canceled',
		warning: order.status == 'modified',
		success: order.status == 'delivered'
	}">
		<div class="customer" @click="$emit('click')" v-if="order.customer">
			<div class="name">{{ order.customer.first_name }} {{ order.customer.last_name }}</div>
			<div class="phone">{{ order.customer.phone }}</div>
		</div>
		<div class="details" :data-icon="icon" @click="$emit('click')">
			<div class="number">{{ order.number }}</div>
			<div class="time">{{ time }}</div>
		</div>
		<template v-if="!preview">
			<div class="delivery" :class="order.delivery.type" @click="$emit('click')" v-if="order.delivery">
				<div class="type">{{ order.delivery.title }}</div>
				<template v-if="order.address">
					<div class="address">{{order.address.street}} {{order.address.house}}, {{ order.address.city }}</div>
					<div class="address" v-if="order.address.apartment || order.address.floor">{{order.address.apartment ? `דירה ${order.address.apartment}` : ''}} {{order.address.floor ? `קומה ${order.address.floor}` : ''}}</div>
				</template>
				<div class="service" v-if="!order.modification && order.delivery && order.delivery.service">מקושר לחברת משלוחים <span v-if="order.status != 'ready' && order.status != 'processing' && order.status != 'approved'" :class="{track: deliveries && deliveries.delivery}">: {{deliveries && deliveries.delivery ? 'שודר' : 'לא שודר'}}</span></div>
				
			</div>
			<slot name="tags" v-if="$slots.tags" />
			<div class="tags" @click="$emit('click')" v-else>
				<div class="tag" v-for="(tag, i) in tags" :key="i" :class="tag.style" :data-icon="tag.icon">{{tag.content}}</div>
			</div>
			<slot name="actions" v-if="$slots.actions" />
			<div class="actions" v-else>
				<template v-if="order.status == 'canceled'">
					<div class="step" data-icon="cancel">הזמנה מבוטלת</div>
				</template>
				<template v-else-if="order.status == 'modified'">
					<div class="step" data-icon="done" :class="{active: order.modification.status == 'approved' || order.modification.status == 'processing'}" @click="update('approved')">אישור</div>
					<div class="step" v-if="selections.defect || selections.replace" data-icon="done_all" :class="{active: order.modification.status == 'ready'}" @click="update('ready')">הכנה</div>
					<div class="step" v-if="selections.defect || selections.replace" data-icon="local_shipping" :class="{active: order.modification.status == 'shipped', service: order.modification.collect && order.modification.collect.service}" @click="update('shipped')">נשלחה</div>
					<div class="step" data-icon="beenhere" :class="{active: order.modification.status == 'delivered'}" @click="update('delivered')">{{ (selections.defect || selections.replace ? 'נמסרה' : 'התקבלה')}}</div>
				</template>
				<template v-else-if="order.delivery.type == 'pickup'">
					<div class="step" data-icon="done" :class="{active: order.status == 'approved' || order.status == 'processing'}" @click="update('approved')">אישור</div>
					<div class="step" data-icon="done_all" :class="{active: order.status == 'ready'}" @click="update('ready')">מוכנה לאיסוף</div>
					<div class="step" data-icon="beenhere" :class="{active: order.status == 'delivered'}" @click="update('delivered')">נאספה</div>
				</template>
				<template v-else-if="order.delivery.type == 'ship'">
					<div class="step" data-icon="done" :class="{active: order.status == 'approved' || order.status == 'processing'}" @click="update('approved')">אישור</div>
					<div class="step" data-icon="done_all" :class="{active: order.status == 'ready'}" @click="update('ready')">הכנה</div>
					<div class="step" data-icon="local_shipping" :class="{active: order.status == 'shipped'}" @click="update('shipped')">נשלחה</div>
					<div class="step" data-icon="beenhere" :class="{active: order.status == 'delivered'}" @click="update('delivered')">נמסרה</div>
				</template>
			</div>
			<div class="summary">
				<div class="count" @click="preview = true">{{ cartSummaryText }}</div>
				<div class="price">{{ price }}</div>
			</div>
		</template>
		<template v-else>
			<div class="preview" key="preview">
				<template v-if="modifications.length">
					<div v-for="(item, key) in modifications" :key="key"
						class="group" :class="{
							error: (item.type == 'defect'),
							warning: (item.type == 'replace' || key == 'collect')
						}">

						<!-- <div class="title" data-icon="done" v-if="key == 'keep'">נשאר אצל הלקוח</div> -->
						<div class="title" data-icon="sync_problem" v-if="item.type == 'defect'">פגום</div>
						<div class="title" data-icon="sync" v-else-if="item.type == 'replace'">החלפה</div>
						<div class="title" data-icon="redo" v-else-if="item.type == 'collect'">החזרה</div>

						<div class="item" >
							<div class="image" :style="`background-image: url('${item.order.stock.product.images[0].source}')`"></div>
							<div class="title" :title="item.order.stock.product.title">{{ item.order.stock.product.title }}</div>
							<div class="attributes" v-if="item.order.stock.attributes">
								<span v-for="(attr) in item.order.stock.attributes" :key="attr.key">{{ attr.key }}: {{ attr.value }}</span>

								<template v-if="item.order.parameters && params" >
									<template v-for="(parameters, group) in item.order.parameters">
										<span v-if="params[group]" :key="group">{{ params[group] }}: {{ Object.fromEntries(item.product.parameters.map(g => [g.id, g]))[group].type == 'input' ? item.cart.parameters[group][0] : parameters.map(param => params[`${group}:${param}`]).join(', ') }}</span>
									</template>
								</template>
							</div>
							<div class="discount" :title="item.order.stock.discount.title" v-if="item.order.stock.discount">{{ item.order.stock.discount.title }}</div>
							<div class="price" :data-old-price="item.order.stock.unitPrice > item.order.stock.paidPrice ? item.order.stock.unitPrice : null">
								{{ item.order.stock.paidPrice }}
							</div>
						</div>
						<div class="item replacement" v-if="item.modification.stock">
							<div class="image" :style="`background-image: url('${item.modification.stock.product.images[0].source}')`"></div>
							<div class="title" :title="item.modification.stock.product.title">{{ item.modification.stock.product.title }}</div>
							<div class="attributes" v-if="item.modification.stock.attributes">
								<span v-for="(attr) in item.modification.stock.attributes" :key="attr.key">{{ attr.key }}: {{ attr.value }}</span>

								<template v-if="item.modification.parameters && params" >
									<template v-for="(parameters, group) in item.modification.parameters">
										<span v-if="params[group]" :key="group">{{ params[group] }}: {{ Object.fromEntries(item.product.parameters.map(g => [g.id, g]))[group].type == 'input' ? item.cart.parameters[group][0] : parameters.map(param => params[`${group}:${param}`]).join(', ') }}</span>
									</template>
								</template>
							</div>
							<div class="discount" :title="item.modification.stock.discount.title" v-if="item.modification.stock.discount">{{ item.modification.stock.discount.title }}</div>
							<div class="price" :data-old-price="item.modification.stock.unitPrice > item.modification.stock.paidPrice ? item.modification.stock.unitPrice : null">
								{{ item.modification.stock.paidPrice }}
							</div>
						</div>

					</div>
				</template>
				<template  v-else>
					<div class="item" v-for="item in items" :key="item.stock._id">
						<div class="image" :style="`background-image: url('${item.product.images[0].source}')`">
							<span v-if="item.stock.inCart > 1">x{{ item.stock.inCart }}</span>
						</div>
						<div class="title" :title="item.product.title">{{ item.product.title }}</div>
						<div class="attributes" v-if="item.stock.attributes || (item.cart.parameters && item.parameters)">
							<span v-for="(attr) in item.stock.attributes" :key="attr.key">{{ attr.key }}: {{ attr.value }}</span>

							<template v-if="item.cart.parameters && item.parameters" >
								<template v-for="(parameters, group) in item.cart.parameters">
									<span v-if="item.parameters[group]" :key="group">{{ item.parameters[group] }}: {{ Object.fromEntries(item.product.parameters.map(g => [g.id, g]))[group].type == 'input' ? item.cart.parameters[group][0] : parameters.map(param => item.parameters[`${group}:${param}`]).join(', ') }}</span>
								</template>
							</template>
						</div>

						<div class="discount" :title="item.stock.discount.title" v-if="item.stock.discount">{{ item.stock.discount.title }}</div>
						<div class="price" :data-old-price="item.stock.unitPrice > item.stock.paidPrice ? item.stock.unitPrice : null">
							{{ item.stock.paidPrice }}
						</div>
					</div>
				</template>
			</div>
			<div class="summary" key="preview-summary">
				<div class="count" @click="preview = false">הסתר תצוגה מקדימה</div>
				<div class="price">{{ price }}</div>
			</div>
		</template>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		order: { type: Object, required: true },
		payment: { type: Object, default: () => ({}) }
	},
	data(){
		return {
			loading: false,
			preview: false,
			updating: false,
			deliveries: null
		}
	},
	mounted(){
		this.deliveries = this.order.deliveryLog || null;
	},
	methods: {
		update(status){
			if(this.updating) return;

			this.updating = true;

			this.loading = true;

			this.$models.orders.order.update(this.$route.params.store, this.order._id, {status})
			.then((res) => {
				let order = res.data.order;

				if(order.modification){
					this.$set(this.order.modification, 'status', order.modification.status);
				}else if(order.status){
					this.$set(this.order, 'status', order.status);
				}

				this.deliveries = order.deliveryLog || null;

				if(res.data.deliveryAsk){
					VueAlerts.alert({
						icon: "done",
						title: "שידור לחברת משלוחים",
						text: `האם לשדר את המשלוח לחברת המשלוחים?`,
						buttons: ["שדר משלוח", "לא כעת"]
					}).then(action => {
						if(action == "שדר משלוח") {
							this.$models.orders.order.delivery(this.$route.params.store, this.order._id)
							.then((result) => {
								if(! this.order.deliveryLog) this.$set(this.order, 'deliveryLog', {});

								this.order.deliveryLog[Object.keys(result.data.deliveryLog)[0]] = Object.values(result.data.deliveryLog)[0];

								this.deliveries = this.order.deliveryLog;

								VueAlerts.flash({
									icon: 'done',
									text: 'עודכן בהצלחה'
								});
							})
							.catch(() => {
								VueAlerts.flash({
									text: 'אירע שגיאה! המשלוח לא שודר'
								});
							})
						}
					})
				} else {
					VueAlerts.flash({
						icon: 'done',
						text: 'עודכן בהצלחה'
					});
				}

				this.loading = false;
				this.updating = false;



			}).catch(() => {
				this.loading = false;
				this.updating = false;
				VueAlerts.flash({
					text: 'אירע שגיאה!'
				});
			});
		}
	},
	computed: {
		icon(){
			if(this.order.status == 'modified') return 'compare_arrows';
			if(this.order.status == 'canceled') return 'close';

			return 'shopping_cart';
		},
		time(){
			let m = moment(this.order.createdAt);

			return m.format("dddd, D בMMMM YYYY LTS");
		},
		params(){
			let parameters = {};

			this.order.products.forEach(product => {
				if(product.parameters){
					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' (₪'+ param.price +')' : ''}`);
					})
				}
			});

			return parameters;
		},
		cart(){
			let fullCart = this.order.cart;

			let keys = Object.keys(fullCart);

			if(keys.length && fullCart[keys[0]].constructor === Number){
				fullCart = Object.fromEntries(keys.map(stock => [stock, [{quantity: fullCart[stock]}]]));
			}

			return fullCart;
		},
		cartCount(){
			return Object.values(this.cart).reduce((count, cart) => count + cart.reduce((cnt, c) => cnt+c.quantity ,0), 0);
		},
		cartSummaryText(){
			let texts = ['סה״כ '];

			if(this.cartCount > 1) texts.push(`${this.cartCount} פריטים`);
			else if(this.cartCount == 1) texts.push('פריט אחד');

			if(this.selections.collect){
				texts.push(this.selections.collect == 1 ? ', החזרה אחת' : `, ${this.selections.collect} החזרות`)
			}
			if(this.selections.defect){
				texts.push(this.selections.defect == 1 ? ', פריט פגום אחד' : `, ${this.selections.defect} פרטים פגומים`)
			}
			if(this.selections.replace){
				texts.push(this.selections.replace == 1 ? ', החלפה אחת' : `, ${this.selections.replace} החלפות`)
			}

			return texts.join('');
		},
		price(){
			return this.order.summary.total + this.order.summary.credit
		},
		tags(){
			let tags = [], payment = null;

			if(this.order.unread > 0){
				tags.push({ style: 'error', icon: 'mark_chat_unread', content: `הודעה חדשה` });
			}

			if(this.order.digital){
				tags.push({ style: 'success', icon: 'mark_email_read', content: 'מוצר דיגיטלי' });
			}
			
			if(this.order.payment && this.order.payment.data && this.order.payment.data.data && this.order.payment.data.data.title){
				payment = this.payment[this.order.payment.data.data.title];
			} else {
				payment = this.payment[this.order.payment.service];
			}

			if(payment) tags.push({ style: 'success', icon: 'credit_card', content: `שולם באמצעות: ${payment}` });
			
			if(this.order.summary.credit && (!this.order.payment || this.order.payment.service != 'credit')){
				tags.push({ style: 'success', icon: 'credit_card', content: `שימוש בקרדיט ₪${this.order.summary.credit}` });
			}

			if(this.order.modification){
				let time = moment(this.order.modification.created_at);

				if(this.order.modification.stage.type == 'awaiting_payment'){
					tags.push({ style: 'error', icon: 'credit_card_off', content: `ממתין לתשלום הלקוח` });
				}else if(this.order.modification.stage.type == 'credit_refound' && this.order.modification.stage.request){
					tags.push({ style: 'error', icon: 'payments', content: `בקשה לזיכוי בכרטיס האשראי` });
				}

				tags.push({ style: 'warning', icon: 'compare_arrows', content: `שונתה ב${time.format("dddd, D בMMMM YYYY LTS")}` });

				if(this.deliveries && this.deliveries.modification){
					tags.push({ style: 'primary',
						icon: "local_shipping",
						content: `${(this.order.modification.collect.includeShipping ? 'החלפות והחזרות שודרו דרך' : 'החזרות שודרו דרך')} ${this.order.modification.collect.title}`
					});
				} else {
					tags.push({ style: 'primary',
						icon: (this.order.modification.collect.includeShipping ? 'cached' : 'redo'),
						content: `${(this.order.modification.collect.includeShipping ? 'החלפה והחזרה באמצעות' : 'החזרה באמצעות')} ${this.order.modification.collect.title}`
					});

					if(this.order.modification.delivery){
						tags.push({ style: 'primary', icon: 'undo',
							content: `${(this.order.modification.delivery.type == 'ship' ? 'שליחה באמצעות ' : 'איסוף מ')}${this.order.modification.delivery.title}`
						});
					}
				}
			}else if(this.order.cancelation && this.order.cancelation.stage.type == 'credit_refound' && this.order.cancelation.stage.request){
				tags.push({ style: 'error', icon: 'payments', content: `בקשה לזיכוי בכרטיס האשראי` });
			} else if(this.order.notes){
				tags.push({ style: 'light', icon: 'feedback', content: `הערת לקוח: ${this.order.notes}` })
			}

			return tags;
		},
		stocks(){
			let stocks = {};

			this.order.products.forEach(product => product.stocks.forEach(stock => {
				stocks[stock._id] = stock;
				stocks[stock._id].product = product
			}))

			if(this.order.modification && this.order.modification.products){
				this.order.modification.products.forEach(product => product.stocks.forEach(stock => {
					stocks[stock._id] = stock;
					stocks[stock._id].product = product
				}))
			}

			return stocks;
		},
		selections(){
			if(this.order && this.order.modification && this.order.modification.selections) return this.order.modification.selections;

			let options = {defect: 0, replace: 0, collect: 0};

			this.modifications.forEach(modification => (options[modification.type]++));

			return options;
		},
		modifications(){
			if(! (this.order && this.order.modification)) return [];

			let fullModificationRequest = this.order.modification.request;

			if(fullModificationRequest.constructor == Array){
				let modification = {};

				fullModificationRequest.forEach((request,key) => {
					if(! modification[request.stock]) {
						modification[request.stock] = [];

						let payload = {quantity: 1};

						if(request.type == 'replace'){
							payload.replace = {[key]: {
								stock: request.replacement
							}};
						}

						if(request.type == 'collect' || request.type == 'defect'){
							payload[request.type] = {[key]: {
								stock: request.stock,
								note: request.note || ''
							}}
						}

						return modification[request.stock].push(payload);
					}

					modification[request.stock].quantity = modification[request.stock].quantity+1;

					if(request.type == 'replace'){
						if(! modification[request.stock].replace) modification[request.stock].replace = {};

						modification[request.stock].replace[key] = {
							stock: request.replacement
						}
					}

					if(request.type == 'collect' || request.type == 'defect'){
						if(! modification[request.stock][request.type]) modification[request.stock][request.type] = {};

						modification[request.stock][request.type][key] = {
							stock: request.stock,
							note: request.note || ''
						}
					}


				});

				fullModificationRequest = modification;
			}

			return Object.keys(fullModificationRequest).reduce((result, stockId) => {
				fullModificationRequest[stockId].forEach(request => {

					if(request.collect) {
						Object.values(request.collect).forEach(collect => result.push({
							type: 'collect',
							order: {
								stock: this.stocks[stockId],
								parameters: request.parameters
							},
							modification: {
								note: collect.note
							}
						}))
					}

					if(request.replace){
						Object.values(request.replace).forEach(replace => result.push({
							type: 'replace',
							order: {
								stock: this.stocks[stockId],
								parameters: request.parameters
							},
							modification: {
								stock: this.stocks[replace.stock],
								parameters: replace.parameters
							}
						}))
					}

					if(request.defect) {
						Object.values(request.defect).forEach(defect => result.push({
							type: 'defect',
							order: {
								stock: this.stocks[stockId]
							},
							modification: {
								note: defect.note
							}
						}))
					}
				})

				return result;
			}, []);
		},
		items(){
			if(! (this.order && this.order.products.length)) return [];

			let items = [], parameters;

			let fullCart = this.order.cart;

			let keys = Object.keys(fullCart);

			if(keys.length && fullCart[keys[0]].constructor === Number){
				fullCart = Object.fromEntries(keys.map(stock => [stock, [{quantity: fullCart[stock]}]]));
			}

			this.order.products.forEach(product => {

				parameters = {};

				if(product.parameters){
					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' (₪'+ param.price +')' : ''}`);
					})
				}

				product.stocks.forEach(stock => {
					if(! fullCart[stock._id]) return;

					fullCart[stock._id].forEach(cart => {
						items.push({
							product,
							stock,
							parameters,
							cart
						})
					})
				})
			})

			return items;
		}
	}
}
</script>
