const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Branch extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			title: "",
			description: "",
			city: "",
			address: "",
			phone: "",
			image: "",
			active: true,
			allow_pickup: true
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/branches').then(response => {
				let result = { response };

				result.data = Cast(response.data, [{
					_id: String,
					title: String,
					description: String,
					city: String,
					address: String,
					phone: String,
					price: String,
					image: String,
					active: Boolean,
					allow_pickup: Boolean
				}]);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/branches/'+id)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					title: String,
					description: String,
					city: String,
					address: String,
					phone: String,
					price: String,
					image: String,
					active: Boolean,
					allow_pickup: Boolean
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let files = {};

		if(typeof payload.image != 'string'){
			files['image'] = payload.image;
		}

		let data = Cast(payload, {
			title: String,
			description: String,
			city: String,
			address: String,
			phone: String,
			price: String,
			image: String,
			active: Boolean,
			allow_pickup: Boolean
		})


		return { data, files }
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/branches/create', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/branches/'+id+'/update', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/branches/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/branches/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Branch()
