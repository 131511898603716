<template>
	<div class="layout forms order">

		<div class="back" @click="$router.push({ name: 'orders', params:{store: $route.params.store}})"></div>
		<div class="title">{{ $route.params.order ? `סיכום הזמנה` : `יצירת הזמנה` }}</div>
		<div class="tabs">
			<div data-icon="description" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטי הזמנה</div>
			<div data-icon="account_circle" :class="{active: state.tab == 'client'}"
				@click="$router.push({query: {tab: 'client'}})">פרטי לקוח</div>
			<div data-icon="local_shipping" :class="{active: state.tab == 'shipping'}"
				@click="$router.push({query: {tab: 'shipping'}})">פרטי משלוח</div>
			<div v-if="data && data.review" data-icon="reviews" :class="{active: state.tab == 'review'}"
				@click="$router.push({query: {tab: 'review'}})">חוות דעת</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">
				<div class="data fullorder" animated>
					<div class="options">
						<span data-icon="receipt_long">סיכום הזמנה</span>
					</div>
					<div class="content" v-if="data">
						<div class="table">
							<order-row-cart :order="JSON.parse(JSON.stringify(data))" />
						</div>
					</div>
				</div>
				<div class="data notes" animated>
					<div class="content">
						<form-textarea name="notes" label="הערות הלקוח" :disabled="true" v-model="model.notes" />
					</div>
				</div>

				<div class="data refound" animated v-if="refoundRequest || refounded">
					<div class="options">
						<span data-icon="money_off">{{ (refounded ? refounded.title : (refoundRequest.type == 'credit_refound' ? 'קיימת בקשה לקבלת החזר לכרטיס האשראי' : 'קיימת בקשה לקבלת החזר כספי'))}}</span>
					</div>
					<div class="content">
						<div class="box" v-if="refoundRequest && refoundRequest.type == 'credit_refound'">
							<div class="return">

								<div class="label"><span>נקודות שהופקדו בהזמנה זו</span>₪{{ refoundRequest.credit }}</div>
								<div class="label" v-if="data && data.clientCredit"><span>סה״כ ללקוח</span>₪{{ data.clientCredit }}</div>

								<div class="refound">
									<form-numbers name="credit" label="הפחתת נקודות" v-model="refound.credit" clear point/>
									<form-numbers name="amount" label="סה״כ זיכוי לכ.האשראי" v-model="refound.amount" clear point />
									<form-numbers name="last_numbers" label="4 ספרות אחרונות כ.האשראי" v-model="refound.last_numbers" clear point />
									<div class="button" data-icon="credit_card" @click="refoundPayment">בוצע זיכוי</div>
								</div>
							</div>
						</div>

						<div class="box" v-else-if="refoundRequest && refoundRequest.type == 'awating_refound'">
							<div class="return">
								<div class="title"></div>
								<div class="label"><span>{{ `${refoundRequest.request ? 'הלקוח ביקש לקבל זיכוי לכ.האשראי' : 'הלקוח ביקש לקבל זיכוי בנקודות'}` }}</span></div>
								<div class="label"><span>סה״כ החזר שמגיע ללקוח</span> ₪{{ refoundRequest.credit }}</div>
								<div class="label" v-if="data && data.clientCredit"><span>סה״כ נקודות ללקוח</span>₪{{ data.clientCredit }}</div>
								<div class="refound">
									<form-numbers v-if="! refoundRequest.request" name="credit" label="הוספת נקודות" v-model="refound.credit" clear point/>
									<form-numbers v-if="refoundRequest.request" name="amount" label="סה״כ זיכוי לכ.האשראי" v-model="refound.amount" clear point />
									<form-numbers v-if="refoundRequest.request" name="last_numbers" label="4 ספרות אחרונות כ.האשראי" v-model="refound.last_numbers" clear point />
									<div class="button" data-icon="credit_card" @click="refoundPayment">בוצע זיכוי</div>
								</div>
							</div>
						</div>

						<div class="box" v-else-if="refounded">
							<div class="return" >
								<div class="title">{{ refounded.title }}</div>
								<div class="label" v-if="refounded.amount"><span>זיכוי לכ.האשראי בסכום של </span>₪{{ refounded.amount }}</div>
								<div class="label" v-if="refounded.last_numbers"><span>4 ספרות אחרונות </span>{{ refounded.last_numbers }}</div>
								<div class="label" v-if="! refounded.amount && refounded.credit"><span>זוכה בנקודות </span>₪{{ refounded.credit }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data awaiting_payment" animated v-if="data && data.modification && data.modification.stage.type == 'awaiting_payment'" >
					<div class="options">
						<span data-icon="money_off">הלקוח צריך לשלם תוספת לשינוי</span>
					</div>
					<div class="content">
						<form-toggle name="paid" label="שולם טלפונית" v-model="paid" />
					</div>
				</div>

				<div class="data summary" animated >
					<div class="options">
						<span data-icon="receipt">תקציר הזמנה</span>
					</div>
					<div class="content" v-if="data">
						<order-row key="0" :order="JSON.parse(JSON.stringify(data))" :payment="Object.keys(services).length && data.payment && data.payment.service && services[data.payment.service] ? services[data.payment.service] : {}" @click="$router.push({ name: 'clients/view', params: { client: data.client }})" />
					</div>
				</div>

				<div class="data chat" animated>
					<div class="options">
						<span data-icon="chat">שירות לקוחות</span>
					</div>
					<div class="content">
						<support v-if="data" :order="data.uuid" :client="data.client" />
					</div>
				</div>
			</div>

			<div class="container client" key="client" :class="{active: (state.tab == 'client')}" v-if="state.tab == 'client'">
				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטי הלקוח</span>
					</div>
					<div class="content">
						<form-input name="first_name" label="שם פרטי" v-model="model.customer.first_name" />
						<form-input name="last_name" label="שם משפחה" v-model="model.customer.last_name" />
						<form-input name="phone" label="מס׳ טלפון" v-model="model.customer.phone" disabled/>
						<form-input name="email" label="אימייל" v-model="model.customer.email" />
					</div>
				</div>
			</div>

			<div class="container shipping" key="shipping" :class="{active: (state.tab == 'shipping')}" v-if="state.tab == 'shipping'">

				<div class="data shipping" animated>
					<div class="options">
						<span data-icon="local_shipping">כתובת להספקה/קבלה</span>
					</div>
					<div class="content">
						<form-input name="city" label="עיר" v-model="model.address.city" />
						<form-input name="street" label="רחוב" v-model="model.address.street" />
						<form-input name="house" label="מספר בית" v-model="model.address.house" />
						<form-input name="apartment" label="דירה" v-model="model.address.apartment" />
						<form-input name="floor" label="קומה" v-model="model.address.floor" />
						<form-input name="zip" label="מיקוד" v-model="model.address.zip" />
					</div>
				</div>

				<div class="data deliveries" animated>
					<div class="options">
						<span data-icon="local_shipping">הספקת ההזמנה</span>
					</div>
					<div class="content">
						<div class="actions disabled" v-if="deliveries">
							<div class="action" data-icon="local_shipping"
								v-for="(delivery, d) in deliveries.ship" v-if="deliveries.ship"
								:key="`${d}_ship`" :class="{active: model.delivery == delivery._id}"
								@click="model.delivery = delivery._id"  >
								<span class="title">{{ delivery.title }}</span>
								<div class="description">
									<div>מחיר: {{ delivery.price > 0 ? '₪' + delivery.price : 'חינם'}}</div>
									<div v-if="delivery.minutes">עד {{ delivery.minutes.to }} דקות</div>
									<div v-else-if="delivery.days && delivery.days.to">{{ expectedDate(delivery) }}</div>
								</div>
							</div>

							<div class="action" data-icon="location_on" v-if="deliveries.pickup"
								v-for="(delivery, d) in deliveries.pickup"
								:key="`${d}_pickup`" :class="{active: model.delivery == delivery._id}"
								@click="model.delivery = delivery._id">
								<span class="title">{{ delivery.title }}</span>
								<div class="description">
									<div>מחיר: {{ delivery.price > 0 ? '₪' + delivery.price : 'חינם'}}</div>
									<div v-if="delivery.address">כתובת לאיסוף: {{ delivery.address }} - {{ delivery.city }}</div>
									<div v-if="delivery.minutes">עד {{delivery.minutes.to}} דקות</div>
									<div v-else-if="delivery.days && delivery.days.to">{{ expectedDate(delivery) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data external_delivery" animated v-if="data && data.delivery.service && (data.status != 'ready' && data.status != 'processing' && data.status != 'approved')">
					<div class="options">
						<span data-icon="local_shipping">חברת משלוחים</span>
					</div>
					<div class="content">
						<div class="title">משלוח</div>
						<div class="actions">
							<div class="action active" data-icon="send" v-if="data.deliveryLog && data.deliveryLog.delivery" @click="getDeliveryStatus(data.deliveryLog.delivery.service, data.deliveryLog.delivery.id)">
								<div class="title">שודר לחברת משלוחים</div>
								<div class="description">
									<div>{{ data.delivery.title }}</div>
									<div>מס׳ משלוח: {{ data.deliveryLog.delivery.id }}</div>
									<div v-if="statusCodes">סטטוס: {{ statusCodes[data.deliveryLog.delivery.id].text }}</div>
									<div v-if="statusCodes && statusCodes[data.deliveryLog.delivery.id].time">זמן: {{ statusCodes[data.deliveryLog.delivery.id].time }}</div>
								</div>
							</div>
							<div class="action" data-icon="send" v-if="! (data.deliveryLog && data.deliveryLog.delivery)" @click="sendToDelivery">
								<div class="title">שדר לחברת משלוחים</div>
								<div class="description">
									<div>{{ data.delivery.title }}</div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data external_delivery_modification" animated v-if="data && data.modification && data.modification.collect && data.modification.collect.service && data.status == 'modified' && data.modification.status == 'shipped'">
					<div class="options">
						<span data-icon="local_shipping">חברת משלוחים</span>
					</div>
					<div class="content">
						<div class="title">החלפות והחזרות</div>
						<div class="actions">
							<div class="action active" data-icon="send" v-if="data.deliveryLog && data.deliveryLog.modification" @click="getDeliveryStatus(data.deliveryLog.modification.service, data.deliveryLog.modification.id)">
								<div class="title">שודר לחברת משלוחים</div>
								<div class="description">
									<div>{{ data.modification.collect.title }}</div>
									<div>מס׳ משלוח: {{ data.deliveryLog.modification.id }}</div>
									<div v-if="statusCodes">סטטוס: {{ statusCodes[data.deliveryLog.modification.id].text }}</div>
									<div v-if="statusCodes && statusCodes[data.deliveryLog.modification.id].time">זמן: {{ statusCodes[data.deliveryLog.delivery.id].time }}</div>
								</div>
							</div>
							<div class="action" data-icon="send" v-if="! (data.deliveryLog && data.deliveryLog.modification)" @click="sendToDelivery">
								<div class="title">שדר לחברת משלוחים</div>
								<div class="description">
									<div>{{ data.modification.collect.title }}</div>
									<div></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data collect" animated v-if="data && data.modification && data.modification.collect">
					<div class="options">
						<span data-icon="compare_arrows">החזרת מוצרים</span>
					</div>
					<div class="content" >
						<div class="actions disabled" v-if="deliveries && deliveries.collect">
							<div class="action" data-icon="compare_arrows"
								v-for="(delivery, d) in deliveries.collect"
								:key="`${d}_collect`" :class="{active: model.modification.collect == delivery._id}"
								@click="model.modification.collect = delivery._id" >
								<span class="title">{{ delivery.title }}</span>
								<div class="description">
									<div>מחיר: {{ delivery.price > 0 ? '₪' + delivery.price : 'חינם'}}</div>
									<div v-if="delivery.minutes">עד {{ delivery.minutes.to }} דקות</div>
									<div v-else-if="delivery.days && delivery.days.to">בין {{ delivery.days.from }} עד ל {{ delivery.days.to}} ימים</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data modification_delivery" animated v-if="data && data.modification && data.modification.delivery">
					<div class="options">
						<span data-icon="compare_arrows">הספקת החלפות</span>
					</div>
					<div class="content" >
						<div class="actions disabled" v-if="deliveries && deliveries.ship">
							<div class="action" data-icon="compare_arrows"
								v-for="(delivery, d) in deliveries.ship"
								:key="`${d}_ship_mod`" :class="{active: model.modification.delivery == delivery._id}"
								@click="model.modification.delivery = delivery._id" >
								<span class="title">{{ delivery.title }}</span>
								<div class="description">
									<div>מחיר: {{ delivery.price > 0 ? '₪' + delivery.price : 'חינם'}}</div>
									<div v-if="delivery.minutes">עד {{ delivery.minutes.to }} דקות</div>
									<div v-else-if="delivery.days && delivery.days.to">בין {{ delivery.days.from }} עד ל {{ delivery.days.to}} ימים</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="container review" key="review" :class="{active: (state.tab == 'review')}" v-if="state.tab == 'review' && data && data.review">
				<div class="data products" animated >
					<div class="content" v-if="data">
						<div class="reviews">
							<div class="review-box" v-for="(stock, s) in data.review.stocks" v-if="stocks[stock.id]" :key="s">
								<div class="image" :style="`background-image:url('${stocks[stock.id].image}')`"></div>
								<div class="title">{{ stocks[stock.id].title }}<span>{{ stocks[stock.id].params }}</span></div>
								<div class="section">
									<div class="stars">
										<div class="star" :class="{active: (stock.rate == n)}" v-for="(n,index) in 5" :key="index"></div>
									</div>
									<div class="description">{{ stock.description }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data review" animated >
					<div class="content" v-if="data">
						<div class="review-box web" >
							<div class="section">
								<div class="title">שירות לקוחות</div>
								<div class="stars">
									<div class="star" :class="{active: (data.review.support == n)}" v-for="(n,index) in 5" :key="index"></div>
								</div>
							</div>
							<div class="section">
								<div class="title">הספקה</div>
								<div class="stars">
									<div class="star" :class="{active: (data.review.delivery == n)}" v-for="(n,index) in 5" :key="index"></div>
								</div>
							</div>
							<div class="description">{{ data.review.description }}</div>
						</div>
						<div class="actions">
							<div class="action" data-icon="visibility" :class="{active: model.review.active}"
								@click.self="model.review.active = ! model.review.active"  >
								<span class="title">חוות דעת פעילה</span>
								<div class="description"></div>
								<div class="form">
									<form-toggle name="principal" v-model="model.review.principal" label="חוות דעת ראשית" v-if="model.review.active" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data response" animated >
					<div class="content" v-if="data">
						<div class="form-group">
							<form-input name="response" label="הגב לחוות הדעת" v-model="model.review.response" />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>
<script>
import moment from 'moment';

export default {
	components:{
		'order-row': require('@/components/order-row').default,
		'order-row-cart': require('@/components/order-row-cart').default
	},
	data(){
		return {
			state: {
				tab: ''
			},
			data: null,
			support: null,
			deliveriesData: [],
			model: {
				date: null,
				closed: false,
				customer: {
					first_name: '',
					last_name: '',
					phone: '',
					email: ''
				},
				address: {
					city: '',
					street: '',
					house: '',
					apartment: '',
					floor: '',
					zip: '',
				},
				notes: '',
				status: '',
				delivery: null,
				modification: {
					collect: null,
					delivery: null
				},
				tracking: {
					company: '',
					tracking: ''
				},
				review: null
			},
			refound: {
				credit: '',
				amount: '',
				last_numbers: ''
			},
			services: {},
			statusCodeList: this.$models.orders.order.statusCodeList()
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		Promise.all([
			this.getData(),this.getDeliveries(),this.getServices()
		]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details')
		});
	},
	methods:{
		setTab(tab){
			if(['details', 'client', 'shipping', 'review'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			if(! this.$route.params.order) return Promise.resolve();

			return this.$models.orders.order.get(this.$route.params.store, this.$route.params.order)
			.then(res => {
				this.data = res.data;

				this.data.$filtered = false;

				this.setup();

				this.$root.loading = false;

			}).catch(error => {
				if(error.data.code == 404){
					this.$router.push({ name: 'orders', params:{store: this.$route.params.order}});
				}

				if(error.data.errors){
					this.errors = response.data.errors;
				}
			});
		},
		setup(){
			Object.assign(this.model.customer, this.data.customer);
			Object.assign(this.model.address, this.data.address);

			this.model.closed = this.data.closed || false;
			this.model.date = this.orderDate(this.data.createdAt);

			if(this.data.status == 'modified'){
				if(this.data.tracking) Object.assign(this.model.tracking, this.data.modification.tracking);
				this.model.status = this.data.modification.status;
			} else {
				if(this.data.tracking) Object.assign(this.model.tracking, this.data.tracking);
				this.model.status = this.data.status;
			}

			if(this.data.delivery) this.model.delivery = this.data.delivery._id;
			if(this.data.modification && this.data.modification.collect) this.model.modification.collect = this.data.modification.collect._id;
			if(this.data.modification && this.data.modification.delivery) this.model.modification.delivery = this.data.modification.delivery._id;

			if(this.data.status == 'canceled' &&
				this.data.cancelation &&
				this.data.cancelation.stage.type == 'credit_refound' &&
				this.data.cancelation.credit.credit
			){
				this.refound.credit = ""+this.data.cancelation.credit.credit;
				this.refound.amount = ""+this.data.cancelation.credit.credit;
			}

			if(this.data.status == 'modified' &&
				this.data.modification &&
				this.data.modification.stage.type == 'credit_refound'  &&
				this.data.modification.stage.credit.credit
			){
				this.refound.credit = ""+this.data.modification.stage.credit.credit;
				this.refound.amount = ""+this.data.modification.stage.credit.credit;
			}

			if(this.data.payment && this.data.payment.data && this.data.payment.data.data && this.data.payment.data.data.CardNum){
				this.refound.last_numbers = ""+this.data.payment.data.data.CardNum.substr(-4);
			}

			if(this.data.review){
				this.$set(this.model, 'review', {
					response: this.data.review.response && this.data.review.response.text ? this.data.review.response.text : '',
					active: this.data.review.active || false,
					principal: this.data.review.principal || false
				})
			}

			this.model.notes = this.data.notes || '';
		},
		getDeliveries(){
			return this.$models.inventory.delivery.all(this.$route.params.store)
			.then((deliveries) => {
				this.deliveriesData = deliveries.data.filter(e => e.active);
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		chooseCollect(delivery){
			this.$set(this.model, 'collect', delivery._id);
		},
		save(){
			let data = {};
			Object.assign(data, this.model);

			this.$models.orders.order.update(this.$route.params.store, this.$route.params.order, data)
			.then((res) => {

				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$root.loading = false;

				if(! this.$route.params.order){
					this.$router.push({ name: 'orders', params:{store: this.$route.params.store}});
				} else {
					this.data = res.data;

					this.data.$filtered = false;

					this.setup();
				}

			}).catch(error => {
				if(error && error.response && error.response.data.errors && error.response.data.errors){
					this.errors = error.response.data.errors;
				}

				this.$root.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		orderDate(obj){
			let date = new Date(obj);

			return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + ((date.getMinutes()<10?'0':'') + date.getMinutes());
		},
		expectedDate(delivery){
			if(! (this.data && delivery.days && delivery.days.to && delivery.days.from)) return '';

			let fromDate = new Date(this.data.createdAt);
			let toDate = new Date(this.data.createdAt);

			fromDate.setDate(fromDate.getDate() + parseInt(delivery.days.from));
			toDate.setDate(toDate.getDate() + parseInt(delivery.days.to));

			return  'בין ' + ('0' + fromDate.getDate()).slice(-2) + '/' + ('0' + (fromDate.getMonth() + 1)).slice(-2)  + '/' + fromDate.getFullYear() + ' ל- ' + ('0' + toDate.getDate()).slice(-2) + '/' + ('0' + (toDate.getMonth() + 1)).slice(-2)  + '/' + toDate.getFullYear();
		},
		refoundPayment(){
			this.$models.orders.order.refound(this.$route.params.store, this.$route.params.order, this.refound)
			.then((res) => {
				Object.assign(this.data, res.data);
				this.setup();

				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$root.loading = false;

				if(! this.$route.params.order){
					this.$router.push({ name: 'orders', params:{store: this.$route.params.store}});
				}

			}).catch(error => {
				if(error && error.response && error.response.data.errors && error.response.data.errors){
					this.errors = error.response.data.errors;
				}

				this.$root.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});

		},
		sendToDelivery(){
			this.$models.orders.order.delivery(this.$route.params.store, this.data._id)
			.then((result) => {
				if(! this.data.deliveryLog) this.$set(this.data, 'deliveryLog', {});

				this.data.deliveryLog[Object.keys(result.data.deliveryLog)[0]] = Object.values(result.data.deliveryLog)[0];

				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			})
		},
		getDeliveryStatus(service, delivery){
			if(! (service && delivery)) return false;

			this.$models.orders.order.deliveryStatus(this.$route.params.store, this.data._id, service, delivery)
			.then((result) => {
				if(! this.data.deliveryStatus) this.data.deliveryStatus = {};

				this.data.deliveryStatus[Object.keys(result.data.status)[0]] = Object.values(result.data.status)[0]
			})
		},
		statusCode(code){
			switch(code.code){
				case "1": return 'משלוח נקלט במערכת'; break;
				case "2": return 'הועבר לשליח'; break;
				case "3": return 'סופק ללקוח'; break;
				case "4": return 'נאספה - עדיין לא הגיע ליעד'; break;
				case "5": return 'חבילה נמסרה ובתמורה נאסף מוצר להחזרה'; break;
				case "7": return 'שליח אישר שראה משימה חבילה'; break;
				case "8": return 'משלוח בוטל במערכת'; break;
				case "12": return 'משלוח בהמתנה'; break;
			}
		},
		getServices(){
			return this.$models.dashboard.service.index(this.$route.params.store, {type: 'payments'})
			.then(result => {
				let services = {};

				Object.values(result.data).forEach(service => {
					if(! services[service.service]) services[service.service] = {};

					services[service.service][service.data && service.data.title ? service.data.title: service.service] =  service.name;
				})

				this.services = services;
			})
		},
	},
	computed: {
		deliveries(){
			if(! this.deliveriesData.length) return [];

			let items = {};

			this.deliveriesData.forEach(delivery => {
				if(! items[delivery.type]) items[delivery.type] = [];

				items[delivery.type].push(delivery);
			});

			return items;
		},
		refoundRequest(){
			if(! this.data) return null;

			let type = this.data.status == 'canceled' ? 'cancelation' : 'modification';

			if(! (this.data.status == 'canceled' || this.data.status == 'modified')) return null;


			if(this.data[type] &&
				(
					this.data[type].stage.type == 'awating_refound' ||
					(this.data[type].stage.type == 'credit_refound' && this.data[type].stage.request)
				)
			){
				return {
					credit: this.data[type].stage.credit ? this.data[type].stage.credit.credit : null,
					request: this.data[type].stage.request,
					type: this.data[type].stage.type
				}
			}

			return null;
		},
		refounded(){
			if(! this.data) return null;

			let type = this.data.status == 'canceled' ? 'cancelation' : 'modification';

			if(! (this.data.status == 'canceled' || this.data.status == 'modified')) return null;
			if(! (this.data[type] && this.data[type].stage.type == 'payment_refounded')) return null;

			return {
				title: (type == 'cancelation' ? 'ההזמנה מבוטלת' : 'בוצע זיכוי ללקוח'),
				amount: this.data[type].stage.amount,
				last_numbers: this.data[type].stage.last_numbers,
				credit: this.data[type].stage.credit ? this.data[type].stage.credit.credit : null
			}
		},
		stocks(){
			let stocks = {};

			this.data.products.forEach(product => product.stocks.forEach(stock => {
				let params = stock.attributes && stock.attributes.length ? stock.attributes.map(attr => (`${attr.key}: ${attr.value}`)).join(',') : '';

				stocks[stock._id] = {
					title: product.title,
					image: product.images[0].source,
					params: params
				};
			}))

			return stocks;
		},
		paid: {
			get(){
				return Boolean(this.model.paid !== undefined);
			},
			set(value){
				if(! value) return this.$delete(this.model, 'paid');

				this.$set(this.model, 'paid', true);
			}
		},
		statusCodes(){
			if(! this.data.deliveryStatus) return null;

			let list = {};

			Object.keys(this.data.deliveryStatus).forEach(key => {
				list[key] = {
					time: moment(this.data.deliveryStatus[key].time).fromNow(),
					update: moment(this.data.deliveryStatus[key].updateTime).fromNow(),
					text: this.statusCodeList[this.data.deliveryStatus[key].code]
				}
			});

			return list;
		}
	}
}
</script>
