<template>
	<div class="support-drawer">
		<div class="header">
			<span>היי,</span>
			<span>איך אפשר לעזור לך היום?</span>
			<div class="report" @click="$parent.active = 'report-drawer'">הצילו! יש כאן</div>
		</div>

		<div class="messages" v-if="messages && !this.$root.loading">
			<div v-for="(message, i) in messages" :key="i" class="message" :class="{system: message.system}"
				:data-initials="message.initials" :data-date="message.time"
			>
				<span class="name">{{ message.name }}</span>
				<span class="text">{{ message.message }}</span>
			</div>
		</div>

		<div class="form">
			<input type="text" name="message" v-model="message" class="text"/>
			<i class="material-icons" v-if="false">add</i>
			<div class="submit" @click="submit">שלח</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	data(){
		return {
			support: null,
			state: 'support',
			message: '',
		}
	},
	mounted(){
		this.$ws(`${this.$route.params.store}/support/master`, this.appendMessage.bind(this));

		this.$root.loading = true;
		Promise.all([
			this.getSupport()
		]).then(() => this.$root.loading = false);
	},
	methods: {
		getSupport(){
			this.$models.dashboard.support.get(this.$route.params.store, this.state)
			.then((results) => {
				this.support = results.data;
			})
		},
		submit(){
			this.$models.dashboard.support.update(this.$route.params.store, this.state, this.message)
			.then((results) => {
				this.message = "";
			})
		},
		appendMessage(message){
			this.$root.loading = true;
			if(! this.support.messages) this.$set(this.support, 'messages', []);
			this.support.messages.push(message);
			this.$nextTick(() => this.$root.loading = false);
		}
	},
	computed: {
		messages(){
			if(! (this.support && this.support.messages && this.support.messages.length)) return [];

			let messages = JSON.parse(JSON.stringify(this.support.messages));


			return messages.map(message => {

				let userName = message.name.split(' ');
				let userInitials = `${userName && userName.length > 1 ? userName.shift().charAt(0) : ''}${userName && userName.length == 1 ? userName.pop().charAt(0) : ''}`;


				message.time = moment(message.time).fromNow();
				message.initials = userInitials.toUpperCase();

				return message;
			}).reverse();
		}
	}
}
</script>
