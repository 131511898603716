<template>
	<div class="layout campaign-form" :class="{ loading }">

		<div class="top fixed">
			<div class="title">
				<div class="back" @click="$router.back()"></div>
				<span>{{ $route.params.campaign ? 'עריכת' : 'יצירת' }} קמפיין</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form">
			<form-group name="details" title="פרטי הקמפיין" style="z-index: 2">
				<form-input name="title" label="שם" v-model="model.title" />
				<form-numbers name="budget" label="תקציב חודשי" v-model="model.budget" clear point />
				<form-area></form-area>
			</form-group>
			<form-group name="details" title="מדיה" style="z-index: 1">
				<form-select name="media" label="מדיה" v-model="model.media" :options="mediaList"/>
				<form-select name="type" label="סוג" v-model="model.type" :options="typeList"/>
				<form-input name="utm" label="UTM" v-model="model.utm" />
				<form-input name="unique" label="מזהה לקמפיין" v-model="model.unique" />
				<form-area class="codeButton" v-if="! model.unique">
					<div @click="createCode" class="button light" data-icon="add">צור מזהה</div>
				</form-area>
				<form-area></form-area>
			</form-group>
		</form-vue>
	</div>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	data(){
		return {
			loading: true,
			model: this.$models.marketing.campaign.model(),
			mediaList: [
				{ value: 'google', label: 'Google' },
				{ value: 'instagram', label: 'Instagram' },
				{ value: 'facebook', label: 'Facebook' },
				{ value: 'newsletter', label: 'Newsletter' },
				{ value: 'sms', label: 'SMS' },
				{ value: 'whatsapp', label: 'Whatsapp' },
			],
			typeList: [
				{value: 'remarketing', label: 'Remarketing'}
			]
		}
	},
	mounted(){
		if(this.$route.params.campaign){
			this.getData();
		}else{
			this.loading = false;
		}
	},
	methods:{
		getData(){
			this.$models.marketing.campaign.get(this.$route.params.store, this.$route.params.campaign)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		createCode(){
			this.model.unique = makeHash(8);
		},
		submit(){
			this.loading = true;

			let request = null;
			if(this.$route.params.campaign){
				request = this.$models.marketing.campaign.update(this.$route.params.store, this.$route.params.campaign, this.model)
			} else {
				request = this.$models.marketing.campaign.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.campaign){
					this.$router.push({ name: 'marketing', params:{store: this.$route.params.store}});
				}

			}).catch(error => {
				this.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	}
}
</script>
