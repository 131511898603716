<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'כותרת ראשית', icon: 'receipt', groups: ['general', 'global'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'text_settings'] }
		}">
		<form-group style="z-index:2" name="global">
			<form-toggle v-if="this.global" name="global" v-model="toggleGlobal" label="משוך כותרת מעמוד"></form-toggle>
		</form-group>
		<form-group style="z-index:1" name="general">
			<form-input name="title" label="כותרת" v-model="input.title" v-if="! toggleGlobal"/>
			<form-area></form-area>
		</form-group>

		<form-group style="z-index:2" name="text_settings">
			<form-select name="type" label="יישור כותרת" v-model="input.settings.type" :options="[
				{ value: 'h1', label: 'h1' },
				{ value: 'h2', label: 'h2' },
				{ value: 'h3', label: 'h3' },
				{ value: 'div', label: 'div' }
			]"/>
			<form-select name="align" label="יישור כותרת" v-model="input.settings.align" :options="[
				{ value: 'right', label: 'ימין' },
				{ value: 'center', label: 'מרכז' },
				{ value: 'left', label: 'שמאל' }
			]"/>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "כותרת ראשית",
	defaults: {
		title: "",
		settings: {
			desktop: true,
			mobile: true,
			align: "right",
			type: 'h1'
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	computed: {
		toggleGlobal: {
			get(){
				return (this.global && this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
