const Model = require('@/models/model');

class Order extends Model{

	constructor(){
		super();
	}

	statusCodeList(){
		return {
			1: "משלוח נקלט במערכת",
			2: "הועבר לשליח",
			3: "סופק ללקוח",
			4: "נאספה - עדיין לא הגיע ליעד",
			5: "חבילה נמסרה ובתמורה נאסף מוצר להחזרה",
			7: "שליח אישר שראה משימה חבילה",
			8: "משלוח בוטל במערכת",
			12: "משלוח בהמתנה",
		}
	}

	all(store, payload, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/orders?limit=${limit}&skip=${skip}`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	graph(store, payload){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/orders/graph`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	importants(store, payload, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/orders/importants?limit=${limit}&skip=${skip}`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	modifications(store, payload, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/orders/modifications?limit=${limit}&skip=${skip}`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	cancelations(store, payload, limit = '', skip = 0){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.post(`${store}/inventory/orders/cancelations?limit=${limit}&skip=${skip}`, payload).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get(store+'/inventory/orders/'+id)
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

			})
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));


			this.$api.post(store+'/inventory/orders/'+id+'/update', payload)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	delivery(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store + '/inventory/orders/' + id +'/delivery')
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	deliveryStatus(store, id, service, delivery) {
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));
			if (!id) return reject(new Error("Missing `id`"));
			if (!service) return reject(new Error("Missing `service`"));
			if (!delivery) return reject(new Error("Missing `delivery`"));

			this.$api.post(store + '/inventory/orders/' + id + '/deliveryStatus', {service, delivery})
				.then(response => resolve(response))
				.catch(error => reject(error))
		});
	}

	refound(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));


			this.$api.post(store+'/inventory/orders/'+id+'/refound', payload)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Order()
