const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Store extends Model{

	constructor(){
		super();
	}

	get(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));


			let key = [store, 'all'].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/settings')
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let files = {};

		if(typeof payload.logo != 'string'){
			files['logo'] = payload.logo;
		}

		let data = Cast(payload, {
			name: String,
			address: String,
			phone: String,
			email: String,
			logo: String,
			checkout_settings: {
				active: Boolean,
				min_price: String,
				max_price: String
			}
		})

		return { data, files }
	}

	update(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/settings', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	files(store, options){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));


			let key = store + JSON.stringify(options);
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.post('dashboard/store/'+store+'/files', options)
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	uploadFiles(store, files){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! files) return reject(new Error("Missing `file`"));

			this.$api.postWithFiles('dashboard/store/'+store+'/uploadFiles', [], files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	setDictionary(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/setDictionary', {id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	notifications(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.get('dashboard/store/'+store+'/notifications')
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Store()
