<template>
	<div class="layout tags" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>תגיות</span>
			</div>
			<div class="actions">
				<div class="button add dark" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-group style="z-index:1" name="lines" v-if="model.tags">
			<index :data="model.tags" sort-by="title">
				<template slot="index" slot-scope="item">
					<div class="table">
						<div class="info">{{ model.tags[item.$index].title }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableLine != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group :style="`z-index:${item.$index}`" :key="item.$index" class="item" v-if="editableLine == item.$index">
						<form-group style="z-index:3">
							<form-input name="title" label="כותרת" v-model="model.tags[item.$index].title" />
						</form-group>
						<form-group style="z-index:2">
							<form-select name="icon" label="מיקום בדף" v-model="model.tags[item.$index].position"
							:options="[
								{ value: 'head', label: 'head'},
								{ value: 'body', label: 'תחילת body'},
								{ value: 'script', label: 'סוף body'},
							]"/>
						</form-group>
						<form-group style="z-index:1">
							<form-textarea v-model="model.tags[item.$index].code" name="code" label="קוד" />
						</form-group>
					</form-group>
				</template>
			</index>
			<div class="buttons">

			</div>
		</form-group>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			editableLine: null,
			model: this.$models.layout.tags.model()
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		addDynamic(){
			this.model.tags.push({title: '', tag: '', position: 'head'});

			this.editableLine = this.model.tags.length - 1;

		},
		removeDynamic(index){
			if(this.editableLine == index) this.editableLine = null;

			this.model.tags.splice(index, 1);
		},
		toggleBox(key){
			if(key == this.editableLine) return this.editableLine = null;

			this.editableLine = key;
		},
		getData(){
			this.loading = true;

			this.$models.layout.tags.get(this.$route.params.store)
			.then((res) => {
				if(res.data) this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			this.$models.layout.tags.update(this.$route.params.store, this.model)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		}
	}
}
</script>
