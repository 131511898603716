const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Redirect extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			fromUrl: "",
			code: "",
			active: true,
			link: {
				ref: "",
				type: "",
				blank: ""
			}
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/redirects').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/redirects/'+id)
			.then(response => {
				let result = { response };

				result.data =  Cast(response.data, {
					fromUrl: String,
					link:{
						ref: String,
						type: String
					},
					code: String,
					active: Boolean
				})

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}


	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				fromUrl: String,
				link:{
					ref: String,
					type: String,
					blank: Boolean
				},
				code: Number,
				active: Boolean
			});

			this.$api.post('dashboard/store/'+store+'/redirects/create', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				fromUrl: String,
				link:{
					ref: String,
					type: String,
					blank: Boolean
				},
				code: Number,
				active: Boolean
			});

			this.$api.post('dashboard/store/'+store+'/redirects/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/redirects/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post('dashboard/store/'+store+'/redirects/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Redirect()
