const Model = require('@/models/model');

class Reports extends Model {

    constructor() {
        super();
    }

    all() {
        return new Promise((resolve, reject) => {
            this.$api.get(`master/reports`).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);

            }).catch(error => reject(error));
        });
    }

    get(store) {
        return new Promise((resolve, reject) => {
            if (!store) return reject(new Error("Missing `store`"));

            this.$api.get(`master/reports/${store}`).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);
            }).catch(error => reject(error));
        });
    }

    update(id, status) {
        return new Promise((resolve, reject) => {
            if (!id) return reject(new Error("Missing `id`"));
            if (!status) return reject(new Error("Missing `status`"));

            this.$api.post(`master/reports/${id}/update`, { status }).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);
            }).catch(error => reject(error));
        });
    }

}

module.exports = new Reports()
