const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Dictionary extends Model{

	constructor(){
		super();
	}

	model() {
		return {
			name: "",
			code: "",
			direction: "rtl",
			defaultVariation: "default",
			dictionary: {}
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			this.$api.get(`dashboard/store/${store}/dictionary`).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get(`dashboard/store/${store}/dictionary/${id}`).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	create(store, payload){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				name: String,
				code: String,
				direction: String,
				defaultVariation: String
			});

			this.$api.post(`dashboard/store/${store}/dictionary/create`, data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));
			if(! id) return reject(new Error("Missing `id`"));


			let data = Cast(payload, {
				name: String,
				code: String,
				direction: String,
				defaultVariation: String
			});

			delete data.dictionary;

			this.$api.post(`dashboard/store/${store}/dictionary/${id}/update`, data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	updateWord(store, id, payload){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));
			if(! id) return reject(new Error("Missing `id`"));


			let data, lang_codes = ['he_IL', 'en_US'], patternizer = {};

			patternizer.dictionary = Object.fromEntries(lang_codes.map(code => [code, {
					default: String,
					verb: String,
					male: String,
					female: String
			}]))

			data = Cast(payload, patternizer);

			this.$api.post(`dashboard/store/${store}/dictionary/${id}/updateWord`, data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});

	}

}

module.exports = new Dictionary()
