const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Home extends Model{

	constructor(){
		super();
	}

	prePost(payload){

		let data = Cast(payload, {
			_id: String,
			slug: String,
			seo: {
				title: String,
				description: String,
				keywords: String
			},
			layout: [{
				props: {
					padding: [Number]
				},
				containers: [{
					props: {
						padding: [Number]
					}
				}]
			}]
		});

		data.id = data._id;
		delete data._id;

		return data;
	}

	get(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = [store].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/pages/home')
			.then(response => {
				let result = { response };
				let data = Object.assign({
					slug: '/',
					seo: {
						title: '',
						description: '',
						keywords: ''
					},
					layout: []
				}, response.data);

				result.data = Cast(data, {
					_id: String,
					slug: String,
					seo: {
						title: String,
						description: String,
						keywords: String
					},
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	update(store, payload, files){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/pages/home', data, files)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					slug: String,
					seo: {
						title: String,
						description: String,
						keywords: String
					},
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}


}

module.exports = new Home()
