<template>
	<div class="layout store" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<router-link class="back" :to="{ name: 'master'}" />
				<span>{{$route.params.id ? 'עריכת' : 'הוספת'}}</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form">
			<form-group name="general" title="פרטי המשתמש" style="z-index: 2">
				<form-input name="name" label="שם מלאה" v-model="model.name" />
				<form-input name="phone" label="מס׳ טלפון" v-model="model.phone" />
				<form-input name="email" label="אימייל" v-model="model.email" />
				<form-area />
			</form-group>

			<form-group name="permissions" title="הרשאות" style="z-index: 1">
				<div class="params" v-if="model.stores.length">
					<index :data="model.stores">
						<div class="store" slot="index" slot-scope="item" :style="`z-index:${model.stores.length - item.$index}`">
							<form-area style="width: 40px;">
								<div class="button error" data-icon="delete" @click="removeStore(item.$index)" style="margin-top: 28px"></div>
							</form-area>

							<form-select name="store" label="אתר" v-model="model.stores[item.$index].store"
								:options="storesList"
							/>
							<form-select name="label" label="הרשאה" v-model="model.stores[item.$index].label"
								:options="[{label: 'בעלים', value: 'owner'}, {label: 'שירות לקוחות', value: 'support'}]"
							/>
							<form-area />
						</div>
					</index>
				</div>
				<div class="button" data-icon="add" @click="addStore">הוסף</div>
			</form-group>


		</form-vue>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			data: null,
			stores: [],
			model: {
				name: "",
				phone: "",
				email: "",
				level: "",
				active: true,
				stores: []
			}
		}
	},
	mounted(){

		this.getStores();
		if(this.$route.params.id){
			this.getData();
		} else {
			this.loading = false;
		}
	},
	methods:{
		getStores(){
			this.$models.master.stores.all().then((res) => {
				this.stores = res.data;
			});
		},
		getData(){
			this.$models.master.users.get(this.$route.params.id)
			.then((res) => {
				this.data = JSON.parse(JSON.stringify(res.data));

				Object.assign(this.model, this.data);

				this.loading = false;
			})
		},
		submit(){
			this.loading = true;

			this.$models.master.users.update(this.model).then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if(! this.$route.params.id) this.$router.push({ name: 'master'});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		addStore(){
			this.model.stores.push({store: '', label: 'support'});
		},
		removeStore(index){
			this.model.stores.splice(index, 1);
		}
	},
	computed: {
		storesList(){
			if(! this.stores.length) return [];

			let items = [];

			this.stores.forEach(store => {
				items.push({label: store.store, value: store._id});
			})

			return items;
		}
	}
}
</script>
