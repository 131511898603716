<template>
	<div class="layout design" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>עיצוב אתר</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue :tabs="{
			theme: { label: 'ערכת נושא', icon: 'receipt', groups: ['name', 'theme', 'logo', 'favicon'] },
			colors: { label: 'צבעים', icon: 'receipt', groups: ['colors'] }
		}" v-if="! loading">

			<form-group name="name">
				<form-input name="name" label="שם האתר" v-model="model.settings.sitename" />
			</form-group>

			<form-group name="theme">
				<form-toggle name="round" label="פינות עגולות" v-model="model.settings.round" />
				<form-toggle name="autocart" label="עגלת אחרי הוספה" v-model="autocart" />
				<form-area />
			</form-group>

			<form-group name="theme">
				<form-toggle name="whatsapp" label="כפתור ואטסאופ" v-model="whatsapp" />
				<form-input name="whatsapp-number" label="מספר טלפון" v-model="model.settings.whatsapp" v-if="whatsapp" />
				<form-area />
			</form-group>

			<form-group name="logo" style="z-index: 2">
				<form-file name="logo" label="לוגו" v-model="logo" />
				<form-area v-if="preview">
					<div class="card">
						<div class="image" :style="`background-image: url('${preview}')`"></div>
					</div>
				</form-area>
			</form-group>

			<form-group name="favicon" style="z-index: 2">
				<form-file name="favicon" label="פבאיקון (32*32)" v-model="favicon" />
				<form-area v-if="previewFavicon">
					<div class="card">
						<div class="image" :style="`background-image: url('${previewFavicon}')`"></div>
					</div>
				</form-area>
			</form-group>

			<form-group name="colors" >
				<form-colors v-if="model.colors" name="color" v-for="color in model.colors"
					:key="color.key" :label="color.label" v-model="color.value"></form-colors>
			</form-group>
		</form-vue>
	</div>
</template>
<script>
export default {
	data(){
		return {
			loading: true,
			files: [],
			model: this.$models.layout.web.model()
		}
	},
	mounted(){
		this.getData();
	},
	methods: {
		getData(){
			this.loading = true;
			this.$models.layout.web.get(this.$route.params.store, 'web')
			.then((res) => {
				if(res.data){
					Object.assign(this.model.colors, res.data.colors);
					Object.assign(this.model.settings, res.data.settings);
					this.model.name = res.data.name;
				}

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			this.$models.layout.web.update(this.$route.params.store, 'web', this.model)
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});
				this.loading = false;

			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		}
	},
	computed: {
		logo:{
			get(){
				return [this.model.settings.logo || ''];
			},
			set(value){
				this.$set(this.model.settings, 'logo', value[0])
			}
		},
		whatsapp: {
			get(){
				return this.model.settings.whatsapp !== undefined;
			},
			set(value){
				if(value) return this.$set(this.model.settings, 'whatsapp', '');

				this.$delete(this.model.settings, 'whatsapp');
			}
		},
		preview(){
			if(! this.model.settings.logo)
				return false;

			if(typeof this.model.settings.logo == 'string')
				return this.model.settings.logo;

			return URL.createObjectURL(this.model.settings.logo);
		},
		favicon:{
			get(){
				return [this.model.settings.favicon || ''];
			},
			set(value){
				this.$set(this.model.settings, 'favicon', value[0])
			}
		},
		previewFavicon(){
			if(! this.model.settings.favicon)
				return false;

			if(typeof this.model.settings.favicon == 'string')
				return this.model.settings.favicon;

			return URL.createObjectURL(this.model.settings.favicon);
		},
		autocart:{
			get(){
				return this.model.settings.autocart === undefined ? true : this.model.settings.autocart;
			},
			set(value){
				this.$set(this.model.settings, 'autocart', value)
			}
		},
	}
}
</script>
