<template>
	<div class="notifications-drawer">
		<div class="header">
			<span>היי,</span>
			<span>ריכזנו עבורך את העדכונים האחרונים</span>
		</div>

		<div class="notifications">
			<div class="notification" v-if="notifications" v-for="notification in notifications" :data-date="notification.timetext" :class="{new: notification.new}" @click="goTo(notification)">
				{{notification.message}}
			</div>
			<div class="empty" v-if="! all && !notifications.length">אין הודעות חדשות</div>
			<div class="all" @click="all = ! all">{{ ! all ? 'צפה בהיסטוריה' : 'הסתר היסטוריה'}}</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	data(){
		return {
			all: false
		}
	},
	beforeDestroy(){
		this.$models.dashboard.user.update({lastNotificationView: true})
		.then(() => {
			this.$root.$refs.notifications.lastView = new Date();
		})
	},
	mounted(){
		moment.locale('he');
	},
	methods: {
		goTo(notification){
			if(notification.type == 'order_support' || notification.type == 'review_order'){
				this.$router.push({name: 'orders/view', params: {store: this.$route.params.store, order: notification.order}}).catch(() => {});
			}

			if(notification.type == 'client_support'){
				this.$router.push({name: 'clients/view', params: {store: this.$route.params.store, client: notification.client}}).catch(() => {});
			}

			if(notification.type == 'new_order'){
				this.$router.push({name: 'orders', params: {store: this.$route.params.store}, query: {tab: 'simple', refresh: 1, dates: [moment(notification.time).format('YYYY-MM-DD'), moment(notification.time).format('YYYY-MM-DD')]}}).catch(() => {});
			}

			if(notification.type == 'cancel_order'){
				this.$router.push({name: 'orders', params: {store: this.$route.params.store}, query: {tab: 'canceled', refresh: 1, dates: [moment(notification.time).format('YYYY-MM-DD'), moment(notification.time).format('YYYY-MM-DD')]}
				}).catch(() => {});
			}

			if(notification.type == 'modification_order'){
				this.$router.push({name: 'orders', params: {store: this.$route.params.store}, query: {tab: 'modified', refresh: 1, dates: [moment(notification.time).format('YYYY-MM-DD'), moment(notification.time).format('YYYY-MM-DD')]}}).catch(() => {});
			}

			if(notification.type == 'master_support'){
				return this.$root.$refs.menuApp.toggle('support-drawer');
			}

			this.$root.$refs.menuApp.active = null;
		}
	},
	computed: {
		notifications(){
			let notifications = JSON.parse(JSON.stringify(this.$root.$refs.notifications.data));

			return notifications.map(notification => {
				notification.new = Boolean(moment(notification.time) > this.$root.$refs.notifications.lastView);
				notification.timetext = moment(notification.time).fromNow();

				if(! this.all && !notification.new) return null;

				return notification;
			}).filter(e => e != null).reverse();
		}
	}
}
</script>
