<template>
	<div class="user-drawer">
		<div class="user" v-if="user">
			<div class="initials">{{ initials }}</div>
			<div class="name">{{ user.name }}</div>
		</div>
		<div class="actions">
			<div class="action" data-icon="settings" @click="goTo('user/settings')">הגדרות אישיות</div>
			<div class="action" data-icon="support" @click="$parent.active = 'support-drawer'">תמיכה</div>
			<div class="action" data-icon="bug_report" @click="$parent.active = 'report-drawer'">דווח על תקלה</div>
			<div class="action" data-icon="logout" @click="$logout">התנתק</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			user: null
		}
	},
	mounted(){
		this.$index.getUser().then(user => {
			this.user = user.data;
		})
	},
	methods:{
		goTo(name){
			this.$parent.active = null;

			this.$router.push({ name }).catch(() => {});
		}
	},
	computed:{
		initials(){
			let initials = [];

			this.user.name.split(' ').forEach(w => initials.push(w[0].toUpperCase()));

			return `${initials[0]}${initials[1] || ''}`;
		}
	}
}
</script>
