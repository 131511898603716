<template>
	<div class="filter" :class="{ active, used }" tabindex="0" @focusout="active = false">
		<label @click="toggle">{{ label }}</label>
		<div class="options">
			<div v-for="option in options" @click="select(option)" :class="{ active: (selected === option.value)}">
				{{ option.label }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		action: { type: Function, required: true },
		label: { type: String, required: true },
		options: { type: Array, required: true },
		value: { type: String },
	},
	data(){
		return {
			active: false,
			selected: null
		}
	},
	mounted(){
		this.selected = this.value || this.options[0].value;
	},
	methods: {
		toggle(){
			this.active = !this.active;
		},
		select(option){
			this.input = option.value;

			this.action(this.selected = option.value);

			this.active = false;

			this.$emit('change', option.value);
		}
	},
	computed: {
		used(){
			return (this.selected !== null);
		},
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
