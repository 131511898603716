let Helpers = {
	makeHash(length = 32) {
		let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		let result = '';
		for ( var i = 0; i < length; i++ ) {
		  result += chars.charAt(Math.floor(Math.random() * chars.length));
		}

		return result;
	},
	deepClone(obj, hash = new WeakMap()) {
		if (Object(obj) !== obj || obj instanceof Function) return obj;

		if (hash.has(obj)) return hash.get(obj); // Cyclic reference

		try {
			var result = new obj.constructor();
		} catch(e) {
			result = Object.create(Object.getPrototypeOf(obj));
		}

		if (obj instanceof Map)
			Array.from(obj, ([key, val]) => result.set(Helpers.deepClone(key, hash),  Helpers.deepClone(val, hash)) );
		else if (obj instanceof Set)
			Array.from(obj, (key) => result.add(Helpers.deepClone(key, hash)) );

		hash.set(obj, result);

		return Object.assign(result, ...Object.keys(obj).map(key => ({ [key]: Helpers.deepClone(obj[key], hash) }) ));
	},
	hexToRgb(color){
		let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}
}


module.exports = Helpers;
