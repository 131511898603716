const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Defaults extends Model{

	constructor(){
		super();
	}

	prePost(payload){
		let data = Cast(payload, {
			_id: String,
			name: String,
			source: String,
			layout: [{
				props: {
					padding: [Number]
				},
				containers: [{
					props: {
						padding: [Number]
					}
				}]
			}]
		});

		data.id = data._id;
		delete data._id;

		return data;
	}

	get(store, name){

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! name) return reject(new Error("Missing `name`"));

			let key = [store, name].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/theme/'+name)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	update(store, payload, files){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/theme/'+data.name, data, files)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					layout: [{
						props: {
							padding: [String]
						},
						containers: [{
							props: {
								padding: [String]
							}
						}]
					}]
				});

				resolve(result)
			})
			.catch(error => reject(error))
		});
	}


}

module.exports = new Defaults()
