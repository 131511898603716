<template>
	<div class="layout forms delivery">

		<div class="back" @click="$router.push({ name: 'inventory', params:{store: $route.params.store}})"></div>
		<div class="title">{{ $route.params.delivery ? 'עריכת' : 'הוספת' }} {{ typeTitle }}</div>
		<div class="tabs">
			<div data-icon="local_shipping" :class="{active: state.tab == 'delivery'}"
				@click="$router.push({query: {tab: 'delivery'}})">פרטים כללים</div>

			<div data-icon="swap_horiz" :class="{active: state.tab == 'collect'}" v-if="model.type == 'collect'"
				@click="$router.push({query: {tab: 'collect'}})">החזרות והחלפות</div>

			<div data-icon="departure_board" :class="{active: state.tab == 'times'}"
				@click="$router.push({query: {tab: 'times'}})">זמני הספקה</div>

			<div data-icon="design_services" :class="{active: state.tab == 'services'}" v-if="model.type != 'pickup'"
				@click="$router.push({query: {tab: 'services'}})">שירותים</div>

			<div data-icon="payments" :class="{active: state.tab == 'prices'}" v-if="model.type == 'ship'"
				@click="$router.push({query: {tab: 'prices'}})">תמחור מתקדם</div>

			<div data-icon="location_on" :class="{active: state.tab == 'cities'}" v-if="model.type == 'ship'"
				@click="$router.push({query: {tab: 'cities'}})">רשימת יישובים</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container delivery" key="delivery" :class="{active: (state.tab == 'delivery')}" v-if="state.tab == 'delivery'">
				<div class="data details" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="web"
								:class="{active: model.active}" @click.self="model.active = !model.active">
								<span class="title">פעיל</span>
								<div class="description"></div>
								<div class="form">
									<form-input name="title" label="שם" v-model="model.title" />
									<form-numbers name="price" label="מחיר בסיס" v-model="model.price" v-if="model.price != undefined"/>
									<form-toggle name="active" label="פעיל" v-model="model.active" />
									<form-toggle name="hidden" label="ידני בלבד" v-model="model.hidden" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data pickup" animated v-if="model.type == 'pickup'">
					<div class="options">
						<span data-icon="swap_horiz">פרטי נקודת האיסוף</span>
					</div>
					<div class="content">
						<form-input name="city" label="עיר" v-model="model.city" />
						<form-input name="address" label="כתובת" v-model="model.address" />
						<form-input name="phone" label="טלפון" v-model="model.phone" />
					</div>
				</div>
			</div>

			<div class="container collect" key="collect" :class="{active: (state.tab == 'collect')}" v-if="state.tab == 'collect'">
				<div class="data collect" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="local_shipping"
								:class="{active: model.includeShipping}" @click.self="model.includeShipping = !model.includeShipping">
								<span class="title">מחיר כולל שליחה</span>
								<div class="description"></div>
							</div>
						</div>
						<div class="form-group" v-if="model.prices">
							<form-numbers name="price-collect" label="מחיר החזרה" v-model="model.prices.collect" />
							<form-numbers name="price-replace" label="מחיר החלפה" v-model="model.prices.replace" />
							<form-numbers name="price-defect" label="מחיר מוצר פגום" v-model="model.prices.defect"/>
						</div>
					</div>
				</div>
			</div>

			<div class="container times" key="times" :class="{active: (state.tab == 'times')}" v-if="state.tab == 'times'">
				<div class="data times" animated>
					<div class="content">
						<div class="actions">
							<div class="action active" data-icon="departure_board">
								<span class="title">זמני הספקה</span>
								<div class="description"></div>
							</div>
						</div>
						<div class="form-group">
							<form-select name="byDaysbyDays" label="הספקה לפי" v-model="byDays" :options="[{label: 'ימים', value: 'days'}, {label: 'דקות', value: 'minutes'}]"/>
							<template v-if="byDays == 'days'">
								<form-numbers name="days" label="מ (ימים)" v-model="model.days.from" clear point  />
								<form-numbers name="daysTo" label="עד (ימים)" v-model="model.days.to" clear point  />
							</template>
							<form-numbers name="minutesTo" v-else label="עד (בדקות)" v-model="model.minutes.to" clear point  />
						</div>
					</div>
				</div>
			</div>

			<div class="container prices" key="prices" :class="{active: (state.tab == 'prices')}" v-if="state.tab == 'prices' && model.type == 'ship'">
				<div class="data prices" v-if="! (model.specialPrices && model.specialPrices.length)" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="payments" @click="addSpecialPrice">
								<span class="title">תמחור מתקדם</span>
								<div class="description">הפעל תמחור מתקדם</div>
							</div>
						</div>
					</div>
				</div>
				<index class="dynamic-groups" :data="model.specialPrices" sort-by="id" v-else>
					<div slot="index" slot-scope="item" class="data dynamic-group" animated>
						<div class="content">
							<div slot="index" class="dynamic-group-row">
								<form-numbers class="grow" :name="`${item.$index}from`" label="בקנייה מעל" placeholder="" v-model="model.specialPrices[item.$index].from" />
								<form-numbers class="grow" :name="`${item.$index}price`" label="מחיר משלוח" placeholder="" v-model="model.specialPrices[item.$index].price" />
								<div class="button light" data-icon="delete" @click="model.specialPrices.splice(item.$index, 1)" ></div>
							</div>
						</div>
					</div>
					<div slot="footer" class="add-dynamic-group" animated @click="addSpecialPrice">הוסף תמחור</div>
				</index>
			</div>

			<div class="container cities" key="cities" :class="{active: (state.tab == 'cities')}" v-if="state.tab == 'cities' && model.type == 'ship'">
				<div class="data cities" v-if="! (model.citiesPrices && model.citiesPrices.length)" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="payments" @click="addCitiesPrices">
								<span class="title">תמחור לפי יישובים</span>
								<div class="description">הפעל תמחור לפי יישובים</div>
							</div>
						</div>
					</div>
				</div>
				<index class="dynamic-groups" :data="model.citiesPrices" sort-by="id" v-else>
					<div slot="index" slot-scope="item" class="data dynamic-group" animated>
						<div class="content">
							<div slot="index" class="dynamic-group-row">
								<form-input class="grow" :name="`${item.$index}title`" label="שם יישוב" placeholder="" v-model="model.citiesPrices[item.$index].title" />
								<form-numbers class="grow" :name="`${item.$index}price`" label="מחיר משלוח" placeholder="" v-model="model.citiesPrices[item.$index].price" />
								<div class="button light" data-icon="delete" @click="model.citiesPrices.splice(item.$index, 1)" ></div>
							</div>
						</div>
					</div>
					<div slot="footer" class="add-dynamic-group" animated @click="addCitiesPrices">הוסף תמחור</div>
				</index>
			</div>

			<div class="container services" key="services" :class="{active: (state.tab == 'services')}" v-if="state.tab == 'services'">
				<div class="data services" animated>
					<div class="options">
						<span data-icon="design_services">שיוך לחברת משלוחים</span>
					</div>
					<div class="content">
						<form-select name="service" label="" :filter="true" v-model="model.service"
							:options="servicesList"/>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			state: {
				tab: ''
			},
			temp: {
				prices: null,
				days: null
			},
			services: [],
			model: this.$models.inventory.delivery.model(this.$route.params.type)
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		if(this.$route.params.type) this.model.type = this.$route.params.type;

		Promise.all([
			this.getData(), this.getDeliveriesServices()
		]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'delivery')
		});
	},
	methods:{
		setTab(tab){
			if(['delivery', 'collect', 'pickup', 'prices', 'times', 'services', 'cities'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			if(! this.$route.params.delivery) return false;

			return this.$models.inventory.delivery.get(this.$route.params.store, this.$route.params.delivery)
			.then(res => {
				this.model = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getDeliveriesServices(){
			return this.$models.dashboard.service.index(this.$route.params.store, {type: 'deliveries'})
			.then(res => {
				this.services = res.data;
			}).catch(() => {});
		},
		save(){
			this.loading = true;

			if(! this.model.title){
				VueAlerts.flash({
					text: 'יש להזין שם',
					closeable: false
				});

				this.loading = false;
				return;
			}

			let request = null;

			if(this.$route.params.delivery){
				request = this.$models.inventory.delivery.update(this.$route.params.store, this.$route.params.delivery, this.model)
			} else {
				request = this.$models.inventory.delivery.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.delivery){
					this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

			}).catch(error => {
				this.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		addSpecialPrice(){
			if(! this.model.specialPrices) this.$set(this.model, 'specialPrices', []);

			this.model.specialPrices.push({from: '', price: ''});
		},
		addCitiesPrices(){
			if(! this.model.citiesPrices) this.$set(this.model, 'citiesPrices', []);

			this.model.citiesPrices.push({title: '', price: ''});
		}
	},
	computed:{
		byDays: {
			get(){
				return this.model.minutes ? 'minutes' : 'days';
			},
			set(value){
				if(value == 'minutes') {
					this.temp.days = this.model.days;
					this.$set(this.model, 'minutes', this.temp.minutes || {to: ''});
					return this.$delete(this.model, 'days');
				}

				this.temp.minutes = this.model.minutes;
				this.$delete(this.model, 'minutes');
				this.$set(this.model, 'days', this.temp.days || {from: '', to: ''});
			}
		},
		typeTitle(){
			switch(this.model.type){
				case "ship": return "משלוח";
				case "pickup": return "נקודת איסוף";
				case "collect": return "שיטת החזרה";
			}
		},
		servicesList(){
			let options = [{value: '', label: '-'}];
			if(! Object.values(this.services).length) return options;

			return options.concat(Object.values(this.services).map(service => ({value: service._id, label: service.name})));
		}
	}
}
</script>
<style lang="scss">
	.form{
		.form-group{
			&.prices{
				.group{
					border-spacing: 20px 0;

					.index{
						margin: 10px 0;
					}
				}
			}
		}
	}
</style>
