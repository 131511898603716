<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'כותרת ראשית', icon: 'receipt', groups: ['general', 'global', 'icon', 'link', 'fontColor', 'background'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">
		<form-group style="z-index:9" name="global">
			<form-toggle v-if="this.global" name="global" v-model="toggleGlobal" label="משוך כותרת מעמוד"></form-toggle>
		</form-group>
		<form-group style="z-index:8" name="general">
			<form-input name="title" label="כותרת" v-model="input.title" v-if="! toggleGlobal"/>
			<form-select name="align" label="יישור טקסט" v-model="input.align" :options="[
				{ value: 'right', label: 'ימין' },
				{ value: 'center', label: 'מרכז' },
				{ value: 'left', label: 'שמאל' }
			]"/>
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:7"  name="icon">
			<form-toggle name="ificon" label="הוסף אייקון" v-model="icon"/>
			<form-input name="icon" label="אייקון" v-if="icon" v-model="input.icon" />
		</form-group>
		<form-group style="z-index:6" name="link">
			<form-toggle name="iflink" label="הוסף קישור" v-model="link" />
			<link-vue v-model="input.link" v-if="link" wrapper="form-group" />
		</form-group>

		<form-group name="fontColor" style="z-index:5">
			<form-toggle name="background" label="צבע פונט" v-model="fontColor"/>
			<form-colors  v-if="fontColor"  name="color" label="צבע פונט" v-model="input.color" />
		</form-group>
		<form-group name="background" style="z-index:4">
			<form-toggle name="background" label="צבע רקע" v-model="background" />
		</form-group>
		<form-group  name="background" v-if="background" style="z-index:3">
			<form-colors name="color" label="צבע רקע" v-model="input.background.colors[0].color"></form-colors>

			<form-select name="opacity" label="שקיפות" v-model="input.background.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />

			<template v-if="input.background.type != 'full'">
				<form-colors name="color" label="צבע רקע" v-model="input.background.colors[1].color"></form-colors>

				<form-select  name="opacity" label="שקיפות" v-model="input.background.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
			</template>
		</form-group>
		<form-group  name="background"  v-if="background" style="z-index:2">
			<form-area class="layout-select">
				<div class="layout-card"
				@click="toggleBackgroundType('full')"
				:class="{active: input.background.type == 'full'}"
				style="width:140px;height:100px;">
					<i :style="`background-color:${input.background.colors[0].color};opacity:${input.background.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>

				<div class="layout-card"
				@click="toggleBackgroundType('top')"
				:class="{active: input.background.type == 'top'}"
				style="width:140px;height:100px;">
					<i :style="`background:${linearGradient('to top', input.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>

				<div class="layout-card"
				@click="toggleBackgroundType('rtl')"
				:class="{active: input.background.type == 'rtl'}"
				style="width:140px;height:100px;">
					<i :style="`background:${linearGradient('to right', input.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>
			</form-area>
		</form-group>

		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { hexToRgb } from '@/bootstrap/helpers';

export default {
	title: "כפתור",
	defaults: {
		title: "",
		align: "right",
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	data(){
		return {
			iconsList: this.$models.layout.web.icons()
		}
	},
	methods: {
		linearGradient(type, options){
			let color_a = hexToRgb(options[0].color),
				color_b = hexToRgb(options[1] ? options[1].color : '#ffffff');

			return `linear-gradient(${type}, rgba(${color_a.r}, ${color_a.g}, ${color_a.b}, ${options[0].opacity/100}),  rgba(${color_b.r}, ${color_b.g}, ${color_b.b}, ${options[1] ? options[1].opacity/100 : '0'}))`
		}
	},
	computed: {
		toggleGlobal: {
			get(){
				return (this.global && this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		icon: {
			get(){
				return this.input.icon != undefined;
			},
			set(value){
				if(value) return this.$set(this.input, 'icon', '');

				this.$delete(this.input, 'icon');
			}
		},
		link: {
			get(){
				return this.input.link != undefined;
			},
			set(value){
				if(value) return this.$set(this.input, 'link', {type: 'home'});

				this.$delete(this.input, 'link');
			}
		},
		fontColor: {
			get(){
				return this.input.color != undefined;
			},
			set(value){
				if(value) return this.$set(this.input, 'color', '#000000');

				this.$delete(this.input, 'color');
			}
		},
		background: {
			get(){
				return this.input.background != undefined;
			},
			set(value){
				if(value) return this.$set(this.input, 'background', {type: 'full', colors: [{color: '#ffffff', opacity: '100'}]});

				this.$delete(this.input, 'background');
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
