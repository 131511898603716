const Helpers = require('@/bootstrap/helpers');

class Cache {
	constructor(){
		this._cache = {};
	}

	set(key, value){
		this._cache[key] = { time: new Date(), value: Helpers.deepClone(value) };
	}

	get(key, time = null){
		if(! this._cache[key]) return null;

		return new Promise((resolve, reject) => {
			if(! this._cache[key].value) throw new Error("$cache Error: Cannot find cache value");

			let result = Object.assign({
				$cache: {time: this._cache[key].time}
			}, this._cache[key].value);

			if(result.data){
				result.data = Helpers.deepClone(this._cache[key].value.data);
			}

			resolve(result);
		});
	}

	clear(){
		this._cache = {};
	}

}


class Model {

	constructor(){
		this.__proto__.$cache = new Cache();
		this.__proto__.$api = window.Api;
	}

	$cast(object, pattern){
		let cast = {};

		Object.keys(object).forEach(key => {
			if(! pattern[key]) return;

			if(pattern[key].constructor == Object){
				return cast[key] = this.$cast(object[key], pattern[key])
			}

			if(pattern[key].constructor == Array){
				return cast[key] = this.$cast(object[key], pattern[key])
			}

			cast[key] = object[key];
		});

		return cast;

	}
}

module.exports = Model;
