<template>
	<div class="layout" :class="{loading}">

		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>{{ this.$route.params.id ? 'עריכת' : 'הוספת' }} פופאפ</span>
			</div>

			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form" >
			<form-group name="general" title="פרטים" style="z-index: 4">
				<form-input name="title" label="כותרת" v-model="model.title" />
				<form-numbers name="width" label="מקסימום רוחב" v-model="model.width" clear point />
				<form-numbers name="height" label="מקסימום גובה" v-model="model.height" clear point />
				<form-toggle name="auto" label="פתיחה אוטומתית" v-model="model.auto" />
				<form-toggle name="active" label="פעיל" v-model="model.active" />
				<form-area></form-area>
			</form-group>

			<form-group name="image" style="z-index: 3">
				<form-file name="image" label="תמונה" v-model="image" />
				<form-area v-if="preview">
					<div class="card">
						<div class="image" :style="`background-image: url('${preview}')`"></div>
					</div>
					<div class="button" data-icon="delete" @click="model.image = ''">מחק תמונה</div>
				</form-area>
			</form-group>

			<form-group name="content" title="תוכן" style="z-index: 2">
				<form-editor name="content" label="תוכן" v-model="model.content" />
			</form-group>

			<form-group name="button" title="כפתור" style="z-index: 1">
				<form-input name="title" label="כותרת" v-model="model.button.title" />
				<form-area>
					<link-vue class="form-link" wrapper="form-group" v-model="model.button.link" />
				</form-area>
				<form-area v-if="model.button.link.type">
					<div class="button" data-icon="delete" @click="resetLink" style="margin-top: 26px;">נקה כפתור</div>
				</form-area>
			</form-group>
		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			model: this.$models.dashboard.popup.model(),
		}
	},
	mounted(){
		if(this.$route.params.id){
			this.getData();
		} else {
			this.loading = false;
		}
	},
	methods:{
		getData(){
			 this.$models.dashboard.popup.get(this.$route.params.store, this.$route.params.id)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			let request = null;
			let create = false;

			if(this.$route.params.id){
				request = this.$models.dashboard.popup.update(this.$route.params.store, this.$route.params.id, this.model)
			}
			else{
				create = true;
				request = this.$models.dashboard.popup.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if((! this.$route.params.id) || create){
					return this.$router.push({ name: 'popups', params:{store: this.$route.params.store}});
				}

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});

		},
		resetLink(){
			this.$set(this.model.button, 'link', {ref: '', type: ''});
		}
	},
	computed:{
		image:{
			get(){
				return [this.model.image || ''];
			},
			set(value){
				this.$set(this.model, 'image', value[0])
			}
		},
		preview(){
			if(! this.model.image)
				return false;

			if(typeof this.model.image == 'string')
				return this.model.image;

			return URL.createObjectURL(this.model.image);
		}
	}
}
</script>
