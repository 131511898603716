<template>
	<div class="form-field file" :class="mode" :data-icon="icon" @drop.prevent="addFile" @dragover.prevent @dragenter="mode = 'drag'" @dragleave="mode = 'upload'">
		<input ref="input" :id="name" type="file" @change="onFileChange" :name="name" :accept="accept" :multiple="multiple" />
		<div class="title">{{ title }}</div>
		<div class="description">{{ description }}</div>
		<div class="actions">
			<!-- <label :for="name" class="action">בחירת {{ multiple ? 'קבצים' : 'קובץ' }}</label> -->
			<div class="action library-open" @click="mode = 'library'" v-if="library">בחירה מספריה</div>
			<slot name="action" />
		</div>
		<slot name="actions" />
		<div class="library" v-if="library">

		</div>
	</div>
</template>
<script>
export default {
	props: {
		name: {type: String, required: true},
		icon: {type: String, default: "add_photo_alternate"},
		title: {type: String, default: "הוספת מדיה"},
		description: {type: String, default: "אפשר לגרור קבצים לכאן"},
		value: {type: Array, default: []},
		accept: {type: String, default: 'image/*'},
		multiple: {type: Boolean, default: false},
		library: {type: Boolean, default: false}
	},
	data(){
		return {
			mode: 'upload',
			libraryFiles: []
		}
	},
	methods: {
		dragover(e){
			console.log(e);
		},
		addFile(e){
			let files = e.dataTransfer.files;

			if(!files) return;

			this.onFileChange({ target: {files} });
		},
		onFileChange(e) {
			this.mode = "upload";

			let files = Array.from(e.target.files || []).filter(file => new RegExp(this.accept).test(file.type));

			if(! files[0]) return;

			if(this.multiple){
				this.input = this.input.concat(files);

				this.$emit('change');
				return this.$refs.input.value = null;
			}

			this.input = [files[0]];
			this.$emit('change');
		}
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
