<template>
	<component :is="wrapper">
		<form-select name="linkType" label="קישור עבור" v-model="input.type" :options="[
			{ value: 'home', label: 'דף הבית' },
			{ value: 'category', label: 'קטגוריה' },
			{ value: 'product', label: 'מוצר' },
			{ value: 'blog', label: 'בלוג' },
			{ value: 'search', label: 'עמוד חיפוש' },
			{ value: 'page', label: 'סטטי' },
			{ value: 'popup', label: 'פופאפ' },
			{ value: 'external', label: 'קישור חיצוני' },
		]"/>
		<form-select name="categoryLink" label="בחר קטגוריה" v-model="input.ref" :options="categories" v-if="input.type == 'category'" :filter="true"/>
		<form-select name="productLink" label="בחר מוצר" v-model="input.ref" :options="products" v-if="input.type == 'product'" :filter="true" />
		<form-select name="blogLink" label="בחר בלוג" v-model="input.ref" :options="blogs" v-if="input.type == 'blog'" :filter="true" />
		<form-select name="searchLink" label="בחר עמוד חיפוש" v-model="input.ref" :options="searches" v-if="input.type == 'search'" :filter="true" />
		<form-select name="staticLink" label="בחר עמוד סטטי" v-model="input.ref" :options="statics" v-if="input.type == 'page'" :filter="true" />
		<form-select name="popupLink" label="בחר פופאפ" v-model="input.ref" :options="popupsList" v-if="input.type == 'popup'" :filter="true" />
		<form-input name="externalLink" label="קישור" v-model="input.ref" v-if="input.type == 'external'" />
	</component>

</template>

<script>
export default {
	props: {
		wrapper: { type: String, default: 'div' },
		value: { type: Object }
	},
	data(){
		return {
			pages: [],
			popups: []
		}
	},
	mounted(){
		this.$models.pages.page.index(this.$route.params.store)
			.then(res => {
				this.pages = res.data;
			})

		this.$models.dashboard.popup.all(this.$route.params.store)
			.then(res => {
				this.popups = res.data;
			})
	},
	watch: {
		'input.type'(newValue, oldValue){
			if(! this.input) return;

			if(this.input.type == "home") return this.$delete(this.input, 'ref');

			this.$set(this.input, 'ref', (this.input.ref || ""));
		}
	},
	computed: {
		categories(){
			return this.pages
				.filter(page => (page.ref && page.ref.collection == 'categories'))
				.map(page => ({label: page.seo.title , value: page._id}))
		},
		products(){
			return this.pages
				.filter(page => (page.ref && page.ref.collection == 'products'))
				.map(page => ({label: page.seo.title , value: page._id}))
		},
		blogs(){
			return this.pages
				.filter(page => (page.type && page.type == 'blog'))
				.map(page => ({label: page.seo.title , value: page._id}))
		},
		searches(){
			return this.pages
				.filter(page => (page.type && page.type == 'search'))
				.map(page => ({label: page.seo.title , value: page._id}))
		},
		statics(){
			return this.pages
				.filter(page => (page.type && page.type == 'page'))
				.map(page => ({label: page.seo.title , value: page._id}))
		},
		popupsList(){
			return this.popups
				.map(page => ({label: page.title , value: page._id}))
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
