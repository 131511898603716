<template>
	<div class="form-field toggle" :class="{ on: input }">
		<label>{{ label }}</label>
		<div @click="toggle"><i></i></div>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: Boolean, required: true },
	},
	methods:{
		toggle(){
			this.input = !this.input;
			this.$emit('toggle');
		}
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
