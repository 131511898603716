<template>
	<form-vue preventHash :tabs="{
		'lines': { label: 'פס נסגר', icon: 'receipt', groups: ['lines'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'interval'] }
		}" class="dynamic">


		<form-group style="z-index:1" name="lines">
			<index :data="input.lines" sort-by="title" @sort="sortElement">
				<template slot="index" slot-scope="item">
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end"></div>
						<div class="info">{{ input.lines[item.$index].title }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableLine != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" v-if="input.lines.length > 1" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group style="z-index:10" :key="item.$index" class="item" v-if="editableLine == item.$index">
						<form-group style="z-index:2">
							<form-input name="title" label="כותרת" v-model="input.lines[item.$index].title" />
						</form-group>
						<form-group style="z-index:1">
							<form-toggle name="link" label="הוסף קישור" v-model="links[item.$index]" @toggle="toggleLink(item.$index)" />
			 				<link-vue v-model="input.lines[item.$index].link" v-if="links[item.$index]" wrapper="form-group" />
						</form-group>
					</form-group>
				</template>
			</index>
			<div class="buttons">
				<div class="button add" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
			</div>
		</form-group>

		<form-group style="z-index:2" name="interval">
			<form-select name="interval" label="זמן החלפה" v-model="input.settings.interval" :options="[
				{ value: '1', label: 'מהיר' },
				{ value: '3', label: 'בינוני' },
				{ value: '6', label: 'איטי' },
			]"/>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="closable" v-model="input.settings.closable" label="ניתן לסגור"></form-toggle>
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>

	</form-vue>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	title: "פס נסגר",
	defaults: {
		lines: [{
			title: ''
		}],
		settings: {
			interval: '3',
			closable: true,
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return {
			editableLine: null
		}
	},
	methods: {
		addDynamic(){
			this.input.lines.push({title: ''});

			this.editableLine = this.input.lines.length - 1;

		},
		removeDynamic(index){
			if(this.editableLine == index) this.editableLine = null;

			this.input.lines.splice(index, 1);
		},
		toggleLink(key){
			if(! this.links[key]) return this.$delete(this.input.lines[key], 'link');

			this.$set(this.input.lines[key], 'link', {type: 'home'});
		},
		sortElement(sort){
			this.input.lines.sort((a, b) => a.__sort - b.__sort);
		},
		toggleBox(key){
			if(key == this.editableLine) return this.editableLine = null;

			this.editableLine = key;
		}
	},
	computed: {
		links(){
			return this.input.lines.map(item => item.link != undefined);
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
