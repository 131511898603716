import Vue from 'vue';
import VueRouter from 'vue-router';
import WebSocket from '@/bootstrap/WebSocket';

Vue.use(WebSocket);

Vue.prototype.$logout = () => {
	localStorage.removeItem('token');
	window.location = "/login";
}

require('@/bootstrap/axios');
require('@/bootstrap/$index')(Vue);
require('@/bootstrap/helpers');
require('@/components/alerts/VueAlerts');

Vue.prototype.$models = {
	master: {
		stores: require('@/models/master/stores'),
		users: require('@/models/master/users'),
		dictionary: require('@/models/master/dictionary'),
		location: require('@/models/master/location'),
		support: require('@/models/master/support'),
		reports: require('@/models/master/reports'),
	},
	inventory: {
		product: require('@/models/inventory/product'),
		category: require('@/models/inventory/category'),
		branch: require('@/models/inventory/branch'),
		bundle: require('@/models/inventory/bundle'),
		discount: require('@/models/inventory/discount'),
		coupon: require('@/models/inventory/coupon'),
		delivery: require('@/models/inventory/delivery'),
		stock: require('@/models/inventory/stock')
	},
	dashboard:{
		user: require('@/models/dashboard/user'),
		store: require('@/models/dashboard/store'),
		client: require('@/models/dashboard/client'),
		support: require('@/models/dashboard/support'),
		redirect: require('@/models/dashboard/redirect'),
		popup: require('@/models/dashboard/popup'),
		dictionary: require('@/models/dashboard/dictionary'),
		review: require('@/models/dashboard/review'),
		service: require('@/models/dashboard/service')
	},
	orders:{
		order: require('@/models/orders/order'),
		checkout: require('@/models/orders/checkout'),
		cart: require('@/models/orders/cart')
	},
	layout:{
		web: require('@/models/layout/web'),
		tags: require('@/models/layout/tags')
	},
	pages:{
		page: require('@/models/pages/page'),
		home: require('@/models/pages/home'),
		defaults: require('@/models/pages/defaults')
	},
	support: {
		contact: require('@/models/support/contact'),
		order: require('@/models/support/order'),
		newsletter: require('@/models/support/newsletter'),
	},
	marketing: {
		campaign: require('@/models/marketing/campaign'),
	},
	calendar: {
		rate: require('@/models/calendar/rate'),
	}
}


Vue.component('menu-app', require('@/components/menu-app').default);
Vue.component('menu-main', require('@/components/menu').default);
Vue.component('notifications', require('@/components/notifications').default);

Vue.component('loader', require('@/components/loader').default);

Vue.component('index', require('@/components/index/index').default);
Vue.component('index-row', require('@/components/index/row').default);
Vue.component('index-search', require('@/components/index/search').default);
Vue.component('index-filter', require('@/components/index/filter').default);
Vue.component('index-datepicker', require('@/components/index/datepicker').default);

Vue.component('form-vue', require('@/components/form/form').default);
Vue.component('form-group', require('@/components/form/group').default);
Vue.component('form-input', require('@/components/form/input').default);
Vue.component('form-editor', require('@/components/form/editor').default);
Vue.component('form-colors', require('@/components/form/colors').default);
Vue.component('form-toggle', require('@/components/form/toggle').default);
Vue.component('form-select', require('@/components/form/select').default);
Vue.component('form-numbers', require('@/components/form/numbers').default);
Vue.component('form-checkbox', require('@/components/form/checkbox').default);
Vue.component('form-datepicker', require('@/components/form/datepicker').default);
Vue.component('form-file', require('@/components/form/file').default);
Vue.component('form-area', require('@/components/form/area').default);
Vue.component('form-textarea', require('@/components/form/textarea').default);

Vue.component('link-vue', require('@/components/link-vue').default);
Vue.component('images-preview', require('@/components/images-preview').default);
Vue.component('images-library', require('@/components/images-library').default);

Vue.component('support', require('@/components/support').default);

Vue.component('query', require('@/components/query/query').default);
Vue.component('query-categories', require('@/components/query/categories').default);
Vue.component('query-properties', require('@/components/query/properties').default);
Vue.component('query-price', require('@/components/query/price').default);
Vue.component('query-quantity', require('@/components/query/quantity').default);
Vue.component('query-products', require('@/components/query/manual-products').default);
Vue.component('query-pages', require('@/components/query/manual-pages').default);

Vue.component('charts-pie', require('@/components/charts/pie').default);

Vue.component('fs', require('@/components/front-start/fs').default);

Vue.use(VueRouter);
const router = new VueRouter({
	routes: [
		{ name: 'index', path: '/', component: require('@/pages/dashboard/index').default },

		{ name: 'master', path: '/master/dashboard', component: require('@/pages/master/dashboard').default },
		{ name: 'master/dictionary', path: '/master/dictionary', component: require('@/pages/master/dictionary/index').default },
		{ name: 'master/dictionary/create', path: '/master/dictionary/create', component: require('@/pages/master/dictionary/edit').default },
		{ name: 'master/dictionary/edit', path: '/master/dictionary/:id', component: require('@/pages/master/dictionary/edit').default },

		{ name: 'master/stores/create', path: '/master/stores/create', component: require('@/pages/master/stores/create').default },
		{ name: 'master/stores/edit', path: '/master/stores/:id', component: require('@/pages/master/stores/edit').default },

		{ name: 'master/users/create', path: '/master/users/create', component: require('@/pages/master/users/edit').default },
		{ name: 'master/users/edit', path: '/master/users/:id', component: require('@/pages/master/users/edit').default },

		{ name: 'user/settings', path: '/user/settings', component: require('@/pages/user/settings').default },

		{ name: 'inventory', path: '/:store/inventory', component: require('@/pages/inventory/dashboard').default },

		{ name: 'inventory/products', path: '/:store/inventory/products', component: require('@/pages/inventory/products/productsIndex').default },
		{ name: 'inventory/products/create', path: '/:store/inventory/products/create', component: require('@/pages/inventory/products/productCreate').default },
		{ name: 'inventory/products/edit', path: '/:store/inventory/products/:product', component: require('@/pages/inventory/products/productEdit').default },

		{ name: 'inventory/stocks', path: '/:store/inventory/stocks', component: require('@/pages/inventory/products/stocksForm').default },

		{ name: 'inventory/categories', path: '/:store/inventory/categories', component: require('@/pages/inventory/categories/categoriesIndex').default },
		{ name: 'inventory/categories/create', path: '/:store/inventory/categories/create', component: require('@/pages/inventory/categories/categoryForm').default },
		{ name: 'inventory/categories/edit', path: '/:store/inventory/categories/:category', component: require('@/pages/inventory/categories/categoryForm').default },

		{ name: 'inventory/bundles/create', path: '/:store/inventory/bundles/create', component: require('@/pages/inventory/bundles/bundlesForm').default },
		{ name: 'inventory/bundles/edit', path: '/:store/inventory/bundles/:bundle', component: require('@/pages/inventory/bundles/bundlesForm').default },

		{ name: 'inventory/discounts', path: '/:store/inventory/discounts', component: require('@/pages/inventory/discounts/discountsIndex').default },
		{ name: 'inventory/discounts/quick', path: '/:store/inventory/discounts/quick', component: require('@/pages/inventory/discounts/quickDiscount').default },
		{ name: 'inventory/discounts/create', path: '/:store/inventory/discounts/create', component: require('@/pages/inventory/discounts/discountsForm').default },
		{ name: 'inventory/discounts/edit', path: '/:store/inventory/discounts/:discount', component: require('@/pages/inventory/discounts/discountsForm').default },

		{ name: 'inventory/coupons', path: '/:store/inventory/coupons', component: require('@/pages/inventory/coupons/couponIndex').default },
		{ name: 'inventory/coupons/create', path: '/:store/inventory/coupons/create', component: require('@/pages/inventory/coupons/couponForm').default },
		{ name: 'inventory/coupons/edit', path: '/:store/inventory/coupons/:coupon', component: require('@/pages/inventory/coupons/couponForm').default },

		{ name: 'inventory/branches', path: '/:store/inventory/branches', component: require('@/pages/inventory/branches/branchIndex').default },
		{ name: 'inventory/branches/create', path: '/:store/inventory/branches/create', component: require('@/pages/inventory/branches/branchForm').default },
		{ name: 'inventory/branches/edit', path: '/:store/inventory/branches/:branch', component: require('@/pages/inventory/branches/branchForm').default },

		{ name: 'inventory/deliveries', path: '/:store/inventory/deliveries', component: require('@/pages/inventory/deliveries/deliveryIndex').default },
		{ name: 'inventory/deliveries/settings', path: '/:store/inventory/deliveries/settings', component: require('@/pages/inventory/deliveries/settings').default },
		{ name: 'inventory/deliveries/create', path: '/:store/inventory/deliveries/create/:type', component: require('@/pages/inventory/deliveries/deliveryForm').default },
		{ name: 'inventory/deliveries/edit', path: '/:store/inventory/deliveries/:delivery', component: require('@/pages/inventory/deliveries/deliveryForm').default },

		{ name: 'clients', path: '/:store/clients', component: require('@/pages/clients/dashboard').default },
		{ name: 'clients/view', path: '/:store/clients/:client', component: require('@/pages/clients/client').default },

		{ name: 'clients/contact', path: '/:store/support/contact', component: require('@/pages/support/contactIndex').default },
		{ name: 'clients/contact/view', path: '/:store/support/contact/:id', component: require('@/pages/support/contactChat').default },

		{ name: 'clients/newsletter', path: '/:store/support/newsletter', component: require('@/pages/support/newsletterIndex').default },

		{ name: 'orders', path: '/:store', component: require('@/pages/orders/dashboard').default },
		{ name: 'orders/create', path: '/:store/orders/create', component: require('@/pages/orders/create').default },
		{ name: 'orders/view', path: '/:store/orders/:order', component: require('@/pages/orders/edit').default },

		{ name: 'app/design', path: '/:store/app/design', component: require('@/pages/app/design').default },

		{ name: 'web', path: '/:store/web', component: require('@/pages/web/dashboard').default },

		{ name: 'web/design', path: '/:store/web/design', component: require('@/pages/web/design').default },
		{ name: 'web/layouts', path: '/:store/web/layouts', component: require('@/pages/web/layoutsIndex').default },
		{ name: 'web/layouts/default', path: '/:store/web/layouts/:name', component: require('@/pages/web/layoutsForm').default },

		{ name: 'web/tags', path: '/:store/web/design/tags', component: require('@/pages/web/tags').default },

		{ name: 'web/pages', path: '/:store/web/pages', component: require('@/pages/pages/pagesIndex').default },
		{ name: 'web/pages/create', path: '/:store/web/pages/create/:type', component: require('@/pages/pages/pagesForm').default },
		{ name: 'web/pages/edit', path: '/:store/web/pages/edit/:id', component: require('@/pages/pages/pagesForm').default },

		{ name: 'web/pages/home', path: '/:store/web/pages/home/', component: require('@/pages/pages/pagesHome').default },

		{ name: 'web/redirects', path: '/:store/web/redirects', component: require('@/pages/redirects/redirectsIndex').default },
		{ name: 'web/redirects/create', path: '/:store/web/redirects/create', component: require('@/pages/redirects/redirectsForm').default },
		{ name: 'web/redirects/edit', path: '/:store/web/redirects/:id', component: require('@/pages/redirects/redirectsForm').default },

		{ name: 'web/popups', path: '/:store/web/popups', component: require('@/pages/popups/popupsIndex').default },
		{ name: 'web/popups/create', path: '/:store/web/popups/create', component: require('@/pages/popups/popupsForm').default },
		{ name: 'web/popups/edit', path: '/:store/web/popups/:id', component: require('@/pages/popups/popupsForm').default },

		{ name: 'web/dictionary', path: '/:store/web/dictionary', component: require('@/pages/dictionary/index').default },
		{ name: 'web/dictionary/create', path: '/:store/web/dictionary/create', component: require('@/pages/dictionary/edit').default },
		{ name: 'web/dictionary/edit', path: '/:store/web/dictionary/:id', component: require('@/pages/dictionary/edit').default },

		{ name: 'marketing', path: '/:store/marketing', component: require('@/pages/marketing/dashboard').default },
		{ name: 'marketing/campaigns/create', path: '/:store/marketing/campaigns/create', component: require('@/pages/marketing/campaigns/edit').default },
		{ name: 'marketing/campaigns/edit', path: '/:store/marketing/campaigns/:campaign', component: require('@/pages/marketing/campaigns/edit').default },


		{ name: 'calendar/rates/create', path: '/:store/calendar/rates/create', component: require('@/pages/calendar/rates/edit').default },
		{ name: 'calendar/rates/edit', path: '/:store/calendar/rates/:rate', component: require('@/pages/calendar/rates/edit').default },

		{ name: 'settings', path: '/:store/settings', component: require('@/pages/store/settings').default },
	],
	mode: 'history',
	transitionOnLoad: true
});

window.App = new Vue({
	data(){
		return {
			loading: false
		}
	},
	router,
	watch: {
		$route(){
			this.$index.setPermission(this.$route.params.store);
		}
	},
	created(){
		this.$index.setPermission(this.$route.params.store);
	}
}).$mount('#app');
