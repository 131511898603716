<template>
	<div class="layout deliveries" :class="{ loading }">
		<div class="top">
			<div class="title">ניהול אספקה</div>
			<div class="actions">
				<div class="button" data-icon="add" @click="goToCreate('inventory/deliveries/create', 'collect')">הוספת שיטת החזרה</div>
				<div class="button" data-icon="add" @click="goToCreate('inventory/deliveries/create', 'pickup')">הוספת נקודת איסוף</div>
				<div class="button" data-icon="add" @click="goToCreate('inventory/deliveries/create', 'ship')">הוספת שיטת משלוח</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['title'], value })" />
			<index-filter label="פעיל"
				:options="[
					{value: null, label: 'הכל'},
					{value: 1, label: 'פעיל בלבד'},
					{value: 0, label: 'לא פעיל'}
				]"
				:action="(value) => $refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value })"
			/>
			<index-filter label="סוגי משלוח"
				:options="[
					{value: null, label: 'הכל'},
					{value: 'ship', label: 'משלוח'},
					{value: 'pickup', label: 'נקודת איסוף'},
					{value: 'return', label: 'שיטת החזרה'}
				]"
				:action="(value) => $refs.index.filter({ id: 'type', method: 'eq', in: ['type'], value })"
			/>
		</div>

		<index ref="index" :data="items" v-if="items">
			<div slot="header" slot-scope="items" class="table header">
				<div>שם המשלוח</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item" :class="{ hide: item.$filtered }">
				<div class="title">
					<span>{{ item.title }}</span>
					<span v-if="item.type == 'ship'">משלוח</span>
					<span v-else-if="item.type == 'pickup'">נקודת איסוף</span>
					<span v-else-if="item.type == 'collect'">שיטת החזרה</span>
				</div>
				<div class="actions">
					<form-toggle name="active" label="" v-model="items[item.$index].active" @toggle="toggle(item)"/>
					<div class="button" data-icon="create" @click="goTo('inventory/deliveries/edit', item._id)">ערוך</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null
		}
	},
	mounted(){


		this.getData();
	},
	methods: {
		getData(){
			this.$models.inventory.delivery.all(this.$route.params.store)
			.then((res) => {
				this.items = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		goTo(name, delivery = null){
			if(delivery)
				return this.$router.push({ name, params: {store: this.$route.params.store, delivery} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		goToCreate(name, type){
			return this.$router.push({ name, params: {store: this.$route.params.store, type} }).catch(() => {});
		},
		toggle(delivery){
			this.$models.inventory.delivery[delivery.active ? 'remove' : 'restore'](this.$route.params.store, delivery._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	}
}
</script>
