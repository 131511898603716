<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'טקסט', icon: 'receipt', groups: ['global', 'general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}">
		<form-group style="z-index:2" name="global">
			<form-toggle v-if="this.global" name="global" v-model="toggleGlobal" label="משוך טקסט מעמוד"></form-toggle>
		</form-group>
		<form-group style="z-index:1" name="general">
			<form-editor name="text" label="כותרת" v-model="input.text" v-if="! toggleGlobal"/>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "טקסט",
	defaults: {
		text: "",
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	computed: {
		toggleGlobal: {
			get(){
				return (this.global && this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
