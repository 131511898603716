import { render, staticRenderFns } from "./categoriesIndex.vue?vue&type=template&id=771afa6f&"
import script from "./categoriesIndex.vue?vue&type=script&lang=js&"
export * from "./categoriesIndex.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports