const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Category extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			name: "",
			parent: "",
			description: "",
			images: [],
			active: true,
			page: {
				slug: "",
				seo: {
					title: "",
					description: "",
					keywords: ""
				},
				active: true
			}
		}
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/categories').then(response => {
				let result = { response };

				let pattern = [{
					_id: String,
					name: String,
					parent: String,
					description: String,
					images: Array,
					active: Boolean,
				}];

				pattern[0].sub = pattern;

				result.data = Cast(response.data, pattern);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	options(store){
		let mapCategories = (category) => {
			let option = {value: category._id, label: category.name};

			if(category.sub){
				option.sub = category.sub.map(subCategory => mapCategories(subCategory))
			}

			return option;
		}

		return this.all(store).then(({response, data}) => ({
			response,
			data: data.map(category => mapCategories(category))
		}));
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/category/'+id)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					name: String,
					parent: String,
					description: String,
					images:[{
						source: String,
						title: String
					}],
					active: Boolean,
					page: {
						slug: String,
						seo: {
							title: String,
							description: String,
							keywords: String
						},
						active: Boolean
					}
				});

				if(! result.data.parent){
					result.data.parent = ""
				}

				if(! result.data.page){
					result.data.page = {
						slug: result.data.title || '',
						active: true,
						seo: {
							title: '',
							description: '',
							keywords: ''
						}
					}
				}

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let files = {};

		if(payload.images.length){
			payload.images.forEach((image, i) => {
				if(! image.file) return;

				files["images@@"+i] = image.file;
				image.source = "file@@images@@"+i;
			});
		}

		let data = Cast(payload, {
			name: String,
			parent: String,
			description: String,
			images:[{
				source: String,
				title: String
			}],
			page: {
				slug: String,
				seo: {
					title: String,
					description: String,
					keywords: String
				},
				active: Boolean
			}
		})

		if(! data.parent.length){
			delete data.parent;
		}

		return { data, files }
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/category/create', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles(store+'/inventory/category/'+id+'/update', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/category/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/category/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}
}

module.exports = new Category()
