<template>
	<div class="layout products" :class="{ loading }">

		<div class="top">
			<div class="title">
				<div class="back" @click="$router.back()"></div>
				<span>עדכון מלאי</span>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['productName', 'sku'], value })" placeholder="חיפוש לפי שם מוצר או מק״ט" />
		</div>

		<index ref="index" :data="stocks" v-if="stocks.length">
			<div slot="header" slot-scope="items" class="table header">
				<div>פרטי מוצר</div>
				<div>כמות במלאי</div>
			</div>

			<div class="table" slot="index" slot-scope="item"
				:class="{ hide: item.$filtered }"
				>
				<div class="title">
					<div class="image" :style="`background-image: url('${item.image}')`" v-if="item.image"></div>
					<span>{{ item.productName }}</span>
					<div class="attributes">
						<template v-for="attr in item.attributes">
							<div class="color" v-if="attr.key == 'צבע'" :style="colors[attr.value] || ''"></div>
							<div v-else>{{ attr.key }}: {{ attr.value }}</div>
						</template>
					</div>
				</div>
				<div class="stock">
					<form-toggle name="quantity" label="כמות" v-model="stocks[item.$index].quantity" v-if="typeof stocks[item.$index].quantity === 'boolean'" @toggle="save(item.$index)"/>
					<form-input v-else name="quantity" label="כמות" v-model="stocks[item.$index].quantity" @blur="save(item.$index)"/>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null,
			colors: {},
			stocks: []
		}
	},
	mounted(){

		this.getData();

		this.$models.inventory.stock.getStockColors(this.$route.params.store)
		.then(result => {
			if(! result.data[0]) return;

			this.colors = Object.fromEntries(result.data[0].colors.map(color => {
				let style = {label: color.label, backgroundColor: color.color};

				if(color.image) style.backgroundImage = `url('${color.image}')`;

				return [color.value, style]
			}));
		})
	},
	methods: {
		getData(){
			this.$models.inventory.product.query(this.$route.params.store, {query:{active: false}})
			.then((res) => {
				this.items = res.data;

				this.items.forEach(product => {
					if(product.stocks){
						product.stocks.forEach(stock => {

							stock.productId = product._id;
							stock.productName = product.title;
							stock.sku = product.sku;
							stock.image = (product.images && product.images[0] ? product.images[0].source : '');
							stock.price = stock.price || product.price || "";

							this.stocks.push(stock);
						});
					}
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		goTo(name, product = null){
			if(product)
				return this.$router.push({ name, params: {store: this.$route.params.store, product} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		save(index){
			this.loading = true;

			this.$models.inventory.stock.updateStock(this.$route.params.store, this.stocks[index]._id, {quantity: this.stocks[index].quantity})
			.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		}
	}
}
</script>
