const Model = require('@/models/model');

class User extends Model{

	constructor(){
		super();
	}

	get(){
		let key = 'user';

		let cache = this.$cache.get(key);

		if(cache) return cache;

		return this.$api.get('dashboard/user/').then(user => {
			this.$cache.set(key, user);

			return user;
		});
	}

	stores(){
		let key = 'user_stores';

		let cache = this.$cache.get(key);

		if(cache) return cache;

		return this.$api.get('dashboard/user/stores');
	}

	update(payload){
		this.$cache.clear();

		return this.$api.post('dashboard/user', payload);
	}

	report(data, screenshot){
		return this.$api.postWithFiles('dashboard/user/report', data, {screenshot});
	}

}

module.exports = new User()
