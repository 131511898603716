<template>
	<div class="layout branches" :class="{ loading }">
		<div class="top">
			<div class="title">ניהול סניפים</div>
			<div class="actions">
				<div class="button" data-icon="add" @click="goTo('inventory/branches/create')">הוספת סניף</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['title'], value })" />
		</div>

		<index ref="index" :data="items" v-if="items">
			<div slot="index" slot-scope="item"
				class="card" :class="{ hide: item.$filtered }"
				@click="goTo('inventory/branches/edit', item._id)">
				<div class="image" :style="`background-image: url('${item.image}')`" v-if="item.image"></div>
				<div class="title">{{ item.title }}</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null
		}
	},
	mounted(){
		this.getData();
	},
	methods: {
		getData(){
			this.$models.inventory.branch.all(this.$route.params.store)
			.then((res) => {
				this.items = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		goTo(name, branch = null){
			if(branch)
				return this.$router.push({ name, params: {store: this.$route.params.store, branch} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		}
	}
}
</script>
