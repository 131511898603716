var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-vue',{attrs:{"preventHash":"","tabs":{
	'general': { label: 'כותרת ראשית', icon: 'receipt', groups: ['general', 'global'] },
	'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'text_settings'] }
	}}},[_c('form-group',{staticStyle:{"z-index":"2"},attrs:{"name":"global"}},[(this.global)?_c('form-toggle',{attrs:{"name":"global","label":"משוך כותרת מעמוד"},model:{value:(_vm.toggleGlobal),callback:function ($$v) {_vm.toggleGlobal=$$v},expression:"toggleGlobal"}}):_vm._e()],1),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"1"},attrs:{"name":"general"}},[(! _vm.toggleGlobal)?_c('form-input',{attrs:{"name":"title","label":"כותרת"},model:{value:(_vm.input.title),callback:function ($$v) {_vm.$set(_vm.input, "title", $$v)},expression:"input.title"}}):_vm._e(),_vm._v(" "),_c('form-area')],1),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"2"},attrs:{"name":"text_settings"}},[_c('form-select',{attrs:{"name":"type","label":"יישור כותרת","options":[
			{ value: 'h1', label: 'h1' },
			{ value: 'h2', label: 'h2' },
			{ value: 'h3', label: 'h3' },
			{ value: 'div', label: 'div' }
		]},model:{value:(_vm.input.settings.type),callback:function ($$v) {_vm.$set(_vm.input.settings, "type", $$v)},expression:"input.settings.type"}}),_vm._v(" "),_c('form-select',{attrs:{"name":"align","label":"יישור כותרת","options":[
			{ value: 'right', label: 'ימין' },
			{ value: 'center', label: 'מרכז' },
			{ value: 'left', label: 'שמאל' }
		]},model:{value:(_vm.input.settings.align),callback:function ($$v) {_vm.$set(_vm.input.settings, "align", $$v)},expression:"input.settings.align"}})],1),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"1"},attrs:{"name":"settings"}},[_c('form-toggle',{attrs:{"name":"desktop","label":"מוצג בדסקטופ"},model:{value:(_vm.input.settings.desktop),callback:function ($$v) {_vm.$set(_vm.input.settings, "desktop", $$v)},expression:"input.settings.desktop"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"mobile","label":"מוצג במובייל"},model:{value:(_vm.input.settings.mobile),callback:function ($$v) {_vm.$set(_vm.input.settings, "mobile", $$v)},expression:"input.settings.mobile"}}),_vm._v(" "),_c('form-area')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }