<template>
	<div class="filter-dates" :class="{ active }" tabindex="0" @focusout="active = false">
		<label @click="toggle">{{ label }}</label>
		<span>{{ format }}</span>
		<div class="datepicker">
			<datepicker v-model="input" v-bind="$attrs" :inline="true" :language="he" @selected="() => this.active = false"/>
		</div>
	</div>
</template>

<script>
import datepicker from 'vuejs-datepicker';
import { he } from 'vuejs-datepicker/dist/locale';

export default {
	components: { datepicker },
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: Date, required: true },
	},
	data(){
		return {
			he,
			active: false
		}
	},
	methods: {
		toggle(){
			this.active = !this.active;
		}
	},
	computed: {
		format() {
			return [
				(this.input.getDate() < 10 ? '0' : '') + this.input.getDate(),
				(this.input.getMonth() < 9 ? '0' : '') + (this.input.getMonth()+1),
				this.input.getFullYear()
			].join('/');
		},
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
