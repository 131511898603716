const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Location extends Model {

    constructor() {
        super();
    }

    query(payload) {
        return new Promise((resolve, reject) => {

            let data = Cast(payload, {
                search: String,
                cities: [Number],
                streets: [Number]
            });

            this.$api.post('master/locations/query', data).then(response => {
                let result = { response };

                result.data = response.data;

                resolve(result);

                this.$cache.set(key, result);

            }).catch(error => reject(error));
        });
    }

}

module.exports = new Location()
