<template>
	<div class="layout forms products">
		<div class="back" @click="$router.push({name: 'inventory'})"></div>
		<div class="title">עריכת מוצר</div>
		<div class="tabs">
			<div data-icon="description" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}}).catch(() => {})">פריט המוצר</div>

			<div data-icon="collections" :class="{active: state.tab == 'media'}"
				@click="$router.push({query: {tab: 'media'}}).catch(() => {})">מדיה</div>

			<div data-icon="stacked_bar_chart" :class="{active: state.tab == 'stocks'}"
				@click="$router.push({query: {tab: 'stocks'}}).catch(() => {})">ניהול מלאי</div>

			<div data-icon="local_shipping" :class="{active: state.tab == 'deliveries'}"
				@click="$router.push({query: {tab: 'deliveries'}}).catch(() => {})">אפשרויות הספקה</div>

			<div data-icon="checklist_rtl" :class="{active: state.tab == 'params'}"
				@click="$router.push({query: {tab: 'params'}}).catch(() => {})">פרמטרים</div>

			<!-- <div data-icon="extension" :class="{active: state.tab == 'related'}"
				@click="$router.push({query: {tab: 'related'}}).catch(() => {})">מוצרים נלווים</div> -->

			<div data-icon="gavel" :class="{active: state.tab == 'terms'}"
				@click="$router.push({query: {tab: 'terms'}}).catch(() => {})">תנאים והגבלות</div>

			<div data-icon="settings_suggest" :class="{active: state.tab == 'settings'}"
				@click="$router.push({query: {tab: 'settings'}}).catch(() => {})">הגדרות תצוגה</div>

			<div data-icon="settings_suggest" :class="{active: state.tab == 'layout'}"
				@click="$router.push({query: {tab: 'layout'}}).catch(() => {})">נראות ועיצוב</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="submit">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">

				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטי המוצר</span>
					</div>
					<div class="content">
						<form-input name="title" label="כותרת" v-model="model.title" />
						<form-input name="sku" label="מק״ט" v-model="model.sku" />
					</div>
				</div>

				<div class="data pricing" animated>
					<div class="options">
						<span data-icon="donut_small">תמחור</span>
					</div>
					<div class="content">
						<form-numbers name="price" label="מחיר בסיס" v-model="model.price" clear point />
						<!-- <form-numbers name="price_before_discount" label="מחיר לפני הנחה" v-model="model.price_before_discount" clear point /> -->
						<!-- <form-numbers name="price_cost" label="מחיר עלות" v-model="model.price_cost" clear point /> -->
					</div>
				</div>

				<div class="data product_status" animated>
					<div class="options">
						<span data-icon="description">סטטוס מוצר</span>
					</div>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="done"
								:class="{active: model.active && model.page.active}"
								@click="changeProductStatus('active')">
								<span class="title">הפוך לפעיל</span>
								<div class="description">המוצר פעיל ומוצג באתר, ניתן לרכישה והחלפה</div>
							</div>
							<div class="action" data-icon="pause"
								:class="{active: model.active && ! model.page.active}"
								@click="changeProductStatus('pause')">
								<span class="title">הפוך למושהה</span>
								<div class="description">המוצר פעיל אך לא מוצג באתר, ניתן לבצע החלפות אך לא רכישות</div>
							</div>
							<div class="action" data-icon="archive"
								:class="{active: ! model.active}"
								@click="changeProductStatus('archive')">
								<span class="title">העבר לארכיון</span>
								<div class="description">המוצר לא פעיל, לא ניתן לצפות, לרכוש ולבצע החלפות</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data description" animated>
					<div class="options">
						<span data-icon="format_align_right">תיאור</span>
					</div>
					<div class="content">
						<form-input name="slogen" label="תיאור קצר" v-model="model.slogen" />
						<form-editor name="description" label="תיאור מלא" v-model="model.description" />
					</div>
				</div>

				<div class="data category" animated>
					<div class="options">
						<span data-icon="category">קטגוריות</span>
					</div>
					<div class="content">
						<form-checkbox name="categories" label="בחר קטגוריות" v-model="model.categories" :options="categories"/>
					</div>
				</div>

			</div>
			<div class="container media" key="media" :class="{active: (state.tab == 'media')}" v-if="state.tab == 'media'">
				<div class="data">
					<div class="options">
						<span data-icon="play_circle_outline">וידאו</span>
					</div>
					<div class="content" animated>
						<form-input name="video" label="סרטון Vimeo" v-model="model.video" />
					</div>
				</div>
				<index class="data uploader" :data="images" sort-by="source" @sort="setImagesSort">
					<template slot="header">
						<div animated>
							<form-file name="images" v-model="images" accept="image/*" title="הוספת תמונות" description="אפשר לגרור תמונות לכאן" multiple />
							<!-- <form-file name="video" v-model="video" accept="video/*" library
								icon="play_circle_outline" title="הוספת וידאו" description="אפשר לגרור וידאו לכאן">

								<div class="actions" slot="actions">
									<div class="action">הוספה דרך YouTube</div>
									<div class="action">הוספה דרך Vimeo</div>
								</div>
							</form-file> -->
						</div>
					</template>

					<div slot="index" slot-scope="image" :key="image.$index" :style="`z-index: ${images.length - image.$index}`" animated>
						<div class="image-card" :draggable="image.$sort.active" @dragover="image.$sort.move" @dragend="image.$sort.end">
							<div class="image" :style="`background-image: url('${image.source}')`" @mousedown="image.$sort.activate" @mouseup="image.$sort.end"></div>
							<form-input name="title" label="" v-model="images[image.$index].title" placeholder="כותרת"/>
							<form-select name="color" label="צבע" :filter="true" v-model="images[image.$index].attributes[0].value" :options="colors" placeholder="שיוך לצבע"/>
							<div class="delete" @click="model.images.splice(image.$index, 1)">הסר תמונה</div>
						</div>
					</div>
				</index>
			</div>
			<div class="container stocks" key="stocks" :class="{active: (state.tab == 'stocks')}" v-if="state.tab == 'stocks'">
				<div class="data stocks_type" animated>
					<div class="options">
						<span data-icon="description">אופן מלאי</span>
					</div>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="grain"
								:class="{active: stockType == 'free'}" @click="stockType = 'free'">
								<span class="title">מלאי חופשי</span>
								<div class="description">אין הגבלה על כמות הרכישות של מוצר זה</div>
							</div>
							<div class="action" data-icon="filter_center_focus"
								:class="{active: stockType == 'single'}" @click="stockType = 'single'">
								<span class="title">מלאי כמותי</span>
								<div class="description">יש כמות ספציפית במלאי למוצר זה</div>
								<form-numbers label="סה״כ כמות במלאי" name="quantity" v-model="model.stocks[0].quantity" v-if="stockType == 'single'" />
							</div>
							<div class="action" data-icon="scatter_plot"
								:class="{active: stockType == 'extend'}" @click="stockType = 'extend'">
								<span class="title">מלאי מורכב</span>
								<div class="description">מוצר זה מתחלק למספר וריאציות עם כמויות מלאי שונות</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data" v-if="stockType == 'extend'" animated>
					<div class="extend">
						<extend-stock ref="stock" :sku="model.sku" :price="model.price" v-model="model.stocks" @update-colors="getColors">
							<template slot="templates">
								<stock-templates ref="templates" :stocks="model.stocks" @setStockTemplate="(template) => $refs.stock.setTemplate(template.attributes)" />
								<div class="buttons">
									<div class="button dark" data-icon="publish" @click="() => $refs.templates.load()">טען תבנית</div>
									<div class="button success" data-icon="save" @click="() => $refs.templates.save()">שמירת תבנית</div>
								</div>
							</template>
						</extend-stock>
					</div>
				</div>
			</div>
			<div class="container deliveries" key="deliveries" :class="{active: (state.tab == 'deliveries')}" v-if="state.tab == 'deliveries'">
				<div class="data deliveries_type" animated>
					<div class="options">
						<span data-icon="local_shipping">אפשרויות הספקה</span>
					</div>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="public"
								:class="{active: deliveriesType.type == 'default'}" @click="deliveriesType = 'default'">
								<span class="title">ברירת מחדל</span>
								<div class="description">אפשרויות משלוח דיפולטיביות של החנות</div>
							</div>
							<div class="action" data-icon="mark_email_read"
								:class="{active: deliveriesType.type == 'digital'}" @click="deliveriesType = 'digital'">
								<span class="title">הספקה דיגיטלית</span>
								<div class="description">מוצר דיגיטלי</div>
							</div>
							<div class="action" data-icon="dashboard_customize"
								:class="{active: deliveriesType.type == 'custom'}" @click="deliveriesType = 'custom'">
								<span class="title">בחירה ידנית</span>
								<div class="description">ניתן להגדיר ידנית את שיטות הספקה למוצר</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data deliveries" v-if="deliveriesType.type == 'custom'" animated>
					<div class="options">
						<span data-icon="dashboard_customize">שיטות משלוח</span>
					</div>
					<div class="content">
						<div class="actions">
							<div class="action" v-for="delivery in deliveries" :key="delivery._id"
								data-icon="local_shipping"
								:class="{active: deliveriesType.list.includes(delivery._id)}"
								@click="toggleDeliveries(delivery._id)">
								<span class="title">{{delivery.title}}</span>
								<div class="description">{{delivery.type == 'ship' ? 'משלוח' : (delivery.type == 'pickup' ? 'נקודת איסוף' : 'שיטת החזרה')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container terms" key="terms" :class="{active: (state.tab == 'terms')}" v-if="state.tab == 'terms'">
				<div class="data modifictions" animated>
					<div class="options">
						<span data-icon="published_with_changes">תנאי החלפות והחזרות</span>
					</div>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="cached"
								:class="{active: replace}" @click.self="replace = !replace">
								<span class="title">החלפת מוצר</span>
								<div class="description">האם ניתן להחליף מוצר זה לאחר קניה?</div>
								<div class="form">
									<form-numbers label="מקסימום ימים להחלפה" name="replace" v-model="model.replace" v-if="replace" />
									<form-toggle label="" name="replace_toggle" v-model="replace" />
								</div>
							</div>
							<div class="action" data-icon="undo"
								:class="{active: collect}" @click.self="collect = !collect">
								<span class="title">החזרת מוצר</span>
								<div class="description">האם ניתן להחזיר מוצר זה לאחר קניה?</div>
								<div class="form">
									<form-numbers label="מקסימום ימים להחזרה" name="collect" v-model="model.collect" v-if="collect" />
									<form-toggle label="" name="collect_toggle" v-model="collect" />
								</div>
							</div>
							<div class="action" data-icon="sync_problem"
								:class="{active: defect}" @click.self="defect = !defect">
								<span class="title">מוצר פגום</span>
								<div class="description">במידה והלקוח קיבל מוצר פגום, האם ניתן להחליפו בחדש?</div>
								<div class="form">
									<form-numbers label="מקסימום ימים להחלפה" name="defect" v-model="model.defect" v-if="defect" />
									<form-toggle label="" name="defect_toggle" v-model="defect" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container params" key="params" :class="{active: (state.tab == 'params')}" v-if="state.tab == 'params'">
				<div class="data explain" v-if="! (model.parameters && model.parameters.length)" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="checklist_rtl" @click="createParamsGroup">
								<span class="title">הוספת פרמטרים לבחירת הלקוח</span>
								<div class="description">רוצה לקבל הזמנה מדוייקת מהלקוחות שלך? אפשר להוסיף קבוצות פרמטרים לבחירת הלקוח במהלך ההזמנה</div>
							</div>
						</div>
					</div>
				</div>
				<colors-vue ref="colors"  @save="selectNewColor" @close="selectNewColor"></colors-vue>
				<index class="dynamic-groups" :data="model.parameters" sort-by="id" @sort="setParamsGroupSort">
					<div slot="index" slot-scope="group" class="data dynamic-group" :style="`z-index:${parseInt(model.parameters.length - group.$index)}`" :draggable="group.$sort.active" @dragover="group.$sort.move" @dragend="group.$sort.end" animated>
						<div class="options">
							<div class="group">
								<span class="sort" data-icon="more_vert" :data-count="group.parameters.length"
									@mousedown="group.$sort.activate" @mouseup="group.$sort.end" @touchstart="group.$sort.activate" @touchend="group.$sort.end">{{ group.title != "" ? group.title : "קבוצה חדשה" }}</span>
							</div>
							<div class="group">
								<template v-if="state.paramsActiveIndex == group.$index">
									<span data-icon="settings" @click="state.paramsActiveIndex = null">הגדרות קבוצה</span>
									<span data-icon="add" @click="createParamOption(group)" v-if="group.type!='input'">הוספת פרמטר</span>
								</template>
								<template v-else>
									<span class="primary" data-icon="app_registration" @click="state.paramsActiveIndex = group.$index">ניהול פרמטרים</span>
									<span class="error" data-icon="delete_sweep" @click="model.parameters.splice(group.$index, 1)">מחיקת קבוצה</span>
								</template>
							</div>
						</div>
						<index :data="group.parameters" sort-by="id" @sort="setParamOptionsSort(group.$index)" class="content parameters" animated v-if="state.paramsActiveIndex == group.$index">
							<div slot="index" slot-scope="param" class="dynamic-group-row" :style="`z-index:${parseInt(group.parameters.length - param.$index)}`"  :draggable="param.$sort.active" @dragover="param.$sort.move" @dragend="param.$sort.end">
								<div class="button light" data-icon="more_vert" @mousedown="param.$sort.activate" @mouseup="param.$sort.end" @touchstart="param.$sort.activate" @touchend="param.$sort.end" v-if="group.parameters.length > 1"></div>
								<form-input v-if="group.type != 'color'" class="grow" :name="`${group.$index}${param.$index}label`" label="כותרת" placeholder="פרמטר חדש" v-model="model.parameters[group.$index].parameters[param.$index].label" />
								<form-select v-if="colorsList && colors.length && group.type == 'color'" :name="`${group.$index}${param.$index}price`" label="צבע" placeholder="צבע" v-model="model.parameters[group.$index].parameters[param.$index].color"
								 @change="(value) => changeParameterColor(group.$index, param.$index, value)"
								 :options="[{value: 'add', label: 'הוסף צבע'}].concat(colors)" />
								<form-numbers v-if="group.type!='input'" :name="`${group.$index}${param.$index}price`" label="מחיר" placeholder="חינם" v-model="model.parameters[group.$index].parameters[param.$index].price" point />
								<div v-if="group.parameters.length > 1" class="button light" data-icon="delete" @click="model.parameters[group.$index].parameters.splice(group.$index, 1)"></div>
							</div>
						</index>
						<div class="content settings" animated v-else>
							<div class="dynamic-group-row">
								<form-input class="grow" :name="`params${group.$index}title`" label="שם הקבוצה" placeholder="קבוצה חדשה" v-model="model.parameters[group.$index].title" />
								<form-select :name="`params${group.$index}type`" label="אופן תצוגה" v-model="model.parameters[group.$index].type" :options="[{label: 'בחירה רגילה', value: 'checkbox'}, {label: 'בחירה מרשימה', value: 'select'}, {label: 'בחריה צבעים', value: 'color'}, {label: 'שדה טקסט', value: 'input'}]" @change="handleParameterGruop(group.$index)"/>
								<form-toggle :name="`params${group.$index}required`" label="בחירה חובה" v-model="model.parameters[group.$index].required"/>
								<form-toggle :name="`params${group.$index}multiple`" label="בחירה מרובה" v-model="model.parameters[group.$index].multiselect" v-if="model.parameters[group.$index].type!='input' && model.parameters[group.$index].type!='select'" />
								<form-toggle :name="`params${group.$index}closable`" label="מצב סגור" v-model="model.parameters[group.$index].closable" v-if="model.parameters[group.$index].type!='input' && model.parameters[group.$index].type!='select' && model.parameters[group.$index].multiselect" />
							</div>
						</div>
					</div>

					<div slot="footer" class="add-dynamic-group" animated @click="createParamsGroup" v-if="model.parameters.length">הוסף קבוצת פרמטרים</div>
				</index>
			</div>
			<div class="container related" key="related" :class="{active: (state.tab == 'related')}" v-if="state.tab == 'related'">
				<div class="data explain" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="extension">
								<span class="title">מוצרים נלווים מגדילים מכירה</span>
								<div class="description">הלקוחות שלך יכולים לקבל את החבילה המושלמת עבורם! אפשר להוסיף קבוצות של מוצרים נלווים לבחירת הלקוח במהלך ההזמנה</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="container settings" key="settings" :class="{active: (state.tab == 'settings')}" v-if="state.tab == 'settings'">
				<div class="data page" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="web"
								:class="{active: model.page.active}" @click.self="model.page.active = !model.page.active">
								<span class="title">עמוד מוצר פעיל</span>
								<div class="description">במידה ולא פעיל, המוצר ישאר זמין לרכישה אך ללא עמוד ייעודי עבורו</div>
							</div>
						</div>
						<div class="form-group">
							<form-input name="slug" label="כתובת URL" v-model="slug" />
							<form-input name="seoTitle" label="כותרת (SEO)" v-model="model.page.seo.title" />
							<form-input name="description" label="תיאור (SEO)" v-model="model.page.seo.description" />
							<form-input name="keywords" label="מילות מפתח (SEO)" v-model="model.page.seo.keywords" />
						</div>
					</div>
				</div>
				<div class="data product-card" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="class"
								:class="{active: tag}" @click.self="tag = !tag">
								<span class="title">תגית בקוביית מוצר</span>
								<div class="description">הוספת תגית על קוביית המוצר עוזרת להבליט אותו משאר המוצרים</div>
								<div class="form">
									<form-toggle label="" name="tag_toggle" v-model="tag" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="tag">
							<form-input name="title" label="טקסט" v-model="model.tag.text" />
							<form-input name="icon" label="אייקון" v-model="model.tag.icon" />
						</div>
					</div>
				</div>
			</div>

			<div class="container layout" key="layout" :class="{active: (state.tab == 'layout')}" v-if="state.tab == 'layout'">
				<div class="data layout-edit" animated>
					<div class="content">
						<form-group name="layout" >
							<form-toggle name="default" label="ברירת מחדל" v-model="customize" />
						</form-group>
					</div>
					<div class="content" v-if="! customize">
						<div class="form">
							<form-group name="layout">
								<form-area>
									<fs v-model="model.page.layout" ref="layout"
									:globals="pages['products'].globals || []"
									:components="pages['products'].components" v-if="pages" />
								</form-area>
							</form-group>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	components: {
		'extend-stock': require('@/components/extend-stock/index').default,
		'stock-templates': require('@/components/extend-stock/templates').default,
		'colors-vue': require('@/components/extend-stock/colors').default
	},
	data(){
		return {
			state: {
				tab: '',
				paramsActiveIndex: null
			},
			model: this.$models.inventory.product.model(),
			cacheStocks: null,
			colorsList: null,
			categories: [],
			deliveries: [],
			video: [],
			replaceService: null,
			pages: this.$models.layout.web.pages(),
			cacheLayout: null,
		}
	},
	watch:{
		'$route.query.tab': 'setTab',
		'model.parameters'(){
			this.state.paramsActiveIndex = null
		}
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([this.getColors(), this.getCategories(), this.getProduct(), this.getStoreServices(), this.getDeliveriesOptions()]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details')

		}).catch(error => {
			VueAlerts.flash({
				text: 'אירע שגיאה',
				closeable: false
			});
		});
	},
	methods: {
		getStoreServices(){
			return this.$models.dashboard.service.index(this.$route.params.store, {type: 'store', service: 'replaces'})
			.then(res => {
				if(res.data[0]) this.replaceService = res.data[0];
			})
		},
		getProduct(){
			return this.$models.inventory.product.get(this.$route.params.store, this.$route.params.product)
			.then(res => {
				this.model = Object.assign(this.model, res.data);

				if(Array.isArray(this.model.page.layout)){
					this.cacheLayout = this.model.page.layout;
				}

			})
		},
		getColors(){
			return this.$models.inventory.stock.getStockColors(this.$route.params.store)
			.then(result => {
				if(! result.data[0]) return;

				this.colorsList = result.data[0].colors || [];
			});
		},
		getCategories(){
			return this.$models.inventory.category.options(this.$route.params.store)
			.then((res) => {
				if(res.data) this.categories = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getDeliveriesOptions(){
			return this.$models.inventory.delivery.all(this.$route.params.store)
			.then((res) => {
				if(res.data) this.deliveries = res.data;
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		setTab(tab){
			if(['details', 'media', 'stocks', 'deliveries', 'params', 'related', 'terms', 'settings', 'layout'].includes(tab)) this.state.tab = tab;
		},
		setImagesSort(){
			this.model.images.sort((a, b) => a.__sort - b.__sort);
		},
		createParamsGroup(){
			let group = {
				title: '',
				required: true,
				multiselect: false,
				closable: false,
				type: 'checkbox',
				parameters: []
			};

			this.createParamOption(group);

			this.model.parameters.push(group);
		},
		setParamsGroupSort(){
			this.model.parameters.sort((a, b) => a.__sort - b.__sort);
		},
		createParamOption(group){
			group.parameters.unshift({
				label: '',
				price: '',
				color: ''
			})
		},
		setParamOptionsSort(group){
			this.model.parameters[group].parameters.sort((a, b) => a.__sort - b.__sort);
		},
		changeParameterColor(key, AttrKey, value){
			if(value == 'add') return this.$refs.colors.activate({key, AttrKey});

			this.model.parameters[key].parameters[AttrKey].label = value.replace(/\$/g, '');
		},
		selectNewColor(value){
			if(value.colors) this.colorsList = value.colors;

			this.model.parameters[value.key].parameters[value.AttrKey].label = value.label;

			this.model.parameters[value.key].parameters[value.AttrKey].color = value.value;
		},
		toggleDeliveries(id){
			if(! (this.model.deliveries && this.model.deliveries.list)) return;

			var index = this.model.deliveries.list.indexOf(id);

			if(index > -1) return this.model.deliveries.list.splice(index, 1);

			this.model.deliveries.list.push(id);
		},
		changeProductStatus(type){
			switch(type){
				case "active":
					this.$set(this.model, 'active' ,true);
					this.$set(this.model.page, 'active' ,true);
					break;

				case "pause":
						this.$set(this.model, 'active' ,true);
						this.$set(this.model.page, 'active' ,false);
					break;

				case "archive":
						this.$set(this.model, 'active' ,false);
						this.$set(this.model.page, 'active' ,false);
					break;
			}
		},
		submit(){
			this.$root.loading = true;

			this.$models.inventory.product.update(this.$route.params.store, this.$route.params.product, this.model).then((res) => {
				this.$root.loading = false;

				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});
			}).catch(error => {
				this.$root.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		handleParameterGruop(index){
			if(this.model.parameters[index].type == 'input'){
				this.model.parameters[index].multiselect = false;
				this.model.parameters[index].closeable = false;
			}
		}
	},
	computed: {
		images: {
			get(){
				return this.model.images.map(file => {
					file.attributes = [file.attributes[0] || {key: 'צבע', value: ''}];

					return file;
				});
			},
			set(images){
				images.splice(0, this.model.images.length);

				images.forEach(file => {
					this.model.images.push({
						file,
						source: URL.createObjectURL(file),
						title: '',
						attributes: [{key: 'צבע', value: ''}]
					})
				})
			}
		},
		colors(){
			let options = [{value: '', label: 'ללא צבע'}];

			return options.concat(this.colorsList ? this.colorsList.map(color => {
				color.icon = color.image || color.color;
				return color;
			}) : []);
		},
		stockType: {
			get(){
				if(this.model.stocks.length >= 1 && this.model.stocks[0].attributes) return "extend";

				if(this.model.stocks.length == 1 && this.model.stocks[0].quantity === true) return "free";

				if((this.model.stocks.length == 1 && !this.model.stocks[0].attributes && this.model.stocks[0].quantity.constructor !== Boolean)) return "single";
			},
			set(value){
				if(this.stockType == value) return;

				if(value == "extend"){
					this.$set(this.model, 'stocks', (this.cacheStocks ? JSON.parse(this.cacheStocks) : [{
						sku: this.model.sku,
						price: this.model.price,
						quantity: "10",
						attributes: [{ key: 'צבע', value: ''}]
					}]));
				} else if(this.stockType == "extend") {
					this.cacheStocks = JSON.stringify(this.model.stocks)
				}

				if(value == "free") this.$set(this.model, 'stocks', [{quantity: true}]);

				if(value == "single") this.$set(this.model, 'stocks', [{quantity: "10"}]);

			}
		},
		deliveriesType: {
			get(){
				if(! this.model.deliveries) this.$set(this.model, 'deliveries', {type: 'default'});

				return this.model.deliveries;
			},
			set(value){
				if(this.deliveriesType.type == value) return;

				if(value == "custom") return this.$set(this.model, 'deliveries', {type: value, list: []});

				this.$set(this.model, 'deliveries', {type: value});
			}
		},
		replace:{
			get(){
				return this.model.replace !== undefined && this.model.replace != 'false';
			},
			set(value){
				if(! value) return this.$delete(this.model, 'replace');

				this.$set(this.model, 'replace', (this.replaceService && this.replaceService.data.replace ? this.replaceService.data.replace : '14'));
			}
		},
		collect:{
			get(){
				return this.model.collect !== undefined && this.model.collect != 'false';
			},
			set(value){
				if(! value) return this.$delete(this.model, 'collect');

				this.$set(this.model, 'collect', (this.replaceService && this.replaceService.data.collect ? this.replaceService.data.collect : '14'));
			}
		},
		defect:{
			get(){
				return this.model.defect !== undefined && this.model.defect != 'false';
			},
			set(value){
				if(! value) return this.$delete(this.model, 'defect');

				this.$set(this.model, 'defect', (this.replaceService && this.replaceService.data.defect ? this.replaceService.data.defect : '14'));
			}
		},
		slug:{
			get(){
				return this.model.page.slug;
			},
			set(value){
				this.model.page.slug = value.split(' ').join('_');
			}
		},
		tag:{
			get(){
				return this.model.tag !== undefined;
			},
			set(value){
				if(! value) return this.$delete(this.model, 'tag');

				this.$set(this.model, 'tag', {text: '', icon: ''});
			}
		},
		customize:{
			get(){
				return Boolean(typeof this.model.page.layout == 'string')
			},
			set(value){

				if(value){
					this.cacheLayout = this.model.page.layout;

					return this.$set(this.model.page, 'layout', 'products');
				}

				this.$set(this.model.page, 'layout', this.cacheLayout || this.model.original_layout || []);
			}
		},
		productStatus(){
			if(this.model.active && this.model.page.active) return 'המוצר פעיל';
			if(this.model.active && ! this.model.page.active) return 'המוצר מושהה';
			if(! this.model.active && !this.model.page.active) return 'המוצר בארכיון';
		}
	}
}
</script>
