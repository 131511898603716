<template>
	<div class="search-drawer">
		SEARCH
	</div>
</template>

<script>
export default {

}
</script>
