<template>
	<div class="index" :class="{ sorting }">
		<slot name="header" v-bind="{ count }"></slot>
		<slot name="index" v-for="(item, i) in filtered"
			v-bind:$index="i" v-bind="item"
			v-bind:$sort="{
				active: (sorting && sorting.key == item[sortBy]),
				activate: (e) => activateSort(e, item, i),
				move: (e) => moveSort(e, i),
				end: endSort
			}"
		></slot>
		<slot name="empty" v-bind="{ active: isEmpty }"></slot>
		<div ref="lazyload" class="lazy-load" v-if="$listeners.lazyload"></div>
		<slot name="footer"></slot>
	</div>
</template>

<script>
export default {
	props: {
		data: { type: Array },
		sortBy: { type: String }
	},
	data(){
		return {
			filters: {},
			filtered: [],
			sorting: null,
			sort: []
		}
	},
	watch:{
		data(){
			this.filtered = this.data;

			if(this.sortBy) this.sort = this.data.map(i => i[this.sortBy]);

			this.handle();
		}
	},
	mounted(){
		this.filtered = this.list;

		if(this.$listeners.lazyload){
			let observer = new IntersectionObserver((elem) =>{
				elem[0].isIntersecting && !this.isEmpty && this.$emit('lazyload')
			});

			observer.observe(this.$refs.lazyload);
		}
	},
	methods: {
		activateSort(e, item, index){
			if(! this.sortBy || (this.count != this.data.length)) return;

			this.sort = this.list.map(i => i[this.sortBy]);
			this.sort.splice(index, 1);

			this.sorting = { key: item[this.sortBy], index };
		},
		moveSort(e, i){
			e.preventDefault();
			e.dataTransfer.effectAllowed = "all";
			e.dataTransfer.dropEffect = "all";

			if(this.sorting) this.sorting.index = i;
		},
		endSort(){
			if(this.sorting){
				this.sort.splice(this.sorting.index, 0, this.sorting.key);

				this.handle();

				this.$emit('sort', this.sort);
			}

			this.sorting = null;
		},
		handle(){
			let filters = Object.values(this.filters);

			if(! filters.length) return this.filtered = this.list;

			this.filtered = this.list.map(item => {
				item.$filtered = ! (filters.length == filters.filter(filter => this[filter.method](item, filter)).length)

				return item;
			});
		},
		filter(filter){
			this.filters[filter.id] = filter;

			this.handle();
		},
		clearFilters(){
			this.filters = {};

			this.handle();
		},
		eq(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] == filter.value)).length;
		},
		not(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] != filter.value)).length;
		},
		like(item, filter){
			return filter.in.filter(key =>
				(filter.value === null || item[key].toString().toLowerCase().search(filter.value.toString().toLowerCase()) >= 0)
			).length;
		},
		gt(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] > filter.value)).length;
		},
		gte(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] >= filter.value)).length;
		},
		lt(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] < filter.value)).length;
		},
		lte(item, filter){
			return filter.in.filter(key => (filter.value === null || item[key] <= filter.value)).length;
		},
		btwn(item, filter){
			let values = filter.value === null ? null : filter.value.split('-');

			return filter.in.filter(key => (values === null || ( item[key] > values[0] && item[key] < values[1] ))).length;
		},
		btwne(item, filter){
			let values = filter.value === null ? null : filter.value.split('-');

			return filter.in.filter(key => (values === null || ( item[key] >= values[0] && item[key] <= values[1] ))).length;
		}
	},
	computed: {
		list(){
			if(! this.sort.length) return this.data;

			return this.data.map(item => {
				item.__sort = this.sort.indexOf(item[this.sortBy]);
				return item;
			}).sort((a, b) => a.__sort - b.__sort);
		},
		count(){
			return this.data.length - this.filtered.filter(filter => filter.$filtered).length
		},
		isEmpty(){
			return this.data.length == this.filtered.filter(filter => filter.$filtered).length
		},
	}
}
</script>
