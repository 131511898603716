const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Web extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			name: 'tomy',
			settings: {
				round: true,
				autocart: true,
				logo: '',
				favicon: '',
				sitename: ''
			},
			"colors": [
				{"key": "primary", "value": "#B9303A", "label": "ראשי"},
			    {"key": "secondary", "value": "#F5EFE8", "label": "משני"},
			    {"key": "light", "value": "#F6F6F6", "label": "בהיר"},
			    {"key": "dark", "value": "#40393A", "label": "כהה"},
			    {"key": "text", "value": "#333333", "label": "טקסט"},
			    {"key": "text-light", "value": "#848484", "label": "טקסט בהיר"},
			    {"key": "background", "value": "#ffffff", "label": "רקע"}
			]
		}
	}

	icons(){
		return [
			{ value: 'local_shipping', label: 'משלוח', icon: 'local_shipping' },
			{ value: 'lock', label: 'מנעול', icon: 'lock' },
			{ value: 'star', label: 'כוכב', icon: 'star' },
			{ value: 'account_circle', label: 'אזור אישי', icon: 'account_circle' },
			{ value: 'language', label: 'שפות', icon: 'language' },
			{ value: 'mail_outline', label: 'הודעות', icon: 'mail_outline' },
			{ value: 'notifications_none', label: 'התרעות', icon: 'notifications_none' },
			{ value: 'notifications_active', label: 'התרעות פעיל', icon: 'notifications_active' },
			{ value: 'favorite', label: 'מועדפים פעיל', icon: 'favorite' },
			{ value: 'favorite_border', label: 'מועדפים', icon: 'favorite_border' },
			{ value: 'location_on', label: 'מיקום', icon: 'location_on' },
			{ value: 'facebook', label: 'פייסבוק', icon: 'facebook' },
		]
	}
	homeComponents(){
		return ['logo', 'closable-line', 'search', 'header-actions', 'menu-vue', 'menu-ref', 'main-title', 'text-component', 'button-vue', 'main-title', 'text-component', 'filters', 'filters-ref', 'sort', 'sort-ref', 'products', 'gallery', 'dynamic-boxes', 'banner', 'faq', 'blogs', 'contact', 'newsletter', 'button-vue', 'reviews', 'video-vue', 'reviews-verified'];
	}

	pages(){
		let pages_components = ['logo', 'closable-line', 'search', 'header-actions', 'menu-vue', 'menu-ref', 'main-title', 'text-component', 'button-vue', 'main-title', 'text-component', 'filters', 'filters-ref', 'sort', 'sort-ref','products', 'gallery', 'dynamic-boxes', 'banner', 'faq', 'blogs', 'contact', 'newsletter', 'button-vue', 'reviews', 'video-vue', 'reviews-verified'];

		return {
			header: {
				title: 'חלק עליון',
				components: pages_components
			},
			footer: {
				title: 'חלק תחתון',
				components: pages_components
			},
			products: {
				title: 'דף מוצר',
				components: ['product-data','logo', 'closable-line', 'search', 'header-actions', 'menu-vue', 'menu-ref', 'main-title', 'text-component', 'button-vue', 'main-title', 'text-component', 'filters', 'filters-ref', 'sort', 'sort-ref', 'products', 'gallery', 'dynamic-boxes', 'banner', 'faq', 'blogs', 'contact', 'newsletter', 'button-vue', 'reviews', 'video-vue', 'reviews-verified', 'bundles'],
				globals: ['main-title', 'text-component', 'gallery']
			},
			categories: {
				title: 'דף קטגוריה',
				components: pages_components,
				globals: ['main-title', 'text-component', 'gallery', 'products', 'filters']
			},
			blog: {
				title: 'דף כתבה',
				components: pages_components,
				globals: ['main-title', 'text-component', 'gallery']
			},
			search: {
				title: 'דף חיפוש',
				components: pages_components,
				globals: ['main-title', 'text-component', 'gallery', 'products']
			},
			page: {
				title: 'דף סטטי',
				components: pages_components,
				globals: ['main-title', 'text-component', 'gallery']
			}
		}
	}

	get(store, source){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! source) return reject(new Error("Missing `id`"));

			let key = [store, source].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get('dashboard/store/'+store+'/theme')
			.then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let files = {};

		if(typeof payload.settings.logo != 'string'){
			files['logo'] = payload.settings.logo;
		}

		if(typeof payload.settings.favicon != 'string'){
			files['favicon'] = payload.settings.favicon;
		}

		let data = Cast(payload, {
			name: String,
			phone: String,
			email: String,
			logo: String,
			favicon: String
		})

		return { data, files }
	}

	update(store, source, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! source) return reject(new Error("Missing `source`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data, files} = this.prePost(payload);

			this.$api.postWithFiles('dashboard/store/'+store+'/theme', data, files)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Web()
