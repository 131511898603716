<template>
	<div class="layout forms store">

		<div class="back" @click="$router.push({ name: 'master'})"></div>
		<div class="title">עריכת אתר</div>
		<div class="tabs">
			<div data-icon="description" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטי אתר</div>
			<div data-icon="support_agent" :class="{active: state.tab == 'messages'}"
				@click="$router.push({query: {tab: 'messages'}})">תמיכה</div>
			<div data-icon="bug_report" :class="{active: state.tab == 'reports'}"
				@click="$router.push({query: {tab: 'reports'}})">דיווח על תקלות</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save" v-if="state.tab == 'details'">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">
				<div class="data website" animated>
					<div class="options">
						<span data-icon="description">פרטי האתר</span>
					</div>
					<div class="content" v-if="data">
						<form-input name="storename" label="כותרת" v-model="model.storename" />
						<form-input name="domain" label="דומיין" v-model="model.domain" />
					</div>
				</div>

				<div class="data prices" animated>
					<div class="options">
						<span data-icon="description">פרטי העסקה</span>
					</div>
					<template v-if="data">
						<div class="content">
							<form-datepicker style="z-index: 2" name="signingDate" label="תאריך סגירת עסקה" v-model="model.signingDate" />
							<form-numbers name="creates" label="עלות הקמה" v-model="model.prices.create" clear point />

							<form-datepicker style="z-index: 1" name="hostingDate" label="תאריך התחלת אחסון" v-model="model.hostingDate" />
							<form-numbers name="monthly" label="סכום אחסון" v-model="model.prices.monthly" clear point />

							<form-textarea name="notes" label="הערות" v-model="notes"/>
						</div>
					</template>
				</div>
			</div>

			<div class="container messages" key="messages" :class="{active: (state.tab == 'messages')}" v-if="state.tab == 'messages'">
				<div class="data messages" animated>
					<div class="options">
						<div class="group">
							<span data-icon="support_agent"
								:class="{active: state.messages == 'support'}" @click="$router.push({query: {tab: 'messages', type: 'support'}})">תמיכה כללית</span>
							<span data-icon="insights"
								:class="{active: state.messages == 'marketing'}" @click="$router.push({query: {tab: 'messages', type: 'marketing'}})">שיווק</span>
						</div>
					</div>
					<div class="content" v-if="data && state.messages">
						<master-support :store="data.store" :storeId="data._id" :type="state.messages" />
					</div>
				</div>
			</div>

			<div class="container reports" key="reports" :class="{active: (state.tab == 'reports')}" v-if="state.tab == 'reports'">
				<div class="data reports" animated>
					<div class="content" v-if="reports.length">
						<div class="report-card" v-for="(report, i) in reports" :key="i"
							>
							<div class="screenshot">
								<div class="image" :style="`
									background-image: url('${report.screenshot}');
									background-position: top -${report.scrollY / 4}px center`"
									:class="{opened: openedImage.screenshot == report.screenshot}"
									@click="toggleImage(report)"
								></div>
								<div class="label" @click="toggleImage(report)">צפה בתמונה</div>
							</div>
							<div class="name">{{ report.user.name }}</div>
							<div class="phone">{{ report.user.phone }}</div>
							<div class="message">{{ report.message }}</div>
							<div class="actions">
								<div class="action" data-icon="check" :class="{active: report.status && report.status == 'checking'}" @click="updateReport(i, report._id, 'checking')"><span class="title">בטיפול</span></div>
								<div class="action" data-icon="done_all" :class="{active: report.status && report.status == 'completed'}" @click="updateReport(i, report._id, 'completed')"><span class="title">טופל</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	components: {
		'master-support': require('@/components/master-support').default,
	},
	data(){
		return {
			state: {
				tab: '',
				messages: ''
			},
			data: null,
			reports: [],
			model: {
				storename: '',
				domain: '',
				notes: '',
				prices: {
					monthly: '',
					create: ''
				},
				signingDate: new Date(),
				hostingDate: new Date()
			},
			openedImage: ''
		}
	},
	watch:{
		'$route.query.tab': 'setTab',
		'$route.query.type': 'setMessagesType',
		'data': 'getReports',
	},
	mounted(){
		Promise.all([
			this.getData(),
		]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details');
			this.setMessagesType(this.$route.query.type || 'support');
		});
	},
	methods:{
		setTab(tab){
			if(['details', 'messages', 'reports'].includes(tab)) this.state.tab = tab;
		},
		setMessagesType(tab){
			if(['support', 'marketing'].includes(tab)) this.state.messages = tab;
		},
		getData(){
			return this.$models.master.stores.get(this.$route.params.id)
			.then((res) => {
				this.data = res.data;

				Object.assign(this.model, {
					prices: {
						monthly: (this.data.prices && this.data.prices.monthly ? this.data.prices.monthly : ''),
						create: (this.data.prices && this.data.prices.create ? this.data.prices.create : ''),
					},
					storename: this.data.theme.settings.sitename,
					notes: this.data.notes,
					domain: this.data.tokens.filter(filter => filter.source == 'web')[0].domain,
					hostingDate: this.data.hostingDate ? new Date(this.data.hostingDate) : this.model.hostingDate,
					signingDate: this.data.signingDate ? new Date(this.data.signingDate) : this.model.signingDate
				})
			})
		},
		getReports(){
			if(! (this.data && this.data.store)) return;

			this.$models.master.reports.get(this.data.store)
			.then((res) => {
				this.reports = res.data;
			})
		},
		toggleImage(item){
			if(this.openedImage.screenshot == item.screenshot) return this.openedImage = '';

			this.openedImage = item;
		},
		save(){
			this.loading = true;

			this.$models.master.stores.update(this.model, this.$route.params.id).then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		updateReport(index, id, status){
			this.$models.master.reports.update(id, status)
			.then((res) => {
				this.$set(this.reports[index], 'status', status);
			})
		}
	},
	computed: {
		notes: {
			get(){
				return this.model.notes || ''
			},
			set(value){
				this.$set(this.model, 'notes', value);
			}
		}
	}
}
</script>
