<template>
	<div class="stores-drawer">
		<div class="store" v-for="store in stores" :key="store.store"
			:style="`background-image: url('${
				store.theme && store.theme.settings ? store.theme.settings.logo : ''
			}')`" @click="goTo(store)">
				<i class="icon" :style="`background-image: url('${
				store.theme && store.theme.settings ? store.theme.settings.favicon : ''
			}')`"></i>
				<span>{{store.store}}</span>
			</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			stores: []
		}
	},
	mounted(){
		this.$index.getStores().then(stores => this.stores = stores.data);
	},
	methods:{
		goTo(store){
			let route = this.$router.resolve({ name: 'orders', params: { store: store.store } });

			if(! (route && route.href)) return;

			window.location.href = route.href;
		}
	}
}
</script>
