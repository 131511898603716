var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout pages",class:{loading: _vm.loading}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("ניהול דפים")]),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goTo('web/pages/create', {type: 'search'})}}},[_vm._v("הוספת דף חיפוש")]),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goTo('web/pages/create', {type: 'blog'})}}},[_vm._v("הוספת דף בלוג")]),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"add"},on:{"click":function($event){return _vm.goTo('web/pages/create', {type: 'page'})}}},[_vm._v("הוספת דף סטטי")])])]),_vm._v(" "),(_vm.items.length)?_c('div',{staticClass:"index-filters"},[_c('index-search',{attrs:{"action":function (value) { return _vm.$refs.index.filter({ id: 'search', method: 'like', in: ['title'], value: value }); }}}),_vm._v(" "),_c('index-filter',{attrs:{"label":"פעיל","options":[
				{value: null, label: 'הכל'},
				{value: 1, label: 'פעיל בלבד'},
				{value: 0, label: 'לא פעיל'}
			],"action":function (value) { return _vm.$refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value: value }); }}}),_vm._v(" "),_c('index-filter',{attrs:{"label":"סוג","options":[
				{value: null, label: 'הכל'},
				{value: 'blog', label: 'בלוג'},
				{value: 'search', label: 'דפי חיפוש'},
				{value: 'page', label: 'דפי סטטי'}
			],"action":function (value) { return _vm.$refs.index.filter({ id: 'type', method: 'eq', in: ['type'], value: value }); }}})],1):_vm._e(),_vm._v(" "),(_vm.items.length)?_c('index',{ref:"index",attrs:{"data":_vm.list},scopedSlots:_vm._u([{key:"header",fn:function(items){return _c('div',{staticClass:"table header"},[_c('div',[_vm._v("כותרת")]),_vm._v(" "),_c('div')])}},{key:"index",fn:function(item){return _c('div',{staticClass:"table",class:{ hide: item.$filtered }},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(item.data && item.data.title ? item.data.title : item.slug))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.type))])]),_vm._v(" "),_c('div',{staticClass:"actions"},[(item.type != 'home')?[_c('form-toggle',{attrs:{"name":"active","label":""},on:{"toggle":function($event){return _vm.toggle(item)}},model:{value:(_vm.list[item.$index].active),callback:function ($$v) {_vm.$set(_vm.list[item.$index], "active", $$v)},expression:"list[item.$index].active"}}),_vm._v(" "),_c('div',{staticClass:"button",attrs:{"data-icon":"create"},on:{"click":function($event){return _vm.goTo('web/pages/edit', {id: item._id})}}},[_vm._v("ערוך")])]:[_c('div',{staticClass:"button",attrs:{"data-icon":"create"},on:{"click":function($event){return _vm.goTo('web/pages/home', {id: item._id})}}},[_vm._v("ערוך")])]],2)])}}],null,false,1919593425)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }