<template>
	<div class="fs-editor" :class="{ active }">
		<i class="material-icons-round" @click="close">close</i>
		<form-vue>
			<form-group name="layout" style="z-index: 3" v-if="mode.indexOf('layout') > -1">
				<form-input name="" label="כותרת" v-model="title" />
				<form-toggle name="" label="הגבלת רוחב" v-model="item.props.wrapper" v-if="item.props.wrapper != undefined" />
				<form-area class="set-layouts" v-if="item.props.layout">
					<div class="layoutType-1" :class="{ active: item.props.layout == 1 }" @click="changeLayout(1)"></div>
					<div class="layoutType-5-5" :class="{ active: item.props.layout == 55 }" @click="changeLayout(55)"></div>
					<div class="layoutType-3-6" :class="{ active: item.props.layout == 36 }" @click="changeLayout(36)"></div>
					<div class="layoutType-6-3" :class="{ active: item.props.layout == 63 }" @click="changeLayout(63)"></div>
					<div class="layoutType-3-3-3" :class="{ active: item.props.layout == 333 }" @click="changeLayout(333)"></div>
				</form-area>
				<form-toggle name="desktop" label="מוצג בדסקטופ" v-model="item.props.desktop" />
				<form-toggle name="mobile" label="מוצג במובייל" v-model="item.props.mobile" />
			</form-group>
			<form-group name="props" style="z-index: 3">
				<form-toggle name="" label="נגלל עם העמוד" v-model="item.props.sticky"/>
				<form-select name="" label="הצמדה" v-if="mode.indexOf('layout') < 0" v-model="item.props.valign" :options="[{value: 'top', label: 'הצמדה למעלה'}, {value: 'middle', label: 'הצמדה לאמצע'}, {value: 'bottom', label: 'הצמדה למטה'}]"/>
				<form-select name="" label="צבע רקע" v-if="mode.indexOf('layout') < 0" v-model="item.props.background" :options="colorsList"/>
				<form-select name="" label="צבע טקסט" v-model="item.props.color" :options="colorsList"/>
				<form-input name="" label="class" v-model="item.props.class" />
			</form-group>

			<form-group name="props" style="z-index: 2" v-if="mode.indexOf('layout') >= 0">
				<form-toggle name="background" label="צבע רקע" v-model="background" />
				<template  v-if="background">
					<form-colors name="color" label="צבע רקע" v-model="item.props.backgrounds.colors[0].color"></form-colors>

					<form-select name="opacity" label="שקיפות" v-model="item.props.backgrounds.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />

					<template v-if="item.props.backgrounds.type != 'full'">
						<form-colors name="color" label="צבע רקע" v-model="item.props.backgrounds.colors[1].color"></form-colors>

						<form-select  name="opacity" label="שקיפות" v-model="item.props.backgrounds.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
					</template>
				</template>
			</form-group>
			<form-group name="layouts" class="layout-select" style="z-index: 1" v-if="background">
				<div class="layout-card"
				@click="toggleBackgroundType('full')"
				:class="{active: item.props.backgrounds.type == 'full'}"
				style="width:140px;height:100px;">
					<i :style="`background-color:${item.props.backgrounds.colors[0].color};opacity:${item.props.backgrounds.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>

				<div class="layout-card"
				@click="toggleBackgroundType('top')"
				:class="{active: item.props.backgrounds.type == 'top'}"
				style="width:140px;height:100px;">
					<i :style="`background:${linearGradient('to top', item.props.backgrounds.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>

				<div class="layout-card"
				@click="toggleBackgroundType('rtl')"
				:class="{active: item.props.backgrounds.type == 'rtl'}"
				style="width:140px;height:100px;">
					<i :style="`background:${linearGradient('to right', item.props.backgrounds.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
				</div>
			</form-group>
			<form-group name="props" style="z-index: 1">
				<form-numbers name="" label="מרווח עליון" v-model="item.props.padding[0]" clear />
				<form-numbers name="" label="מרווח שמאל" v-model="item.props.padding[1]" clear />
				<form-numbers name="" label="מרווח תחתון" v-model="item.props.padding[2]" clear />
				<form-numbers name="" label="מרווח ימין" v-model="item.props.padding[3]" clear />
			</form-group>
		</form-vue>
	</div>
</template>

<script>
import { makeHash, hexToRgb } from '@/bootstrap/helpers';

export default {
	data(){
		return {
			active: false,
			colors: null,
			mode: [],
			item: {
				props: {
					layout: 1,
					wrapper: false,
					sticky: false,
					valign: 'top',
					background: '',
					color: '',
					class: '',
					desktop: true,
					mobile: true,
					padding: ['0', '0', '0', '0'],
				}
			}
		}
	},
	mounted(){
		this.getColors()
	},
	methods: {
		init(settings){
			this.item = settings.item;

			if(this.item.props.desktop === undefined) this.$set(this.item.props, 'desktop', true);
			if(this.item.props.mobile === undefined) this.$set(this.item.props, 'mobile', true);

			this.mode = settings.mode;

			this.active = true;
		},
		close(){
			this.active = false;
			this.$emit('close');
		},
		changeLayout(layout){
			let requiredContainers = (layout.toString().length - this.item.props.layout.toString().length);

			if(requiredContainers < 0){
				return VueAlerts.alert({
					title: 'שים לב!',
					text: 'כמות האזורים שבחרת נמוכה מכמות האזורים הנוכחית. האם למחוק את האזור השמאלי ביותר?',
					buttons: ['בטל שינויים', 'מחק אזור']
				}).then((result) => {
					if(result == 'בטל שינויים') return;

					for(let i=0;i > requiredContainers;i--){
						this.item.containers.pop();
					}

					this.item.props.layout = layout;
				})
			}

			if(requiredContainers > 0){
				for(let i=0;i < requiredContainers;i++){
					this.item.containers.push({
						id: makeHash(10),
						props: {
							sticky: false,
							background: '',
							color: '',
							class: '',
							valign: 'top',
							padding: ['0', '0', '0', '0']
						},
						components: []
					});
				}
			}

			this.item.props.layout = layout;
		},
		getColors(){
			this.$models.layout.web.get(this.$route.params.store, 'web')
			.then((res) => {
				if(res.data.colors) this.colors = res.data.colors;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		toggleBackgroundType(type){
			let colors = this.item.props.backgrounds.colors;

			this.$set(this.item.props.backgrounds, 'type', type);

			if(type == 'full') return this.$set(this.item.props.backgrounds, 'colors', [colors[0]]);

			if(colors.length == 1) colors.push({color: '#000000', opacity: '100'});

			this.$set(this.item.props.backgrounds, 'colors', colors);
		},
		linearGradient(type, options){
			let color_a = hexToRgb(options[0].color),
				color_b = hexToRgb(options[1] ? options[1].color : '#ffffff');

			return `linear-gradient(${type}, rgba(${color_a.r}, ${color_a.g}, ${color_a.b}, ${options[0].opacity/100}),  rgba(${color_b.r}, ${color_b.g}, ${color_b.b}, ${options[1] ? options[1].opacity/100 : '0'}))`
		}
	},
	computed: {
		background: {
			get(){
				return this.item.props.backgrounds != undefined;
			},
			set(value){
				if(value) return this.$set(this.item.props, 'backgrounds', {type: 'full', colors: [{color: '#000000', opacity: '100'}]});

				this.$delete(this.item.props, 'backgrounds');
			}
		},
		colorsList(){
			if(! this.colors) return [{ value: '', label: 'ללא צבע רקע' }];

			return [{ value: '', label: 'ללא צבע רקע' }, { value: 'white', label: 'לבן', icon: '#ffffff' }, { value: 'black', label: 'שחור', icon: '#000000' }].concat(
				this.colors.map(color => ({ value: color.key, label: color.label, icon: color.value }))
			);
		},
		title:{
			get(){
				if(! this.item.props.title) this.$set(this.item.props, 'title', '');

				return this.item.props.title;
			},
			set(value){

				this.item.props.title = value;
			}
		}
	}
}
</script>
