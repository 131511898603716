<template>
	<div class="charts pie" :class="{loading}">
		<svg :viewBox="`0 0 ${r*3} ${r*3}`" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg">
			<g :stroke-width="r/2">
				<circle class="bg" :r="r" :cy="r*1.5" :cx="r*1.5"
					@mouseenter="active = 1"
					@mouseleave="active = null"
					@click="active = (active === 1 ? null : 1)"
				/>
				<circle class="bar" :r="r" :cy="r*1.5" :cx="r*1.5"
					:stroke-dashoffset="sdo"
					:stroke-dasharray="(2 * Math.PI * this.r)"
					:transform="`rotate(-90 ${r*1.5} ${r*1.5})`"
					@mouseenter="active = 0"
					@mouseleave="active = null"
					@click="active = (active === 0 ? null : 0)"
				/>
			</g>
		</svg>
		<slot name="inner" v-bind="{active, precentage}" />
	</div>
</template>

<script>
export default {
	props: {
		r: {type: Number, default: 50},
		data: {type: Array,required:true}
	},
	data(){
		return {
			loading: true,
			active: null
		}
	},
	mounted(){
		setTimeout(() => this.loading = false, 400);
	},
	computed: {
		precentage(){
			return (this.data[0].value/(this.data[1].value))*100;
		},
		sdo(){
			return ((2 * Math.PI * this.r) / 100) * (100 - (this.loading ? 0 : this.precentage));
		}
	}
}
</script>
