<template>
	<div class="layout forms coupon">

		<div class="back" @click="$router.push({ name: 'inventory', params:{store: $route.params.store}})"></div>
		<div class="title">עריכות קופונים</div>
		<div class="tabs">
			<div data-icon="card_giftcard" :class="{active: state.tab == 'reward'}"
				@click="$router.push({query: {tab: 'reward'}})">מה הלקוח מקבל?</div>
			<div data-icon="rule" :class="{active: state.tab == 'limitation'}"
				@click="$router.push({query: {tab: 'limitation'}})">מתי המבצע תקף?</div>
			<div data-icon="class" :class="{active: state.tab == 'page'}"
				@click="$router.push({query: {tab: 'page'}})">איך המבצע יראה ללקוחות?</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container reward" key="reward" :class="{active: (state.tab == 'reward')}" v-if="state.tab == 'reward'">
				<div class="data reward" animated>
					<div class="options">
						<span data-icon="card_giftcard">מה הלקוח מקבל?</span>
					</div>
					<div class="content">
						<form-select name="reward-type" label="בחר תמורה ללקוח" v-model="type"
						:options="[
							{ value: 'discount', label: 'הנחה בשקלים'},
							{ value: 'precentage', label: 'הנחה באחוזים'},
						]"/>

						<form-numbers name="reward-discount" label="סכום הנחה" v-model="model.reward.discount" clear v-if="model.reward.type == 'discount'" />
						<form-numbers name="reward-precentage" label="אחוזי הנחה" v-model="model.reward.precentage" clear v-else-if="model.reward.type == 'precentage'" />
					</div>
				</div>
			</div>

			<div class="container limitation" key="limitation" :class="{active: (state.tab == 'limitation')}" v-if="state.tab == 'limitation'">
				<div class="data dates" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byDate}" @click.self="byDate = !byDate">
								<span class="title">עפ״י תאריכים</span>
								<div class="description">הגבל לפי תאריכים</div>
								<div class="form">
									<form-toggle label="" name="bydate_toggle" v-model="byDate" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byDate">
							<form-datepicker name="limitation-from-date" label="החל מתאריך" v-model="model.limitation.fromDate" :disabled-dates="{
								to: yesterday,
								from: new Date(model.limitation.toDate.getTime() - (24 * 60 * 60 * 1000))
							}" />
							<form-datepicker name="limitation-to-date" label="ועד לתאריך" v-model="model.limitation.toDate" :disabled-dates="{
								to: new Date(model.limitation.fromDate.getTime() + (24 * 60 * 60 * 1000))
							}" />
						</div>
					</div>
				</div>
				<div class="data prices" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byPrice}" @click.self="byPrice = !byPrice">
								<span class="title">עפ״י סכום ההזמנה</span>
								<div class="description">הגבל לפי סכום</div>
								<div class="form">
									<form-toggle label="" name="defect_toggle" v-model="byPrice" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byPrice">
							<form-numbers name="limitation-from-price" label="בהזמנה מעל סכום" v-model="model.limitation.fromPrice" />
							<form-numbers name="limitation-to-price" label="בהזמנה עד סכום" v-model="model.limitation.toPrice" />
						</div>
					</div>
				</div>
				<div class="data quantity" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byQuantity}" @click.self="byQuantity = !byQuantity">
								<span class="title">עפ״י כמות פריטים בהזמנה</span>
								<div class="description">הגבל לפי כמות</div>
								<div class="form">
									<form-toggle label="" name="quantity_toggle" v-model="byQuantity" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byQuantity">
							<form-numbers name="limitation-from-quantity" label="מעל מס׳ פרטים" v-model="model.limitation.fromQuantity" />
							<form-numbers name="limitation-to-quantity" label="עד מס׳ פרטים" v-model="model.limitation.toQuantity" />
						</div>
					</div>
				</div>
			</div>

			<div class="container page" key="page" :class="{active: (state.tab == 'page')}" v-if="state.tab == 'page'">
				<div class="data details" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="web"
								:class="{active: model.active}" @click.self="model.active = !model.active">
								<span class="title">מבצע פעיל</span>
								<div class="description">מבצע פעיל ויוצג באתר</div>
								<div class="form">
									<form-toggle label="" name="page_toggle" v-model="model.active" />
								</div>
							</div>
						</div>
						<div class="form-group">
							<form-input name="page-title" label="כותרת לבמצע" v-model="model.title" />
						</div>
						<div class="form-group">
							<form-input name="code" label="קוד קופון" v-model="model.code" />
							<form-area class="codeButton" v-if="! model.code">
								<div @click="createCode" class="button light" data-icon="add">צור קוד קופון</div>
							</form-area>
						</div>
					</div>
				</div>

				<div class="data cart" animated v-if="model.active">
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="smart_button"
								:class="{active: model.cartVisible}" @click.self="model.cartVisible = !model.cartVisible">
								<span class="title">מוצג בעגלה</span>
								<div class="description">מבצע יהיה פעיל רק בעגלה</div>
								<div class="form">
									<form-toggle name="cartVisible" label="" v-model="model.cartVisible" />
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="data automatic" animated v-if="model.active">
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="motion_photos_auto"
								:class="{active: model.automatic}" @click.self="model.automatic = !model.automatic">
								<span class="title">קופון אוטומטי</span>
								<div class="description">מבצע יתעדכן אוטומית בכניסה לעגלה</div>
								<div class="form">
									<form-toggle name="automatic" label="" v-model="model.automatic" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
const Cast = require('@/bootstrap/Cast');
import { makeHash } from '@/bootstrap/helpers';

export default {
	data(){
		return {
			state: {
				tab: ''
			},
			loading: true,
			model: this.$models.inventory.coupon.model()
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		this.getData().then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'reward')
		});
	},
	methods:{
		setTab(tab){
			if(['reward', 'query', 'limitation', 'page'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			if(! this.$route.params.coupon) return Promise.resolve();

			return this.$models.inventory.coupon.get(this.$route.params.store, this.$route.params.coupon)
			.then(res => {
				this.model = Object.assign(this.model, res.data);
				this.$root.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		save(){
			this.$root.loading = true;

			let request = null;

			if(this.$route.params.coupon){
				request = this.$models.inventory.coupon.update(this.$route.params.store, this.$route.params.coupon, this.model);
			} else {
				request = this.$models.inventory.coupon.create(this.$route.params.store, this.model);
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$root.loading = false;

				if(! this.$route.params.coupon){
					this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.$root.loading = false;
			});
		},
		createCode(){
			this.model.code = makeHash(8);
		}
	},
	computed: {
		yesterday(){
			return new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
		},
		type: {
			get(){
				return this.model.reward.type;
			},
			set(type){
				this.model.reward = { type };

				this.$set(this.model.reward, type, '');
			}
		},
		byDate: {
			get(){
				return Boolean(this.model.limitation.fromDate != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromDate');
					this.$delete(this.model.limitation, 'toDate');

					return;
				}

				this.$set(this.model.limitation, 'fromDate', new Date())
				this.$set(this.model.limitation, 'toDate', new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
			}
		},
		byPrice: {
			get(){
				return Boolean(this.model.limitation.fromPrice != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromPrice');
					this.$delete(this.model.limitation, 'toPrice');

					return;
				}

				this.$set(this.model.limitation, 'fromPrice', "0")
				this.$set(this.model.limitation, 'toPrice', "100")
			}
		},
		byQuantity: {
			get(){
				return Boolean(this.model.limitation.fromQuantity != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromQuantity');
					this.$delete(this.model.limitation, 'toQuantity');

					return;
				}

				this.$set(this.model.limitation, 'fromQuantity', "10")
				this.$set(this.model.limitation, 'toQuantity', "")
			}
		}
	}
}
</script>
<style lang="scss">
	.codeButton{
		width: 140px;
		.button{
			margin-top: 28px;
		}
	}
</style>
