<template>
	<div class="menu-app">
		<div class="actions">
			<div class="group">
				<i class="material-icons-round mobile" :class="{active: active == menuToggle}" @click="toggle(menuToggle)" v-if="menuToggle">menu</i>
				<div class="logo">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384.01 512">
						<rect id="rectangle" width="128" height="512"/>
						<polygon id="triangle" points="384 512 128 512 128 256 384 512"/>
						<path id="round" d="M448,0c1.3,139.62-116.38,257.29-256,256V128A128,128,0,0,0,320,0Z" transform="translate(-63.99)"/>
					</svg>
				</div>
				<i class="material-icons-round desktop" :class="{active: active == 'stores-drawer'}" @click="toggle('stores-drawer')">dashboard</i>
				<i class="notifications material-icons-round desktop" :class="{active: active == 'notifications-drawer'}" @click="toggle('notifications-drawer')" v-if="this.$route.params.store" :data-count="$root.$refs.notifications.new.length">notifications</i>
				<i class="material-icons-round desktop" :class="{active: active == 'search-drawer'}" @click="toggle('search-drawer')" v-if="false">search</i>
			</div>
			<div class="group" v-if="$index.master">
				<i class="material-icons-round desktop mobile" @click="$router.push({name: 'master'})">admin_panel_settings</i>
			</div>
			<div class="group">
				<i class="notifications material-icons-round mobile" :class="{active: active == 'notifications-drawer'}" @click="toggle('notifications-drawer')" v-if="this.$route.params.store" :data-count="$root.$refs.notifications.new.length">notifications</i>
				<i class="material-icons-round mobile" :class="{active: active == 'stores-drawer'}" @click="toggle('stores-drawer')">dashboard</i>
				<i class="material-icons-round mobile" :class="{active: active == 'search-drawer'}" @click="toggle('search-drawer')" v-if="false">search</i>
				<i class="material-icons-round desktop" :class="{active: active == 'support-drawer'}" @click="toggle('support-drawer')" v-if="this.$route.params.store">support</i>
				<i class="material-icons-round desktop mobile" :class="{active: active == 'user-drawer'}" @click="toggle('user-drawer')">person</i>
			</div>
		</div>
		<div class="drawer" :class="{active}">
			<div class="container">
				<div class="close" @click="active = null"></div>
				<transition name="fade" mode="out-in">
					<component :is="active" v-if="active" />
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components:{
		'master-drawer': require('@/components/menu-drawers/master').default,
		'menu-drawer': require('@/components/menu-drawers/menu').default,
		'stores-drawer': require('@/components/menu-drawers/stores').default,
		'search-drawer': require('@/components/menu-drawers/search').default,
		'user-drawer': require('@/components/menu-drawers/user').default,
		'support-drawer': require('@/components/menu-drawers/support').default,
		'report-drawer': require('@/components/menu-drawers/report').default,
		'notifications-drawer': require('@/components/menu-drawers/notifications').default,
	},
	data(){
		return {
			menu: false,
			active: null
		}
	},
	watch:{
		menu(){
			this.active = null;
			window.localStorage.setItem('menu-open', this.menu);
		},
		$route(){
			this.active = null;
			this.menu = false;
		}
	},
	methods: {
		toggle(section){
			if(this.active == section) return this.active = null;

			this.active = section;
		}
	},
	computed: {
		menuToggle(){
			if(this.$route.params.store) return 'menu-drawer';
			if(this.$route.name.split('/')[0] == 'master') return 'master-drawer';

			return false;
		}
	}
}
</script>
