<template>
	<div class="layout" :class="{loading}">

		<div class="top fixed">
			<div class="title">
				<router-link  class="back" :to="{ name: 'web', params: { store: this.$route.params.store }}"></router-link>
				<span>{{ this.$route.params.id ? 'עריכת' : 'הוספת' }} הפניה</span>
			</div>

			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>

		</div>

		<form-vue ref="form" >
			<form-group name="general" title="פרטי ההפניה" style="z-index: 8">
				<form-input name="title" label="מעמוד" v-model="model.fromUrl" />
				<form-area>
					<link-vue class="form-link" wrapper="form-group" v-model="model.link" />
				</form-area>
				<form-select name="code" label="קוד סטטוס" v-model="model.code"
				:options="[
					{ value: '300', label: '300'},
					{ value: '301', label: '301'},
					{ value: '302', label: '302'},
					{ value: '303', label: '303'},
					{ value: '304', label: '304'},
					{ value: '305', label: '305'},
					{ value: '306', label: '306'},
					{ value: '307', label: '307'},
					{ value: '308', label: '308'},
				]"/>
				<form-toggle name="active" label="פעיל" v-model="model.active" />
			</form-group>
		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			model: this.$models.dashboard.redirect.model(),
		}
	},
	mounted(){
		if(this.$route.params.id){
			this.getData();
		} else {
			this.loading = false;
		}

	},
	methods:{
		getData(){
			 this.$models.dashboard.redirect.get(this.$route.params.store, this.$route.params.id)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		submit(){
			this.loading = true;

			let request = null;
			let create = false;

			if(this.$route.params.id){
				request = this.$models.dashboard.redirect.update(this.$route.params.store, this.$route.params.id, this.model)
			}
			else{
				create = true;
				request = this.$models.dashboard.redirect.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if((! this.$route.params.id) || create){
					return this.$router.push({ name: 'redirects', params:{store: this.$route.params.store}});
				}

				this.loading = false;
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});

		}
	}
}
</script>
