<template>
	<div class="layout contact" :class="{loading}">

		<div class="top">
			<div class="title">צאטים</div>
		</div>

		<div class="index-filters" v-if="items.length">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like',
			in: ['phone'], value })" />
		</div>

		<index ref="index" :data="items" v-if="items.length">
			<div slot="header" slot-scope="items" class="table header">
				<div>צאט</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item" :class="{ hide: item.$filtered }"
				@click="goTo(item)">
				<div class="title">
					<span>{{ `${item.contact && item.contact.first_name ? item.contact.first_name : ""} ${item.contact && item.contact.last_name ? item.contact.last_name : ""}` }}</span>
					<span>{{ item.contact.phone }}</span>
					<div class="last-message">{{ item.messages[item.messages.length - 1].message }}</div>
				</div>

				<div class="actions" >
					<div class="time">{{ buildDate(item.messages[item.messages.length - 1].time) }}</div>
					<div v-if="item.order" class="tag"><span>מס׳ הזמנה</span> {{ item.order.number }}</div>
					<div v-if="!item.lastUserView || (item.lastUpdate > item.lastUserView)" class="tag new">הודעה חדשה</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	data(){
		return {
			loading: true,
			items: [],
			active: {
				answer: '',
				data: null,
				messages: null
			},
			filters: {
				date: {
					type: 'custom',
					from: moment(),
					to: moment()
				}
			}
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.dashboard.support.all(
				this.$route.params.store,
				'2021-01-01',
				this.filters.date.to.format('YYYY-MM-DD'),
				false
			).then(res => {
				this.items = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		goTo(item){
			if(item.order) return this.$router.push({
				name: 'orders/view',
				params: {order: item.order._id}
			});

			return this.$router.push({
				name: 'clients/view',
				params: {client: item.client._id}
			});
		},
		addZero(number){
			if(number < 10){
				number = "0" + number;
			}

			return number;
		},
		buildDate(item){
			let date = new Date(item);

			let resolve = [this.addZero(date.getDate()), this.addZero(date.getMonth() + 1), date.getFullYear()];
			let times = [this.addZero(date.getHours()), this.addZero(date.getMinutes())];

			return resolve.join('-') + ' ' + times.join(':');
		}
	}
}
</script>
