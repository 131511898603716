const Model = require('@/models/model');

class Support extends Model{

	constructor(){
		super();
	}

	all(store, from, to, unread = false){
		if(! store) return reject(new Error("Missing `store`"));

		return this.$api.post(`dashboard/store/${store}/supports`, { from, to, unread })
	}

	get(store, type){
		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));

			this.$api.post(`dashboard/store/${store}/support/master`, {type}).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	update(store, type, message) {
		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));
			if (!message) return reject(new Error("Missing `message`"));

			this.$api.post(`dashboard/store/${store}/support/master/update`, {type, message}).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

}

module.exports = new Support()
