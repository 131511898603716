<template>
	<div class="layout forms rates">
		<div class="back" @click="$router.back()"></div>
		<div class="title">{{ $route.params.rate ? `עריכת תעריפון` : `יצירת תעריפון` }}</div>
		<div class="tabs">
			<div data-icon="description" :class="{active: state.tab == 'details'}"
				@click="$router.push({query: {tab: 'details'}})">פרטים כלליים</div>
			<div data-icon="rule" :class="{active: state.tab == 'limitations'}"
				@click="$router.push({query: {tab: 'limitations'}})">הגבלות</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container details" key="details" :class="{active: (state.tab == 'details')}" v-if="state.tab == 'details'">

				<div class="data details" animated>
					<div class="options">
						<span data-icon="description">פרטים</span>
					</div>
					<div class="content">
						<form-input name="title" label="כותרת" v-model="model.title" />
                        <form-toggle name="active" label="פעיל" v-model="model.active" />
					</div>
				</div>

				<div class="data pricing" animated>
					<div class="options">
						<span data-icon="donut_small">תמחור</span>
					</div>
					<div class="content">
                        <form-select name="type" label="סוג" v-model="model.price.type"
                            :options="[
                                {value: 'distance', label: 'לפי ק״מ'},
                                {value: 'time', label: 'לפי זמן'},
                                {value: 'fixed', label: 'קבוע'}
                            ]"
                        />
						<form-numbers name="price" label="מחיר" v-model="model.price.value" clear point />
					</div>
				</div>
			</div>
            <div class="container limitations" key="limitations" :class="{active: (state.tab == 'limitations')}" v-if="state.tab == 'limitations'">
                <div class="data booking" animated>
					<div class="options">
						<span data-icon="description">לפי הזמנה</span>
					</div>
                    <div class="content">
                        <form-toggle name="booking" label="הגבל" v-model="booking" />
                    </div>
                    <template v-if="booking">
                        <div class="content">
                            <form-numbers name="min" label="מינימום להזמנה" v-model="model.limitations.booking.min" clear point />
                            <form-numbers name="max" label="מקסימום להזמנה" v-model="model.limitations.booking.max" clear point />
                        </div>
                        <div class="content">
                            <form-select style="z-index: 2" name="start" label="משעה" v-model="model.limitations.booking.start"
                                :options="times"
                            />
                            <form-select style="z-index: 1"  name="end" label="עד שעה" v-model="model.limitations.booking.end"
                                :options="times"
                            />
                        </div>
                    </template>
				</div>

                <div class="data session" animated>
					<div class="options">
						<span data-icon="description">לפי זמן פעילות</span>
					</div>
                    <div class="content">
                        <form-toggle name="session" label="הגבל" v-model="session" />
                    </div>
                    <template v-if="session">
                        <div class="content">
                            <form-checkbox name="days" label="ימים" v-model="model.limitations.session.days"
				                :options="days"
                            />
                            <form-toggle name="holidays" label="כולל חגים" v-model="model.limitations.session.holidays" />
                        </div>
                        <div class="content">
                            <form-select style="z-index: 2" name="start" label="משעה" v-model="model.limitations.session.start"
                                :options="times"
                            />
                            <form-select style="z-index: 1" name="end" label="עד שעה" v-model="model.limitations.session.end"
                                :options="times"
                            />
                        </div>
                    </template>
				</div>

                <div class="data clients" animated>
					<div class="options">
						<span data-icon="description">לפי זמן פעילות</span>
					</div>
                    <div class="content">
                        <form-input name="phone" label="מס׳ טלפון" v-model="phone" />
                    </div>
				</div>
            </div>
		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			state: {
				tab: ''
			},
            data: [],
            cache: {
                booking: null,
                session: null,
                clients: null
            },
            days: [
                { value: 0, label: 'יום א'},
                { value: 1, label: 'יום ב'},
                { value: 2, label: 'יום ג'},
                { value: 3, label: 'יום ד'},
                { value: 4, label: 'יום ה'},
                { value: 5, label: 'יום ו'},
                { value: 6, label: 'יום ש'}
            ],
			model: this.$models.calendar.rate.model(),
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([this.getRate()]).then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'details')
		});
	},
	methods: {
		getRate(){
            if(! this.$route.params.rate) return;

			this.$models.calendar.rate.get(this.$route.params.store, this.$route.params.rate)
			.then(result => {
				if(! result.data) return;

				this.data = result.data;

                this.model = JSON.parse(JSON.stringify(this.data));
			});
		},
		setTab(tab){
			if(['details', 'limitations'].includes(tab)) this.state.tab = tab;
		},
        save(){

            let request = null;

            this.$root.loading = true;

			if(this.$route.params.rate){
				request = this.$models.calendar.rate.update(this.$route.params.store, this.$route.params.rate, this.model)
			}
			else{
				request = this.$models.calendar.rate.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if(! this.$route.params.rate){
					return this.$router.push({ name: 'calendar', params:{store: this.$route.params.store}});
				}


				this.$root.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.$root.loading = false;
			});
        }
	},
    computed: {
        times(){
            let times = [];
            for(let i = 0; i < 24; i++){
                times.push({value: `${i}:00`, label: `${i}:00`});
            }

            return times;
        },
        booking: {
            get(){
                return this.model.limitations.booking !== undefined;
            },
            set(value){
                if(! value) {
                    this.cache.booking = JSON.parse(JSON.stringify(this.model.limitations.booking));
                    return this.$delete(this.model.limitations, 'booking');
                }

                this.$set(this.model.limitations, 'booking', this.cache.booking || {min: '', max: '', start: '', end: ''});
            }
        },
        session: {
            get(){
                return this.model.limitations.session !== undefined;
            },
            set(value){
                if(! value) {
                    this.cache.session = JSON.parse(JSON.stringify(this.model.limitations.session));
                    return this.$delete(this.model.limitations, 'session');
                }

                this.$set(this.model.limitations, 'session', this.cache.session || {days: [], start: '', end: '', offset: '', holidays: false});
            }
        }
    }
}
</script>
