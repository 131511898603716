<template>
	<div class="alert" :class="{active}">
		<i class="material-icons-round icon">{{ icon }}</i>
		<div class="title">{{ title }}</div>
		<div class="text">{{ text }}</div>
		<div class="buttons">
			<div class="action" v-for="button in buttons" @click="resolve(button)">
				<span>{{ button }}</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			active: false,
			title: '',
			text: '',
			icon: 'notification_important',
			buttons: [],
			resolver: null
		}
	},

	methods: {
		init(params){
			this.text = params.text;
			this.title = params.title;
			this.icon = params.icon;
			this.buttons = params.buttons;
			this.active = true;
			return new Promise((resolve) => this.resolver = resolve);
		},
		resolve(button){
			this.resolver(button);
			this.active = false;
		}
	}
}
</script>
