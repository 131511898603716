<template>
	<div class="index-card order checkout" :class="state">
		<div class="customer" v-if="order.customer">
			<div class="name">{{ order.customer.first_name }} {{ order.customer.last_name }}</div>
			<div class="phone">{{ order.customer.phone }}</div>
		</div>
		<div class="details" :data-icon="icon">
			<div class="number">{{ time }}</div>
			<div class="time">פעילות אחרונה</div>
		</div>
		<div class="delivery" :class="order.delivery.type" v-if="order.delivery">
			<div class="type">{{ order.delivery.title }}</div>
			<div class="address" v-if="order.address">{{ order.address.street }} {{ order.address.house }}, {{ order.address.city }}</div>
		</div>
		<div class="preview">
			<div class="item" v-for="(item,i) in items" :key="`${item.stock._id}_${i}`">
				<div class="image" :style="`background-image: url('${item.product.images[0].source}')`">
					<span v-if="item.stock.inCart > 1">x{{ item.stock.inCart }}</span>
				</div>
				<div class="title" :title="item.product.title">{{ item.product.title }}</div>
				<div class="attributes" v-if="item.stock.attributes || (item.cart.parameters && item.parameters)">
					<span v-for="(attr) in item.stock.attributes" :key="attr.key">{{ attr.key }}: {{ attr.value }}</span>

					<template v-if="item.cart.parameters && item.parameters" >
						<template v-for="(parameters, group) in item.cart.parameters">
							<span v-if="item.parameters[group]" :key="group">{{ item.parameters[group] }}: {{ parameters.map(param => item.parameters[`${group}:${param}`]).join(', ') }}</span>
						</template>
					</template>
				</div>
				<div class="discount" :title="item.stock.discount.title" v-if="item.stock.discount">{{ item.stock.discount.title }}</div>
				<div class="price" :data-old-price="item.stock.unitPrice > item.stock.paidPrice ? item.stock.unitPrice : null">
					{{ item.stock.paidPrice }}
				</div>
			</div>
		</div>
		<div class="summary">
			<div class="count" v-if="cartCount < 2">סה״כ פריט אחד</div>
			<div class="count" v-else>סה״כ {{ cartCount }} פריטים</div>
			<div class="price">{{ price }}</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		order: { type: Object, required: true }
	},
	watch:{
		order: 'calculateState'
	},
	data(){
		return {
			state: ''
		}
	},
	beforeDestroy(){
		clearInterval(this.interval);
	},
	mounted(){
		this.interval = setInterval(this.calculateState.bind(this), 30000);

		this.calculateState();
	},
	methods: {
		calculateState(){
			let update = moment(this.order.updates[0].updatedAt).diff(moment.now(), 'minutes');

			if(update < -4) return this.state = 'light';

			if(update < -3) return this.state = 'error';

			if(update < -1) return this.state = 'warning';

			return this.state = (this.order.complete ? 'success' : '');
		}
	},
	computed: {
		icon(){
			if(this.order.payments) return 'credit_score';

			if(this.order.complete) return 'done';

			if(this.state == 'light') return 'production_quantity_limits';

			return 'wifi_tethering';
		},
		time(){
			let m = moment(this.order.updates[0].updatedAt);

			return m.format("LTS");
		},
		cart(){
			let fullCart = this.order.cart;

			let keys = Object.keys(fullCart);

			if(keys.length && fullCart[keys[0]].constructor === Number){
				fullCart = Object.fromEntries(keys.map(stock => [stock, [{quantity: fullCart[stock]}]]));
			}

			return fullCart;
		},
		cartCount(){
			return Object.values(this.cart).reduce((count, cart) => count + cart.reduce((cnt, c) => cnt+c.quantity ,0), 0);
		},
		price(){
			return this.order.summary.total + this.order.summary.credit
		},
		stocks(){
			let stocks = {};

			this.order.products.forEach(product => product.stocks.forEach(stock => {
				stocks[stock._id] = stock;
				stocks[stock._id].product = product
			}))

			return stocks;
		},
		items(){
			if(! (this.order && this.order.products.length)) return [];

			let items = [], parameters;

			let fullCart = this.cart;

			this.order.products.forEach(product => {

				parameters = {};

				if(product.parameters){
					product.parameters.forEach(group => {
						parameters[group.id] = group.title;

						group.parameters.forEach(param => parameters[`${group.id}:${param.id}`] = `${param.label}${param.price ? ' (₪'+ param.price +')' : ''}`);
					})
				}

				product.stocks.forEach(stock => {
					if(! fullCart[stock._id]) return;

					fullCart[stock._id].forEach(cart => {
						items.push({
							product,
							stock,
							parameters,
							cart
						})
					})
				})
			})

			return items;
		}
	}
}
</script>
