<template>
	<div class="query" :class="{ loading }">

		<div class="presets">
			<slot name="preset"></slot>
		</div>

		<div class="filters" :class="{block}">
			<slot name="filters"></slot>
		</div>

		<div class="results" :class="{ hold }">
			<index-search v-if="search"
				:action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: search, value })" />

			<div class="head" v-if="showSort || limit || priority">
				<div class="sort"  v-if="showSort">
					<form-toggle name="sort" label="סידור ידני" v-model="sortActive"/>
				</div>
				<div class="limit" :class="{active: limitActive}" v-if="limit">
					<form-toggle name="sort" label="סידור ידני" v-model="sortActive"/>
					<form-toggle name="limit" label="הגבלת כמות" v-model="limitActive"/>
					<form-numbers name="limit" label="סה״כ כמות" v-model="limitNumber" clear/>
				</div>
				<div class="priority" :class="{active: priorityActive}" v-if="priority">
					<form-toggle name="type" label="עדיפות חכמה" v-model="priorityActive" />
					<form-select name="type" label="סוג עדיפות" v-model="sort.priority.type" :options="[
						{ value: 'orders', label: 'מוצרים הכי נמכרים'},
						{ value: 'favorites', label: 'מוצרים הכי אהובים'},
						{ value: 'recents', label: 'מוצרים אחרונים'},
					]" v-if="priorityActive" />
					<form-select name="type" label="לפי תקופה" v-model="sort.priority.period" :options="[
						{ value: 'w', label: 'בשבוע האחרון'},
						{ value: 'm', label: 'החודש האחרון'},
						{ value: 'y', label: 'בשנה האחרון'},
					]" v-if="priorityActive && sort.priority.type != 'recents'" />
					<form-numbers name="limit" label="סה״כ כמות" v-model="sort.priority.limit" clear v-if="priorityActive" />

				</div>
			</div>

			<index ref="index" :data="data" :sort-by="sortKey" @sort="setStaticSort">
				<div class="table" slot="index" slot-scope="item" v-if="! item.$filtered"
					:draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
					<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" v-if="sortKey"></div>
					<slot name="index" v-bind="item"></slot>
				</div>
			</index>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		load: { type: Function, required: true },
		query: { type: Object, required: true },
		sort: { type: Object },
		showSort: { type: Boolean, default: false },
		priority: { type: Boolean, default: false },
		limit: { type: Object },
		search: { type: Array },
		uncache: { type: Array, default: () => [] },
	},
	data(){
		return {
			loading: false,
			updated: new Date(),
			cache: null,
			block: false,
			data: []
		}
	},
	watch: {
		query: {
			handler(){
				this.updated = new Date();
				setTimeout(() => {
					if(this.hold && (new Date().getTime() - this.updated.getTime()) >= 2000){
						this.refresh();
					}
				}, 2000);
			},
			deep: true
		}
	},
	mounted(){
		this.cacheQuery();
		this.refresh();
	},
	methods: {
		refresh(){
			this.loading = true;
			this.load(this.query, this.sort, this.limit).then(({ data }) => {
				this.cacheQuery();

				this.data = data;
				this.loading = false;
			}).catch(() => {
				VueAlerts.alert({
					title: 'שגיאה בעדכון תוצאות',
					text: 'אירעה שגיאה בעדכון התוצאות, האם לנסות שוב או לבטל שינויים אחרונים?',
					buttons: ['בטל שינויים', 'נסה שנית']
				}).then((action) => {
					if(action == 'נסה שנית') return this.refresh();

					Object.keys(this.query).forEach(key => this.query[key] = (this.cache[key] || undefined));

					this.loading = false;
				});
			});
		},
		cacheQuery(){
			this.cache = JSON.parse(JSON.stringify(this.query));
		},
		setStaticSort(order){
			this.sort.static = order;
		},
		blockFilters(){
			this.block = true;
		},
		unBlockFilters(){
			this.block = false;
		}
	},
	computed: {
		hold(){
			if(! this.cache) return false;

			this.uncache.forEach(key => {
				this.cache[key] = this.query[key];
			});

			return JSON.stringify(this.cache) != JSON.stringify(this.query);
		},
		limitActive: {
			get(){
				return Boolean(this.limit.limit != undefined);
			},
			set(val){
				this.$set(this.limit, 'limit', (val ? "10" : undefined));
			}
		},
		limitNumber: {
			get(){
				if(this.limit.limit == undefined) return "0";

				return this.limit.limit;
			},
			set(val){
				this.$set(this.limit, 'limit', val);
			}
		},
		sortActive: {
			get(){
				return Boolean(this.sort.static);
			},
			set(val){
				if(! val) return this.$delete(this.sort, 'static');

				this.$set(this.sort, 'static', []);
			}
		},
		sortKey(){
			return (this.sort.static != undefined ? '_id' : null);
		},
		priorityActive: {
			get(){
				return Boolean(this.sort.priority != undefined);
			},
			set(val){
				if(! val) return this.$delete(this.sort, 'priority');

				this.$set(this.sort, 'priority', {
					type: 'orders',
					period: 'w',
					limit: '10'
				});
			}
		}
	}
}
</script>
