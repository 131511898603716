import axios from 'axios';

let token = localStorage.token;

if (token) {
	window.Api = axios.create({
		baseURL: process.env.MIX_KIPI_API,
		headers: {
			'Accept': 'application/json',
			'Authorization': `Bearer ${token}`
		}
	});

	window.Api.postWithFiles = (url, data, files) => {
		let formData = new FormData(), dataBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });

		formData.append("data", dataBlob);

		Object.keys(files).forEach(name => {
			formData.append(name, files[name]);
		});

		return window.Api.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
	}

	window.Api.interceptors.response.use(null, function(error) {
		if (error.response.status == 460) {
			return window.App.$logout();
		}

		return Promise.reject(error);
	});
} else {
	console.error('token not found');
	window.location = "/login";
}
