const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Delivery extends Model{

	constructor(){
		super();
	}

	model(type = 'ship'){
		let model = {
			type: type,
			title: "",
			price: "",
			service: "",
			days: {
				from: "",
				to: ""
			},
			hidden: false,
			active: true
		};

		if(type == 'pickup'){
			model.city = '';
			model.address = '';
			model.phone = '';
		}

		if(type == 'collect'){
			delete model.price;

			model.prices = {
				collect: '',
				replace: '',
				defect: ''
			}

			model.includeShipping = false
		}

		return model;
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/deliveries').then(response => {
				let result = { response };

				result.data = Cast(response.data, [{
					_id: String,
					title: String,
					type: String,
					city: String,
					address: String,
					phone: String,
					price: String,
					days: {
						from: String,
						to: String
					},
					hidden: Boolean,
					active: Boolean
				}]);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));


			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/deliveries/'+id)
			.then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					title: String,
					type: String,
					city: String,
					address: String,
					phone: String,
					price: String,
					days: {
						from: String,
						to: String
					},
					minutes: {
						to: String
					},
					prices: {
						collect: String,
						replace: String,
						defect: String,
					},
					specialPrices: [{
						from: String,
						price: String
					}],
					citiesPrices: [{
						title: String,
						price: String
					}],
					includeShipping: Boolean,
					hidden: Boolean,
					active: Boolean
				});

				resolve(result);

				this.$cache.set(key, result);
			})
			.catch(error => reject(error))
		});
	}

	prePost(payload){
		let data = Cast(payload, {
			title: String,
			type: String,
			city: String,
			address: String,
			phone: String,
			price: Number,
			days: {
				from: Number,
				to: Number
			},
			minutes: {
				to: Number
			},
			prices: {
				collect: Number,
				replace: Number,
				defect: Number,
			},
			specialPrices: [{
				from: Number,
				price: Number
			}],
			citiesPrices: [{
				title: String,
				price: Number
			}],
			includeShipping: Boolean,
			hidden: Boolean,
			active: Boolean
		})


		return { data }
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data} = this.prePost(payload);

			this.$api.post(store+'/inventory/deliveries/create', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let {data} = this.prePost(payload);

			this.$api.post(store+'/inventory/deliveries/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/deliveries/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/deliveries/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	getSettings(store) {
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));

			this.$api.get(store + '/inventory/deliveries/settings')
				.then(response => resolve(response))
				.catch(error => reject(error))
		});
	}

	updateSettings(store, payload) {
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if (!store) return reject(new Error("Missing `store`"));

			let data = Cast(payload, {
				distributions: [
					{
						city: {
							id: Number,
							name: String,
							input: String,
						},
						restrictions: [Number],
						branches: [String],
						deliveries: [{
							delivery: String,
							overrides: {
								price: Number,
								days: {
									from: String,
									to: String
								},
								minutes: {
									to: String
								}
							}
						}]
					}
				]
			});

			this.$api.post(store + '/inventory/deliveries/settings', data)
				.then(response => resolve(response))
				.catch(error => reject(error))
		});
	}
}

module.exports = new Delivery()
