<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'מוצרים', icon: 'receipt', groups: ['global','query'] },
		'product': { label: 'תצוגת מוצרים', icon: 'design_services', groups: ['product_layout', 'product_settings'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['row', 'quickview', 'views', 'split', 'settings'] }
		}">
		<form-group style="z-index:2" name="global">
			<form-toggle v-if="this.global" name="global" v-model="toggleGlobal" label="משוך מוצרי הדף"></form-toggle>
		</form-group>
		<form-group name="query">
			<form-area v-if="! toggleGlobal">
				<query ref="query" :query="input.query" :sort="input.sort" :limit="input.limit" priority
					:search="['_id', 'title']" :uncache="['products']" showSort
					:load="this.loadQuery.bind(this)"
				>
					<query-products slot="filters" />
					<query-price slot="filters" />
					<query-categories slot="filters" />
					<query-quantity slot="filters" />
					<query-properties slot="filters" />

					<template slot="index" slot-scope="item">
						<div class="checkbox" v-if="input.query.products">
							<input type="checkbox" :value="item._id" v-model="input.query.products">
							<label></label>
						</div>

						<div><span>{{ item.title }}</span></div>
						<div><span>₪{{ item.price }}</span></div>
						<div><span>{{ (! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי')) }}</span></div>

					</template>
				</query>
			</form-area>
		</form-group>
		<form-group style="z-index:2" name="product_settings">
			<form-toggle name="title" v-model="input.product.title" label="שם מוצר" />
			<form-toggle name="sizes" v-model="input.product.sizes" label="מידות במלאי" />
			<form-toggle name="colors" v-model="input.product.colors" label="צבעים נוספים" />
			<form-toggle name="favorite" v-model="input.product.favorite" label="כפתור 'אהבתי'" />
			<form-toggle name="square" v-model="input.product.square" label="תמונה מרובעת" />
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:6" name="product_layout">
			<form-area class="layout-select">
				<div class="layout-card" v-for="layout in layouts" @click="input.product.layout = layout.name"
					:class="{active: input.product.layout == layout.name}" style="width:240px;height:320px;">
					<i v-for="i in layout.layout" :style="i"></i>
				</div>
			</form-area>
		</form-group>

		<form-group style="z-index:5" name="row">
			<form-select name="rowCount" v-model="input.settings.rowCount" label="כמות מקסימלית לתצוגה בשורה"
				:options="[{label: '1 בשורה', value: '1'}, {label: '2 בשורה', value: '2'},{label: '3 בשורה', value: '3'}, {label: '4 בשורה', value: '4'},{label: '5 בשורה', value: '5'}]"
			/>
		</form-group>

		<form-group style="z-index:4" name="settings">
			<form-toggle name="quickbuy" v-model="input.settings.quickbuy" label="קנייה מהירה" />
			<form-toggle name="hidecart" v-model="input.settings.hidecart" label="ללא הוספה לסל"></form-toggle>
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:3" name="quickview">
			<form-toggle name="quickview" v-model="quickview" label="צפייה מהירה" />
			<form-toggle name="showquantity" v-if="quickview" v-model="input.settings.showquantity" label="הצג כמות" />
			<form-toggle name="gallery" v-if="quickview" v-model="input.settings.gallery" label="תמונות כגלריה" />
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:2" name="split">
			<form-toggle name="split" v-model="split" label="פיצול וריאציות" />
			<form-toggle name="split_view" v-model="input.settings.show_full_attributes" label="תצוגה מלאה בפיצול" v-if="split"/>
			<form-select name="split_by" v-model="input.settings.split" label="פיצל לפי מאפיין" v-if="split"
				:options="properties"
			/>
			<form-area></form-area>
		</form-group>
		<form-group style="z-index:1" name="views">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ" />
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל" />
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "מוצרים",
	defaults: {
		query: {},
		sort: {},
		limit: {},
		product: {
			layout: '',
			title: true,
			sizes: true,
			colors: true,
			favorite: true,
			square: false
		},
		settings: {
			rowCount: '4',
			desktop: true,
			mobile: true,
			quickbuy: true,
			quickview: false,
			hidecart: false
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	data(){
		return {
			properties: []
		}
	},
	created(){
		this.input.product = Object.assign(JSON.parse(JSON.stringify(this.$options.defaults.product)), this.value.product);
		this.input.settings = Object.assign(JSON.parse(JSON.stringify(this.$options.defaults.settings)), this.value.settings);
	},
	mounted(){
		this.init();
	},
	methods: {
		init(){
			Api.get(this.$route.params.store+'/inventory/stock_templates/attributes').then(({ data }) => {
				if(data){
					this.properties = data.map(propery => ({ value: propery._id, label: propery._id }));
				}
			});
		},
		loadQuery(query, sort, limit){
			let q = Object.assign(JSON.parse(JSON.stringify(query)), {products: undefined});
			return this.$models.inventory.product.query(this.$route.params.store, { query: q, sort, limit })
		}
	},
	computed: {
		layouts(){
			return [
				{
					name: '',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '20px',
							top: '10px', left: '10px', right: '10px',
							zIndex: 2, opacity: (this.input.product.title ? 1 : 0)
						},
						{
							height: '40px',
							left: '10px', bottom: '10px', right: (this.input.product.favorite ? '60px' : '10px'),
							zIndex: 2
						},
						{
							width: '40px', height: '40px',
							bottom: '10px', right: '10px',
							zIndex: 2, opacity: (this.input.product.favorite ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: '60px', left: '10px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: '60px', left: '50px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: '60px', left: '90px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: (this.input.product.sizes ? '100px' : '60px'), left: '10px',
							zIndex: 2, opacity: (this.input.product.colors ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: (this.input.product.sizes ? '100px' : '60px'), left: '40px',
							zIndex: 2, opacity: (this.input.product.colors ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: (this.input.product.sizes ? '100px' : '60px'), left: '70px',
							zIndex: 2, opacity: (this.input.product.colors ? 1 : 0)
						}
					]
				},
				{
					name: 'type-b',
					layout: [
						{
							top: (this.input.product.title ? '30px' : 0), left: 0, bottom: (this.input.product.colors ? '80px' : '50px'), right: 0,
							opacity: 0.2
						},
						{
							height: '20px',
							top: 0, left: 0, right: 0,
							opacity: (this.input.product.title ? 1 : 0)
						},
						{
							height: '40px',
							left: 0, bottom: 0, right: (this.input.product.favorite ? '50px' : 0)
						},
						{
							width: '40px', height: '40px',
							bottom: 0, right: 0,
							opacity: (this.input.product.favorite ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: (this.input.product.colors ? '90px' : '60px'),  left: '10px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: (this.input.product.colors ? '130px' : '100px') , left: '10px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '30px', height: '30px',
							bottom: (this.input.product.colors ? '170px' : '140px') , left: '10px',
							zIndex: 2, opacity: (this.input.product.sizes ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '50px', left: 0,
							opacity: (this.input.product.colors ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '50px', left: '30px',
							opacity: (this.input.product.colors ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '50px', left: '60px',
							opacity: (this.input.product.colors ? 1 : 0)
						}
					]
				},
				{
					name: 'type-c',
					layout: [
						{
							top: 0, left: 0, bottom: 0, right: 0,
							opacity: 1
						}
					]
				}
			]
		},
		split: {
			get(){
				return Boolean(this.value.settings.split != undefined);
			},
			set(value){
				if(! value) {
					this.$delete(this.value.settings, 'show_full_attributes');
					return this.$delete(this.value.settings, 'split');
				}

				this.$set(this.value.settings, 'split', this.properties[0] ? this.properties[0].value : '');
				this.$set(this.value.settings, 'show_full_attributes', false);
			}
		},
		toggleGlobal: {
			get(){
				return (this.global && this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		},
		quickview: {
			get(){
				return Boolean(this.input.settings.quickview)
			},
			set(value){
				if(value){
					this.$set(this.input.settings, 'showquantity', true);
					this.$set(this.input.settings, 'gallery', true);
					return this.$set(this.input.settings, 'quickview', true);
				}

				this.$delete(this.input.settings, 'quickview');
				this.$delete(this.input.settings, 'showquantity');
				this.$delete(this.input.settings, 'gallery');
			}
		}
	}
}
</script>
