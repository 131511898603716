<template>
	<div class="popover" :class="{loading, active}">
		<div class="cancel" data-icon="close" @click="active = false"></div>
		<template v-if="templates">
			<div class="list">
				<div class="item header">תבניות שמורות</div>
				<div class="item" v-for="template in templates">
					<div class="name">{{ template.name }}</div>
					<div class="button" data-icon="publish" v-if="state == 'load'" @click="set(template)">טען</div>
					<div class="button" data-icon="create" v-else @click="updateTemplate(template.name, true)">עדכן</div>
				</div>
			</div>
			<form-vue class="save-box" v-if="state == 'save'">
				<form-group name="saveTemplate" title="" style="z-index: 1">
					<form-input ref="name" label="שם התבנית" name="template" v-model="name" />
					<form-area class="save-button">
						<div class="button success" data-icon="save" @click="updateTemplate(name)">שמירת תבנית</div>
					</form-area>
				</form-group>
			</form-vue>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		stocks: {type: Array, required: true}
	},
	data(){
		return {
			loading: true,
			active: false,
			templates: null,
			state: 'load',
			name: ''
		}
	},
	watch: {
		active(){
			if(! this.active) return;

			this.loading = true;

			this.$models.inventory.stock.getTemplates(this.$route.params.store)
			.then((res) => {
				this.templates = res.data;
				this.loading = false;
			})
			.catch(error => {
				this.active = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			})
		}
	},
	methods: {
		load(){
			this.state = 'load';
			this.active = true;
		},
		save(){
			this.state = 'save';
			this.active = true;
		},
		set(template){
			this.$emit('setStockTemplate', template);
			this.active = false;
		},
		updateTemplate(name, force = false){
			if(! name){
				return VueAlerts.flash({
					text: 'יש לבחור שם לתבנית',
					closeable: false
				});
			}

			if(! force && this.templates.filter(item => name == item.name).length){
				return VueAlerts.alert({
					title: "שם התבנית תפוס",
					text: 'שם התבנית שבחרת קיים במערכת, האם לעדכן?',
					buttons: ["עדכן", "שנה שם"]
				}).then(res => {
					if(res == 'עדכן'){
						this.updateTemplate(name, true);
					} else {
						return setTimeout( () => {
							this.$refs.name.focus();
						}, 500);
					}
				});
			}

			this.$models.inventory.stock.updateTemplate(this.$route.params.store, name, this.stocks)
			.then((res) => {
				VueAlerts.flash({
					text: 'התבנית נשמרה בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.active = false;
				this.name = '';
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});

		}
	}
}
</script>
