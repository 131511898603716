<template>
	<div class="layout pages" :class="{loading}">

		<div class="top">
			<div class="title">עריכת תבניות</div>

			<div class="actions">
				<div class="button" data-icon="settings" @click="goTo('web/design')">הגדרות</div>
			</div>

		</div>

		<index ref="index" :data="list" v-if="pages">
			<div slot="header" slot-scope="items" class="table header">
				<div>כותרת</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item">
				<div class="title">
					<span>{{ item.title }}</span>
				</div>

				<div class="actions">
					<div class="button" data-icon="create" @click="goTo('web/layouts/default', {name: item.name})">ערוך</div>
				</div>
			</div>
		</index>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			pages: this.$models.layout.web.pages()
		}
	},
	mounted(){


		this.loading = false;
	},
	methods: {
		goTo(name, page = null){
			if(page){
				let params = {store: this.$route.params.store}
				Object.assign(params, page);
				return this.$router.push({ name, params }).catch(() => {});
			}

			this.$router.push({ name }).catch(() => {});
		}
	},
	computed: {
		list(){
			return Object.keys(this.pages).map(key =>  key != 'blog-main' ? ({ name: key, title: this.pages[key].title }) : null).filter(function (el) {
			  return el != null;
			});
		}
	}
}
</script>
