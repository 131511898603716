<template>
	<div class="index-card product" :class="{ loading, hide: product.$filtered, off: !product.active }">
		<div class="images" @click="go">
			<div class="gallery"><div class="image" v-for="i in Math.min(3, product.images.length)" :key="i"
					:style="`background-image: url('${product.images[i-1].source}')`"
					v-bind="Object.assign(
						(product.video && (product.images.length == 1 || i ==2)) ? {'data-icon': 'play_circle_outline'} : {},
						i == 3 ? {'data-count': `+${product.images.length - 2}`} : {}
					)"></div></div>
			<!-- <div class="add">הוספת תמונות</div> -->
		</div>
		<div class="title" @click="go">{{ product.title }}</div>
		<div class="tags" @click="go">
			<div class="tag" v-for="(tag, i) in $tags" :key="i" :class="tag.style" :data-icon="tag.icon">{{tag.content}}</div>
		</div>
		<div class="active" v-text="productStatus.text" :data-icon="productStatus.icon" :class="productStatus.class"></div>
		<div class="price" @click="go">{{ product.price }}</div>
	</div>
</template>

<script>
import moment from 'moment';
moment.locale('he');

export default {
	props: {
		product: { type: Object, required: true },
		tags: { type: Array, default: () => [] }
	},
	data(){
		return {
			loading: false
		}
	},
	methods: {
		go(){
			this.$router.push({name: 'inventory/products/edit', params: {product: this.product._id}})
		}
	},
	computed: {
		$tags(){
			let tags = [],
				stocks = this.product.stocks.reduce((sum, stock) => {
					if(sum.total.constructor === Boolean || stock.quantity.constructor === Boolean){
						sum.total = true;

						return sum;
					}

					let s = parseInt(stock.quantity);

					if(s < 1){
						sum.out++;
					} else if(s < 4){
						sum.last++;
					}

					sum.total += s;

					return sum;
				}, {total: 0, last: 0, out: 0})

			if(stocks.out > 1){
				tags.push({ style: 'error', icon: 'error', content: `${stocks.out} פריטים אזלו מהמלאי`});
			}else if(stocks.out > 0){
				tags.push({ style: 'error', icon: 'error', content: 'פריט אחד אזל מהמלאי'});
			}

			if(stocks.last > 1){
				tags.push({ style: 'warning', icon: 'warning', content: `${stocks.last} פריטים עם מלאי נמוך`});
			}else if(stocks.last > 0){
				tags.push({ style: 'warning', icon: 'warning', content: `פריט אחד עם מלאי נמוך`});
			}

			if(stocks.total.constructor === Boolean){
				tags.push({ style: 'light', icon: 'style', content: 'מוצר עם מלאי חופשי'});
			}else if(stocks.total < 1){
				tags.push({ style: 'error', icon: 'error', content: 'מוצר אזל מהמלאי'});
			} else if(stocks.total < 4){
				tags.push({ style: 'warning', icon: 'warning', content: `נותרו ${stocks.total} פריטים אחרונים במלאי`});
			}else{
				tags.push({ style: 'primary', icon: 'style', content: `סה״כ ${stocks.total} פריטים במלאי`});
			}

			if(this.product.orders > 1)
				tags.push({ style: 'primary', icon: 'paid', content: `${this.product.orders} הזמנות למוצר זה בשבוע האחרון`})
			else if(this.product.orders == 1)
				tags.push({ style: 'primary', icon: 'paid', content: `הזמנה אחת למוצר זה בשבוע האחרון`})


			if(this.product.cart > 1)
				tags.push({ style: 'success', icon: 'shopping_cart', content: `${this.product.cart} פריטים נמצאים בעגלות`})
			else if(this.product.cart == 1)
				tags.push({ style: 'success', icon: 'shopping_cart', content: `פריט אחד נמצא בעגלה`})

			if(this.product.notify > 1)
				tags.push({ style: 'success', icon: 'notification_important', content: `${this.product.notify} פריטים ברשימת עדכון מלאי`})
			else if(this.product.notify == 1)
				tags.push({ style: 'success', icon: 'notification_important', content: `פריט אחד ברשימת עדכון מלאי`})

			return tags.concat(this.tags)
		},
		productStatus(){
			if(this.product.active && this.product.link && this.product.link.active) return {text: 'המוצר פעיל', icon: 'done', class: 'active'};
			if(this.product.active && !(this.product.link && this.product.link.active)) return {text: 'המוצר מושהה', icon: 'pause', class: 'pause'};
			if(! this.product.active && !(this.product.link && this.product.link.active)) return {text: 'המוצר בארכיון', icon: 'archive', class: 'archive'};

			return {text: 'המוצר בארכיון', icon: 'archive', class: 'archive'};
		}
	}
}
</script>
