var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-vue',{staticClass:"info-box",attrs:{"preventHash":"","tabs":{
	'general': { label: 'גלריה', icon: 'receipt', groups: ['global', 'image', 'preview' ] },
	'visual': { label: 'תצוגה', icon: 'design_services', groups: ['layout', 'gallery_settings'] },
	'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
	}}},[_c('form-group',{staticStyle:{"z-index":"3"},attrs:{"name":"global"}},[(this.global)?_c('form-toggle',{attrs:{"name":"global","label":"משוך גלריה מעמוד"},model:{value:(_vm.toggleGlobal),callback:function ($$v) {_vm.toggleGlobal=$$v},expression:"toggleGlobal"}}):_vm._e()],1),_vm._v(" "),(! _vm.toggleGlobal)?[_c('form-group',{staticStyle:{"z-index":"2"},attrs:{"name":"image"}},[_c('form-file',{attrs:{"name":"images","label":"בחר תמונות","multiple":""},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"1"},attrs:{"name":"preview"}},[_c('images-preview',{attrs:{"form":[{
				title: { type: 'form-input', label: 'כותרת', default: '' }
			}]},on:{"delete":_vm.removeImage},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})],1)]:_vm._e(),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"1"},attrs:{"name":"layout"}},[_c('form-area',{staticClass:"layout-select"},_vm._l((_vm.layouts),function(l){return _c('div',{staticClass:"layout-card",class:{active: _vm.layout == l.name},staticStyle:{"width":"320px","height":"240px"},on:{"click":function($event){_vm.layout = l.name}}},_vm._l((l.layout),function(i){return _c('i',{style:(i)})}),0)}),0)],1),_vm._v(" "),(_vm.layout == 'slider')?_c('form-group',{staticStyle:{"z-index":"2"},attrs:{"name":"gallery_settings"}},[_c('form-toggle',{attrs:{"name":"autoplay","label":"אוטומטי"},model:{value:(_vm.input.visual.autoplay),callback:function ($$v) {_vm.$set(_vm.input.visual, "autoplay", $$v)},expression:"input.visual.autoplay"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"loop","label":"לולאה"},model:{value:(_vm.input.visual.loop),callback:function ($$v) {_vm.$set(_vm.input.visual, "loop", $$v)},expression:"input.visual.loop"}}),_vm._v(" "),_c('form-select',{attrs:{"name":"interval","label":"זמן החלפת תמונות","options":[
				{ value: '1', label: 'מהיר' },
				{ value: '3', label: 'בינוני' },
				{ value: '6', label: 'איטי' } ]},model:{value:(_vm.input.visual.interval),callback:function ($$v) {_vm.$set(_vm.input.visual, "interval", $$v)},expression:"input.visual.interval"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"arrows","label":"חצים"},model:{value:(_vm.input.visual.arrows),callback:function ($$v) {_vm.$set(_vm.input.visual, "arrows", $$v)},expression:"input.visual.arrows"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"dots","label":"נקודות"},model:{value:(_vm.input.visual.dots),callback:function ($$v) {_vm.$set(_vm.input.visual, "dots", $$v)},expression:"input.visual.dots"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"thumbs","label":"תמונות ממוזערות"},model:{value:(_vm.thumbs),callback:function ($$v) {_vm.thumbs=$$v},expression:"thumbs"}}),_vm._v(" "),(_vm.thumbs)?_c('form-select',{attrs:{"name":"thumbsPosition","label":"מיקום תמונות ממוזערות","options":[
				{value: 'top', label: 'למעלה'},
				{value: 'left', label: 'שמאל'},
				{value: 'bottom', label: 'למטה'},
				{value: 'right', label: 'ימין'}
			]},model:{value:(_vm.input.visual.thumbsPosition),callback:function ($$v) {_vm.$set(_vm.input.visual, "thumbsPosition", $$v)},expression:"input.visual.thumbsPosition"}}):_vm._e(),_vm._v(" "),_c('form-area')],1):_vm._e(),_vm._v(" "),_c('form-group',{staticStyle:{"z-index":"1"},attrs:{"name":"settings"}},[_c('form-toggle',{attrs:{"name":"desktop","label":"מוצג בדסקטופ"},model:{value:(_vm.input.settings.desktop),callback:function ($$v) {_vm.$set(_vm.input.settings, "desktop", $$v)},expression:"input.settings.desktop"}}),_vm._v(" "),_c('form-toggle',{attrs:{"name":"mobile","label":"מוצג במובייל"},model:{value:(_vm.input.settings.mobile),callback:function ($$v) {_vm.$set(_vm.input.settings, "mobile", $$v)},expression:"input.settings.mobile"}}),_vm._v(" "),_c('form-area')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }