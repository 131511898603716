<template>
	<div class="layout dashboard clients">

		<div class="header">
			<div class="title">
				<span>ניהול לקוחות</span>
				<span v-for="(note, i) in notes" :key="i" :class="note.type">{{ note.text }}</span>
			</div>
		</div>

		<div class="grid">

			<div class="data stats">
				<div class="options">
					<div class="group">
						<span data-icon="people" class="active">שירות לקוחות</span>
					</div>
				</div>

				<div class="content">
					<div class="stat primary" data-icon="people">
						<span>{{ main.counts.clients }}</span>
						<span>סה״כ משתמשים רשומים</span>
					</div>
					<div class="stat success" data-icon="speaker_phone">
						<span>{{ lastUseds.length }}</span>
						<span>פעילים ב-5 דקות האחרונות</span>
					</div>
					<div class="stat" data-icon="mark_email_read">
						<span>{{ newsletterClients.length }}</span>
						<span>סה״כ רשומים בניוזלטר</span>
					</div>
					<div class="stat warning" data-icon="mark_chat_unread">
						<span>{{ messages.unread.length }}</span>
						<span>הודעות חדשות</span>
					</div>
				</div>
			</div>

			<div class="data messages">
				<div class="options">
					<div class="group">
						<span data-icon="mark_chat_unread" :data-count="messages.unread.length" :class="{active: messages.state == 'unread'}" @click="messages.state = 'unread'">הודעות חדשות</span>
						<span data-icon="forum" :data-count="messages.supports.length" :class="{active: messages.state == 'supports'}" @click="messages.state = 'supports'">כל ההודעות</span>
					</div>
				</div>

				<index class="content" :data="messages[messages.state]">
					<div class="message" slot="index" slot-scope="message" :key="message.$index"
						@click="$router.push(message.order ?
							{ name: 'orders/view', params: { order: message.order._id } } :
							{ name: 'clients/view', params: { client: message.client._id }}
						)">
						<div class="name">{{ message.name }}</div>
						<div class="phone">{{ message.phone }}</div>
						<div class="date">{{ message.time }}</div>
						<div class="order" v-if="message.order">{{ message.order.number }}</div>
						<div class="text">{{ message.message }}</div>
					</div>

					<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו הודעות</div>
				</index>
			</div>

			<div class="data main">
				<div class="options">
					<div class="group">
						<span data-icon="people" :data-count="main.counts.clients"
							:class="{active: main.state == 'clients'}" @click="setMainState('clients')">לקוחות</span>
						<span data-icon="category" :data-count="newsletterClients.length"
							:class="{active: main.state == 'newsletterClients'}" @click="setMainState('newsletterClients')">ניוזלטר</span>
					</div>
					<div class="group">
						<span data-icon="search" data-label="חיפוש" :class="{active: main.mobile.filters}" @click="main.mobile.filters = !main.mobile.filters; main.mobile.sort = false"></span>
						<span data-icon="sort" data-label="מיון" :class="{active: main.mobile.sort}" @click="main.mobile.sort = !main.mobile.sort; main.mobile.filters = false"></span>
					</div>
				</div>

				<div class="content">
					<index ref="main" :data="mainData" :class="main.state"
						@lazyload="lazyload">
						<template slot="header">
							<div class="index-top">
								<div class="filters" :class="{ active: main.mobile.filters }">
									<div class="close" @click="main.mobile.filters = false"></div>
									<component v-for="filter in mainFilters"
										:key="`${main.state}_${filter.id}`" :is="filter.component" :action="(value) => setMainFilter(filter, value)"
										:label="filter.label" :options="filter.options" :placeholder="`חיפוש ב${filter.placeholder.join(', ')}`" />
								</div>
							</div>
							<div class="header" :class="{active: main.mobile.sort}">
								<div class="close" @click="main.mobile.sort = false"></div>
								<div class="item" v-for="header in main.headers" :key="header.key"
									:class="[(main.sort && header.key == main.sort.key ? {
										active: true,
										up: main.sort.dir > 0,
										down: main.sort.dir < 0
									} : ''), {sort: header.sort}]"
									@click="setMainSort(header)">
									{{ header.label }}
								</div>
							</div>
						</template>

						<div class="index-card clients" slot="index" slot-scope="item" :key="item.$index"
							:class="{hide: item.$filtered}" @click="$router.push({ name: 'clients/view', params: { client: item._id }})">
							<div class="customer">
								<div class="name">{{ item.name }}</div>
								<div class="phone">{{ item.phone }}</div>
							</div>
							<div class="last_seen" v-if="item.lastUsed">
								<div class="title">נראה לאחרונה</div>
								<div class="time">{{ item.lastUsed.format("dddd, D בMMMM YYYY LTS") }}</div>
							</div>
							<div class="tags">
								<div v-for="(tag, i) in item.tags" :key="i" :class="tag.style" :data-icon="tag.icon">{{tag.content}}</div>
							</div>
							<div class="data">
								<div class="item" :data-count="formatNumber(item.cartCount)">פריטים בסל</div>
								<div class="item" :data-count="formatNumber(item.favoritesCount)">אהבתי</div>
								<div class="item" :data-count="`₪${formatNumber(item.credit)}`">קרדיט</div>
							</div>
							<div class="summary">
								<div class="count">סה״כ {{ item.orders }} הזמנות</div>
								<div class="price">{{ formatNumber(item.ordersAmount) }}</div>
							</div>
						</div>

						<div class="empty" slot="empty" slot-scope="active" v-if="active.active">לא נמצאו משתמשים</div>
					</index>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
moment.locale('he');

export default {
	data(){
		return {
			addActive: false,
			blockLoading: false,
			summary: {
				state: 'cart',
				expend: false
			},
			main: {
				state: 'clients',
				counts: {},
				headers: [],
				mobile: {
					filters: false,
					sort: false
				}
			},
			clients: [],
			newsletterClients: [],
			messages:{
				state: 'unread',
				unread: [],
				supports: []
			}
		}
	},
	mounted(){
		this.$root.loading = true;

		Promise.all([
			this.getClients(),
			this.getNewsletterClients(),
			this.getSupports(),
			this.getUnreadSupports()
		]).then(() => this.setMainState('clients'));
	},
	methods: {
		lazyload(){
			if(Object.keys(this.$refs.main.filters).length) return;

			if(this.main.state == 'clients') this.getClients();
		},
		getClients(all = false){
			if((this.clients.length >= (this.main.counts.clients !== undefined ? this.main.counts.clients : 1515))) return;

			if(this.blockLoading) return;

			this.blockLoading = true;

			return this.$models.dashboard.client.all(this.$route.params.store, (all ? '' : 15), this.clients.length).then((res) => {
				this.clients = this.clients.concat(res.data);

				if(res.response.headers["kipi-pagination-total"])
					this.main.counts.clients = parseInt(res.response.headers["kipi-pagination-total"]);
				else this.main.counts.clients = this.clients.length

				this.blockLoading = false;
			})
		},
		getNewsletterClients(){
			return this.$models.support.newsletter.index(this.$route.params.store).then((res) => {
				this.newsletterClients = res.data;
			})
		},
		getSupports(){
			return this.$models.dashboard.support.all(this.$route.params.store,
				"2021-01-01",
				"2030-01-01"
			).then((res) => {
				this.messages.supports = res.data.map(support => {
					return {
						_id: support._id,
						client: (support.client || null),
						order: (support.order || null),
						name: (support.contact ? `${support.contact.first_name || ''} ${support.contact.last_name || ''}` : ''),
						phone: (support.contact && support.contact.phone ? support.contact.phone : ''),
						time: moment(support.messages[(support.messages.length - 1)].time).format("HH:mm DD/MM/YYYY"),
						message: support.messages[(support.messages.length - 1)].message
					}
				});
			})
		},
		getUnreadSupports(){
			return this.$models.dashboard.support.all(this.$route.params.store,
				"2021-01-01",
				"2030-01-01",
				true
			).then((res) => {
				this.messages.unread = res.data.map(support => {
					return {
						_id: support._id,
						client: (support.client || null),
						order: (support.order || null),
						name: (support.contact ? `${support.contact.first_name || ''} ${support.contact.last_name || ''}` : ''),
						phone: (support.contact && support.contact.phone ? support.contact.phone : ''),
						time: moment(support.messages[(support.messages.length - 1)].time).format("HH:mm DD/MM/YYYY"),
						message: support.messages[(support.messages.length - 1)].message
					}
				});
			})
		},
		formatNumber(n, f = 0){
			return n.toFixed(f).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		setMainState(state){
			let headers = {
				clients: [
					{key: 'name', label: 'שם הלקוח', sort: true, sortLocale: true, search: true},
					{key: 'phone', label: 'טלפון', search: true},
					{key: 'email', label: 'דוא״ל', search: true},
					{key: 'lastUsed', label: 'נראה לאחרונה', dateFormat: 'DD/MM/YYYY HH:mm:ss', sort: true},
					{key: 'cartCount', label: 'פריטים בסל', numberFormat: true, sort: true},
					{key: 'favoritesCount', label: 'מוצרים באהבתי', numberFormat: true, sort: true},
					{key: 'credit', label: 'סה״כ קרדיט', sort: true, priceFormat: 2},
					{key: 'orders', label: 'סה״כ הזמנות', sort: true, numberFormat: true},
					{key: 'ordersAmount', label: 'סה״כ סכום הזמנות', sort: true, priceFormat: 2},
					{key: 'lastOrder', label: 'תאריך הזמנה אחרונה', dateFormat: 'DD/MM/YYYY HH:mm:ss', sort: true},
					{key: 'lastOrderAmount', label: 'סכום הזמנה אחרונה', sort: true, priceFormat: 2},
				],
				newsletterClients: [
					{key: 'email', label: 'שם', sort: true, sortLocale: true, search: true},
					{key: 'time', label: 'תאריך הרשמה', dateFormat: 'DD/MM/YYYY', sort: true},
					{key: 'active', label: 'פעיל', sort: true, filter: {method: 'eq', options: [
						{value: null, label: 'הכל'},
						{value: true, label: 'פעיל בלבד'},
						{value: false, label: 'לא פעיל בלבד'},
					]}}
				]
			};

			this.$root.loading = true;

			this.$refs.main.clearFilters();

			this.main = Object.assign(this.main, {
				state, headers: headers[state],
				mobile: {
					filters: false,
					sort: false
				}
			});

			this.$root.loading = false;
		},
		setMainSort(header){
			if(! header.sort) return;

			if(this.main.state == 'clients') this.getClients(true)

			let sort = {key: header.key, dir: -1, sortLocale: header.sortLocale};

			if(this.main.sort && this.main.sort.key == header.key) sort.dir = (this.main.sort.dir*-1);
			this.$set(this.main, 'sort', sort);
			this.$set(this.main.mobile, 'sort', false);
		},
		setMainFilter(filter, value){
			if(this.main.state == 'clients') this.getClients(true);

			this.$refs.main.filter(Object.assign(filter, { value }));
		},
		goTo(name, params = {}){
			Object.assign(params, {store: this.$route.params.store});

			this.$router.push({ name, params }).catch(() => {})
		},
		goToItem(item){
			let params = {
				store: this.$route.params.store
			};

			if(this.main.state == 'clients') params.client = item._id;


			this.$router.push({ name: `${this.main.state}/view`, params }).catch(() => {})
		}
	},
	computed: {
		lastUseds(){
			let now = moment();

			return this.clients.filter(client => {
				let t = moment(client.lastUsed);

				return moment.duration(now.diff(t)).asMinutes() < 5;
			});
		},
		notes(){
			let notes = [];

			if(this.messages.unread.length){
				notes.push({
					type: 'warning',
					text: 'יש ' + (this.messages.unread.length == 1 ? 'הודעה חדשה אחת' : `${this.messages.unread.length} הודעות חדשות`)
				});
			}

			if(! notes.length){
				return [{
					type: 'success',
					text: 'הכל רגוע, יש לך שירות לקוחות נהדר.'
				}];
			}

			return notes;
		},
		mainData(){
			let data = this[this.main.state].map(item => {
				if(this.main.state == 'clients'){
					item.name = (item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '');
					item.email = (item.customer ? item.customer.email : '');

					item.favoritesCount = (item.favorites ? item.favorites.length : 0);
					item.lastUsed = item.lastUsed && !item.lastUsed._isAMomentObject ? moment(item.lastUsed) : null;
					item.tags = [];

					if(item.lastOrder){
						item.tags.push({ style: 'primary', icon: 'done', content: `הזמנה אחרונה ב${moment(item.lastOrder).format('DD/MM/YYYY')} בסכום ${this.formatNumber(item.lastOrderAmount)} ₪`})
					}

					if(item.cart){
						let values = Object.values(item.cart);

						if(values[0] && values[0].quantity)
							item.cartCount = values.reduce((count, stock) => count + stock.quantity, 0);
						else item.cartCount = values.reduce((count, group) => count + group.reduce((quantity, stock) => quantity + stock.quantity, 0), 0);

					}else item.cartCount = 0;

				}

				return item;
			});

			if(this.main.sort){
				data.sort((a, b) => (this.main.sort.sortLocale ?
					(b[this.main.sort.key].toString().localeCompare(a[this.main.sort.key].toString(), 'he')) :
					(a[this.main.sort.key] - b[this.main.sort.key])
				) * this.main.sort.dir);
			}

			return data;
		},
		mainFilters(){
			let filters = {};

			this.main.headers.forEach(header => {
				if(header.search){
					if(! filters.search) filters.search = {
						id: 'search',
						component: 'index-search',
						method: 'like',
						in: [],
						placeholder: [],
						options: []
					};

					filters.search.in.push(header.key);
					filters.search.placeholder.push(header.label);
				}

				if(header.filter){
					filters[header.key] = Object.assign(header.filter, {
						id: header.key,
						in: [header.key],
						label: header.label,
						component: 'index-filter',
						placeholder: []
					})
				}
			});

			return Object.values(filters);
		}
	}
}
</script>
