<template>
	<div class="layout pages" :class="{loading}">

		<div class="top">
			<div class="title">ניהול הפניות</div>

			<div class="actions">

				<div class="button" data-icon="add" @click="goTo('redirects/create')">הוספת חדש</div>
			</div>

		</div>

		<div class="index-filters" v-if="items.length">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['fromUrl'], value })" />
			<index-filter label="פעיל"
				:options="[
					{value: null, label: 'הכל'},
					{value: 1, label: 'פעיל בלבד'},
					{value: 0, label: 'לא פעיל'}
				]"
				:action="(value) => $refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value })"
			/>
		</div>

		<index ref="index" :data="items" v-if="items.length">
			<div slot="header" slot-scope="items" class="table header">
				<div>מעמוד</div>
				<div>קוד סטטוס</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item" :class="{ hide: item.$filtered }">
				<div class="title">
					<span>{{ item.fromUrl }}</span>
				</div>
				<div class="status">
					<span>{{ item.code }}</span>
				</div>

				<div class="actions" >
					<form-toggle name="active" label="" v-model="items[item.$index].active" @toggle="toggle(item)"/>
					<div class="button" data-icon="create" @click="goTo('redirects/edit', {id: item._id})">ערוך</div>
				</div>
			</div>
		</index>


	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: []
		}
	},
	mounted(){

		this.getData();
	},
	methods: {
		getData(){
			this.$models.dashboard.redirect.all(this.$route.params.store)
			.then((res) => {

				if(res.data) this.items = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			});
		},
		goTo(name, page = null){
			if(page){
				let params = {store: this.$route.params.store}
				Object.assign(params, page);
				return this.$router.push({ name, params }).catch(() => {});
			}

			this.$router.push({ name }).catch(() => {});
		},
		toggle(page){
			this.$models.dashboard.redirect[page.active ? 'remove' : 'restore'](this.$route.params.store, page._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	}
}
</script>
