<template>
	<div class="form-field checkbox">
		<label>{{ label }}</label>
		<div class="options">
			<template v-for="option in options">
				<div class="option" @click="select(option)" :class="{ active: active(option), disabled: option.disabled }">
					<i v-if="option.icon && option.icon[0] == '#'" :style="`background: ${option.icon}`"></i>
					<i v-else-if="option.icon && option.icon.includes('http')" :style="`background-image: url('${option.icon}')`"></i>
					<i v-else-if="option.icon" :data-icon="option.icon" class="material-icons"></i>
					{{ option.label }}
				</div>


				<form-checkbox v-if="option.sub" class="nested" :name="name" label="" v-model="input" :options="option.sub" />
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: { type: String, required: true },
		label: { type: String, required: true },
		value: { type: Array, required: true },
		options: { type: Array, required: true }
	},
	methods: {
		active(option){
			return this.input.indexOf(option.value) > -1;
		},
		select(option){
			let index = this.input.indexOf(option.value);

			if(index > -1){
				return this.input.splice(index, 1);
			}

			this.input.push(option.value);
		}
	},
	computed: {
		input: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			}
		}
	}
}
</script>
