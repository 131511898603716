import Vue from 'vue';
Vue.component('alert', require('./alert').default);
Vue.component('falsh', require('./falsh').default);

if(document.getElementById('alerts')){
	window.VueAlerts = new Vue({
		name: 'Alerts',
		methods: {
			alert(params){
				let defaults = {
					title: 'הודעה',
					text: 'טקסט',
					icon: 'notification_important',
					buttons: ["אישור"]
				}

				return this.$refs.alert.init(Object.assign(defaults, params));
			},
			flash(params){
				let defaults = {
					text: 'הודעה',
					icon: 'notification_important',
					time: 3000,
					closeable: true
				}

				this.$refs.flash.init(Object.assign(defaults, params));
			}
		}
	}).$mount('#alerts');
}
