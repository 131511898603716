<template>
	<div class="layout forms discount" :class="{ loading }">

		<div class="back" @click="$router.push({ name: 'inventory', params:{store: $route.params.store}})"></div>
		<div class="title">עריכות מבצעים</div>
		<div class="tabs">
			<div data-icon="card_giftcard" :class="{active: state.tab == 'reward'}"
				@click="$router.push({query: {tab: 'reward'}})">מה הלקוח מקבל?</div>
			<div data-icon="gps_fixed" :class="{active: state.tab == 'query'}"
				@click="$router.push({query: {tab: 'query'}})">עבור איזה מוצרים המבצע תקף?</div>
			<div data-icon="rule" :class="{active: state.tab == 'limitation'}"
				@click="$router.push({query: {tab: 'limitation'}})">מתי המבצע תקף?</div>
			<div data-icon="class" :class="{active: state.tab == 'page'}"
				@click="$router.push({query: {tab: 'page'}})">איך המבצע יראה ללקוחות?</div>
		</div>
		<div class="actions">
			<div data-icon="save" @click="save">שמירה</div>
		</div>

		<transition name="fade" mode="out-in">
			<div class="container reward" key="reward" :class="{active: (state.tab == 'reward')}" v-if="state.tab == 'reward'">
				<div class="data reward" animated>
					<div class="options">
						<span data-icon="card_giftcard">מה הלקוח מקבל?</span>
					</div>
					<div class="content">
						<form-select name="reward-type" label="בחר תמורה ללקוח" v-model="type"
							:options="[
								{ value: 'discount', label: 'הנחה בשקלים'},
								{ value: 'precentage', label: 'הנחה באחוזים'},
								{ value: 'fixed', label: 'מחיר קבוע'}
							]"/>

						<form-numbers name="reward-discount" label="סכום הנחה" v-model="model.reward.discount" clear v-if="type == 'discount'" />
						<form-numbers name="reward-precentage" label="אחוזי הנחה" v-model="model.reward.precentage" clear v-else-if="type == 'precentage'" />
						<form-numbers name="reward-fixed" label="מחיר קבוע" v-model="model.reward.fixed" clear v-else-if="type == 'fixed'" />
					</div>
				</div>
				<div class="data reward-query-toggle" animated>
					<div class="content">
						<div class="actions">
							<div class="action" :class="{active: !model.reward.query}" @click="$delete(model.reward, 'query')" data-icon="published_with_changes">
								<span class="title">עפ״י הפריטים המשתתפים במבצע</span>
								<div class="description">הפריטים שעליהם חל המבצע זהים לפריטים המשתתפים במבצע.</div>
							</div>
							<div class="action" :class="{active: model.reward.query}" @click="$set(model.reward, 'query', {})" data-icon="add_task">
								<span class="title">הגדרת פרטים בהנחה</span>
								<div class="description">הגדרת פריטים שעליהם יחול המבצע במידה והפריטים המשתתפים במבצע קיימים בעגלה.</div>
							</div>
						</div>
					</div>
				</div>
				<div class="data reward-query-results" v-if="model.reward.query" animated>
					<div class="content">
						<query ref="query"
							:query="model.reward.query"
							:sort="{}"
							:search="['_id', 'title']"
							:uncache="['products']"
							:load="this.loadQuery.bind(this)"
						>
							<query-products slot="filters" />
							<query-price slot="filters" />
							<query-categories slot="filters" />
							<query-quantity slot="filters" />
							<query-properties slot="filters" />

							<template slot="index" slot-scope="item">
								<div class="checkbox" v-if="model.reward.query.products">
									<input type="checkbox" :value="item._id" v-model="model.reward.query.products">
									<label></label>
								</div>

								<div><span>{{ item.title }}</span></div>
								<div><span>₪{{ item.price }}</span></div>
								<div><span>{{ (! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי')) }}</span></div>

							</template>
						</query>
					</div>
				</div>
			</div>

			<div class="container query" key="query" :class="{active: (state.tab == 'query')}" v-if="state.tab == 'query'">
				<div class="data query-buttons" animated>
					<div class="options">
						<span data-icon="gps_fixed">עבור איזה מוצרים המבצע תקף?</span>
					</div>
					<div class="content">
						<div class="autogrid">
							<div class="button large" data-icon="check_box" @click="setQueryTemplate('all')">כל המוצרים</div>
							<div class="button large" data-icon="shopping_bag" @click="setQueryTemplate('best')">10 המוצרים הכי נמכרים</div>
							<div class="button large" data-icon="favorite" @click="setQueryTemplate('favorites')">10 המוצרים הכי אהובים</div>
							<div class="button large" data-icon="fiber_new" @click="setQueryTemplate('recents')">10 המוצרים הכי חדשים</div>
						</div>
					</div>

				</div>
				<div class="data query-results" animated>
					<div class="content">
						<query ref="query" :query="model.query" :sort="model.sort" :limit="model.limit" priority
							:search="['_id', 'title']" :uncache="['products']"
							:load="this.loadQuery.bind(this)"
						>
							<query-products slot="filters" />
							<query-price slot="filters" />
							<query-categories slot="filters" />
							<query-quantity slot="filters" />
							<query-properties slot="filters" />

							<template slot="index" slot-scope="item">
								<div class="checkbox" v-if="model.query.products">
									<input type="checkbox" :value="item._id" v-model="model.query.products">
									<label></label>
								</div>

								<div><span>{{ item.title }}</span></div>
								<div><span>₪{{ item.price }}</span></div>
								<div><span>{{ (! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי')) }}</span></div>

							</template>
						</query>
					</div>
				</div>
			</div>
			<div class="container limitation" key="limitation" :class="{active: (state.tab == 'limitation')}" v-if="state.tab == 'limitation'">
				<div class="data dates" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byDate}" @click.self="byDate = !byDate">
								<span class="title">עפ״י תאריכים</span>
								<div class="description">הגבל לפי תאריכים</div>
								<div class="form">
									<form-toggle label="" name="bydate_toggle" v-model="byDate" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byDate">
							<form-datepicker name="limitation-from-date" label="החל מתאריך" v-model="model.limitation.fromDate" :disabled-dates="{
								to: yesterday,
								from: new Date(model.limitation.toDate.getTime() - (24 * 60 * 60 * 1000))
							}" />
							<form-datepicker name="limitation-to-date" label="ועד לתאריך" v-model="model.limitation.toDate" :disabled-dates="{
								to: new Date(model.limitation.fromDate.getTime() + (24 * 60 * 60 * 1000))
							}" />
						</div>
					</div>
				</div>
				<div class="data quantity" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byQuantity}" @click.self="byQuantity = !byQuantity">
								<span class="title">עפ״י כמות פריטים</span>
								<div class="description">הגבל לפי כמות</div>
								<div class="form">
									<form-toggle label="" name="quantity_toggle" v-model="byQuantity" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byQuantity">
							<form-numbers name="limitation-from-quantity" label="בקניה מעל מס׳ פרטים" v-model="model.limitation.fromQuantity" />
							<form-numbers name="limitation-gift-quantity" label="פריטים במבצע" v-model="model.limitation.giftQuantity" clear />
						</div>
					</div>
				</div>
				<div class="data price" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="rule"
								:class="{active: byPrice}" @click.self="byPrice = !byPrice">
								<span class="title">עפ״י סכום העגלה</span>
								<div class="description">הגבל לפי סכום העגלה</div>
								<div class="form">
									<form-toggle label="" name="quantity_toggle" v-model="byPrice" />
								</div>
							</div>
						</div>
						<div class="form-group" v-if="byPrice">
							<form-numbers name="limitation-from-price" label="בקניה מעל סכום" v-model="model.limitation.fromPrice" clear />
							<form-numbers name="limitation-to-price" label="בקניה עד סכום" v-model="model.limitation.toPrice" clear />
						</div>
					</div>
				</div>
			</div>
			<div class="container page" key="page" :class="{active: (state.tab == 'page')}" v-if="state.tab == 'page'">
				<div class="data details" animated>
					<div class="content">
						<div class="actions">
							<div class="action" data-icon="web"
								:class="{active: model.show}" @click.self="model.show = !model.show">
								<span class="title">מבצע פעיל</span>
								<div class="description">מבצע פעיל ויוצג באתר</div>
								<div class="form">
									<form-toggle label="" name="page_toggle" v-model="model.show" />
								</div>
							</div>
						</div>
						<div class="form-group">
							<form-input name="page-title" label="כותרת לבמצע" v-model="model.title" />
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	data(){
		return {
			state: {
				tab: ''
			},
			loading: true,
			model: this.$models.inventory.discount.model()
		}
	},
	watch:{
		'$route.query.tab': 'setTab'
	},
	mounted(){
		this.getData().then(() => {
			this.$root.loading = false;
			this.setTab(this.$route.query.tab || 'reward')
		});
	},
	methods: {
		setTab(tab){
			if(['reward', 'query', 'limitation', 'page'].includes(tab)) this.state.tab = tab;
		},
		getData(){
			if(! this.$route.params.discount) return Promise.resolve();

			return this.$models.inventory.discount.get(this.$route.params.store, this.$route.params.discount)
			.then(res => {
				this.model = res.data;

				this.$root.loading = false;
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		loadQuery(query, sort, limit){
			let q = Object.assign(JSON.parse(JSON.stringify(query)), {products: undefined});
			return this.$models.inventory.product.query(this.$route.params.store, { query: q, sort, limit })
		},
		setQueryTemplate(template){
			let templates = {
				'all': {
					query: {},
					sort: {},
					limit: {}
				},
				'best': {
					query: {},
					sort: {
						priority: { type: 'orders', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				},
				'favorites': {
					query: {},
					sort: {
						priority: { type: 'favorites', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				},
				'recents': {
					query: {},
					sort: {
						priority: { type: 'recents', period: 'w', limit: '10' }
					},
					limit: { limit: '10' }
				}
			}

			this.model.query = templates[template].query;
			this.model.sort = templates[template].sort;
			this.model.limit = templates[template].limit;

			this.$refs.query.unBlockFilters();
		},
		save(){
			this.$root.loading = true;

			let request = null;

			if(this.$route.params.discount){
				request = this.$models.inventory.discount.update(this.$route.params.store, this.$route.params.discount, this.model)
			}
			else{
				request = this.$models.inventory.discount.create(this.$route.params.store, this.model)
			}

			request.then(() => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				if(! this.$route.params.discount){
					return this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

				this.$root.loading = false;
			}).catch(() => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.$root.loading = false;
			});
		}
	},
	computed: {
		yesterday(){
			return new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
		},
		type: {
			get(){
				return this.model.reward.type;
			},
			set(type){
				this.model.reward.type = type;

				this.$set(this.model.reward, type, '');
			}
		},
		byDate: {
			get(){
				return Boolean(this.model.limitation.fromDate != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromDate');
					this.$delete(this.model.limitation, 'toDate');

					return;
				}

				this.$set(this.model.limitation, 'fromDate', new Date())
				this.$set(this.model.limitation, 'toDate', new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000)))
			}
		},
		byQuantity: {
			get(){
				return Boolean(this.model.limitation.fromQuantity != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromQuantity');
					this.$delete(this.model.limitation, 'giftQuantity');

					return;
				}

				this.$set(this.model.limitation, 'fromQuantity', "1")
				this.$set(this.model.limitation, 'giftQuantity', "1")
			}
		},
		byPrice: {
			get(){
				return Boolean(this.model.limitation.fromPrice != undefined);
			},
			set(value){
				if(! value){
					this.$delete(this.model.limitation, 'fromPrice');
					this.$delete(this.model.limitation, 'toPrice');

					return;
				}

				this.$set(this.model.limitation, 'fromPrice', "100")
				this.$set(this.model.limitation, 'toPrice', "200")
			}
		}
	}
}
</script>
