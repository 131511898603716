const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Users extends Model{

	constructor(){
		super();
	}

	model() {
		return {
			store: ""
		}
	}

	all(){
		return new Promise((resolve, reject) => {
			this.$api.get('master/users').then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(id){
		return new Promise((resolve, reject) => {
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.get('master/users/'+id).then(response => {
				let result = { response };

				result.data = response.data;

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	update(payload){
		return new Promise((resolve, reject) => {
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				name: String,
				phone: String,
				email: String,
				stores: [{
					store: String,
					label: String
				}]
			});


			this.$api.post('master/users/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Users()
