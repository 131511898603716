<template>
	<div class="table">
		<div class="title">{{ word.dictionary[code].default }}</div>
		<div class="words" v-for="vars in variations">
			<form-textarea name="name" :label="vars.label"
				v-if="word.variations.includes(vars.value)"
				v-model="inputs[vars.value]"
				:placeholder="word.dictionary[code][vars.value]"
				:autocomplete="word.params || []"
			/>
		</div>
		<div class="action">
			<div class="button" data-icon="save" @click="saveRow"></div>
		</div>
	</div>
</template>

<script>
export default {
	props:{
		dictionary: {type: String, required: true},
		id: {type: String, required: true},
		code: {type: String, required: true},
		word: {type: Object, required: true},
		variations: {type: Array, required: true},
		value: {type: Object, required: true}
	},
	methods:{
		saveRow(){
			this.$models.dashboard.dictionary.updateWord(this.$route.params.store, this.dictionary, {key: this.word.key, words: this.value[this.word.key]})
			.then(result => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});
			})
			.catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			})
		}
	},
	computed: {
		inputs() {
			return this
		},
		default: {
			get(){
				if(! (this.value[this.word.key] && this.value[this.word.key].default)) return '';

				return this.value[this.word.key].default
			},
			set(value){
				if(! this.value[this.word.key]) return this.$set(this.value, this.word.key, {default:value})

				this.$set(this.value[this.word.key], 'default', value);
			}
		},
		verb: {
			get(){
				if(! (this.value[this.word.key] && this.value[this.word.key].verb)) return '';

				return this.value[this.word.key].verb
			},
			set(value){
				if(! this.value[this.word.key]) return this.$set(this.value, this.word.key, {verb:value})

				this.$set(this.value[this.word.key], 'verb', value);
			}
		},
		male: {
			get(){
				if(! (this.value[this.word.key] && this.value[this.word.key].male)) return '';

				return this.value[this.word.key].male
			},
			set(value){
				if(! this.value[this.word.key]) return this.$set(this.value, this.word.key, {male:value})

				this.$set(this.value[this.word.key], 'male', value);
			}
		},
		female: {
			get(){
				if(! (this.value[this.word.key] && this.value[this.word.key].female)) return '';

				return this.value[this.word.key].female
			},
			set(value){
				if(! this.value[this.word.key]) return this.$set(this.value, this.word.key, {female:value})

				this.$set(this.value[this.word.key], 'female', value);
			}
		}
	}
}
</script>
