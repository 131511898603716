<template>
	<div class="layout branches-form" :class="{ loading }">

		<div class="top fixed">
			<div class="title">
				<div class="back" @click="$router.back()"></div>
				<span>{{ $route.params.branch ? 'עריכת' : 'יצירת' }} סניף</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form">
			<form-group name="details" title="פרטי הסניף" style="z-index: 4">
				<form-input name="title" label="שם" v-model="model.title" />
				<form-toggle name="allow_pickup" label="אפשר איסוף עצמי" v-model="model.allow_pickup" />
				<form-toggle name="active" label="פעיל" v-model="model.active" />
			</form-group>
			<form-group name="location" style="z-index: 3">
				<form-input name="city" label="עיר" v-model="model.city" />
				<form-input name="address" label="כתובת" v-model="model.address" />
				<form-input name="phone" label="טלפון" v-model="model.phone" />
			</form-group>
			<form-group name="image" style="z-index: 2">
				<form-file name="image" label="תמונה" v-model="image" />
				<form-area v-if="preview">
					<div class="card">
						<div class="image" :style="`background-image: url('${preview}')`"></div>
					</div>
				</form-area>
			</form-group>
			<form-group name="description" style="z-index: 1">
				<form-editor name="description" label="תיאור" v-model="model.description" />
			</form-group>

		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			model: this.$models.inventory.branch.model()
		}
	},
	mounted(){
		if(this.$route.params.branch){
			this.getData();
		}else{
			this.loading = false;
		}
	},
	methods:{
		getData(){
			this.$models.inventory.branch.get(this.$route.params.store, this.$route.params.branch)
			.then(res => {
				this.model = res.data;

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;

			let request = null;
			if(this.$route.params.branch){
				request = this.$models.inventory.branch.update(this.$route.params.store, this.$route.params.branch, this.model)
			} else {
				request = this.$models.inventory.branch.create(this.$route.params.store, this.model)
			}

			request.then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.branch){
					this.$router.push({ name: 'inventory', params:{store: this.$route.params.store}});
				}

			}).catch(error => {
				this.loading = false;

				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	},
	computed:{
		image:{
			get(){
				return [this.model.image];
			},
			set(value){
				this.model.image = value[0];
			}
		},
		preview(){
			if(! this.model.image)
				return false;

			if(typeof this.model.image == 'string')
				return this.model.image;

			return URL.createObjectURL(this.model.image);
		},
	}
}
</script>
