<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'גלריה', icon: 'receipt', groups: ['global', 'image', 'preview', ] },
		'visual': { label: 'תצוגה', icon: 'design_services', groups: ['layout', 'gallery_settings'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}" class="info-box">
		<form-group style="z-index:3" name="global">
			<form-toggle v-if="this.global" name="global" v-model="toggleGlobal" label="משוך גלריה מעמוד"></form-toggle>
		</form-group>
		<template v-if="! toggleGlobal">
			<form-group style="z-index:2" name="image">
				<form-file name="images" label="בחר תמונות" v-model="images" multiple />
			</form-group>
			<form-group style="z-index: 1" name="preview">
				<images-preview v-model="images" @delete="removeImage" :form="[{
					title: { type: 'form-input', label: 'כותרת', default: '' }
				}]" />
			</form-group>
		</template>

		<form-group style="z-index:1" name="layout">
			<form-area class="layout-select">
				<div class="layout-card" v-for="l in layouts" @click="layout = l.name"
					:class="{active: layout == l.name}" style="width:320px;height:240px;">
					<i v-for="i in l.layout" :style="i"></i>
				</div>
			</form-area>
		</form-group>

		<form-group style="z-index:2" name="gallery_settings" v-if="layout == 'slider'">
			<form-toggle name="autoplay" label="אוטומטי" v-model="input.visual.autoplay" />
			<form-toggle name="loop" label="לולאה" v-model="input.visual.loop" />
			<form-select name="interval" label="זמן החלפת תמונות" v-model="input.visual.interval" :options="[
					{ value: '1', label: 'מהיר' },
					{ value: '3', label: 'בינוני' },
					{ value: '6', label: 'איטי' },
				]"/>
			<form-toggle name="arrows" label="חצים" v-model="input.visual.arrows" />
			<form-toggle name="dots" label="נקודות" v-model="input.visual.dots" />
			<form-toggle name="thumbs" label="תמונות ממוזערות" v-model="thumbs" />
			<form-select name="thumbsPosition" label="מיקום תמונות ממוזערות" v-if="thumbs" v-model="input.visual.thumbsPosition"
				:options="[
					{value: 'top', label: 'למעלה'},
					{value: 'left', label: 'שמאל'},
					{value: 'bottom', label: 'למטה'},
					{value: 'right', label: 'ימין'}
				]" />
			<form-area></form-area>
		</form-group >


		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { makeHash } from '@/bootstrap/helpers';

export default {
	title: "גלריה",
	defaults: {
		visual: {
			layout: 'slider',
			autoplay: true,
			loop: true,
			arrows: true,
			dots: true,
			thumbs: true,
			thumbsPosition: 'bottom',
			interval: '3',
		},
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true },
		global: { type: Boolean, default: false }
	},
	methods: {
		bindFiles(data){
			if(! (data.images)) return {};
			let files = {};

			data.images.map(image => {
				if(! image.file) return;

				let key = makeHash();

				files[key] =  image.file;

				image._file = key;
			})

			return files;
		},
		removeImage(index){
			this.input.images.splice(index, 1);
		}
	},
	computed: {
		images:{
			get(){
				return this.input.images ? this.input.images : [];
			},
			set(value){
				if(! value) return this.$delete(this.input, 'images');

				this.$set(this.input, 'images', value);
			}
		},
		layout:{
			get(){
				return this.input.visual.layout;
			},
			set(value){
				let layout = { layout: value }

				if(value == 'slider'){
					layout.autoplay = true;
					layout.loop = true;
					layout.arrows = true;
					layout.dots = true;
					layout.thumbs = true;
					layout.thumbsPosition = 'bottom';
					layout.interval = '3';
				}

				this.$set(this.input, 'visual', layout);
			}
		},
		thumbs: {
			get(){
				return this.input.visual.thumbs != undefined;
			},
			set(value){
				if(value){
					this.$set(this.input.visual, 'thumbs', true);
					this.$set(this.input.visual, 'thumbsPosition', 'right');
					return;
				}

				this.$delete(this.input.visual, 'thumbs');
				this.$delete(this.input.visual, 'thumbsPosition');
			}
		},
		toggleGlobal: {
			get(){
				return (this.global && this.input.global != undefined);
			},
			set(value){
				if(value) return this.$set(this.input, 'global', true);

				this.$delete(this.input, 'global');
			}
		},
		layouts(){
			return [
				{
					name: 'slider',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: (this.input.visual.thumbs ? '180px' : '220px'),
							top: '10px', left: '10px', right: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '120px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '150px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '180px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '60px', height: '40px',
							bottom: '5px', left: '10px',
							zIndex: 2, opacity: (this.input.visual.thumbs ? 1 : 0)
						},
						{
							width: '60px', height: '40px',
							bottom: '5px', left: '80px',
							zIndex: 2, opacity: (this.input.visual.thumbs ? 1 : 0)
						},
						{
							width: '60px', height: '40px',
							bottom: '5px', left: '150px',
							zIndex: 2, opacity: (this.input.visual.thumbs ? 1 : 0)
						},
						{
							width: '60px', height: '40px',
							bottom: '5px', left: '220px',
							zIndex: 2, opacity: (this.input.visual.thumbs ? 1 : 0)
						},
						{
							width: '20px', height: '40px',
							bottom: '5px', left: '290px',
							zIndex: 2, opacity: (this.input.visual.thumbs ? 0.4 : 0)
						},
						{
							width: '20px', height: '20px',
							top: '100px', left: '20px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.arrows ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							top: '100px', right: '20px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.arrows ? 1 : 0)
						}
					]
				},
				{
					name: 'bigThumbs',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '220px', width: '140px',
							top: '10px', left: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '70px',
							top: '10px', right: '90px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '70px',
							top: '10px', right: '10px',
							zIndex: 2, opacity: 1
						},{
							height: '100px', width: '70px',
							bottom: '10px', right: '90px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '70px',
							bottom: '10px', right: '10px',
							zIndex: 2, opacity: 1
						},
					]
				},
				{
					name: 'normal',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '100px', width: '140px',
							top: '10px', left: '10px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '140px',
							top: '10px', right: '10px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '140px',
							left: '10px',bottom: '10px',
							zIndex: 2, opacity: 1
						},
						{
							height: '100px', width: '140px',
							right: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						}
					]
				}
			]
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
