<template>
	<div class="layout discounts-quick" :class="{ loading }">

		<div class="top fixed">
			<div class="title">
				<div class="back" @click="$router.back()"></div>
				<span>הוספת מבצע מהיר</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue class="toggle-tabs" ref="form" :tabs="{
			'discount-total-above': { label: 'הנחה בקניה מעל סכום', icon: 'shopping_cart', groups: ['discount-total-above', 'general'] },
			'cashback': { label: 'שובר קאשבק בקניה מעל סכום', icon: 'published_with_changes', groups: ['cashback', 'general'] },
			'free-shipping': { label: 'משלוח חינם בקניה מעל סכום', icon: 'local_shipping', groups: ['free-shipping', 'general'] },
			'1+1-by-category': { label: '1+1 על קטגוריות נבחרות', icon: 'category', groups: ['1+1-by-category', 'general'] },
			'abounded-cart': { label: 'מבצע על עגלה נטושה', icon: 'remove_shopping_cart', groups: ['abounded-cart', 'general'] },
		}">

			<form-group name="general" v-if="! loading">
				<form-input name="title" label="כותרת מבצע (לתצוגה בלבד)" v-model="title" disabled />
			</form-group>

			<form-group name="discount-total-above">
				<form-select name="reward-type" label="בחר תמורה ללקוח" v-model="type"
					:options="[
						{ value: 'discount', label: 'הנחה בשקלים'},
						{ value: 'precentage', label: 'הנחה באחוזים'},
					]"/>
				<form-numbers v-if="type == 'discount'" name="discount" label="סכום הנחה בשקלים (X)" v-model="model.reward.discount" clear point />
				<form-numbers v-else-if="type == 'precentage'" name="precentage" label="סכום הנחה באחוזים (X)" v-model="model.reward.precentage" clear point />
				<form-numbers name="limitation-price" label="תקף בקניה מעל (Y)" v-model="model.limitation.fromPrice" clear point v-if="model.limitation.fromPrice != undefined" />
			</form-group>

			<form-group name="cashback">
				<form-numbers v-if="type == 'coupon'" name="coupon" label="סכום שובר (X)" v-model="model.reward.coupon" clear point />
				<form-numbers name="limitation-price" label="תקף בקניה מעל (Y)" v-model="model.limitation.fromPrice" clear point v-if="model.limitation.fromPrice != undefined" />
			</form-group>

			<form-group name="free-shipping">
				<form-numbers name="limitation-price" label="תקף בקניה מעל (X)" v-model="model.limitation.fromPrice" clear point v-if="model.limitation.fromPrice != undefined" />
			</form-group>

			<form-group name="1+1-by-category">
				<form-checkbox name="categories" label="בחר קטגוריות משתתפות במבצע (X)" v-model="model.query.categories" :options="categories" v-if="model.query.categories != undefined" />
			</form-group>

			<form-group name="abounded-cart">
				<form-numbers v-if="type == 'coupon'" name="coupon" label="סכום שובר (X)" v-model="model.reward.coupon" clear point />
				<form-numbers name="limitation-price" label="תקף לגעלה מעל סכום (Y)" v-model="model.limitation.fromPrice" clear point v-if="model.limitation.fromPrice != undefined" />
			</form-group>
		</form-vue>

	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			model: this.$models.inventory.discount.model(),
			categories: []
		}
	},
	mounted(){
		this.setModel();
	},
	watch:{
		'$route.hash'(){
			this.loading = true;

			setTimeout(() => this.setModel(), 500);
		}
	},
	methods: {
		setModel(){
			this.model = this.$models.inventory.discount.model();

			let quick = this.$refs.form.activeTab;

			if(quick == 'discount-total-above'){
				this.type = 'discount';
				this.model.reward.discount = '';
				this.$set(this.model.limitation, 'fromPrice', '');
			}
			else if(quick == 'cashback'){
				this.type = 'coupon';
				this.model.reward.coupon = '';
				this.$set(this.model.limitation, 'fromPrice', '');
			}
			else if(quick == 'free-shipping'){
				this.type = 'free-shipping';
				this.$set(this.model.limitation, 'fromPrice', '');
			}
			else if(quick == '1+1-by-category'){
				this.type = 'free-item';
				this.$set(this.model.query, 'categories', []);

				return this.loadCategories();
			}
			else if(quick == 'abounded-cart'){
				this.type = 'coupon';
				this.model.reward.coupon = '';

				this.$set(this.model.limitation, 'fromPrice', '');
			}

			this.loading = false;
		},
		loadCategories(){
			this.$models.inventory.category.options(this.$route.params.store).then(res => {
				this.categories = res.data;
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;

			let error = null;

			if(this.model.query.categories && this.model.query.categories.length < 1){
				error = "יש לבחור קטגוריה אחת לפחות";
			}
			else if(this.model.limitation.fromPrice != undefined && this.model.limitation.fromPrice.length < 1){
				error = "חובה להזין מחיר";
			}
			else if(this.model.reward.discount != undefined && this.model.reward.discount.length < 1){
				error = "חובה להזין סכום הנחה";
			}
			else if(this.model.reward.precentage != undefined && this.model.reward.precentage.length < 1){
				error = "חובה להזין אחוזי הנחה";
			}
			else if(this.model.reward.coupon != undefined && this.model.reward.coupon.length < 1){
				error = "חובה להזין סכום לקופון";
			}

			if(error){
				this.loading = false;
				return VueAlerts.alert({
					title: 'לא ניתן לייצר מבצע',
					text: error
				});
			}

			this.model.title = this.title;

			this.$models.inventory.discount.create(this.$route.params.store, this.model).then(res => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.$router.push({ name: 'inventory/discounts', params:{store: this.$route.params.store}});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});

				this.loading = false;
			})
		}
	},
	computed: {
		type: {
			get(){
				return this.model.reward.type;
			},
			set(type){
				this.model.reward = { type };

				if(type == "free-shipping") return;

				if(type == "free-item"){
					this.$set(this.model.reward, 'getFree', '1');
					this.$set(this.model.reward, 'buyFree', '1');
					return;
				}

				this.$set(this.model.reward, type, '');
			}
		},
		categoriesNames(){
			let categories = {},
				catMap = (category) => {
					categories[category.value] = category.label;

					if(category.sub){
						category.sub.forEach(catMap);
					}
				}

			this.categories.forEach(catMap);

			return categories;
		},
		title(){
			let quick = this.$refs.form.activeTab;

			if(quick == 'discount-total-above'){
				return `${this.model.reward[this.type] || 'X'} ${(this.type == "discount" ? "שקלים" : "אחוזים")} הנחה בקניה מעל ${this.model.limitation.fromPrice || 'Y'} שקלים`;
			}
			else if(quick == 'cashback'){
				return `שובר קאשבק על סך ${this.model.reward.coupon || 'X'} שקלים בקניה מעל ${this.model.limitation.fromPrice || 'Y'} שקלים`;
			}
			else if(quick == 'free-shipping'){
				return `משלוח חינם בקניה מעל ${this.model.limitation.fromPrice || 'X'} שקלים`;
			}
			else if(quick == '1+1-by-category'){
				return `1+1 על מוצרים ב${this.model.query.categories.length > 1 ? 'קטגוריות' : 'קטגורית'} ${this.model.query.categories.length < 1 ? 'X' : this.model.query.categories.map(cat => this.categoriesNames[cat]).join(', ')}`;
			}
			else if(quick == 'abounded-cart'){
				return `קופון בסך ${this.model.reward.coupon || 'X'} שקלים הנחה לעגלה נטושה מעל ${this.model.limitation.fromPrice || 'Y'} שקלים`;
			}
		}
	}
}
</script>
