<template>
	<div class="query-filter price" :class="{ active }">
		<div class="header">
			<span data-icon="vertical_align_center">סנן לפי מחיר</span>
			<form-toggle name="" label="" v-model="active" />
		</div>
		<div class="box" v-if="active">
			<div class="group">
				<form-numbers name="" label="החל ממחיר:" v-model="input[0]" clear />
				<form-numbers name="" label="עד מחיר:" v-model="input[1]" clear />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return {
			cache: null,
		}
	},
	computed: {
		active:{
			get(){
				return Boolean(this.input != undefined);
			},
			set(val){
				if(val){
					return this.input = this.cache || ['', '']
				}

				this.cache = this.input;
				this.input = undefined
			}
		},
		input: {
			get() {
				return this.$parent.query.price;
			},
			set(val) {
				this.$set(this.$parent.query, 'price', val);
			}
		}
	}
}
</script>
