const Model = require('@/models/model');
const Cast = require('@/bootstrap/Cast');

class Discount extends Model{

	constructor(){
		super();
	}

	model(){
		return {
			title: '',
			active: true,
			show: true,
			query: {},
			sort: {},
			limit: {},
			reward: {
				type: 'discount',
				discount: ''
			},
			limitation: {
			}
		};
	}

	all(store){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));

			let key = store + '_all';
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/discounts').then(response => {
				let result = { response };

				result.data = Cast(response.data, [{
					_id: String,
					title: String,
					query: {
						price: [Number],
						products: [String],
						quantity: [Number],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					},
					sort: {
						priority: {
							type: String,
							period: String,
							limit: Number
						},
						static:[String]
					},
					limit: Number,
					reward: {
						type: String,
						discount: Number,
						precentage: Number,
						fixed: Number,
						query: {
							price: [Number],
							products: [String],
							quantity: [Number],
							categories: [String],
							properties: [{
								key: String, value: String
							}]
						}
					},
					limitation:{
						fromDate: Date,
						toDate: Date,
						fromQuantity: Number,
						giftQuantity: Number,
						fromPrice: Number,
						toPrice: Number
					},
					show: Boolean,
					active: Boolean
				}]);

				resolve(result);

				this.$cache.set(key, result);

			}).catch(error => reject(error));
		});
	}

	get(store, id){
		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			let key = [store, id].join('_');
			let cache = this.$cache.get(key);
			if(cache) return resolve(cache);

			this.$api.get(store+'/inventory/discounts/'+id).then(response => {
				let result = { response };

				result.data = Cast(response.data, {
					_id: String,
					title: String,
					query: {
						price: [String],
						products: [String],
						quantity: [String],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					},
					sort: {
						priority: {
							type: String,
							period: String,
							limit: String
						},
						static:[String]
					},
					limit: String,
					reward: {
						type: String,
						discount: String,
						precentage: String,
						fixed: String,
						query: {
							price: [Number],
							products: [String],
							quantity: [Number],
							categories: [String],
							properties: [{
								key: String, value: String
							}]
						}
					},
					limitation:{
						fromDate: Date,
						toDate: Date,
						fromQuantity: String,
						giftQuantity: String,
						fromPrice: String,
						toPrice: String
					},
					show: Boolean,
					active: Boolean
				});

				result.data.limit = result.data.limit ? { limit: result.data.limit } : {};

				if(result.data.query.price){
					result.data.query.price[0] = result.data.query.price[0] || ""
					result.data.query.price[1] = result.data.query.price[1] || ""
				}

				if(result.data.query.quantity){
					result.data.query.quantity[0] = result.data.query.quantity[0] || ""
					result.data.query.quantity[1] = result.data.query.quantity[1] || ""
				}

				if(result.data.limitation && (result.data.limitation.fromPrice || result.data.limitation.toPrice)){
					result.data.limitation.fromPrice = result.data.limitation.fromPrice || ""
					result.data.limitation.toPrice = result.data.limitation.toPrice || ""
				}

				if(result.data.limitation && (result.data.limitation.fromQuantity || result.data.limitation.giftQuantity)){
					result.data.limitation.fromQuantity = result.data.limitation.fromQuantity || ""
					result.data.limitation.giftQuantity = result.data.limitation.giftQuantity || ""
				}

				resolve(result);

				this.$cache.set(key, result);
			}).catch(error => reject(error));
		});
	}

	create(store, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				title: String,
				query: {
					price: [Number],
					products: [String],
					quantity: [Number],
					categories: [String],
					properties: [{
						key: String, value: String
					}]
				},
				sort: {
					priority: {
						type: String,
						period: String,
						limit: Number
					},
					static:[String]
				},
				limit: { limit: Number },
				reward: {
					type: String,
					discount: Number,
					precentage: Number,
					fixed: Number,
					query: {
						price: [Number],
						products: [String],
						quantity: [Number],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					}
				},
				limitation:{
					fromDate: Date,
					toDate: Date,
					fromQuantity: Number,
					giftQuantity: Number,
					fromPrice: Number,
					toPrice: Number
				},
				show: Boolean,
				active: Boolean
			});

			data.limit = (data.limit && data.limit.limit) ? data.limit.limit : undefined;

			this.$api.post(store+'/inventory/discounts/create', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	update(store, id, payload){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));
			if(! payload) return reject(new Error("Missing `payload`"));

			let data = Cast(payload, {
				_id: String,
				title: String,
				query: {
					price: [Number],
					products: [String],
					quantity: [Number],
					categories: [String],
					properties: [{
						key: String, value: String
					}]
				},
				sort: {
					priority: {
						type: String,
						period: String,
						limit: Number
					},
					static:[String]
				},
				limit: { limit: Number },
				reward: {
					type: String,
					discount: Number,
					precentage: Number,
					fixed: Number,
					query: {
						price: [Number],
						products: [String],
						quantity: [Number],
						categories: [String],
						properties: [{
							key: String, value: String
						}]
					}
				},
				limitation:{
					fromDate: Date,
					toDate: Date,
					fromQuantity: Number,
					giftQuantity: Number,
					fromPrice: Number,
					toPrice: Number
				},
				show: Boolean,
				active: Boolean
			});

			data.limit = (data.limit && data.limit.limit) ? data.limit.limit : undefined;

			this.$api.post(store+'/inventory/discounts/'+id+'/update', data)
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	remove(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/discounts/'+id+'/remove', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

	restore(store, id){
		this.$cache.clear();

		return new Promise((resolve, reject) => {
			if(! store) return reject(new Error("Missing `store`"));
			if(! id) return reject(new Error("Missing `id`"));

			this.$api.post(store+'/inventory/discounts/'+id+'/restore', {_id: id})
			.then(response => resolve(response))
			.catch(error => reject(error))
		});
	}

}

module.exports = new Discount()
