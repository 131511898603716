<template>
	<div class="form-field area">
		<div class="title" v-if="title">{{ title }}</div>
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		title: { type: String }
	}
}
</script>
