<template>
	<form-vue preventHash :tabs="{
		'general': { label: 'באנר', icon: 'receipt', groups: ['general'] },
		'visual': { label: 'תצוגה', icon: 'design_services', groups: ['layout', 'gallery'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings', 'height'] }
		}" class="dynamic">

		<form-group style="z-index:1" name="general">
			<index :data="input.banner" sort-by="title" @sort="sortElement">
				<template slot="index" slot-scope="item">
					<div class="table" style="z-index:0" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end"></div>
						<div class="image" v-if="item.image && item.image.source">
							<img :src="item.image.source">
						</div>
						<div class="info">{{ input.banner[item.$index].title }}</div>
						<div class="actions">
							<div class="button" data-icon="edit" v-if="editableBox != item.$index" @click="toggleBox(item.$index)">ערוך</div>
							<div class="button dark" data-icon="close" v-else @click="toggleBox(item.$index)">סגור</div>

							<div class="button error" v-if="input.banner.length > 1" data-icon="delete" @click="removeDynamic(item.$index)"></div>
						</div>
					</div>
					<form-group :style="`z-index:${(item.$index+1)}`" :key="item.$index" class="item" v-if="editableBox == item.$index">
						<form-group style="z-index:10">
							<form-area style="vertical-align:top;z-index:2">
								<form-file name="image" v-if="! images[item.$index].length" label="בחר תמונה" v-model="images[item.$index]" @change="setImage(item.$index)" style="min-width:320px;" />

								<images-preview v-model="images[item.$index]" @delete="removeImage(item.$index)" :form="[{
									title: { type: 'form-input', label: 'כותרת', default: '' }
								}]" />
							</form-area>
							<form-area style="vertical-align:top;z-index:1">
								<form-group style="z-index:3">
									<form-toggle name="background" label="צבע רקע" v-model="background[item.$index]" @toggle="toggleBackground(item.$index)" />
								</form-group>
								<form-group v-if="background[item.$index]" style="z-index:2">
									<form-colors name="color" label="צבע רקע" v-model="input.banner[item.$index].background.colors[0].color"></form-colors>

									<form-select name="opacity" label="שקיפות" v-model="input.banner[item.$index].background.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />

									<template v-if="input.banner[item.$index].background.type != 'full'">
										<form-colors name="color" label="צבע רקע" v-model="input.banner[item.$index].background.colors[1].color"></form-colors>

										<form-select  name="opacity" label="שקיפות" v-model="input.banner[item.$index].background.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
									</template>
								</form-group>
								<form-group v-if="background[item.$index]" style="z-index:1">
									<form-area class="layout-select">
										<div class="layout-card"
										@click="toggleBackgroundType('full', item.$index)"
										:class="{active: input.banner[item.$index].background.type == 'full'}"
										style="width:140px;height:100px;">
											<i :style="`background-color:${input.banner[item.$index].background.colors[0].color};opacity:${input.banner[item.$index].background.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
										</div>

										<div class="layout-card"
										@click="toggleBackgroundType('top', item.$index)"
										:class="{active: input.banner[item.$index].background.type == 'top'}"
										style="width:140px;height:100px;">
											<i :style="`background:${linearGradient('to top', input.banner[item.$index].background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
										</div>

										<div class="layout-card"
										@click="toggleBackgroundType('rtl', item.$index)"
										:class="{active: input.banner[item.$index].background.type == 'rtl'}"
										style="width:140px;height:100px;">
											<i :style="`background:${linearGradient('to right', input.banner[item.$index].background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
										</div>
									</form-area>
								</form-group>
							</form-area>
						</form-group>

						<form-group style="z-index:9">
							<form-toggle name="buttons" label="הגבלת רוחב" v-model="wrappers[item.$index]" @toggle="toggleWrapper(item.$index)" />
							<form-select name="position" label="מיקום התוכן" v-model="input.banner[item.$index].position" :options="[
								{ value: 'center', label: 'מרכז' },
								{ value: 'left', label: 'שמאל' },
								{ value: 'right', label: 'ימין' },
							]"/>
						</form-group>

						<form-group style="z-index:8">
							<form-toggle name="title" label="הוסף כותרת" v-model="titles[item.$index]" @toggle="toggleTitle(item.$index)" />
							<form-input name="title" label="כותרת" v-if="titles[item.$index]" v-model="input.banner[item.$index].title" style="vertical-align: top;" />

							<form-colors name="titleColor" v-if="titles[item.$index]"  label="צבע כותרת" v-model="input.banner[item.$index].titleColor" style="vertical-align: top;"></form-colors>

							<form-area></form-area>
						</form-group>
						<form-group style="z-index:7">
							<form-toggle name="contents" label="הוסף טקסט" v-model="content[item.$index]" @toggle="toggleContent(item.$index)" />
			 				<form-editor name="text" label="טקסט" v-if="content[item.$index]" v-model="input.banner[item.$index].content" />
						</form-group>
						<form-group style="z-index:6">
							<form-toggle name="link" label="הוסף קישור" v-model="links[item.$index]" @toggle="toggleLink(item.$index)" />
			 				<link-vue v-model="input.banner[item.$index].link" v-if="links[item.$index]" wrapper="form-group" />
						</form-group>
						<form-group style="z-index:5">
							<form-toggle name="buttons" label="הוסף כפתור" v-model="buttons[item.$index]" @toggle="toggleButton(item.$index)" />
						</form-group>

						<template v-if="buttons[item.$index]">
							<form-group style="z-index:4">
				 				<form-input name="button" label="טקסט על הכפתור" v-model="input.banner[item.$index].button.text" />
							</form-group>
							<form-group name="fontColor" style="z-index:3">
								<form-colors name="color" label="צבע פונט" v-model="input.banner[item.$index].button.color" />
							</form-group>
							<form-group  name="background" style="z-index:2" v-if="input.banner[item.$index].button.background.colors">
								<form-colors name="color" label="צבע רקע" v-model="input.banner[item.$index].button.background.colors[0].color"></form-colors>

								<form-select name="opacity" label="שקיפות" v-model="input.banner[item.$index].button.background.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />

								<template v-if="input.banner[item.$index].button.background.type != 'full'">
									<form-colors name="color" label="צבע רקע" v-model="input.banner[item.$index].button.background.colors[1].color"></form-colors>

									<form-select  name="opacity" label="שקיפות" v-model="input.banner[item.$index].button.background.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
								</template>
							</form-group>
							<form-group  name="background" style="z-index:1">
								<form-area class="layout-select">
									<div class="layout-card"
									@click="toggleButtonBackgroundType('full', item.$index)"
									:class="{active: input.banner[item.$index].button.background.type == 'full'}"
									style="width:140px;height:100px;">
										<i :style="`background-color:${input.banner[item.$index].button.background.colors[0].color};opacity:${input.banner[item.$index].button.background.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>

									<div class="layout-card"
									@click="toggleButtonBackgroundType('top', item.$index)"
									:class="{active: input.banner[item.$index].button.background.type == 'top'}"
									style="width:140px;height:100px;">
										<i :style="`background:${linearGradient('to top', input.banner[item.$index].button.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>

									<div class="layout-card"
									@click="toggleButtonBackgroundType('rtl', item.$index)"
									:class="{active: input.banner[item.$index].button.background.type == 'rtl'}"
									style="width:140px;height:100px;">
										<i :style="`background:${linearGradient('to right', input.banner[item.$index].button.background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>
								</form-area>
							</form-group>
			 			</template>

					</form-group>
				</template>
			</index>
			<div class="buttons">
				<div class="button add" data-icon="add" @click="addDynamic()" style="z-index: 0">הוסף חדש</div>
			</div>
		</form-group>

		<form-group style="z-index:2" name="layout">
			<form-area class="layout-select">
				<div class="layout-card" v-for="l in layouts" @click="layout = l.name"
					:class="{active: layout == l.name}" style="width:320px;height:240px;">
					<i v-for="i in l.layout" :style="i"></i>
				</div>
			</form-area>
		</form-group>
		<form-group style="z-index:1" name="gallery" v-if="layout == 'slider'">
			<form-toggle name="arrows" label="חצים" v-model="input.visual.arrows"  />
			<form-toggle name="dots" label="נקודות" v-model="input.visual.dots"  />
			<form-select name="interval" label="זמן החלפת תמונות" v-model="input.visual.interval" :options="[
					{ value: '1', label: 'מהיר' },
					{ value: '3', label: 'בינוני' },
					{ value: '6', label: 'איטי' },
				]"/>
		</form-group>


		<form-group style="z-index:2" name="height">
			<form-toggle name="height-toggle" label="גובה דיפולט" v-model="height" />
			<form-numbers name="height" label="גובה" v-if="! height" v-model="input.settings.height" clear point />
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { makeHash, hexToRgb } from '@/bootstrap/helpers';

export default {
	title: "באנר",
	defaults: {
		banner: [{
			position: 'center'
		}],
		visual: {
			layout: 'default',
			position: 'center'
		},
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return {
			editableBox: null,
			colors: [
			'#FFFFFF', '#EEF2F6', '#E3E8EF', '#CDD5DF', '#A3ADBD', '#758093', '#4C5565', '#2F3746', '#1B202B', 'black',
			'#FDF5F5', '#F7D8D9', '#F1B5B4', '#EA8886', '#E26F6C', '#D24D4A', '#B43F3B', '#8D3533', '#6A302E', 'red',
			'#FEFAF1', '#FAEBCE', '#F4D299', '#EAAE6A', '#DF8D4F', '#CE703D', '#B15B36', '#8F472F', '#713827', 'orange',
			'#FFFFF2', '#FEFAC8', '#F8EE9B', '#F2DF7A', '#E6C869', '#CD9E4E', '#AD7A3B', '#8E5C2C', '#6C4320', 'yellow',
			'#F3FFF5', '#D0F3D8', '#ACE2B9', '#86CF98', '#6BB780', '#599D6F', '#4A815E', '#3A654C', '#315340', 'green',
			'#EBFEFA', '#C2F3EA', '#9BE3D9', '#77CDC4', '#60B0AA', '#529593', '#447879', '#385D60', '#2F4D51', 'teal',
			'#EDF8FE', '#C6E2F5', '#9ECDEE', '#78B3E6', '#5B9AD8', '#4A84C5', '#3E6EA9', '#36537C', '#2F4461', 'blue',
			'#EDF4FE', '#C7DBFA', '#A8C0F3', '#859FEC', '#6A83E0', '#5C6DCE', '#4E58B6', '#424589', '#3B3867', 'indigo',
			'#F9F5FE', '#E6DBF9', '#D2BFF4', '#B19AEB', '#9981E0', '#7A62CB', '#6550B7', '#514393', '#413874', 'purple',
			'#FDF5F7', '#F7D8E2', '#F0BACD', '#E68FB2', '#DB70A4', '#C3508A', '#A8427D', '#89346A', '#662C56', 'pink',
			]
		}
	},
	mounted(){
		if(this.input.banner.length == 1 && ! this.input.banner[0].image) this.editableBox = 0;
	},
	methods: {
		addDynamic(){
			this.input.banner.push({title: '', titleColor: '#000000', position: 'center'});

			this.editableBox = (this.input.banner.length - 1 );
		},
		removeDynamic(index){
			if(this.editableBox == index) this.editableBox = null;

			this.input.banner.splice(index, 1);
		},
		toggleTitle(key){
			if(! this.titles[key]){

			this.$delete(this.input.banner[key], 'titleColor');
			return this.$delete(this.input.banner[key], 'title');
			}

			this.$set(this.input.banner[key], 'title', '');
			this.$set(this.input.banner[key], 'titleColor', '#000000');
		},
		toggleIcon(key){
			if(! this.icons[key]) return this.$delete(this.input.banner[key], 'icon');

			this.$set(this.input.banner[key], 'icon', '');
		},
		toggleLink(key){
			if(! this.links[key]) return this.$delete(this.input.banner[key], 'link');

			this.$set(this.input.banner[key], 'link', {type: 'home'});
		},
		toggleButton(key){
			if(! this.buttons[key]) return this.$delete(this.input.banner[key], 'button');

			this.$set(this.input.banner[key], 'button', {text: '', color: '', background: {type: 'full', colors: [{color: '#ffffff', opacity: '100'}]}});
		},
		toggleWrapper(key){
			if(! this.wrappers[key]) return this.$delete(this.input.banner[key], 'wrapper');

			this.$set(this.input.banner[key], 'wrapper', true);
		},
		toggleContent(key){
			if(! this.content[key]) return this.$delete(this.input.banner[key], 'content');

			this.$set(this.input.banner[key], 'content', '');
		},
		toggleBackground(key){
			if(! this.background[key]) return this.$delete(this.input.banner[key], 'background');

			this.$set(this.input.banner[key], 'background', {type: 'full', colors: [{color: '#ffffff', opacity: '100'}]});
		},
		setImage(key){
			this.$set(this.input.banner[key], 'image', {
				file: this.images[key][0],
				source: URL.createObjectURL(this.images[key][0]),
				title: ''
			});
		},
		removeImage(key){
			this.$delete(this.input.banner[key], 'image');
		},
		bindFiles(data){
			let files = {};

			data.banner.forEach(box => {
				if(! (box.image && box.image.file)) return;

				let key = makeHash();

				files[key] = box.image.file;

				box.image._file = key;
			});

			return files;
		},
		sortElement(sort){
			this.input.banner.sort((a, b) => a.__sort - b.__sort);
		},
		toggleBox(key){
			if(key == this.editableBox) return this.editableBox = null;

			this.editableBox = key;
		},
		toggleBackgroundType(type, key){
			let colors = this.input.banner[key].background.colors;

			this.$set(this.input.banner[key].background, 'type', type);

			if(type == 'full') return this.$set(this.input.banner[key].background, 'colors', [colors[0]]);

			if(colors.length == 1) colors.push({color: '#000000', opacity: '100'});

			this.$set(this.input.banner[key].background, 'colors', colors);
		},
		toggleButtonBackgroundType(type, key){
			let colors = this.input.banner[key].button.background.colors;

			this.$set(this.input.banner[key].button.background, 'type', type);

			if(type == 'full') return this.$set(this.input.banner[key].button.background, 'colors', [colors[0]]);

			if(colors.length == 1) colors.push({color: '#000000', opacity: '100'});

			this.$set(this.input.banner[key].button.background, 'colors', colors);
		},
		linearGradient(type, options){
			let color_a = hexToRgb(options[0].color),
				color_b = hexToRgb(options[1] ? options[1].color : '#ffffff');

			return `linear-gradient(${type}, rgba(${color_a.r}, ${color_a.g}, ${color_a.b}, ${options[0].opacity/100}),  rgba(${color_b.r}, ${color_b.g}, ${color_b.b}, ${options[1] ? options[1].opacity/100 : '0'}))`
		}
	},
	computed: {
		icons() {
			return this.input.banner.map(item => item.icon != undefined);
		},
		links(){
			return this.input.banner.map(item => item.link != undefined);
		},
		titles(){
			return this.input.banner.map(item => item.title != undefined);
		},
		titleColor(){
			return this.input.banner.map(item => item.titleColor != undefined);
		},
		images(){
			return this.input.banner.map(item => item.image ? [item.image] : []);
		},
		buttons(){
			return this.input.banner.map(item => item.button != undefined);
		},
		content(){
			return this.input.banner.map(item => item.content != undefined);
		},
		background(){
			return this.input.banner.map(item => item.background != undefined);
		},
		wrappers(){
			return this.input.banner.map(item => item.wrapper != undefined);
		},
		height: {
			get(){
				return this.input.settings.height == undefined;
			},
			set(value){
				if(! value) return this.$set(this.input.settings, 'height', '');

				this.$delete(this.input.settings, 'height');
			}
		},
		layout:{
			get(){
				return this.input.visual.layout;
			},
			set(value){
				let layout = { layout: value, position: this.input.visual.position }

				if(value == 'slider'){
					layout.arrows = true;
					layout.dots = true;
					layout.interval = '3';
					layout.position = 'center';
				}

				this.$set(this.input, 'visual', layout);
			}
		},
		layouts(){
			return [
				{
					name: 'default',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '220px', width: '140px',
							top: '10px', left: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						},
						{
							height: '220px', width: '140px',
							top: '10px', right: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						}
					]
				},
				{
					name: 'slider',
					layout: [
						{top: 0, left: 0, bottom: 0, right: 0, opacity: 0.2},
						{
							height: '220px',
							top: '10px', left: '10px', right: '10px', bottom: '10px',
							zIndex: 2, opacity: 1
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '120px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '150px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							bottom: '60px', left: '180px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.dots ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							top: '100px', left: '20px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.arrows ? 1 : 0)
						},
						{
							width: '20px', height: '20px',
							top: '100px', right: '20px',
							background: 'white',
							zIndex: 2, opacity: (this.input.visual.arrows ? 1 : 0)
						}
					]
				}
			]
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
