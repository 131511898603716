<template>
	<div class="layout products" :class="{ loading }">

		<div class="top">
			<div class="title">ניהול מוצרים</div>
			<div class="actions">
				<div class="button" data-icon="system_update_alt" @click="goTo('inventory/stocks')">עדכן מלאי</div>
				<!-- <div class="uploadList">
					<div class="button" data-icon="add" @click="chooseFile">טען מוצרים</div>
					<form-file name="file" accept=".xlsx, .xls, .csv" label="קובץ" class="uploadFile" v-model="file" ref="uploadFile" @change="uploadFile"/>
				</div> -->
				<div class="button" data-icon="add" @click="goTo('inventory/products/create')">הוספה</div>
			</div>
		</div>

		<div class="index-filters" v-if="items">
			<index-search :action="(value) => $refs.index.filter({ id: 'search', method: 'like', in: ['title', 'sku'], value })" placeholder="חיפוש לפי שם מוצר או מק״ט" />
			<index-filter label="פעיל"
				:options="[
					{value: null, label: 'הכל'},
					{value: 1, label: 'פעיל בלבד'},
					{value: 0, label: 'לא פעיל'}
				]"
				:action="(value) => $refs.index.filter({ id: 'active', method: 'eq', in: ['active'], value })"
			/>
			<index-filter v-if="categories" label="לפי קטגוריה" :options="categoriesOptions"
				:action="(value) => $refs.index.filter({ id: 'categories', method: 'like', in: ['_categories'], value })"
			/>
			<index-filter v-if="items" label="לפי מחיר" :options="pricesOptions"
				:action="(value) => $refs.index.filter({ id: 'prices', method: 'lte', in: ['price'], value })"
			/>
		</div>

		<index ref="index" :data="items" v-if="items">
			<div slot="header" slot-scope="items" class="table header">
				<div>פרטי מוצר</div>
				<div>מחיר בסיס</div>
				<div>מצב מלאי</div>
				<div></div>
			</div>

			<div class="table" slot="index" slot-scope="item"
				:class="{ hide: item.$filtered }"
				>
				<div class="title">
					<div class="image" :style="`background-image: url('${item.images[0].source}')`" v-if="item.images.length"></div>
					<span>{{ item.title }}</span>
					<span>{{ item.sku }}</span>
				</div>
				<div class="price">
					<div v-if="item.price">₪{{ item.price }}</div>
				</div>
				<div class="stock">{{ ! item.quantity ? 'לא קיים במלאי' : (item.quantity === true ? 'קיים במלאי' : item.quantity + ' במלאי') }}</div>

				<div class="actions">
					<form-toggle name="active" label="" v-model="items[item.$index].active" @toggle="toggle(item)"/>
					<div class="button" data-icon="create" @click="goTo('inventory/products/edit', item._id)">ערוך</div>
				</div>
			</div>
		</index>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			items: null,
			categories: null,
			file: null
		}
	},
	mounted(){

		this.getData();
		this.getCategories();
	},
	methods: {
		getCategories(){
			this.$models.inventory.category.options(this.$route.params.store)
			.then((res) => {
				this.categories = res.data;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		getData(){
			this.$models.inventory.product.query(this.$route.params.store, {query:{active: false}})
			.then((res) => {
				this.items = res.data.map(product => {
					product._categories = product.categories ? product.categories.join(',') : '';
					return product;
				});
				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		goTo(name, product = null){
			if(product)
				return this.$router.push({ name, params: {store: this.$route.params.store, product} }).catch(() => {});

			this.$router.push({ name }).catch(() => {});
		},
		toggle(product){
			this.$models.inventory.product[product.active ? 'remove' : 'restore'](this.$route.params.store, product._id)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		chooseFile(event){
			this.$refs.uploadFile.$refs.input.click()
		},
		uploadFile(event){
			this.$models.inventory.product.file(this.$route.params.store, {file: this.file[0]})
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});

				this.getData();
			}).catch(error => {
				console.log(error);
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		}
	},
	computed: {
		categoriesOptions(){
			let options = [{value: null, label: 'הצג הכל'}],
				flat = (category) => {
					options.push({value: category.value, label: category.label });

					if(category.sub) category.sub.forEach(sub => flat(sub))
				}

			this.categories.forEach(category => flat(category));

			return options;
		},
		pricesOptions(){
			let options = this.items.map(product => ({value: product.price, label: 'עד ₪'+product.price+''}));
			options.unshift({value: null, label: 'הצג הכל'});
			return options;
		}
	}
}
</script>
