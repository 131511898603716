<template>
	<div class="layout dictionary" :class="{ loading }">
		<div class="top fixed">
			<div class="title">
				<router-link class="back" :to="{ name: 'master/dictionary'}" />
				<span>{{$route.params.id ? 'עריכת' : 'הוספת'}}</span>
			</div>
			<div class="actions">
				<div class="button" data-icon="save" @click="submit">שמור</div>
			</div>
		</div>

		<form-vue ref="form">
			<form-group name="general" title="כללי" style="z-index: 5">
				<form-input name="name" label="מפתח" v-model="model.key" />
				<form-select name="section" label="אזור" v-model="model.section"
					:options="sections"/>
				<form-area />
			</form-group>
			<form-group name="details" title="וריאציות" style="z-index: 3">
				<form-checkbox name="variations" label="בחירת וריאציות" v-model="model.variations"
				:options="variations"/>
			</form-group>
			<form-group name="parameters" title="פרמטרים" style="z-index: 2">
				<div class="params" v-if="model.params.length">
					<index :data="model.params">
						<div class="parameter" slot="index" slot-scope="item">
							<form-input name="key" label="מפתח" v-model="model.params[item.$index].key" />
							<form-input name="label" label="תיאור" v-model="model.params[item.$index].label" />
							<form-area>
								<div class="button error" data-icon="delete" @click="removeParameter(item.$index)" style="margin-top: 28px"></div>
							</form-area>
						</div>
					</index>
				</div>
				<div class="button" data-icon="add" @click="addParameter">הוסף</div>
			</form-group>
			<form-group name="dictionary" title="תרגום" style="z-index: 1" v-if="activeVariations.length">
				<div class="index dictionary" >
					<div class="table" v-for="lang in lang_codes">
						<div class="title">{{ lang.label }}</div>
						<div class="words" v-for="vars in variations">
							<form-textarea name="name" :label="vars.label"
								v-if="model.dictionary[lang.value] && model.variations.includes(vars.value)"
								v-model="model.dictionary[lang.value][vars.value]"
								:autocomplete="model.params || []"
							/>
						</div>
					</div>
				</div>
			</form-group>
		</form-vue>
	</div>
</template>

<script>
export default {
	data(){
		return {
			loading: true,
			dictionary: null,
			model: this.$models.master.dictionary.model(),
			variations: this.$models.master.dictionary.variations(),
			lang_codes: this.$models.master.dictionary.lang_codes(),
			sections: this.$models.master.dictionary.sections()
		}
	},
	watch:{
		'model.variations': 'setDictionaryData'
	},
	mounted(){


		if(this.$route.params.id){
			this.getData();
		}else{
			this.setDictionaryData();
			this.loading = false;
		}
	},
	methods:{
		getData(){
			this.$models.master.dictionary.get(this.$route.params.id)
			.then(res => {
				this.dictionary = JSON.parse(JSON.stringify(res.data.dictionary));

				this.model = res.data;
				if(this.dictionary) this.$set(this.model, 'dictionary', JSON.parse(JSON.stringify(this.dictionary)));
				this.setDictionaryData();

				this.loading = false;
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
			});
		},
		submit(){
			this.loading = true;

			this.$models.master.dictionary.update(this.model).then((res) => {
				VueAlerts.flash({
					text: 'עודכן בהצלחה',
					closeable: false,
					icon: 'done'
				});

				this.loading = false;

				if(! this.$route.params.id){
					this.$router.push({ name: 'master/dictionary'});
				}
			}).catch(error => {
				VueAlerts.flash({
					text: 'אירע שגיאה',
					closeable: false
				});
				this.loading = false;
			});
		},
		addParameter(){
			this.model.params.push({key: '', label: ''});
		},
		removeParameter(index){
			this.model.params.splice(index, 1);
		},
		setDictionaryData(){
			this.lang_codes.forEach(code => {
				if(! this.model.dictionary[code.value]){
					this.$set(this.model.dictionary, code.value, {});
				}

				this.variations.forEach(variation => {
					if(! this.model.variations.includes(variation.value)) {
						return this.$delete(this.model.dictionary[code.value], variation.value);
					}

					let value = this.dictionary && this.dictionary[code.value] && this.dictionary[code.value][variation.value] ? this.dictionary[code.value][variation.value] : "";

					this.$set(this.model.dictionary[code.value], variation.value, value);
				})
			})
		}
	},
	computed: {
		activeVariations(){
			if(! this.model.variations.length) return [];

			return this.variations.filter(variation => (this.model.variations.includes(variation.value) ? variation : null));
		}
	}
}
</script>
