<template>
	<form-vue preventHash :tabs="nested ? null : {
		'general': { label: 'תפריט', icon: 'receipt', groups: ['general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}" >
		<form-group style="z-index:1" name="general">
			<index :data="input.menu" sort-by="id" @sort="sort" class="fs-menu-vue" :class="{ nested }">
				<template slot="index" slot-scope="item">
					<div class="table" :style="`z-index:${(input.menu.length - item.$index)}`" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" v-if="active == null"></div>
						<div class="info">{{ item.title }}</div>
						<div class="actions">
							<div class="button dark" data-icon="add" @click="addSub(item.$index)" v-if="! (item.menu && item.menu.length)">הוסף תת תפריט</div>
							<div class="button error" data-icon="delete" @click="remove(item.$index)"></div>
							<div class="button" :data-icon="active == item.$index ? 'close' : 'create'"
								@click="edit(item.$index)"></div>
							</div>
						</div>
					</div>
					<form-vue class="menu-edit" v-if="active == item.$index">
						<form-area style="vertical-align:top;z-index:2">
							<form-group style="z-index:3">
								<form-input name="title" label="כותרת" v-model="input.menu[item.$index].title" />
							</form-group>
							<form-group style="z-index:2">
								<form-area>
									<link-vue class="form-link" wrapper="form-group" v-model="item.link" />
								</form-area>
							</form-group>
							<form-group style="z-index:1">
								<form-toggle name="link" label="הוסף אייקון" v-model="icons[item.$index]" @toggle="toggleIcon(item.$index)" />
								<form-input name="icon" label="אייקון" v-if="icons[item.$index]" v-model="input.menu[item.$index].icon" />
							</form-group>
						</form-area>
						<form-area style="vertical-align:top;z-index:1">
							<form-group style="z-index:6">
								<form-toggle name="bold" label="בולד" v-model="bold[item.$index]" @toggle="toggleBold(item.$index)" />
							</form-group>

							<form-group style="z-index:5">
								<form-toggle name="ifsize" label="גודל פונט" v-model="fontSize[item.$index]" @toggle="toggleFontSize(item.$index)" />

								<form-select v-if="fontSize[item.$index]" name="size" label="בחר גודל פונט" v-model="input.menu[item.$index].size" :options="[{value: 'big', label: 'גדול'},{value: 'medium', label: 'בינוני'},{value: 'small', label: 'קטן'}]" />
							</form-group>

							<form-group style="z-index:4">
								<form-toggle name="background" label="צבע פונט" v-model="fontColor[item.$index]" @toggle="toggleFontColor(item.$index)" />

								<form-colors  v-if="fontColor[item.$index]"  name="color" label="צבע פונט" v-model="input.menu[item.$index].color" />
							</form-group>

							<form-group style="z-index:3">
								<form-toggle name="background" label="צבע רקע" v-model="background[item.$index]" @toggle="toggleBackground(item.$index)" />
							</form-group>
							<form-group v-if="background[item.$index]" style="z-index:2">
								<form-colors name="color" label="צבע רקע" v-model="input.menu[item.$index].background.colors[0].color"></form-colors>

								<form-select name="opacity" label="שקיפות" v-model="input.menu[item.$index].background.colors[0].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />

								<template v-if="input.menu[item.$index].background.type != 'full'">
									<form-colors name="color" label="צבע רקע" v-model="input.menu[item.$index].background.colors[1].color"></form-colors>

									<form-select  name="opacity" label="שקיפות" v-model="input.menu[item.$index].background.colors[1].opacity" :options="[{value: '100', label: '100%'},{value: '90', label: '90%'},{value: '80', label: '80%'},{value: '70', label: '70%'},{value: '60', label: '60%'},{value: '50', label: '50%'},{value: '40', label: '40%'},{value: '30', label: '30%'},{value: '20', label: '20%'},{value: '10', label: '10%'},{value: '0', label: '0%'}]" />
								</template>
							</form-group>
							<form-group v-if="background[item.$index]" style="z-index:1">
								<form-area class="layout-select">
									<div class="layout-card"
									@click="toggleBackgroundType('full', item.$index)"
									:class="{active: input.menu[item.$index].background.type == 'full'}"
									style="width:140px;height:100px;">
										<i :style="`background-color:${input.menu[item.$index].background.colors[0].color};opacity:${input.menu[item.$index].background.colors[0].opacity}%;height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>

									<div class="layout-card"
									@click="toggleBackgroundType('top', item.$index)"
									:class="{active: input.menu[item.$index].background.type == 'top'}"
									style="width:140px;height:100px;">
										<i :style="`background:${linearGradient('to top', input.menu[item.$index].background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>

									<div class="layout-card"
									@click="toggleBackgroundType('rtl', item.$index)"
									:class="{active: input.menu[item.$index].background.type == 'rtl'}"
									style="width:140px;height:100px;">
										<i :style="`background:${linearGradient('to right', input.menu[item.$index].background.colors)};height: 90px; width: 130px; top: 5px; right: 5px; bottom: 5px; z-index: 2;`"></i>
									</div>
								</form-area>
							</form-group>
						</form-area>
					</form-vue>
					<menu-vue nested v-model="item" v-if="item.menu && item.menu.length" :style="`z-index:${(item.menu.length + 2)}`"/>
				</template>
				<div slot="footer" class="button dark add" data-icon="add" @click="add">הוסף</div>
			</index>
		</form-group>
		<form-group style="z-index:1" name="settings" v-if="! nested">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
import { makeHash, hexToRgb } from '@/bootstrap/helpers';

export default {
	name: 'menu-vue',
	title: "תפריט",
	defaults: {
		menu: [{
			id: makeHash(),
			title: "דף הבית",
			link: {
				type: 'home'
			}
		}],
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		nested: { type: Boolean, default: false},
		value: { type: Object, required: true }
	},
	data(){
		return {
			active: null,
			iconsList: this.$models.layout.web.icons()
		}
	},
	methods: {
		add(){
			this.active = null;

			this.input.menu.push({id: makeHash(), title: 'דף הבית', link: { type: 'home' }});
		},
		edit(index){
			if(this.active == index) return this.active = null;

			this.active = index;
		},
		remove(index){
			this.active = null;

			this.input.menu.splice(index, 1);
		},
		addSub(index){
			this.active = null;

			this.$set(this.input.menu[index], 'menu', [{id: makeHash(), title: 'דף הבית', link: { type: 'home' }}]);
		},
		sort(sort){
			this.input.menu.sort((a, b) => a.__sort - b.__sort);
		},
		toggleIcon(key){
			if(! this.icons[key]) return this.$delete(this.input.menu[key], 'icon');

			this.$set(this.input.menu[key], 'icon', '');
		},
		toggleBold(key){
			if(! this.bold[key]) return this.$delete(this.input.menu[key], 'bold');

			this.$set(this.input.menu[key], 'bold', true);
		},
		toggleFontSize(key){
			if(! this.fontSize[key]) return this.$delete(this.input.menu[key], 'size');

			this.$set(this.input.menu[key], 'size', 'medium');
		},
		toggleFontColor(key){
			if(! this.fontColor[key]) return this.$delete(this.input.menu[key], 'color');

			this.$set(this.input.menu[key], 'color', '#000000');
		},
		toggleBackground(key){
			if(! this.background[key]) return this.$delete(this.input.menu[key], 'background');

			this.$set(this.input.menu[key], 'background', {type: 'full', colors: [{color: '#ffffff', opacity: '100'}]});
		},
		toggleBackgroundType(type, key){
			let colors = this.input.menu[key].background.colors;

			this.$set(this.input.menu[key].background, 'type', type);

			if(type == 'full') return this.$set(this.input.menu[key].background, 'colors', [colors[0]]);

			if(colors.length == 1) colors.push({color: '#000000', opacity: '100'});

			this.$set(this.input.menu[key].background, 'colors', colors);
		},
		linearGradient(type, options){
			let color_a = hexToRgb(options[0].color),
				color_b = hexToRgb(options[1] ? options[1].color : '#ffffff');

			return `linear-gradient(${type}, rgba(${color_a.r}, ${color_a.g}, ${color_a.b}, ${options[0].opacity/100}),  rgba(${color_b.r}, ${color_b.g}, ${color_b.b}, ${options[1] ? options[1].opacity/100 : '0'}))`
		},
	},
	computed: {
		icons() {
			return this.input.menu.map(item => item.icon != undefined);
		},
		bold() {
			return this.input.menu.map(item => item.bold != undefined);
		},
		background(){
			return this.input.menu.map(item => item.background != undefined);
		},
		fontColor(){
			return this.input.menu.map(item => item.color != undefined);
		},
		fontSize(){
			return this.input.menu.map(item => item.size != undefined);
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', this.value);
			}
		}
	}
}
</script>
