<template>
	<form-vue preventHash  :tabs="{
		'general': { label: 'צור קשר', icon: 'receipt', groups: ['general'] },
		'settings': { label: 'הגדרות', icon: 'settings', groups: ['settings'] }
		}" class="product-data">
		<form-group style="z-index:1" name="general">

			<index :data="list" sort-by="title" @sort="sortElement">
				<div slot="header" slot-scope="items" class="table header">
					<div class="sort"></div>
					<div class="info">כותרת</div>
					<div class="small">מוצג</div>
					<div class="small">חובה</div>
				</div>
				<template slot="index" slot-scope="item" >
					<div class="table" :draggable="item.$sort.active" @dragover="item.$sort.move" @dragend="item.$sort.end">
						<div class="sort" @mousedown="item.$sort.activate" @mouseup="item.$sort.end" ></div>
						<div class="info">{{ item.title }}</div>
						<form-toggle name="active" label="" v-model="list[item.$index].active" />
						<form-toggle name="required" label="" v-model="list[item.$index].required" v-if=""/>
					</div>
				</template>
			</index>
		</form-group>
		<form-group style="z-index:1" name="settings">
			<form-toggle name="desktop" v-model="input.settings.desktop" label="מוצג בדסקטופ"></form-toggle>
			<form-toggle name="mobile" v-model="input.settings.mobile" label="מוצג במובייל"></form-toggle>
			<form-area></form-area>
		</form-group>
	</form-vue>
</template>

<script>
export default {
	title: "צור קשר",
	defaults: {
		fields: [],
		settings: {
			desktop: true,
			mobile: true
		}
	},
	props: {
		value: { type: Object, required: true }
	},
	data(){
		return {
			fields: [
			{ title: "שם מלא", active: true, required: true, name: 'name' },
			{ title: "טלפון", active: true, required: false, name: 'phone'},
			{ title: "אמייל", active: true, required: true, name: 'email'},
			{ title: "כתובת", active: true, required: false, name: 'address'},
			{ title: "הערות", active: true, required: true, name: 'notes'},
			]
		}
	},
	methods: {
		sortElement(sort){
			this.input.fields.sort((a, b) => a.__sort - b.__sort);
		}
	},
	computed: {
		list(){
			if(! this.input.fields.length) this.input.fields = this.fields;

			return this.input.fields;
		},
		input: {
			get(){
				return this.value
			},
			set(value){
				this.$emit('input', value);
			}
		}
	}
}
</script>
