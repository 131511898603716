<template>
	<div class="support">
		<div class="title">שירות לקוחות</div>
		<div class="messages" v-if="messages.length">
			<div class="message" v-for="message in messages" :class="{system: message.userId}"
			:data-initials="message.initials" :data-date="message.time ">
				{{ message.message }}
			</div>
		</div>
		<div class="form">
			<input ref="input" class="text" contenteditable  v-model="model.support" />
			<div class="submit" @click="update">שלח</div>
		</div>

	</div>
</template>

<script>
import moment from 'moment';

export default {
	props: {
		client: {type: String, required: true},
		order: {type: String}
	},
	data(){
		return {
			loading: true,
			support: [],
			model: {
				support: ''
			},
			user: null
		}
	},
	mounted(){
		this.get();

		this.$ws(`${this.$route.params.store}/support/${this.order ? 'order' : 'client'}/${this.order || this.client}`, this.appendMessage.bind(this));

		this.$index.getUser().then((response) => {
			this.user = response.data.name;
		});

		moment.locale('he');
	},
	methods: {
		get(){
			this.loading = true;
			this.$models.support[this.order ? 'order' : 'contact'].get(this.$route.params.store, (this.order || this.client))
			.then((res) => {
				if(res.response.data) this.support = res.response.data;

				this.loading = false;
			}).catch(({response}) => {
				console.log(response);
				this.loading = false;
			});
		},
		update(){
			if(! this.model.support) return;
			this.loading = true;

			this.$models.support[this.order ? 'order' : 'contact'].update(this.$route.params.store, (this.order || this.client), this.model.support)
			.then((res) => {
				VueAlerts.flash({
					icon: 'done',
					text: 'עודכן בהצלחה'
				});

				this.support = res.response.data;
				this.model.support = '';
				this.loading = false;

			}).catch(({response}) => {
				VueAlerts.flash({
					text: 'אירע שגיאה!'
				});

				this.loading = false;
			});
		},
		appendMessage(message){
			this.loading = true;
			this.support.messages.push(message);
			this.$nextTick(() => this.loading = false);
		}
	},
	computed: {
		messages(){
			if(! (this.support && this.support.messages && this.support.messages.length)) return [];

			let messages = JSON.parse(JSON.stringify(this.support.messages));

			let userName = this.user.split(' ');
			let userInitials = `${userName && userName.length > 1 ? userName.shift().charAt(0) : ''}${userName && userName.length == 1 ? userName.pop().charAt(0) : ''}`;
			let clientInitials = `${this.support && this.support.contact && this.support.contact.first_name ? this.support.contact.first_name.charAt(0) : ''}${this.support && this.support.contact && this.support.contact.last_name ? this.support.contact.last_name.charAt(0) : ''}`;

			return messages.map(message => {
				message.time = moment(message.time).fromNow();
				message.initials = message.userId ? userInitials.toUpperCase() : clientInitials.toUpperCase() ;

				return message;
			}).reverse();
		}
	}
}
</script>
